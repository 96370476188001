import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PLZOrtDropdown } from './PLZOrtDropdown';
import { Laenderdropdown } from './Laenderdropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { KundenService } from '../service/KundenService';
import { Laender } from './Laenderdropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import VerkäuferTemplate from './Verkäufer';
import { MitarbeiterService } from '../service/MitarbeiterService';
import { useLocation } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { UIDService } from '../service/UIDService';
import { FirmenbuchService } from '../service/FirmenbuchService';
import QRCode from 'qrcode';

import CONFIG from 'config';
import { KassenService } from '../service/KassenService';



export default function Kunde(props) {
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunde, setKunde] = useState({});	
	const [prozent, setProzent] = useState(0);	
	const [ansprechperson, setAnsprechperson] = useState({});
	const [abteilungen, setAbteilungen] = useState([]);
	const [filteredAbreilungen, setFilteredAbteilungen] = useState([]);
	const [start, setStart] = useState({});	
	const [ende, setEnde] = useState({});	
	const [sontiges, setSontiges] = useState({});
	const [lockStatus, setLockStatus] = useState(false);	
	const [bearbeiten, setBearbeiten] = useState(false);	
	const [selectedVorwahlErstePersonMobil, setSelectedVorwahlErstePersonMobil] = useState("+43");
	const [erstePersonMobilSelectionStart, setErstePersonMobilSelectionStart] = useState();	
	const [erstePersonMobilValid, setErstePersonMobilValid] = useState(false);
	const [selectedVorwahlErstePersonTelefon, setSelectedVorwahlErstePersonTelefon] = useState("+43"); 	
	const [erstePersonTelefonSelectionStart, setErstePersonTelefonSelectionStart] = useState();
	const [erstePersonTelefonValid, setErstePersonTelefonValid] = useState(false);
	const [erstePersonEmailValid, setErstePersonEmailValid] = useState(true);

	const [selectedVorwahlZweitePersonMobil, setSelectedVorwahlZweitePersonMobil] = useState("+43");
	const [zweitePersonMobilSelectionStart, setZweitePersonMobilSelectionStart] = useState();	
	const [zweitePersonMobilValid, setZweitePersonMobilValid] = useState(false);
	const [selectedVorwahlZweitePersonTelefon, setSelectedVorwahlZweitePersonTelefon] = useState("+43"); 	
	const [zweitePersonTelefonSelectionStart, setZweitePersonTelefonSelectionStart] = useState();
	const [zweitePersonTelefonValid, setZweitePersonTelefonValid] = useState(false);
	const [zweitePersonEmailValid, setZweitePersonEmailValid] = useState(true);
	
	const [selectedVorwahlAnsprechPersonTelefon, setSelectedVorwahlAnsprechPersonTelefon] = useState("+43");
	const [selectedVorwahlAnsprechPersonMobil, setSelectedVorwahlAnsprechPersonMobil] = useState("+43");
	const [ansprechPersonMobilSelectionStart, setAnsprechPersonMobilSeletionStart] = useState();
	const [ansprechPersonTelefonSelectionStart, setAnsprechPersonTelefonSelectionStart] = useState();
	const [ansprechPersonMobilValid, setAnsprechPersonMobilValid] = useState(false);
	const [ansprechPersonTelefonValid, setAnsprechPersonTelefonValid] = useState(false);
	const [ansprechPersonEmailValid, setAnsprechPersonEmailValid] = useState(true);


	const [uidValidationError, setUidValidationError] = useState("notchecked");
	const [uidLoading, setUidLoading] = useState(false);
	const [firmenbuchloading, setFirmenbuchloading] = useState(false);
	const [fnValidationError, setFnValidationError] = useState("notchecked")
	const [AnsprechPartnerDialogVisible, setAnsprechPartnerDialogVisible] = useState(false);

	const [rePlausible, setRePlausible] = useState(true);
	const [preislisteVisible, setPreislisteVisible] = useState(false);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const [produkte, setProdukte] = useState(false);
	const [loading01, setLoading01] = useState(false);
	const [showError, setShowError] = useState(false);
	const [showEingabeWrong, setShowEingabeWrong] = useState(false);
	const [bemerkung, setBemerkung] = useState('');
	const [angebot, setAngebot] = useState({});	
	const [preislisteTemp, setPreislisteTemp] = useState({});	
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [firma, setFirma] = useState(false);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1850);
	const [initialRun, setInitialRun] = useState(false);
	const [showDeleteAnsprechpartner, setShowDeleteAnsprechpartner] = useState(false);
	const [ansprechpartnerDelte, setAnsprechpartnerDelete] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [kassen, setKassen] = useState([]);
	const [showPreiskategorieNichtGesetzt, setShowPreiskategorieNichtGesetzt] = useState(false);

	const [columnVisibility, setColumnVisibility] = useState({
		ID: false,
		ArtNr: false,
		Bezeichnung: true,
		Warengruppe: true,
		Barcode: true,
		Lieferant: true,
		Produzent: true,
		Einheit: false,
	});

	const { id } = useParams()

	const Laendervorwahlen = ["+43", "+42", "+49"]
	
	const location = useLocation();
	const history = useHistory();
	const kundenService = new KundenService();
	const mitarbeiterService = new MitarbeiterService();
	const uidService = new UIDService();
	const firmenbuchService = new FirmenbuchService();
	const kassenService = new KassenService();

	const labelWidth = '10em';
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1850);
	};
	const Anrede = [
		"Herr",
		"Frau",
		"Sonstiges"
    ];
	const PreisMöglichkeiten = [
		"Prozent",
		"VK2",
		"VK3",
		"Preisliste"
	]

	const erstepersonmobilref = useRef(null);
	const erstepersontelefonref = useRef(null);
	const zweitepersonmobilref = useRef(null);
	const zweitepersontelefonref = useRef(null);
	const ansprechpersonmobilref = useRef(null);
	const ansprechpersontelefonref = useRef(null);

	const dt = useRef(null);
	const canvasRef = useRef(null);

	const onSetRePLZOrt = (e) => {
		setKunde({...kunde, RePLZ: e.target.value.PLZ, ReOrt: e.target.value.Ort});
	}
	const onSetRePLZ = (e) => {
		setKunde({...kunde, RePLZ: e.target.value});
	}
	const onSetOrt = (e) => {
		setKunde({...kunde, ReOrt: e.target.value});
	}
	const renderFooter = () => {
		return (
			<div>
				<Button label="Speichern" icon="pi pi-check" onClick={() => setPreislisteVisible(false)} autoFocus />
			</div>
		);
	}
	const onAllKassen = () => {
		const allKassenIDs = kassen.map(kasse => kasse.KassenID);
    	setSelectedKassen(allKassenIDs);
	}
	const renderHeader = () => {
		return (
			<div>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
				</span>
			</div>
		)
	}
	const wertemplate = (row) => {
		return <VerkäuferTemplate 
					MitarbeiterNr={row.MitarbeiterNr} 
					MitarbeiterVorname={row.MitarbeiterVorname}
					MitarbeiterNachname={row.MitarbeiterNachname}
					/>;
	}
	const handleCheckboxChange = (id) => {
		setSelectedKassen((prevSelected) =>
		  prevSelected.includes(id)
			? prevSelected.filter((kassenId) => kassenId !== id)
			: [...prevSelected, id]
		);
	};
	const datumTemplate = (row) => {
		var temp = new Date(row.Datum)

		const year = temp.getFullYear();
		let month = temp.getMonth() + 1;
		if (month < 10){
			month = "0"+month;
		}
		const day = temp.getDate();
		const hour = temp.getHours();
		let minutes = temp.getMinutes();
		if (minutes < 10){
			minutes = "0"+minutes;
		}


		const datestring = `${day}.${month}.${year}  ${hour}:${minutes}`
		return datestring; 
	}
	const statusOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div style={{borderRadius: "50%", backgroundColor: conditioncolor(option)}} className={option === "Gesperrt" ? "pi pi-times-circle" : 
			option === "Aktiv" ? "pi pi-check-circle" : "pi pi-circle-off"}></div>
                <span> {option}</span>
            </div>
        );
    }
	const selectedOptionTemplate = (option, props) => {
		if(option) {
			return (
				<div className="country-item">
                <div style={{borderRadius: "50%", backgroundColor: conditioncolor(option)}} className={option === "Gesperrt" ? "pi pi-times-circle" : 
				option === "Aktiv" ? "pi pi-check-circle" : "pi pi-circle-off"}></div>
                <span> {option}</span>
            </div>
			);
		}
		return (
			<span>
				{props.placeholder}
			</span>
		);
	}
	const conditioncolor = (rowData) => {
		if(rowData === "Gesperrt"){
			return "LightPink"; 
		}
		
		else if (rowData === "Aktiv"){
			return "LightGreen";
		}
		else{
			return "Azure";
		}
	}
	const onAnsprechPartnerEditClick = (data) => {
		data.Mobil = splitTelNummerAndSetSelection(data.Mobil, setSelectedVorwahlAnsprechPersonMobil, Laendervorwahlen);
		data.Telefon = splitTelNummerAndSetSelection(data.Telefon, setSelectedVorwahlAnsprechPersonTelefon, Laendervorwahlen);
		setAnsprechperson(data);
		setAnsprechPartnerDialogVisible(true);
	}

	const onAnsprechPartnerDeleteClick = () => {
		try {
		  let ansprechpartner = kunde.Ansprechpartner.filter(p => p.ID !== ansprechpartnerDelte.ID);

		  let _kunde = { ...kunde, Ansprechpartner: ansprechpartner };
		  setKunde(_kunde);
		  setAnsprechperson({});
		} catch (e) {
		  console.log(e);
		}
	  
		setAnsprechPartnerDialogVisible(false);
	  }

	const onAnsprechPartnerAnlegenClick = (e) => {
		try {
			let ansprechpartner = Array.isArray(kunde.Ansprechpartner) ? [...kunde.Ansprechpartner] : [];
			
			let _ansprechperson = { ...ansprechperson };

			if (_ansprechperson.Telefon !== undefined && _ansprechperson.Telefon !== null && ansprechperson.Telefon.length > 0)
				_ansprechperson.Telefon = selectedVorwahlAnsprechPersonTelefon + ' ' + _ansprechperson.Telefon;
			if (_ansprechperson.Mobil !== undefined && _ansprechperson.Mobil !== null && ansprechperson.Mobil.length > 0) 
				_ansprechperson.Mobil = selectedVorwahlAnsprechPersonMobil + ' ' + _ansprechperson.Mobil;
	
			if (_ansprechperson.ID !== undefined && _ansprechperson.ID !== null) {
				ansprechpartner = ansprechpartner.map(p => p.ID !== _ansprechperson.ID ? p : _ansprechperson);
			} else {
				ansprechpartner.push(_ansprechperson);
			}
	
			let _kunde = { ...kunde, Ansprechpartner: ansprechpartner };
			setKunde(_kunde);
			setAnsprechperson({});
		} catch (e) {
			console.log(e);
		}
		setAnsprechPartnerDialogVisible(false);
	}
	const abteilungAutocomplete = (event) => {
        setTimeout(() => {
            let _filteredAbteilungen;
            if (!event.query.trim().length) {
                _filteredAbteilungen = [...abteilungen];
            }
            else {
                _filteredAbteilungen = abteilungen.filter((a) => {
                    return a.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredAbteilungen(_filteredAbteilungen);
        }, 250);
    }
	const handleEmailChange = (e) => {
		const email = e.target.value;
		setKunde({ ...kunde, Email: email });
	
		setErstePersonEmailValid(emailRegex.test(email));
	};


	useEffect(() => {
		const fetchData = async () => {
			setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Kunden > bearbeiten' : 'Kunden > anlegen'});
			setFirma(location.state?.Firma);
			
			kassenService.getKassen().then(data => {
				setKassen(data);
			})

			setLoading01(true);

			let _derzeitigerBenutzer;
			if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
				_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
				setDerzeitigerBenutzer(_derzeitigerBenutzer);
			}
			else{
				mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.username).then(data => {
					setDerzeitigerBenutzer(data.data);
					_derzeitigerBenutzer = data;
				});
			}

			if (props.action === "bearbeiten") {
				setBearbeiten(true);
				const kunde = await kundenService.getKundeByGuid(id);
				const kundeData = kunde[0];
				
				
				if(kundeData.PreiseArt == "VK2" || kundeData.PreiseArt == "VK3"){
					console.log(kundeData.PreiseArt)
					setLockStatus(true);
				}
		
				setFirma(kundeData.Firma === true);
				setKunde(prevKunde => ({
					...prevKunde,
					Ansprechpartner: kundeData.KundenAnsprechpartner,
					KundenNummer: kundeData.KundenNummer,
					Guid: kundeData.ID,
					Anrede: kundeData.Anrede,
					Titel: kundeData.Titel,
					Vorname: kundeData.Vorname,
					Nachname: kundeData.Nachname,
					Mobil: kundeData.Mobil,
					Telefon: kundeData.Telefon,
					Fax: kundeData.Fax,
					Email: kundeData.Email,
					Anrede2: kundeData.Anrede2,
					Titel2: kundeData.Titel2,
					Vorname2: kundeData.Vorname2,
					Nachname2: kundeData.Nachname2,
					Mobil2: kundeData.Mobil2,
					Telefon2: kundeData.Telefon2,
					Fax2: kundeData.Fax2,
					Email2: kundeData.Email2,
					ReStrasse: kundeData.ReStrasse,
					RePLZ: kundeData.RePLZ,
					ReOrt: kundeData.ReOrt,
					RePerEmail: kundeData.RePerEmail,
					ReEmailAdresse: kundeData.ReEmailAdresse,
					PreiseArt: kundeData.PreiseArt,
					Info: kundeData.Info,
					BlacklistStatus: kundeData.BlacklistStatus,
					Firmenbezeichnung: kundeData.Firmenbezeichnung,
					UID: kundeData.UID,
					FN: kundeData.FN,
					ZVR: kundeData.ZVR,
					MitgliedschaftStart: kundeData.MitgliedschaftStart ? new Date(kundeData.MitgliedschaftStart) : null,
					MitgliedschaftEnde: kundeData.MitgliedschaftEnde ? new Date(kundeData.MitgliedschaftEnde) : null,
					Aktenkundigkeit: kundeData.Aktenkundigkeit ? new Date(kundeData.Aktenkundigkeit) : null,
					ReLand: Laender.find(country => country.code === kundeData.ReLand) || null
				}));

				if(kundeData.Barcode){
					setKunde(prevKunde => ({
						...prevKunde,
						Barcode: kundeData.Barcode
					}));
				}
				else{
					const newCustomerBarcode = await kundenService.getKundenBarcode();
					setKunde(prevKunde => ({
						...prevKunde,
						Barcode: newCustomerBarcode.newCustomerBarcode
					}));
				}

				if (kundeData.MitgliedschaftStart) {
					setStart(new Date(kundeData.MitgliedschaftStart));
				} else {
					setStart(null);
				}
				if (kundeData.MitgliedschaftEnde) {
					setEnde(new Date(kundeData.MitgliedschaftEnde));
				} else {
					setEnde(null);
				}
				if (kundeData.Aktenkundigkeit) {
					setSontiges(new Date(kundeData.Aktenkundigkeit));
				} else {
					setSontiges(null);
				}
				if (kundeData.Prozent) {
					setProzent(kundeData.Prozent);
				} else {
					setProzent(0);
				}


				setPreislisteTemp(kundeData.KundenPreisliste);
				setAngebot(prevAngebot => ({
					...prevAngebot,
					Bemerkungen: kundeData.KundenBemerkungen
				}));

				const allKassenIDs = kundeData.KundenKassa.map(kasse => kasse.KassenID);
    			setSelectedKassen(allKassenIDs);
			}
			else{
				const newCustomerBarcode = await kundenService.getKundenBarcode();
				setKunde(prevKunde => ({
					...prevKunde,
					Barcode: newCustomerBarcode.newCustomerBarcode
				}));
			}


			const newCustomerNumber = await kundenService.getNeueKundennummer();
			setKunde(prevKunde => ({
				...prevKunde,
				KundenNummer: newCustomerNumber.newCustomerNumber
			}));

			const produkteData = await kundenService.getProdukte();
			setProdukte(produkteData);
			setLoading01(false);

		};
		fetchData();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if(!initialRun && produkte && preislisteTemp.length > 0){
			preislisteTemp.forEach(produkt => {
				const preisProdukt = produkte.find(item => item.produkt.ID === produkt.Produkt);
				
				if (preisProdukt) {
					preisProdukt.NeuerPreis = produkt.Preis;
				}
			});	
			setInitialRun(true);		
		}
	},[produkte, preislisteTemp, initialRun])

	useEffect(() => {
		if(kunde.Barcode){
			const Barcode = String(kunde.Barcode);
			QRCode.toCanvas(canvasRef.current, Barcode, (error) => {
				if (error) console.error(error);
			});
		}
	},[kunde])


	function formatPhoneNumber(phoneNumberString, selectedVorwahl) {
		if (phoneNumberString === undefined || phoneNumberString === null || phoneNumberString.length === 0)
			return { status: true, Nummer: ''}

		var cleaned = selectedVorwahl + phoneNumberString.replace(/[^0-9+]/g, '');
 
		if (cleaned[0] === '0' && cleaned[1] === '0' && cleaned.length >= 4)
			cleaned = '+' + cleaned.substring(2);

		if (cleaned.length === 0)
			return { status: false, Nummer: cleaned };
		if (cleaned[0] === '+')
		{
			var match = cleaned.match(/^(\+\d{2})(6\d{2})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(\+\d{2})(\d{4})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: cleaned.substring(selectedVorwahl.length, cleaned.length) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else if (cleaned.length > 2 && cleaned[0] == '0' && cleaned[1] === '0')
		{
			var match = cleaned.match(/^(00\d{2})(6\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(00\d{2})(\d{4})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: formatMatch(match) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else {
			return { status: false, Nummer: formatMatch(match) };
		}
	}

	function onPreiseArtChange(e){
		var temp;
		setKunde({...kunde, PreiseArt: e, BlacklistStatus: "Inaktiv"})
		if(e == "VK2"){
			temp = produkte.filter(e => e.VK2 <= 0)
		}
		else if(e == "VK3"){
			temp = produkte.filter(e => e.VK3 <= 0)
		}

		if(temp && temp.length > 0){
			setShowPreiskategorieNichtGesetzt(true);
			setLockStatus(true);
		}
		else{
			setShowPreiskategorieNichtGesetzt(false);
			setLockStatus(false);
		}
	}

	function splitTelNummerAndSetSelection(nummer, setSelection, options)
	{
		if (nummer  === undefined || nummer === null)
			return '';
		nummer = nummer.trim();
		if (nummer.length >= 3)
		{
			let vorwahl = nummer.substring(0, 3);
			let index = options.find(x => x === vorwahl);
			setSelection(index);
			if (nummer.length === 3)
				return '';
			let n = formatPhoneNumber(nummer.substring(3, nummer.length), index).Nummer;
			if (n === undefined || n === null || n.length === 0)
				return nummer;
			return n;
		}
		else
			return '';
	}

	function formatMatch(_match) {
		if (_match === undefined || _match === null)
			return;
		if (_match.length < 1)
			return;
		
		let _return = '';
		let match = new Array();
		_match.forEach(e => {
			if (e !== undefined && e !== null && e.length > 0)
				match.push(e);
		});
		for (let i = 1; i < match.length - 1; i++) {
			_return += match[i] + ' ';
		}
		_return += match[match.length-1];
		_return = _return.substring(_match[1].length + 1, _return.length);
		return _return;
	}

	function checkZweitePerson() {
		let ZweitePersonStarted = false;
		if(kunde.ZweitePersonAnrede !== undefined && kunde.ZweitePersonAnrede?.length !== 0 && kunde.ZweitePersonAnrede !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonTitel !== undefined && kunde.ZweitePersonTitel?.length !== 0 && kunde.ZweitePersonTitel !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonVorname !== undefined && kunde.ZweitePersonVorname?.length !== 0 && kunde.ZweitePersonVorname !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonNachname !== undefined && kunde.ZweitePersonNachname?.length !== 0 && kunde.ZweitePersonNachname !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonMobil !== undefined && kunde.ZweitePersonMobil?.length !== 0 && kunde.ZweitePersonMobil !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonEmail !== undefined && kunde.ZweitePersonEmail?.length !== 0 && kunde.ZweitePersonEmail !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonAnrede === undefined && kunde.ZweitePersonTitel === undefined && kunde.ZweitePersonVorname === undefined && kunde.ZweitePersonNachname === undefined &&
			kunde.ZweitePersonMobil === undefined && kunde.ZweitePersonEmail === undefined)
			{
				ZweitePersonStarted = false;   	
			}
		return ZweitePersonStarted; 
	}

	function goToDB() {

		if(produkte && kunde.PreiseArt == "Preisliste"){
			const validProdukte = produkte.filter(product => product.NeuerPreis != null && product.NeuerPreis !== "").map(product => ({Produkt: product.produkt.ID, Preis: product.NeuerPreis}));

			kunde.Preislisten = validProdukte;
		}
		if(kunde.PreiseArt == "Prozent"){
			kunde.Prozent = prozent;
		}


		if(angebot.Bemerkungen && angebot.Bemerkungen.length > 0){
			kunde.Bemerkungen = angebot.Bemerkungen;
		}

		if(firma == true){
			kunde.Firma = true;
		}
		else{
			kunde.Firma = false;
		}		

		if(selectedKassen.length > 0){
			kunde.Kassen = selectedKassen;
		}
		
		
		if(bearbeiten == true){
			kundenService.update(kunde).then(data =>{
				if(data.status == 200){
					history.goBack()
				}
				else{
					setShowError(true);
				}
			});
		}
		else if(bearbeiten == false){
			kundenService.new(kunde).then(data => {
				if(data.status == 200){
					history.goBack()
				}
				else{
					setShowError(true);
				}
			});
		} 
	}

	function checkEingabe() {
		if(kunde){
			if(
				kunde.KundenNummer &&
				kunde.MitgliedschaftStart &&
				kunde.MitgliedschaftEnde &&
				kunde.Anrede &&
				kunde.Vorname &&
				kunde.Nachname &&
				kunde.Email &&
				kunde.PreiseArt
			){
				if(kunde.RePerEmail){
					if(kunde.ReEmailAdresse){
						goToDB();
					}
					else{
						setShowEingabeWrong(true);
					}
				}
				else{
					goToDB();
				}
			}
			else{
				setShowEingabeWrong(true);
			}
		}
		else{
			setShowEingabeWrong(true);
		}
	}

	return (
		<React.Fragment>
			<div className="p-component grid" 
				style={(loading01) ? { position: 'relative', filter: 'blur(2px)', backgroundColor: 'rgba(128, 128, 128, 0.5)', zIndex: 1,
					pointerEvents: 'none' } : {}}>
				{ (loading01) && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(48% - 1rem)", top: "calc(50% - 1rem)", zIndex: 9999}}/> }
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={checkEingabe} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} style={{marginRight: '.5em'}} />
					</div>
				</div>
				<div className="col-4" style={{marginTop: isLargeScreen ? "" : "3rem"}}>
					<div className="field grid">
						<label htmlFor="KundenNr" className="col-fixed" style={{width: "calc("+labelWidth+"*1.2)"}}>Kunden-Nr. *</label>
						<div className="col">
							<InputText id="KundenNr" value={kunde.KundenNummer} style={{width: "calc("+labelWidth+"/2.3)"}} disabled/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="KundenGUID" className="col-fixed" style={{width: "calc("+labelWidth+"*1.2)"}}>Kunden-GUID</label>
						<div className="col">
							<InputText id="KundenGUID" value={kunde.Guid} style={{width: "calc("+labelWidth+"*2)"}} disabled/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="MitgliedschaftStart" className="col-fixed" style={{width: "calc("+labelWidth+"*1.2)"}}>Mitgliedschaft Start: *</label>
						<div className="col">
							<Calendar 
								id="MitgliedschaftStart" 
								value={start}
								style={{width: labelWidth}} 
								showIcon={true} 
								dateFormat="dd.mm.yy"
								onChange={e => {
									setStart(e.target.value);
									const selectedDate = e.target.value;
									let date;
									if (selectedDate instanceof Date) {
										date = selectedDate;
									} else {
										const dateParts = selectedDate.split('.');
										date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
									}
									const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();

									setKunde({ ...kunde, MitgliedschaftStart: formattedDate });
								}}	
								/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="MitgliedschaftEnde" className="col-fixed" style={{width: "calc("+labelWidth+"*1.2)"}}>Mitgliedschaft Ende: *</label>
						<div className="col">
							<Calendar 
								id="MitgliedschaftEnde" 
								value={ende} 
								style={{width: labelWidth}}
								showIcon={true} 
								dateFormat="dd.mm.yy"
								onChange={e => {
									setEnde(e.target.value);

									const selectedDate = e.target.value;
									let date;
									if (selectedDate instanceof Date) {
										date = selectedDate;
									} else {
										const dateParts = selectedDate.split('.');
										date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
									}
									const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();

									setKunde({ ...kunde, MitgliedschaftEnde: formattedDate });
								}}	

								/>
						</div>
					</div>					
				</div>
				<div className="col-6">
					<canvas ref={canvasRef} style={{marginTop: isLargeScreen ? "1.5rem" : "-1rem", marginLeft: isLargeScreen ? "" : "3.5rem"}}/>
				</div>

				<div className="col-12">
					<Accordion activeIndex={firma ? 1 : 0}>
						<AccordionTab header="Allgemein" hidden={firma}>
							<div className="grid">
								<div className={isLargeScreen ? 'col-6' : 'col-12'}>
									<h1>1. Person</h1>
									<div className="field grid">
										<label htmlFor="Anrede" className="col-fixed" style={{width: labelWidth}}>Anrede *</label>
										<div className="col">
											<Dropdown value={kunde.Anrede} options={Anrede} id="Anrede" onChange={e => setKunde({...kunde, Anrede: e.target.value})} showClear className={kunde.Anrede === undefined ? "p-invalid" : ""}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Titel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
										<div className="col">
											<InputText value={kunde.Titel} id="Titel" onChange={e => setKunde({...kunde, Titel: e.target.value})}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Vorname" className="col-fixed" style={{width: labelWidth}}>Vorname *</label>
										<div className="col">
											<InputText value={kunde.Vorname} id="Vorname"
											onChange={e => setKunde({...kunde, Vorname: e.target.value})}
											className={kunde.Vorname?.length === 0 || kunde.Vorname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Nachname" className="col-fixed" style={{width: labelWidth}}>Nachname *</label>
										<div className="col">
											<InputText value={kunde.Nachname} id="Nachname"
											onChange={e => setKunde({...kunde, Nachname: e.target.value})}
											className={kunde.Nachname?.length === 0 || kunde.Nachname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Mobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonMobil(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Mobil} id="Mobil"
														ref={erstepersonmobilref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonMobil).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonMobilSelectionStart(e.target.selectionStart);
															setKunde({...kunde, Mobil: _nummer});
														}}
														inputStyle={{maxWidth:"10rem"}}
														style={{maxWidth:"10rem"}}
														className={erstePersonMobilValid === false ? 'p-invalid' : ''}
													/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 664 111 11 11</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1'>Bsp. +43 664 111 11 11</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Telefon" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Telefon (Fax DW)</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonTelefon(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Telefon}
														ref={erstepersontelefonref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonTelefon).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonTelefonSelectionStart(e.target.selectionStart);
															setKunde({...kunde, Telefon: _nummer});
														}}
														inputStyle={{maxWidth:"10rem"}}
														style={{maxWidth:"10rem"}}
														className={erstePersonTelefonValid === false ? 'p-invalid' : ''}
														/>
													<InputText value={kunde.Fax}
														onChange={e => setKunde({...kunde, Fax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
														/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 2522 111 11 11 (20)</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Email" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>E-Mail *</label>
										<div className="col">
											<InputText value={kunde.Email}
												id="Email"
												onChange={e => handleEmailChange(e)}
												className={erstePersonEmailValid === false ? 'p-invalid' : ''}
												/>
											<span className='ml-1' hidden={!isLargeScreen}>Bsp. m.mustermann@gmx.at</span>
										</div>
										{!isLargeScreen &&(
											<div className="col">
											<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
											</div>
										)}
									</div>
								</div>

								<div className={isLargeScreen ? 'col-6' : 'col-12'}>
									<h1>2. Person</h1>
									<div className="field grid">
										<label htmlFor="Anrede2" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
										<div className="col">
											<Dropdown value={kunde.Anrede2} options={Anrede} id="Anrede2" onChange={e => setKunde({...kunde, Anrede2: e.target.value})}
											className={(kunde.Anrede2 === undefined || kunde.Anrede2?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}
											showClear/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Titel2" className="col-fixed" style={{width: labelWidth}}>Titel</label>
										<div className="col">
											<InputText value={kunde.Titel2} id="Titel2" onChange={e => setKunde({...kunde, Titel2: e.target.value})}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Vorname2" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
										<div className="col">
											<InputText value={kunde.Vorname2} id="Vorname2" onChange={e => setKunde({...kunde, Vorname2: e.target.value})}
											className={(kunde.Vorname2 === undefined || kunde.Vorname2?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Nachname2" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
										<div className="col">
											<InputText value={kunde.Nachname2} id="Nachname2" onChange={e => setKunde({...kunde, Nachname2: e.target.value})}
											className={(kunde.Nachname2 === undefined || kunde.Nachname2?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Mobil2" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlZweitePersonMobil} style={{marginRight: "10px"}} options={["+43", "+42", "+49"]} onChange={e => setSelectedVorwahlZweitePersonMobil(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Mobil2} id="Mobil2"
													ref={zweitepersonmobilref}
													onChange={e => {
														let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlZweitePersonMobil).Nummer;
														if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
															setZweitePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
														else
															setZweitePersonMobilSelectionStart(e.target.selectionStart);
														setKunde({...kunde, Mobil2: _nummer});
													}}
													inputStyle={{maxWidth:"10rem"}}
													style={{maxWidth:"10rem"}}
													className={(kunde.ZweitePersonMobil === undefined || zweitePersonMobilValid === false) && checkZweitePerson() && (zweitePersonMobilValid == false && zweitePersonTelefonValid == false) ? 'p-invalid' : ''}
													/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 664 111 11 11</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1'>Bsp. +43 664 111 11 11</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Telefon2" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Telefon (Fax DW)</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlZweitePersonTelefon} style={{marginRight: "10px"}} options={["+43", "+42", "+49"]} onChange={e => setSelectedVorwahlZweitePersonTelefon(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Telefon2} id="Telefon2"
														ref={zweitepersontelefonref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlZweitePersonTelefon).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setZweitePersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setZweitePersonTelefonSelectionStart(e.target.selectionStart);
															setKunde({...kunde, Telefon2: _nummer});
														}}	
														inputStyle={{maxWidth:"10rem"}}
														style={{maxWidth:"10rem"}}											
														/>
													<InputText value={kunde.Fax2} id="Fax2"
														onChange={e => setKunde({...kunde, Fax2: e.target.value})} style={{marginLeft: "10px", width: "40px"}}
														/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 2522 111 11 11 (20)</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1' >Bsp. +43 2522 111 11 11 (20)</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Email2" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>E-Mail</label>
										<div className="col">
											<InputText value={kunde.Email2} id="Email2" onChange={e => setKunde({...kunde, Email2: e.target.value})}
												className={zweitePersonEmailValid === false ? 'p-invalid' : ''}
												/>
											<span className='ml-1' hidden={!isLargeScreen}>Bsp. m.mustermann@gmx.at</span>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
											</div>
										)}
									</div>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Allgemein" hidden={!firma}>
							<div className="grid">
								<div className={isLargeScreen ? 'col-6' : 'col-12'}>
									<h1>Zu Handen</h1>
									<div className="field grid">
										<label htmlFor="Anrede" className="col-fixed" style={{width: labelWidth}}>Anrede *</label>
										<div className="col">
											<Dropdown value={kunde.Anrede} options={Anrede} id="Anrede" onChange={e => setKunde({...kunde, Anrede: e.target.value})} showClear className={kunde.Anrede === undefined ? "p-invalid" : ""}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Titel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
										<div className="col">
											<InputText value={kunde.Titel} id="Titel" onChange={e => setKunde({...kunde, Titel: e.target.value})}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Vorname" className="col-fixed" style={{width: labelWidth}}>Vorname *</label>
										<div className="col">
											<InputText value={kunde.Vorname} id="Vorname"
											onChange={e => setKunde({...kunde, Vorname: e.target.value})}
											className={kunde.Vorname?.length === 0 || kunde.Vorname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Nachname" className="col-fixed" style={{width: labelWidth}}>Nachname *</label>
										<div className="col">
											<InputText value={kunde.Nachname} id="Nachname"
											onChange={e => setKunde({...kunde, Nachname: e.target.value})}
											className={kunde.Nachname?.length === 0 || kunde.Nachname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Mobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonMobil(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Mobil} id="Mobil"
														ref={erstepersonmobilref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonMobil).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonMobilSelectionStart(e.target.selectionStart);
															setKunde({...kunde, Mobil: _nummer});
														}}
														inputStyle={{maxWidth:"10rem"}}
														style={{maxWidth:"10rem"}}
														className={erstePersonMobilValid === false ? 'p-invalid' : ''}
													/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 664 111 11 11</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1' >Bsp. +43 2522 111 11 11 (20)</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Telefon" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Telefon (Fax DW)</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonTelefon(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.Telefon}
														ref={erstepersontelefonref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonTelefon).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonTelefonSelectionStart(e.target.selectionStart);
															setKunde({...kunde, Telefon: _nummer});
														}}
														inputStyle={{maxWidth:"10rem"}}
														style={{maxWidth:"10rem"}}
														className={erstePersonTelefonValid === false ? 'p-invalid' : ''}/>
													<InputText value={kunde.Fax}
														onChange={e => setKunde({...kunde, Fax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
														/>
													<span className='ml-1' hidden={!isLargeScreen}>Bsp. +43 2522 111 11 11 (20)</span>
												</div>
											</div>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1' >Bsp. +43 2522 111 11 11 (20)</span>
											</div>
										)}
									</div>
									<div className="field grid">
										<label htmlFor="Email" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>E-Mail *</label>
										<div className="col">
											<InputText value={kunde.Email}
												id="Email"
												onChange={e => setKunde({...kunde, Email: e.target.value})}
												className={erstePersonEmailValid === false ? 'p-invalid' : ''}
												/>
											<span className='ml-1' hidden={!isLargeScreen}>Bsp. m.mustermann@gmx.at</span>
										</div>
										{!isLargeScreen &&(
											<div className="col">
												<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
											</div>
										)}
									</div>
								</div>

								<div className={isLargeScreen ? 'col-6' : 'col-12'}>
									<div className="field grid">
										<label htmlFor="bezeichnung" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "2rem"}}>Firmenbezeichnung</label>
										<div className="col">
											<InputText value={kunde.Firmenbezeichnung} id="bezeichnung" onChange={e => setKunde({...kunde, Firmenbezeichnung: e.target.value})} className={kunde.Firmenbezeichnung === undefined ? "p-invalid" : ""}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="uid" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>UID Nummer</label>
										<div className="col">
											<InputText value={kunde.UID} id="fn" onChange={e => {
												setKunde({...kunde, UID: e.target.value});
												setUidValidationError(false);
											}}
											className={(uidValidationError === "error" ) ? "p-invalid" : ""}/>
											
											{(uidValidationError === "checked") &&
												<i style={{color: "green", fontSize: "1.5em"}} className="ml-2 pi pi-check"></i>
											}
											{(uidValidationError === "error") &&
												<i style={{color: "red", fontSize: "1.5em"}} className="ml-2 pi pi-times"></i>
											}

											{(uidValidationError !== "checked") &&
											<Button label="Überprüfen" icon="pi pi-sync"
												loading={uidLoading}
												style={{marginLeft: isLargeScreen ? "10px" : "", marginTop: isLargeScreen ? "" : "10px"}} 
												onClick={() => {
													setUidLoading(true);
													uidService.checkUID(kunde.UID).then(x => {
														setUidLoading(false);
														if (x.data.errorCode !== 0)
															setUidValidationError("error");
														else
															setUidValidationError("checked");
													});
												}}/>
											}
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="fn" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Firmenbuchnummer</label>
										<div className="col">
											<InputText value={kunde.FN} id="fn" onChange={e => {
												setKunde({...kunde, FN: e.target.value})
												setFnValidationError(false);
											}}
											className={(fnValidationError === "error" ) ? "p-invalid" : ""} />
											
											{(fnValidationError === "checked") &&
												<i style={{color: "green", fontSize: "1.5em"}} className="ml-2 pi pi-check"></i>
											}
											{(fnValidationError === "error") &&
												<i style={{color: "red", fontSize: "1.5em"}} className="ml-2 pi pi-times"></i>
											}
											{(fnValidationError !== "checked") &&
												<Button label="Überprüfen" icon="pi pi-sync"
													loading={firmenbuchloading}
													style={{marginLeft: isLargeScreen ? "10px" : "", marginTop: isLargeScreen ? "" : "10px"}} 
													onClick={() => {
														setFirmenbuchloading(true);
														firmenbuchService.checkFirmenbuchnummer(kunde.FN).then(x => {
															if(x.data.result.fnr && x.data.result.fnr != "undefined"){
																setFirmenbuchloading(false);
																setFnValidationError("checked");
															}
															else if(x.data.result.error){
																setFirmenbuchloading(false);
																setFnValidationError("error");
															}
															else{
																setFirmenbuchloading(false);
																setFnValidationError("error");
															}
															
														}).catch((e) => {setFirmenbuchloading(false)});
													}}/>
											}
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="fn" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>ZVR Nummer</label>
										<div className="col">
											<InputText value={kunde.ZVR} id="fn" onChange={e => setKunde({...kunde, ZVR: e.target.value})}/>
										</div>
									</div>
								</div>

							</div>
						</AccordionTab>
						<AccordionTab header="Ansprechpartner" hidden={!firma}>
							<Button onClick={() => {setAnsprechPartnerDialogVisible(true)}}>neuer Ansprechpartner</Button>
							<DataTable
								value={kunde.Ansprechpartner}
								emptyMessage="keine Ansprechpartner vorhanden"
								>
								<Column field="Vorname" header="Vorname" />
								<Column field="Nachname" header="Nachname" />
								<Column field="Telefon" header="Telefonnummer" />
								<Column field="Mobil" header="Mobil" />
								<Column field="Email" header="E-Mail" />
								<Column field="Aktionen" header="Aktionen"
									body={rowData => 
										<>
											<Button onClick={_ => onAnsprechPartnerEditClick(rowData)} buttonClassName="p-button" icon="pi pi-pencil"/>
											<Button onClick={_ => {setShowDeleteAnsprechpartner(true); setAnsprechpartnerDelete(rowData)}} buttonClassName="p-button" icon="pi pi-trash" style={{
												marginLeft:"1rem", 
												marginRight:"1rem",
												backgroundColor: "red", 
												color: "white",
												border: "none",
												boxShadow: "none"}}
												/>
										</>
										}
									style={{width: '10em', textAlign: 'center'}}
									/>
							</DataTable>
						</AccordionTab>
						<AccordionTab header="Rechnungsadresse">
							<div className="field grid">
								<label htmlFor="ReStrasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={kunde.ReStrasse} id="adresse" type="text" style={{width: 'calc(20em / 1.096)', maxWidth: isLargeScreen ? "" : "18rem"}}
										onChange={e => setKunde({...kunde, ReStrasse: e.target.value})}
										className={kunde.ReStrasse === undefined || kunde.ReStrasse == null || kunde.ReStrasse === "" ? 'p-invalid' : ''}
										inputStyle={{maxWidth: isLargeScreen ? "" : "18em"}}
									/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4))', marginLeft: isLargeScreen ? "" : "4.2rem", marginRight: isLargeScreen ? "5rem" : "0.77rem"}}>Ort</label>
								<div className="col">
									<PLZOrtDropdown 
										setPlausible={setRePlausible}
										plzvalue={kunde.RePLZ} 
										Land={kunde.ReLand} 
										ortvalue={kunde.ReOrt}
										setPLZOrt={onSetRePLZOrt} 
										setPLZ={onSetRePLZ} 
										setOrt={onSetOrt}>
										</PLZOrtDropdown>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
								<div className="col">
									<Laenderdropdown Land={kunde.ReLand} Pflichtfeld={true} onChange={(e) => {setKunde({...kunde, ReLand: e.target.value})}}></Laenderdropdown>
						
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="reperEmail" className="col-fixed" style={{width: labelWidth}}>Rechnung per Mail</label>
								<div className="col">
									<Checkbox id="reperEmail" checked={kunde.RePerEmail} onChange={(e) => {
										if(e.checked === true)
											setKunde({...kunde, ReEmailAdresse: kunde.Email, RePerEmail: e.checked});
										else
										{
											let _kunde = {...kunde, ReEmailAdresse: "", RePerEmail: e.checked}
											setKunde(_kunde);
										}
									}}/>
								</div>
							</div>
							<div className='field grid'>
								<label htmlFor="remail" className="col-fixed" style={{width: labelWidth}}>Rechnung E-Mail</label>
								<div className="col">
									<InputText value={kunde.ReEmailAdresse} disabled={!kunde.RePerEmail} id="ReEmailAdresse" type="text" style={{width: 'calc(20em / 1.096)'}}
									onChange={e => {
										setKunde({...kunde, ReEmailAdresse: e.target.value})}
									}
									className={kunde.RePerEMail === true && kunde.ReEmailAdresse === "" ? 'p-invalid' : ''}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Preise">
							<div className="field grid">
								<label htmlFor="preise" className="col-fixed" style={{width: labelWidth}}>Preise *</label>
								<div className="col">
									<Dropdown 
										value={kunde.PreiseArt} 
										options={PreisMöglichkeiten} 
										id="preise"
										onChange={e => {onPreiseArtChange(e.target.value);}} 
										showClear 
										className={kunde.Anrede === undefined ? "p-invalid" : ""}/>
								</div>
							</div>
							{kunde.PreiseArt == "Prozent" && (
								<div className="field grid">
									<label htmlFor="preise" className="col-fixed" style={{width: labelWidth}}>Prozent </label>
									<div className="col">
										<InputText 
											value= {prozent}
											id="Prozent" 
											type="text" 
											style={{width: "3rem"}}
											onChange={e => {
												const value = e.target.value;
												if (!isNaN(value)) {
													setProzent(value);
												}
											}}
										/>
										<span htmlFor="preise" className="col-fixed" style={{width: labelWidth}}>% </span>

									</div>
								</div>
							)}
							{kunde.PreiseArt == "Preisliste" && (
								<div className="field grid">
									<label htmlFor="preise" className="col-fixed" style={{width: labelWidth}}>Preisliste</label>
									<div className="col">
										<Button
											id="preisliste" 
											label="Preisliste setzen" 
											style={{width: "10rem"}}
											onClick={(e) => setPreislisteVisible(true)}
											disabled={loading01}
											/>
										{loading01 && 
											<>
												<i
													className="pi pi-spin pi-spinner"
													style={{
														fontSize: '1.5rem',
														zIndex: 1000,
														position: 'absolute',
														marginLeft:"1rem",
														marginTop:"0.25rem"
													}}
													></i>
												<span style={{ marginLeft: '3.5rem', marginTop: '0.25rem', position: 'absolute' }}>Preisliste wird geladen</span>
											</>
										}

									</div>
								</div>
							)}
						</AccordionTab>
						<AccordionTab 
							header="Kassen">
							<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'block' : 'none',  }}>
								<div className="col" style={{ width: '100%' }}>
								<Button   label={<><i className="pi pi-check"></i> alle auswählen</>} style={{ width:"10rem", height:"2rem", }} onClick={onAllKassen} />

									<div style={{ display: 'flex' ,marginTop:"1rem"}}>
										
										{/* Erste Spalte für die ersten 4 Kassen */}
										<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
											{kassen.slice(0, 4).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
													id={`kasse-${kasse.KassenID}`}
													checked={selectedKassen.includes(kasse.KassenID)}
													onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Zweite Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem"  }}>
											{kassen.slice(4,8).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kasse.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Dritte Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem" }}>
											{kassen.slice(8,12).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kassen.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Vierte Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem" }}>
											{kassen.slice(12,16).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kasse.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>
									</div>
								</div>
							</div>

							<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'none' : 'block',  }}>
								<div className="col" style={{ width: '100%' }}>
								<Button   label={<><i className="pi pi-check"></i> alle auswählen</>} style={{ width:"10rem", height:"2rem", }} onClick={onAllKassen} />

									<div style={{ display: 'flex' ,marginTop:"1rem"}}>
										<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
											{kassen.slice(0,16).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
													id={`kasse-${kasse.KassenID}`}
													checked={selectedKassen.includes(kasse.KassenID)}
													onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
											</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Bemerkungen">
							<div className="field grid">
								<div className="col-12">
									<InputTextarea value={bemerkung} rows={5} cols={60} onChange={e => setBemerkung(e.target.value)} style={{width: isLargeScreen ? '40em' : '18em'}}></InputTextarea>
									<Button style={{marginBottom: "5px", marginLeft: isLargeScreen ? "1rem" : "", marginTop: isLargeScreen ? "" : "1rem"}} className='p-mt-1' icon="pi pi-plus" label="Bemerkung hinzufügen"
										onClick={e => {
											if (bemerkung.length === 0)
												return;
											let _bemerkung =
											{
												Datum: new Date(),
												Bemerkung: bemerkung,
												MitarbeiterNr: derzeitigerBenutzer.MitarbeiterNr,
												MitarbeiterVorname: derzeitigerBenutzer.Vorname, 
												MitarbeiterNachname: derzeitigerBenutzer.Nachname
											};
											let bemerkungen = angebot.Bemerkungen?.concat(_bemerkung);
											if (bemerkungen === undefined)
												bemerkungen = [_bemerkung];
											setAngebot({...angebot, Bemerkungen: bemerkungen});
											setBemerkung('');
										}}
									/>
								</div>
								<div className='col-12' style={{marginTop:"1rem"}}>
									<DataTable value={angebot.Bemerkungen}
										className="p-datatable-striped" rowHover paginator rows={20}
										rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh"
										emptyMessage="Keine Bemerkungen gefunden"
										>
										<Column field="Datum" header="Datum" style={{width: "100px"}} body={datumTemplate} sortable filter/>
										<Column field="Mitarbeiter" header="Mitarbeiter" style={{width: "200px"}} body={wertemplate}  sortable filter/>
										<Column field="Bemerkung" header="Bemerkung" style={{width: "200px"}} sortable filter/>
									</DataTable>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Sonstiges">
						<div className="grid">
								<div className='col-12'>
									<div className="field grid">
										<label htmlFor="aktenkundigkeit" className="col-fixed" style={{width: labelWidth}}>Aktenkundigkeit</label>
										<div className="col">
											<Calendar 
												inputId="aktenkundigkeit" 
												value={sontiges} 
												showIcon={true} 
												onChange={e => {
													setSontiges(e.target.value)
													const selectedDate = e.target.value;
													let date;
													if (selectedDate instanceof Date) {
														date = selectedDate;
													} else {
														const dateParts = selectedDate.split('.');
														date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
													}
													const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();

													setKunde({ ...kunde, Aktenkundigkeit: formattedDate });
												}}
												style={{width: labelWidth}} 
												dateFormat="dd.mm.yy"
												/>
										</div>
									</div>
										<div className="field grid">
										<label htmlFor="info" className="col-fixed" style={{width: labelWidth}}>Info</label>
										<div className="col">
											<InputTextarea value={kunde.Info} rows={10} cols={80} id="info" onChange={e => setKunde({...kunde, Info: e.target.value})} autoResize style={{width: isLargeScreen ? '40em' : '18em'}}/>
										</div>
									</div>
									<div className='field grid'>
										<label htmlFor="status" className='col-fixed' style={{width: labelWidth}}>Status</label>
										<div className="col">
											<Dropdown options={["Aktiv", "Inaktiv", "Gesperrt"]} value={kunde.BlacklistStatus === undefined ? setKunde({...kunde, BlacklistStatus: "Inaktiv"}) : kunde.BlacklistStatus} valueTemplate={selectedOptionTemplate} itemTemplate={statusOptionTemplate} onChange={e => setKunde({...kunde, BlacklistStatus: e.target.value})} disabled={lockStatus}/>
										</div>
									</div>
								</div>
							</div>
					    </AccordionTab>
					</Accordion>
				</div>
			</div>

			<Dialog 
				header="Setze die Preisliste" 
				visible={preislisteVisible} 
				style={{ width: '80vw' }}
				footer={renderFooter}
				onHide={() => setPreislisteVisible(false)}>
					<div className='grid'>
					<div className="col-12" style={{textAlign: 'right'}}>
						<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
					</div>
				</div>
				<div class="flex">
					<DataTable 
						header={renderHeader} 
						value={produkte} 
						ref={dt} 
						responsive
						filterDisplay="row"
						className="p-datatable-striped tableCursorPointer"
						currentPageReportTemplate="({first} - {last} von {totalRecords})"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowHover paginator rows={10} rowsPerPageOptions={[10, 20,30,50,100]} scrollable="true" scrollHeight="650px" scrollDirection="both"
						selectionMode="single"
						globalFilter={globalFilterValue}
						sortOrder={1} sortMode="single"
					>
						<Column
							field="ID" sortable header="ID"
							showFilterMenu={false}
							hidden={!columnVisibility.ID}
							body={(rowData) => {
								return rowData.produkt.ID;
							}}
						/>

						<Column
							field="ArtNr" sortable filter header="ArtNr"
							showFilterMenu={false}
							hidden={!columnVisibility.ArtNr}
							body={(rowData) => {
								return rowData.produkt.ArtNr;
							}}
						/>

						<Column field="Bezeichnung" filter filterPlaceholder="Bezeichnung eingeben"
							sortable header="Bezeichnung"
							showFilterMenu={false}
							hidden={!columnVisibility.Bezeichnung}
							body={(rowData) => {
								return rowData.produkt.Bezeichnung;
							}}
						/>

						<Column field="Warengruppe" filter filterPlaceholder="Warengruppe eingeben"
							sortable header="Warengruppe" 
							showFilterMenu={false}
							hidden={!columnVisibility.Warengruppe}
							body={(rowData) => {
								return rowData.produkt.Warengruppe;
							}}
						/>

						<Column field="Barcode" filter filterPlaceholder="Barcode eingeben" bodyStyle={{overflow: 'hidden'}}
							sortable header="Barcodes" 
							showFilterMenu={false}
							hidden={!columnVisibility.Barcode}
							body={(rowData) => {
								return rowData.produkt.Barcode;
							}}
						/>

						<Column field="Lieferant"
							filter
							filterPlaceholder="Lieferant eingeben"
							sortable header="Lieferant"
							showFilterMenu={false}  							
							hidden={!columnVisibility.Lieferant}
							body={(rowData) => {
								return rowData.produkt.Lieferant;
							}}
						/>

						<Column field="Produzent"
							filter
							filterPlaceholder='Produzent eingeben'
							sortable header='Produzent'
							showFilterMenu={false}
							hidden={!columnVisibility.Produzent}
							body={(rowData) => {
								return rowData.produkt.Produzent;
							}}
						/>

						<Column field="Einheit" 
							filter
							filterPlaceholder="Einheit wählen"
							sortable header="Einheit"
							headerStyle={{maxWidth: '10em'}}
							style={{maxWidth: '10em'}}
							bodyStyle={{maxWidth: '10em'}}
							showFilterMenu={false}
							hidden={!columnVisibility.Einheit}
							body={(rowData) => {
								return rowData.produkt.Einheit;
							}}
						/>
						<Column 
							field="VK" 
							header="aktueller Preis"
							body={(rowData) => {
								const price = rowData.VK;
								return price ? `${price.toFixed(2).replace(".", ",")}€` : "-";
							  }}
							showFilterMenu={false}
							style={{minWidth:"7rem", maxWidth: "7rem", width:"7rem"}}

							/>
						<Column 
							field="Preis" 
							header="neuer Preis"
							body={(rowData, { rowIndex }) => (
								<>
									<InputNumber 
										type="text"
										value={rowData.NeuerPreis}
										style={{width:"4rem"}}
										inputStyle={{width:"4rem"}}
										locale="de-DE"
										minFractionDigits={2}	
										onValueChange={(e) => {
											const updatedProdukte = [...produkte];
											updatedProdukte[rowIndex].NeuerPreis = e.value;
											setProdukte(updatedProdukte);
										}}
									/>
									<span> €</span>
								</>
							)}
							showFilterMenu={false}
							style={{minWidth:"6rem", maxWidth: "6rem", width:"6rem"}}
						/>
					</DataTable>
				</div>
			</Dialog>

			<Dialog 
				header="Einstellungen" 
				visible={einstellungenVisible} 
				style={{ width: isLargeScreen ? '15vw' : '70vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setEinstellungenVisble(false);}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
				}}
				>
					<div className="grid">
						{['ID', 'ArtNr', 'Bezeichnung', 'Warengruppe', 'Barcode', 'Lieferant', 'Produzent', 'Einheit'].map((field) => (
						<div className="col-12" key={field}>
							<Checkbox 
							value={field} 
							checked={columnVisibility[field]} 
							className="mr-3"
							onChange={(e) => {
								setColumnVisibility(prev => ({
								...prev,
								[field]: e.checked,
								}));
							}}
							/>
							{field}
						</div>
						))}
					</div>
			</Dialog>

			<Dialog 
				header="Fehler!" 
				visible={showError} 
				style={{ width: '25vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowError(false);}} />
					</div>
				}
				onHide={e => {
					setShowError(false);
				}}
				>
					<div className="grid" style={{marginLeft:"0.1rem"}}>
						<span>Fehler: Der Kunde konnte nicht gespeichert werden!</span>
					</div>
			</Dialog>

			<Dialog 
				header="Fehler!" 
				visible={showPreiskategorieNichtGesetzt} 
				style={{ width: '25vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowPreiskategorieNichtGesetzt(false);}} />
					</div>
				}
				onHide={e => {
					setShowPreiskategorieNichtGesetzt(false);
				}}
				>
					<div className="grid" style={{marginLeft:"0.1rem"}}>
						<span>Fehler: Der Kunde kann nur Inaktiv gespeichert werden, da für mehrere Produkte {kunde.PreiseArt} nicht gesetzt ist!</span>
					</div>
			</Dialog>

			<Dialog 
				header="Fehler!" 
				visible={showEingabeWrong} 
				style={{ width: '25vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowEingabeWrong(false);}} />
					</div>
				}
				onHide={e => {
					setShowEingabeWrong(false);
				}}
				>
					<div className="grid" style={{marginLeft:"0.1rem"}}>
						<span>Fehler: Es wurden nicht alle Pflichtfelder ausgefüllt!</span>
					</div>
			</Dialog>

			<Dialog 
				visible={AnsprechPartnerDialogVisible && isLargeScreen}
				onHide={_ => setAnsprechPartnerDialogVisible(false)}
				header="neuer Ansprechpartner"
				footer={
					<React.Fragment>
					<Button
						label="Speichern"
						onClick={() => onAnsprechPartnerAnlegenClick()} />
					<Button label="Abbrechen" onClick={() => setAnsprechPartnerDialogVisible(false)} />
					</React.Fragment>
				}>
				<div className='grid'>
					<div className='col-12'>
						<div className="field grid">
							<label htmlFor="ansprechpersonanrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
							<div className="col">
								<Dropdown value={ansprechperson.Anrede} options={Anrede} id="ansprechpersonanrede" onChange={e => setAnsprechperson({...ansprechperson, Anrede: e.target.value})} showClear className={ansprechperson.Anrede === undefined ? "p-invalid" : ""}/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersontitel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
							<div className="col">
								<InputText value={ansprechperson.Titel} id="ansprechpersontitel" onChange={e => setAnsprechperson({...ansprechperson, Titel: e.target.value})}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonvorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
							<div className="col">
								<InputText value={ansprechperson.Vorname} id="ansprechpersonvorname"
								onChange={e => setAnsprechperson({...ansprechperson, Vorname: e.target.value})}
								className={ansprechperson.Vorname?.length === 0 || ansprechperson.Vorname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonnachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
							<div className="col">
								<InputText value={ansprechperson.Nachname} id="ansprechpersonnachname"
								onChange={e => setAnsprechperson({...ansprechperson, Nachname: e.target.value})}
								className={ansprechperson.Nachname?.length === 0 || ansprechperson.Nachname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonmobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonMobil(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Mobil} id="erstepersonmobil"
											ref={ansprechpersonmobilref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonMobil).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Mobil: _nummer});
											}}
											className={ansprechPersonMobilValid === false ? 'p-invalid' : ''}
										/>
										<span className='ml-1'>Bsp. +43 664 111 11 11</span>
									</div>
								</div>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="erstepersontel" className="col-fixed" style={{width: labelWidth}}>Telefon (Fax DW)</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonTelefon(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Telefon}
											ref={ansprechpersontelefonref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonTelefon).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Telefon: _nummer});
											}}
											className={ansprechPersonTelefonValid === false ? 'p-invalid' : ''}/>
										<InputText value={ansprechperson.Fax}
											onChange={e => setAnsprechperson({...ansprechperson, Fax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
										/>
										<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonabteilung" className="col-fixed" style={{width: labelWidth}}>Abteilung</label>
							<div className="col">
								<AutoComplete suggestions={filteredAbreilungen}
									completeMethod={abteilungAutocomplete}
									value={ansprechperson.Abteilung}
									id="ansprechpersonabteilung"
									onChange={e => setAnsprechperson({...ansprechperson, Abteilung: e.target.value})}
									/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonemail" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
							<div className="col">
								<InputText value={ansprechperson.Email}
									id="erstepersonemail"
									onChange={e => setAnsprechperson({...ansprechperson, Email: e.target.value})}
									className={ansprechPersonEmailValid === false ? 'p-invalid' : ''}
									/>
								<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
							</div>
						</div>
					</div>
				</div>
			</Dialog>

			<Dialog 
				visible={AnsprechPartnerDialogVisible && !isLargeScreen}
				onHide={_ => setAnsprechPartnerDialogVisible(false)}
				style={{width: "92vw"}}
				header="neuer Ansprechpartner"
				footer={
					<React.Fragment>
					<Button
						label="Speichern"
						onClick={() => onAnsprechPartnerAnlegenClick()} />
					<Button label="Abbrechen" onClick={() => setAnsprechPartnerDialogVisible(false)} />
					</React.Fragment>
				}>
				<div className='grid'>
					<div className='col-12'>
						<div className="field grid">
							<label htmlFor="ansprechpersonanrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
							<div className="col">
								<Dropdown value={ansprechperson.Anrede} options={Anrede} id="ansprechpersonanrede" onChange={e => setAnsprechperson({...ansprechperson, Anrede: e.target.value})} showClear className={ansprechperson.Anrede === undefined ? "p-invalid" : ""}/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersontitel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
							<div className="col">
								<InputText value={ansprechperson.Titel} id="ansprechpersontitel" onChange={e => setAnsprechperson({...ansprechperson, Titel: e.target.value})}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonvorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
							<div className="col">
								<InputText value={ansprechperson.Vorname} id="ansprechpersonvorname"
								onChange={e => setAnsprechperson({...ansprechperson, Vorname: e.target.value})}
								className={ansprechperson.Vorname?.length === 0 || ansprechperson.Vorname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonnachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
							<div className="col">
								<InputText value={ansprechperson.Nachname} id="ansprechpersonnachname"
								onChange={e => setAnsprechperson({...ansprechperson, Nachname: e.target.value})}
								className={ansprechperson.Nachname?.length === 0 || ansprechperson.Nachname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonmobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonMobil(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Mobil} id="erstepersonmobil"
											ref={ansprechpersonmobilref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonMobil).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Mobil: _nummer});
											}}
											inputStyle={{maxWidth:"10rem"}}
											style={{maxWidth:"10rem"}}
											className={ansprechPersonMobilValid === false ? 'p-invalid' : ''}
										/>
									</div>
								</div>
							</div>
							<div className="col">
								<span className='ml-1'>Bsp. +43 664 111 11 11</span>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="erstepersontel" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Telefon (Fax DW)</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonTelefon(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Telefon}
											ref={ansprechpersontelefonref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonTelefon).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Telefon: _nummer});
											}}
											inputStyle={{maxWidth:"10rem"}}
											style={{maxWidth:"10rem"}}
											className={ansprechPersonTelefonValid === false ? 'p-invalid' : ''}/>
										<InputText value={ansprechperson.Fax}
											onChange={e => setAnsprechperson({...ansprechperson, Fax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
										/>
									</div>
								</div>
							</div>
							<div className="col">
								<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonabteilung" className="col-fixed" style={{width: labelWidth, marginTop: isLargeScreen ? "0rem" : "1rem"}}>Abteilung</label>
							<div className="col">
								<AutoComplete suggestions={filteredAbreilungen}
									completeMethod={abteilungAutocomplete}
									value={ansprechperson.Abteilung}
									id="ansprechpersonabteilung"
									onChange={e => setAnsprechperson({...ansprechperson, Abteilung: e.target.value})}
									/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonemail" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
							<div className="col">
								<InputText value={ansprechperson.Email}
									id="erstepersonemail"
									onChange={e => setAnsprechperson({...ansprechperson, Email: e.target.value})}
									className={ansprechPersonEmailValid === false ? 'p-invalid' : ''}
									/>
							</div>
							<div className="col">
								<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
							</div>
						</div>
					</div>
				</div>
			</Dialog>

			<Dialog 
				header="Willst du diese Ansprechperson wirklich löschen?" 
				visible={showDeleteAnsprechpartner} 
				style={{ width: '25vw' }}
				footer={
					<div>
						<Button label="Abbrechen" icon="pi pi-times" onClick={e => {setShowDeleteAnsprechpartner(false);}} />
						<Button label="Löschen" icon="pi pi-trash" onClick={e => {onAnsprechPartnerDeleteClick(); setShowDeleteAnsprechpartner(false);}}
							style={{
								backgroundColor: "red", 
								color: "white",
								border: "none",
								boxShadow: "none"
							}}/>	
					</div>
				}
				onHide={e => {
					setShowDeleteAnsprechpartner(false);
				}}
				>
					<div className="grid" style={{marginLeft:"0.1rem"}}>
						<span>Willst du diese Ansprechperson wirklich löschen?</span>
					</div>
			</Dialog>
		</React.Fragment>

		
	);
}