import React, { useRef, useState, useEffect, useContext } from 'react';
import { WarenbestandService } from '../service/WarenbestandService';
import { KassenService } from '../service/KassenService';
import { LieferantenService } from '../service/LieferantenService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { GlobalState } from './GlobalState';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { ProduktService } from '../service/ProduktService';
import { FilterMatchMode } from 'primereact/api';

function Warenbestand(props)
{
	const [warenbestand, setWarenbestand] = useState([]);
	const [selectedLieferanten, setSelectedLieferanten] = useState([]);
	const [kassen, setKassen] = useState([]);
	const [lieferanten, setLieferanten] = useState([]);
	const [warenbestandDisplay, setWarenbestandDisplay] = useState([]);
	const [filteredWarenbestandDisplay, setFilteredWarenbestandDisplay] = useState(null);
	const [warenbestandDetailsOffen, setWarenbestandDetailsOffen] = useState(false);
	const [selectedWarenbestand, setSelectedWarenbestand] = useState({});
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [showOnlyKritisch, setShowOnlyKritisch] = useState(false);
	const [hideZero, setHideZero] = useState(false);
	const [filters, setFilters] = useState({});
	//const [showSpinner, setShowSpinner] = useState(false);
	//const [roles, setRoles] = useState([]);
	const [produkte, setProdukte] = useState([]);
	const [selectedProdukte, setSelectedProdukte] = useState([]);
	const warenbestandService = new WarenbestandService();
	const kassenService = new KassenService();
	const lieferantenService = new LieferantenService();
	//const exportService = new ExportService();
	//const authService = new AuthService();
	const produktService = new ProduktService();
	const dt = useRef(null);
	const toastRef = useRef(null);

	const onRowClicked = e => {
		warenbestandService.getDetails(e.data.Id).then(data => {
			setSelectedWarenbestand(data);
			setWarenbestandDetailsOffen(true);
		});
	}

	const onWarenbestandDetailsHide = () => {
		setSelectedWarenbestand({});
		setWarenbestandDetailsOffen(false);
	}

	useEffect(() => {
		setGlobalState({...globalState, page: 'Warenbestand'});
		warenbestandService.getAll().then(data => setWarenbestand(data));
		kassenService.getKassen().then(data => setKassen(data));
		lieferantenService.getAll().then(data => setLieferanten(data));
		//authService.getTokenInfo().then(info => setRoles(info.roles.split(';')));
		produktService.getAll().then(setProdukte);

		if (props.dashboard !== undefined) {
			setShowOnlyKritisch(true);
			setHideZero(true);
		}

		InitFilters();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const InitFilters = () => {
        setFilters({
            KassenID: { value: null, matchMode: FilterMatchMode.IN },
            Datum: { value: null, matchMode: FilterMatchMode.DATE_IS },
            Bezeichnung: { value: null, matchMode: FilterMatchMode.IN },
            Lieferant: { value: null, matchMode: FilterMatchMode.IN },
            Grund: { value: null, matchMode: FilterMatchMode.IN },
			ArtNr: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        });
    }

	useEffect(() => {
		let bestand = warenbestand;
		if (hideZero) bestand = bestand.filter(ware => ware.SollBestand !== 0);
		if (showOnlyKritisch) bestand = bestand.filter(ware => ware.Bestand < ware.WarnBestand);
		setWarenbestandDisplay(bestand.map(ware => ({...ware, AufzMenge: Math.max(ware.SollBestand - ware.Bestand, 0)})));
	}, [warenbestand, showOnlyKritisch, hideZero]);

	const lieferantenFilter = (options) => {
		return (
			<MultiSelect
				className="column-filter w-full md:w-12rem"
				value={options.value}
				options={lieferanten}
				onChange={e => { options.filterApplyCallback(e.value); }}
				placeholder="Alle"
				optionLabel="Name"
				optionValue="Name"
				maxSelectedLabels={1}
				filter
			/>
		);
	};

	const kassenFilter = (options) => {
		return (
			<MultiSelect
				className="column-filter"
				value={options.value}
				options={kassen}
				onChange={e => { options.filterApplyCallback(e.value); }}
				placeholder="Alle"
				optionLabel="KassenID"
				optionValue="KassenID"
				maxSelectedLabels={1}
			/>
		);
	};

	const bezeichnungFilter = (options) => {
		return (
			<MultiSelect
				className="column-filter"
				value={options.value}
				options={produkte}
				onChange={e => { options.filterApplyCallback(e.value); }}
				placeholder="Produkt"
				optionLabel="Bezeichnung"
				optionValue="Bezeichnung"
				maxSelectedLabels={1}
				filter
				filterMatchMode="contains"
			/>
		);
	}

	const formatNumber = (value) => {
		if (value === null || value === undefined)
			return '';
		return value.toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid p-component">
				<div className="col-12 grid" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					{/*<Button label="Bestandsliste" icon="pi pi-download" className="ml-2 mt-2" onClick={onBestandslisteClick} />
					<Button label="Befüllungsliste" icon="pi pi-download" className="ml-2 mt-2" onClick={onBefuellungslisteClick} />
					{ roles.includes('Chef') &&
						<React.Fragment>
							<Button label="Bestellliste" icon="pi pi-download" className="ml-2 mt-2" onClick={onBestelllisteClick} />
						</React.Fragment>
					}*/}
					
					<div className="checkbox-container" style={{ marginBottom: '10px' }}>
						<Checkbox className="ml-2 mt-2" inputId="showOnlyKritisch" checked={showOnlyKritisch} onChange={e => setShowOnlyKritisch(e.target.checked)}/>
						<label className="ml-1 mt-2" htmlFor="showOnlyKritisch">nur Kritische Warenbestände anzeigen</label>
					</div>

					<div className="checkbox-container" style={{ marginBottom: '10px' }}>
						<Checkbox className="ml-2 mt-2" inputId="hideZero" checked={hideZero} onChange={e => setHideZero(e.target.checked)}/>
						<label className="ml-1 mt-2"htmlFor="hideZero">keine Warenbestände mit einen Soll-Bestand von 0 anzeigen</label>
					</div>
					
					{/*<div style={{float: 'right'}}>
						{showSpinner ? <i className="pi pi-spin pi-spinner ml-2 mt-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
					</div>*/}
				</div>
				<div className="col-12">
					<DataTable
						ref={dt} value={warenbestandDisplay}
						responsive
						className="p-datatable-warenbestand p-datatable-striped tableCursorPointer"
						filterDisplay="row"
						dataKey="id"
						rowHover paginator rows={20}
						emptyMessage="keine Warenbestände gefunden"
						currentPageReportTemplate='({first} von {totalRecords})'
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[20,30,40]}
						onRowClick={onRowClicked}
						sortField={props.dashboard !== undefined ? 'AufzMenge' : undefined}
						sortOrder={props.dashboard !== undefined ? -1 : undefined}
						onValueChange={setFilteredWarenbestandDisplay}
						scrollable
						scrollHeight="80vh"
						showGridlines={false}
						filters={filters}
						>
						<Column
							field="KassenID"
							sortable
							filter
							filterElement={kassenFilter}
							header="Standort"
							showFilterMenu={false}
							style={{width: "14em", maxWidth:"14em"}}
							/>
						<Column
							field="ArtNr"
							sortable
							filter
							header="Art.-Nr."
							style={{maxWidth: "8em", width: "8em"}}
							showFilterMenu={false}
							/>
						<Column
							field="Bezeichnung"
							sortable
							filter
							header="Bezeichnung"
							filterElement={bezeichnungFilter}
							showFilterMenu={false}
							/>
						<Column
							className="column-filter w-full md:w-12rem"
							field="Lieferant"
							sortable
							filter
							filterElement={lieferantenFilter}
							header="Lieferant"
							showFilterMenu={false}
							/>
						<Column
							field="Bestand" style={{width: "8em", maxWidth: "8em", textAlign: "right"}} sortable header="Ist-Bestand"
							body={row => <React.Fragment>{row.Bestand?.toLocaleString('de')}</React.Fragment>}
							footer={<React.Fragment>{formatNumber(Math.round((filteredWarenbestandDisplay ?? warenbestandDisplay).reduce((res, val) => res + val.Bestand, 0) * 100) / 100)}</React.Fragment>}
							showFilterMenu={false}
							/>
						<Column
							field="SollBestand" header="Soll-Bestand" style={{maxWidth: "8em", width: "8em", textAlign: "right"}} sortable
							body={row => <React.Fragment>{row.SollBestand?.toLocaleString('de')}</React.Fragment>}
							footer={<React.Fragment>{formatNumber(Math.round((filteredWarenbestandDisplay ?? warenbestandDisplay).reduce((res, val) => res + val.SollBestand, 0) * 100) / 100)}</React.Fragment>}
							showFilterMenu={false}
							/>
						<Column
							field="WarnBestand" header="Warn-Bestand" style={{maxWidth: "8em", width: "8em", textAlign: "right"}} sortable
							body={row => <React.Fragment>{formatNumber(row.WarnBestand)}</React.Fragment>}
							showFilterMenu={false}
							/>
						<Column
							field="AufzMenge" header="Aufz. Menge" style={{maxWidth: "8em", width: "8em", paddingRight: "15px", textAlign: "right"}} sortable
							showFilterMenu={false}
							body={row => <React.Fragment>{row.AufzMenge?.toLocaleString('de')}</React.Fragment>}
							footer={<React.Fragment>{formatNumber(Math.round((filteredWarenbestandDisplay ?? warenbestandDisplay).reduce((res, val) => res + val.AufzMenge, 0) * 100) / 100)}</React.Fragment>}/>
					</DataTable>
				</div>
			</div>
			<Dialog header={selectedWarenbestand.Bezeichnung} visible={warenbestandDetailsOffen} onHide={onWarenbestandDetailsHide}>
				<table className="tablePadding" style={{width: "100%"}}>
					<tr>
						<td style={{paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Art.-Nr.</span></td>
						<td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Standort</span></td>
						<td style={{textAlign: "right", paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Lieferant</span></td>
					</tr>
					<tr>
						<td style={{paddingLeft: "2em", paddingRight: "2em"}}>{selectedWarenbestand.ArtNr}</td>
						<td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}>{selectedWarenbestand.KassenID}</td>
						<td style={{textAlign: "right", paddingLeft: "2em", paddingRight: "2em"}}>{selectedWarenbestand.Lieferant}</td>
					</tr>
				</table>
				<Divider />
				<table className="tablePadding" style={{width: "100%", borderCollapse: "collapse"}}>
					<tr>
						<td style={{fontWeight: "500", textAlign: "center"}}>Datum</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Zeit</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Menge</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Grund</td>
					</tr>
					{
						selectedWarenbestand.Einlagerungen?.map(einlagerung => <tr>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{new Date(einlagerung.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{new Date(einlagerung.Datum).toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit", second: undefined })}</td>
							<td style={{textAlign: "right", borderTopStyle: "solid", borderWidth: "1px"}}>{einlagerung.Menge}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px", paddingLeft: "15px"}}>{einlagerung.Grund}</td>
						</tr>)
					}
				</table>
			</Dialog>
		</React.Fragment>
	);
}

export default Warenbestand;