import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { KundenService } from '../service/KundenService';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Laender } from './Laenderdropdown';
import { PopupMenu } from './PopupMenu';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { ZoomPopup } from './ZoomPopup';
import AdresseColumn from './Kunden/Adresse';
import LandColumn from './Kunden/Land';
import PLZColumn from './Kunden/PLZ';
import OrtColumn from './Kunden/Ort';
import EmailColumn from './Kunden/Email';
import MobilColumn from './Kunden/Mobil';
import KundentypColumn from './Kunden/Kundentyp';
import TelefonColumn from './Kunden/Telefon';
import FaxDWColumn from './Kunden/FaxDW';
import VornameColumn from './Kunden/Vorname';
import NachnameColumn from './Kunden/Nachname';
import FirmenbezeichnungColumn from './Kunden/Firmenbezeichnung';
import KundennummerColumn from './Kunden/Kundennummer';
import { KassenService } from '../service/KassenService';
import { ExportService } from '../service/ExportService';
import { Calendar } from 'primereact/calendar';


export default function Kunden(props) {
	const kundenService = new KundenService();
	const kassenService = new KassenService();
	const exportService = new ExportService();
	const history = useHistory();
	const datatableref = useRef(null);
	const [filteredKunden, setFilteredKunden] = useState([]);
	const [selectedVorname, setSelectedVorname] = useState('');
	const [selectedNachname, setSelectedNachname] = useState('');
	const [selectedAdresse, setSelectedAdresse] = useState('');
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunden, setKunden] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [selectedPLZ, setSelectedPLZ] = useState([]);
	const [selectedKommission, setSelectedKommission] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState([]);
	const [selectedMail, setSelectedMail] = useState([]);
	const [selectedMobil, setSelectedMobil] = useState([]);
	const [selectedVerkäufer, setSelectedVerkäufer] = useState([]);
	const [selectedKundenTyp, setSelectedKundenTyp] = useState([]);
	const [selectedTelefon, setSelectedTelefon] = useState([]);
	const [selectedBlacklistStatus, setSelectedBlacklistStatus] = useState([]);
	const [selectedLand, setSelectedLand] = useState([]);
	const [selectedFaxDW, setSelectedFaxDW] = useState([]); 
	const [selectedFirmenname, setSelectedFirmenname] = useState([]);
	const [selectedKundennummer, setSelectedKundennummer] = useState([]); 
	const [selectedKunden, setSelectedKunden] = useState([]);
	const [Adressen, setAdressen] = useState([]); 
	const [PLZ, setPLZ] = useState([]);
	const [Ort, setOrt] = useState([]);
	const [Mail, setMail] = useState([]); 
	const [Mobil, setMobil] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [FaxDW, setFaxDW] = useState([]);
	const [Firmenbezeichnung, setFirmenbezeichnung] = useState([]);
	const [Vorname, setVorname] = useState([]);
	const [Nachname, setNachname] = useState([]);
	const [Kundennummer, setKundennummer] = useState([]);
	const [showDelete, setShowDelete] = useState(false);
	const [showBericht, setShowBericht] = useState(false);
	const [showBerichtTabelle, setShowBerichtTabelle] = useState(false);
	const [vonBericht, setVonBericht] = useState(false);
	const [bisBericht, setBisBericht] = useState(false);
	const [deletData, setDeletData] = useState(false);
	const [selectedRow, setSelectedRow] = useState(false);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1850);
	const [selectedMonateMenge, setSelectedMonateMenge] = useState(3);
	const [selectedKassen1, setSelectedKassen1] = useState([]);
	const [selectedMonateMenge2, setSelectedMonateMenge2] = useState(new Date().getMonth()+1);
	const [selectedJahrMenge2, setSelectedJahrMenge2] = useState(new Date().getFullYear());
	const [jahresOptions, setJahresOptions] = useState();
	const [selectedKassen2, setSelectedKassen2] = useState([]);
	const [kassen, setKassen] = useState([]);
	const [loading01, setLoading01] = useState([]);

	const [columnVisibility, setColumnVisibility] = useState({
			ID: false,
			Status: true,
			Adresse: true,
			Land: true,
			PLZ: true,
			Ort: true,
			Email: true, 
			Telefonnummer: true,
			Handynummer : true,
			Typ : true,
			FaxDW: true,
			Vorname: true,
			Nachname: true,
			Firmenname: true,
			Firmenbuchnummer: false,
			UIDNummer: false,
			ZVRNummer: false,
			Kundennummer: true,
		});
	const columns = [
			{field: "Kommission", visible: true },
			{field: "Adresse", visible: true },
			{field: "Land", visible: true},
			{field: "PLZ", visible: true },
			{field: "Ort", visible: true },
			{field: "E-Mail", visible: true},
			{field: "Telefon", visible: true},
			{field: "Mobil", visible: true},
			{field: "Kundentyp", visible: true},
			{field: "Verkäufer", visible: true},
			{field: "Fax-DW", visible: true},
			{field: "Vorname", visible: true},
			{field: "Nachname", visible: true},
			{field: "Firmenname", visible: true},
			{field: "Kundennummer", visible: true},
			{field: "offeneAngebote", visible: true},
			{field: "VKGesamt", visible: true}
		];
	const [selectedColumns, setSelectedColumns] = useState(columns);
	const [monateVergleichData, setMonateVergleichData] = useState({});
	const [monateVergleichKundenData, setMonateVergleichKundenData] = useState({});
	const monateVergleichChartRef = useRef(null);

	
	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	const monthLookup = {
		0: "Jänner",
		1: "Februar",
		2: "März",
		3: "April",
		4: "Mai",
		5: "Juni",
		6: "Juli",
		7: "August",
		8: "September",
		9: "Oktober",
		10: "November",
		11: "Dezember"
	}



	useEffect(() => {
		setGlobalState({...globalState, page: 'Kunden'});
		setLoading01(true);
		kassenService.getKassen().then(data => setKassen(data));
		kundenService.getAll().then(data => {
			setKunden(data);
			setFilteredKunden(data);
			onKundeLoad(data);
			setLoading01(false);
		});

		var currentYear = new Date().getFullYear();
		var years = [];

		for (var i = 2021; i <= currentYear; i++) {
			years.push(i);
		}

		setJahresOptions(years);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);


	useEffect(() => {
		let kassenIds = selectedKassen1;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		const month = new Date().getMonth();
	
		if(selectedKunden.length > 0 && kassenIds.length > 0 && selectedMonateMenge != null && selectedMonateMenge > 0){
			let kundenIDs = selectedKunden.map(k => k.ID); 

			const promises = [
				kundenService.getVergleichsDataVerkauf(selectedMonateMenge, kassenIds, kundenIDs)
			];

			Promise.all(promises).then((results) => {
				const labels = Array.from(new Array(selectedMonateMenge), (_, i) => monthLookup[(12 + month + (-selectedMonateMenge + i + 1)) % 12]);
				const datasetsMap = new Map();

				const monateData = results[0];
				monateData.sort((a, b) => a.Monat - b.Monat);

				monateData.forEach((monat) => {
					Object.entries(monat.Werte).forEach(([id, value]) => {
						if (!datasetsMap.has(id)) {
							datasetsMap.set(id, {
								label: id,
								data: [],
								borderWidth: 1,
								backgroundColor: generateColorFromId(id)
							});
						}
						const dataset = datasetsMap.get(id);
						dataset.data.push(value);
					});
				});

				function generateColorFromId(id) {
					let hash = 0;
					for (let i = 0; i < id.length; i++) {
						hash = id.charCodeAt(i) + ((hash << 5) - hash);
					}
					const color = (hash & 0x00FFFFFF)
						.toString(16)
						.toUpperCase()
						.padStart(6, '0');
					return `#${color}`;
				}

				const datasets = Array.from(datasetsMap.values());

				datasets.forEach(dataset => {
					const missingDataCount = selectedMonateMenge - dataset.data.length;
					if (missingDataCount > 0) {
						dataset.data = dataset.data.concat(new Array(missingDataCount).fill(null));
					}
					dataset.data.reverse();
					dataset.label = kunden.find(x => x.ID === dataset.label)?.Vorname;
				});

				setMonateVergleichData({
					labels: labels,
					datasets: datasets,
				});
			});
		}
		else{
			const labels = Array.from(new Array(selectedMonateMenge), (_, i) => monthLookup[(12 + month + (-selectedMonateMenge + i + 1)) % 12]);

			setMonateVergleichData({
				labels: labels,
				datasets: [],
			});
		}
	}, [selectedKassen1, kassen, selectedMonateMenge, selectedKunden]);

	useEffect(() => {
		let kassenIds = selectedKassen2;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		if(selectedJahrMenge2 > 0 && selectedMonateMenge2 > 0){
			kundenService.getMonatsVergleich(selectedMonateMenge2, selectedJahrMenge2, kassenIds).then(data => {

				const transformedData = data.map(item => ({
					label: item.key,
					value: item.count,
				}));
				  
				const labels = transformedData.map(item => {
					const kunde = kunden.find(x => x.ID === item.label);
					return kunde ? kunde.Vorname : null;
				});

				setMonateVergleichKundenData({
					labels: labels,
					datasets: [
						{
						label: 'Monatsvergleich',
						data: transformedData.map(item => item.value),
						backgroundColor: transformedData.map((item, index) => {
							return index % 2 === 0 ? 'rgba(75, 192, 192)' : 'rgba(153, 102, 255)';
						}),
						borderWidth: 1
						},
					],
				});
			});
		}

	}, [selectedKassen2, selectedJahrMenge2, selectedMonateMenge2, kassen, kunden])

	const statuse = [
		{BlacklistStatus: "Aktiv"},
		{BlacklistStatus: "Inaktiv"},
		{BlacklistStatus: "Gesperrt"}
	];

	const createKunde = () => {
		history.push({
			pathname: `/kunde/neu`,
			state: {Firma: false}
		});
    }

	const createKundeFa = () => {
		history.push({
			pathname: `/kunde/neu`,
			state: {Firma: true}
		});
    }

	const getIndividuellenBericht = (e) => {
		let kassenIds;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		const von = vonBericht
		const bis = bisBericht


		exportService.downloadBerichtKunde(von, bis, e, null, kassenIds, von.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('')
				+ "-" + bis.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_Wochenbericht").then(() => {
			}).catch(() => {
				console.log({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
			}
		);
	}

	const getIndividuellenBerichtTabelle = () => {
		var temp = kunden;

		if(selectedBlacklistStatus.length > 0){
			temp = temp.filter(x => selectedBlacklistStatus.includes(x.BlacklistStatus));
		}
		if(selectedAdresse.length > 0){
			temp = temp.filter(x => selectedAdresse.includes(x.ReStrasse));
		}
		if(selectedLand.length > 0){
			temp = temp.filter(x => selectedLand.includes(x.ReLand));
		}
		if(selectedPLZ.length > 0){
			temp = temp.filter(x => selectedPLZ.includes(x.RePLZ));
		}
		if(selectedOrt.length > 0){
			temp = temp.filter(x => selectedOrt.includes(x.ReOrt));
		}
		if(selectedMail.length > 0){
			temp = temp.filter(x => selectedMail.includes(x.Email));
		}
		if(selectedTelefon.length > 0){
			temp = temp.filter(x => selectedTelefon.includes(x.Telefon));
		}
		if(selectedMobil.length > 0){
			temp = temp.filter(x => selectedMobil.includes(x.Mobil));
		}
		if(selectedFaxDW.length > 0){
			temp = temp.filter(x => selectedFaxDW.includes(x.Fax));
		}
		if (selectedKundenTyp && selectedKundenTyp.length > 0) {
			temp = temp.filter(x => {
				if (x.Firma === false) {
					return selectedKundenTyp.includes(0) || selectedKundenTyp.includes(2);
				} else {
					return selectedKundenTyp.includes(1);
				}
			});
		}
		if(selectedVorname.length > 0){
			temp = temp.filter(x => selectedVorname.includes(x.Vorname));
		}
		if(selectedNachname.length > 0){
			temp = temp.filter(x => selectedNachname.includes(x.Nachname));
		}
		if(selectedFirmenname.length > 0){
			temp = temp.filter(x => selectedFirmenname.includes(x.Firmenbezeichnung));
		}
		if(selectedKundennummer.length > 0){
			temp = temp.filter(x => selectedKundennummer.includes(x.KundenNummer));
		}


		let kassenIds;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		const von = vonBericht
		const bis = bisBericht

		let kundenIDs = temp.map(k => k.ID);


		exportService.downloadBerichtKunde(von, bis, null, temp, kassenIds, von.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('')
				+ "-" + bis.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_IndividuellerBericht").then(() => {
			}).catch(() => {
				console.log({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
			}
		);
		
	}

	const getTableData = () => {
		var temp = kunden;

		if(selectedBlacklistStatus.length > 0){
			temp = temp.filter(x => selectedBlacklistStatus.includes(x.BlacklistStatus));
		}
		if(selectedAdresse.length > 0){
			temp = temp.filter(x => selectedAdresse.includes(x.ReStrasse));
		}
		if(selectedLand.length > 0){
			temp = temp.filter(x => selectedLand.includes(x.ReLand));
		}
		if(selectedPLZ.length > 0){
			temp = temp.filter(x => selectedPLZ.includes(x.RePLZ));
		}
		if(selectedOrt.length > 0){
			temp = temp.filter(x => selectedOrt.includes(x.ReOrt));
		}
		if(selectedMail.length > 0){
			temp = temp.filter(x => selectedMail.includes(x.Email));
		}
		if(selectedTelefon.length > 0){
			temp = temp.filter(x => selectedTelefon.includes(x.Telefon));
		}
		if(selectedMobil.length > 0){
			temp = temp.filter(x => selectedMobil.includes(x.Mobil));
		}
		if(selectedFaxDW.length > 0){
			temp = temp.filter(x => selectedFaxDW.includes(x.Fax));
		}
		if (selectedKundenTyp && selectedKundenTyp.length > 0) {
			temp = temp.filter(x => {
				if (x.Firma === false) {
					return selectedKundenTyp.includes(0) || selectedKundenTyp.includes(2);
				} else {
					return selectedKundenTyp.includes(1);
				}
			});
		}
		if(selectedVorname.length > 0){
			temp = temp.filter(x => selectedVorname.includes(x.Vorname));
		}
		if(selectedNachname.length > 0){
			temp = temp.filter(x => selectedNachname.includes(x.Nachname));
		}
		if(selectedFirmenname.length > 0){
			temp = temp.filter(x => selectedFirmenname.includes(x.Firmenbezeichnung));
		}
		if(selectedKundennummer.length > 0){
			temp = temp.filter(x => selectedKundennummer.includes(x.KundenNummer));
		}


		let kundenIDs = temp.map(k => k.ID);

		let kassenIds = selectedKassen1;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		const month = new Date().getMonth();

		kundenService.getVergleichsDataVerkauf(selectedMonateMenge, kassenIds, kundenIDs).then(data => {
			const labels = Array.from(new Array(selectedMonateMenge), (_, i) => monthLookup[(12 + month + (-selectedMonateMenge + i + 1)) % 12]);
			const datasetsMap = new Map();

			const monateData = data;
			monateData.sort((a, b) => a.Monat - b.Monat);

			monateData.forEach((monat) => {
				Object.entries(monat.Werte).forEach(([id, value]) => {
					if (!datasetsMap.has(id)) {
						datasetsMap.set(id, {
							label: id,
							data: [],
							borderWidth: 1,
							backgroundColor: generateColorFromId(id)
						});
					}
					const dataset = datasetsMap.get(id);
					dataset.data.push(value);
				});
			});

			function generateColorFromId(id) {
				let hash = 0;
				for (let i = 0; i < id.length; i++) {
					hash = id.charCodeAt(i) + ((hash << 5) - hash);
				}
				const color = (hash & 0x00FFFFFF)
					.toString(16)
					.toUpperCase()
					.padStart(6, '0');
				return `#${color}`;
			}

			const datasets = Array.from(datasetsMap.values());

			datasets.forEach(dataset => {
				const missingDataCount = selectedMonateMenge - dataset.data.length;
				if (missingDataCount > 0) {
					dataset.data = dataset.data.concat(new Array(missingDataCount).fill(null));
				}
				dataset.data.reverse();
				dataset.label = kunden.find(x => x.ID === dataset.label)?.Vorname;
			});

			setMonateVergleichData({
				labels: labels,
				datasets: datasets,
			});
		})

	}

	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1850);
	};
	const dialogFooterDelete = (
		<>
			<Button label="Abbrechen" icon="pi pi-times" onClick={() => {setShowDelete(false)}} autoFocus />
			<Button label="Löschen" icon="pi pi-trash" onClick={() => {deleteKunde(deletData); setShowDelete(false)}} style={{backgroundColor: "red", color: "white", border: "none", boxShadow: "none", height:"2.3rem"}}autoFocus />
		</>
	);

	const dialogFooterBericht = (
		<>
			<Button label="Abbrechen" icon="pi pi-times" style={{backgroundColor: "red", color: "white", border: "none", boxShadow: "none", }}onClick={() => {setShowBericht(false)}} autoFocus />
			<Button label="Bericht erstellen" icon="pi pi-chart-bar" onClick={() => {getIndividuellenBericht(selectedRow); setShowBericht(false)}} style={{height:"2.2rem"}}autoFocus />
		</>
	);

	const dialogFooterBerichtTabelle = (
		<>
			<Button label="Abbrechen" icon="pi pi-times" style={{backgroundColor: "red", color: "white", border: "none", boxShadow: "none", }}onClick={() => {setShowBerichtTabelle(false)}} autoFocus />
			<Button label="Bericht erstellen" icon="pi pi-chart-bar" onClick={() => {getIndividuellenBerichtTabelle(); setShowBerichtTabelle(false)}} style={{height:"2.2rem"}}autoFocus />
		</>
	);

	const onEmailSend = (rowData) => {
		window.location.href = "mailto:"+rowData.Email;
	}

	const BlacklistFilterTemplate = (rowData) => {
		return(
			<div ><span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} className={rowData.BlacklistStatus === "Gesperrt" ? "pi pi-times-circle" : 
			rowData.BlacklistStatus === "Aktiv" ? "pi pi-check-circle" : "pi pi-circle-off"}></span><span> {rowData.BlacklistStatus}</span></div>
		)
	}

	const Emailbody = (row) => {
		let _mail = "mailto:" + row.Email;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={_mail}>{row.Email}</a>
	}

	const Adressenbody = (row) => {return <a >{row.ReStrasse}</a>}

	const PLZbody = (row) => {return <a >{row.RePLZ}</a>}

	const Mobilbody = (row) => {{
		let tellcall = "tel:"+row.Mobil;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.Mobil}</a>}}

	const FaxDWBody = (row) => {{return <a >{row.Fax}</a>}}

	const Vornamebody = (row) => {{return <a>{row.Vorname}</a>}}
	
	const Nachnamebody = (row) => {{return <a>{row.Nachname}</a>}}

	const KundenNummerbody = (row) => {{return <a>{row.KundenNummer}</a>}}

	const Telefonbody = (row) => {
		let tellcall = "tel:"+row.Telefon;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.Telefon}</a>}

	const selectedBlacklistFilterTemplate = (rowData) => {
		if(rowData === undefined){
			return; 
		} 
		if(rowData == "Gesperrt"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightPink"}} className={"pi pi-times-circle"}></div>
			)
		}
		if(rowData == "Aktiv"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightGreen"}} className={"pi pi-check-circle"}></div>
			)
		}
		if(rowData == "Inaktiv"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "Azure"}} className={"pi pi-circle-off"}></div>
			)
		}
	}

	const onBlacklistStatusChange = (e) => {
		setSelectedBlacklistStatus(e.target.value);
		datatableref.current.filter(e.target.value, "BlacklistStatus", "in")
	}

	const statusBodyTemplate = (rowData) => {
        return <span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} className={rowData.BlacklistStatus === "Gesperrt" ? "pi pi-times-circle" : 
								rowData.BlacklistStatus === "Aktiv" ? "pi pi-check-circle" : "pi pi-circle-off"}></span>;
    }

	const conditioncolor = (rowData) => {
		if(rowData.BlacklistStatus === "Gesperrt"){
			return "LightPink"; 
		}
		
		else if (rowData.BlacklistStatus === "Aktiv"){
			return "LightGreen";
		}
		
		else{
			return "Azure";
		}
	}

	const editKunde = rowData => {history.push("/kunde/bearbeiten/" + rowData.ID);}

	const deleteKunde = rowData => {
		kundenService.delete(rowData.ID).then(() => {
			kundenService.getAll().then(data => { setKunden(data); setFilteredKunden(data) });
		});
	};

	const onFilterResetClick = () => {
		setSelectedAdresse([]);
		setSelectedPLZ([]);
		setSelectedKommission([]);
		setSelectedOrt([]);
		setSelectedMail([]);
		setSelectedMobil([]);
		setSelectedVerkäufer([]);
		setSelectedKundenTyp ([]);
		setSelectedTelefon([]);
		setSelectedBlacklistStatus([]);
		setSelectedLand([]);
		setSelectedFaxDW([]); 
		setSelectedVorname([]);
		setSelectedNachname([]);
		setSelectedFirmenname([]);
		setSelectedKundennummer([]);
		setSelectedKunden([]);
		datatableref.current.reset();
	}

	const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
				<span className='m-0' style={{float: "right"}}>
					{/**<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>*/}
				</span>
            </div>
        )
    }

	function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

	function SimpleStringHash(str) {//https://stackoverflow.com/questions/17083316/hashing-a-string-between-two-integers-with-a-good-distribution-uniform-hash
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash += str.charCodeAt(i);
		}
		return hash;
	}

	function onKundeLoad(data) {
		setKunden(data);
		setFilteredKunden(data)
		let adressen = [];
		let PLZ = []; 
		let Ort = [];
		let Mail = [];
		let Mobil = []; 
		let Telefon = [];
		let FaxDW = [];
		let Vorname = []; 
		let Nachname = [];
		let Firmenname = []; 
		let Kundennummer = []; 

		data.forEach(element => {
			adressen.push(element.ReStrasse);
			PLZ.push(element.RePLZ);
			Kundennummer.push(element.KundenNummer); 
			if (element.Vorname?.length > 0){
				Vorname.push(element.Vorname); 
			} 
			if (element.Nachname?.length > 0){
				Nachname.push(element.Nachname); 
			} 
			if (element.Firmenbezeichnung?.length > 0){
				Firmenname.push(element.Firmenbezeichnung); 
			} 
			if (element.Fax?.length > 0){
				FaxDW.push(element.Fax); 
			} 
			/**
			if (element.Kommission?.length > 0) {
				Kommission.push(element.Kommission);
			}
			*/
			if (element.ReOrt?.length > 0) {
				Ort.push(element.ReOrt);
			}
			if (element.Email?.length > 0) {
				Mail.push(element.Email);
			}
			if (element.Mobil?.length > 0) {
				Mobil.push(element.Mobil);
			}
			if (element.Telefon?.length > 0){
				Telefon.push(element.Telefon);
			}
			
		});

		let _Vorname = [];
		Vorname.filter(onlyUnique).forEach(e => {
			_Vorname.push({Vorname: e});
		})
		setVorname(_Vorname); 

		let _Nachname = []; 
		Nachname.filter(onlyUnique).forEach(e => {
			_Nachname.push({Nachname: e});
		})
		setNachname(_Nachname); 

		let _Firmenbezeichnung = []; 
		Firmenname.filter(onlyUnique).forEach(e => {
			_Firmenbezeichnung.push({Firmenname: e});
		})
		setFirmenbezeichnung(_Firmenbezeichnung); 

		let _Kundennummer = [];
		Kundennummer.filter(onlyUnique).forEach(e => {
			_Kundennummer.push({Kundennummer: e}); 
		})
		setKundennummer(_Kundennummer); 

		let _FaxDW = [];
		FaxDW.filter(onlyUnique).forEach(e => {
			_FaxDW.push({FaxDW: e});
		});
		setFaxDW(_FaxDW); 

		let _Telefon = [];
		Telefon.filter(onlyUnique).forEach(e => {
			_Telefon.push({ErstePersonTelefon: e});
		}); 
		setTelefon(_Telefon); 

		let _Mobil = [];
		Mobil.filter(onlyUnique).forEach(e => {
			_Mobil.push({ErstePersonMobil: e});
		});
		setMobil(_Mobil);

		let _Mail = [];
		Mail.filter(onlyUnique).forEach(e => {
			_Mail.push({ErstePersonEmail: e});
		});
		setMail(_Mail);

		let _adressen = [];
		adressen.filter(onlyUnique).forEach(e => {
			_adressen.push({ReStrassen: e});
		});
		setAdressen(_adressen);

		let _PLZ = [];
		PLZ.filter(onlyUnique).forEach(e => {
			_PLZ.push({RePLZ: e});
		});
		setPLZ(_PLZ);

		let _Ort = [];
		Ort.filter(onlyUnique).forEach(e => {
			_Ort.push({ReOrt: e});
		});
		setOrt(_Ort);
	}


	return (
		<React.Fragment>
			<div className="p-component grid">
				{isLargeScreen &&(
					<>
						<div className="col-12">
							<Button label="Neuer priv. Kunde" icon="pi pi-plus-circle" onClick={createKunde}/>
							<Button label="Neuer Fa. Kunde" icon="pi pi-plus-circle" onClick={createKundeFa} style={{marginLeft:"1rem"}}/>
							<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)} style={{float:"right"}}/>
							<Button label="Bericht zur Tabelle erstellen" icon="pi pi-chart-bar" onClick={e => setShowBerichtTabelle(true)} style={{float:"right", marginRight:"1rem"}}/>
						</div>
					</>
				)}
				{!isLargeScreen &&(
					<>
						<div className="col-12">
							<Button label="Neuer priv. Kunde" icon="pi pi-plus-circle" onClick={createKunde}/>
							<Button label="Neuer Fa. Kunde" icon="pi pi-plus-circle" onClick={createKundeFa} style={{marginTop:"0.5rem"}}/>
							<Button icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)} style={{float:"right", marginTop:"0.5rem"}}/>

						</div>
					</>
				)}

			<div className="col-12" style={(loading01) ? { position: 'relative', filter: 'blur(2px)', zIndex: 1, pointerEvents: 'none' } : {}}>
				{(loading01) && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(48% - 1rem)", top: "calc(50% - 1rem)", zIndex: 9999}}/>}
				<DataTable ref={datatableref} responsive 
						value={kunden}
						style={{marginTop:"1rem"}}
						filterDisplay="row"
						className="p-datatable-striped tableCursorPointer"
						currentPageReportTemplate="({first} - {last} von {totalRecords})"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowHover paginator rows={20} rowsPerPageOptions={[20,30,50,100]} scrollable="true" scrollHeight="650px" scrollDirection="both"
						globalFilter={globalFilterValue} 
						selectionMode="multiple"  
						selection={selectedKunden}
						onRowClick={(e) => {
							const selectedData = e.data;
							setSelectedKunden((prevSelectedKunden) => {
							  	const isSelected = prevSelectedKunden.find(kunde => kunde.ID === selectedData.ID);

								if (isSelected) {
									return prevSelectedKunden.filter(kunde => kunde.ID !== selectedData.ID);
								} else {
									var temp = [...prevSelectedKunden, selectedData];
									return temp;
								}
							});
						  }}
						globalFilterFields={["global", "Kommission", "ReAdresse", "RePlz", "ReOrt", "ErstePersonEmail", "ErstePersonMobil", "KundenArt", "Verkäufer.Name", "ErstePersonTelefon", "FaxDW", "ErstePersonVorname", "ErstePersonNachname", "Firmenbezeichnung", "Kundennumer"]}
						sortOrder={1}
						sortMode="single"
						dataKey="ID"
						>
						<Column frozen={isLargeScreen} alignFrozen="left" align="center" field="BlacklistStatus" header="Status" style={{width: "110px"}} body={statusBodyTemplate} filter sortable
						filterMatchMode='contains'
						bodyClassName='align-content-center align-items-center text-center'
						filterElement={
							<MultiSelect value={selectedBlacklistStatus} onChange={onBlacklistStatusChange}
								style={{width: "80px"}} header="Status" options={statuse}
								optionLabel="BlacklistStatus" optionValue="BlacklistStatus"
								itemTemplate={BlacklistFilterTemplate} selectedItemTemplate={selectedBlacklistFilterTemplate} placeholder={"Alle"} filter/>
						}
						showFilterMatchModes={false} showFilterMenuOptions={false} showFilterOperator={false}
						filterClear={<div></div>} filterApply={<div></div>}  showFilterMenu={false} hidden={!columnVisibility.Status}
						/>

						<Column 
							field="ID" 
							sortable 
							header="ID"
							showFilterMenu={false}
							hidden={!columnVisibility.ID}
						/>

						
{selectedColumns.find((item) => item.field === "Vorname")?.visible === true && columnVisibility.Vorname &&
							VornameColumn({
								selectedVorname: selectedVorname,
								Vorname: Vorname,
								setSelectedVorname: setSelectedVorname,
								datatableref: datatableref,
								body: Vornamebody
							})
						}

						{selectedColumns.find((item) => item.field === "Nachname")?.visible === true && columnVisibility.Nachname &&
							NachnameColumn({
								selectedNachname: selectedNachname,
								Nachname: Nachname,
								setSelectedNachname: setSelectedNachname,
								datatableref: datatableref,
								body: Nachnamebody
							})
						}

						{selectedColumns.find((item) => item.field === "Firmenname")?.visible === true && columnVisibility.Firmenname &&
							FirmenbezeichnungColumn({
								selectedFirmenname: selectedFirmenname,
								Firmenbezeichnung: Firmenbezeichnung,
								setSelectedFirmenname: setSelectedFirmenname,
								datatableref: datatableref
							})
						}

						<Column 
							field="FN" 
							sortable 
							header="Firmenbuchnummer"
							showFilterMenu={false}
							hidden={!columnVisibility.Firmenbuchnummer}
						/>

						<Column 
							field="UID" 
							sortable 
							header="UID Nummer"
							showFilterMenu={false}
							hidden={!columnVisibility.UIDNummer}
						/>

						<Column 
							field="ZVR" 
							sortable 
							header="ZVR Nummer"
							showFilterMenu={false}
							hidden={!columnVisibility.ZVRNummer}
						/>

						{selectedColumns.find((item) => item.field === "Adresse").visible === true && columnVisibility.Adresse &&
							AdresseColumn({
								selectedAdresse: selectedAdresse, 
								Adressen: Adressen, 
								setSelectedAdresse:setSelectedAdresse, 
								datatableref:datatableref,
								body: Adressenbody
							})
						}
					
					
						{selectedColumns.find((item) => item.field === "Land")?.visible === true && columnVisibility.Land &&
							LandColumn({
								selectedLand: selectedLand, 
								Laender: Laender,
								setSelectedLand: setSelectedLand,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "PLZ")?.visible === true && columnVisibility.PLZ &&
							PLZColumn({
								selectedPLZ: selectedPLZ,
								PLZ: PLZ,
								setSelectedPLZ: setSelectedPLZ,
								datatableref: datatableref,
								body: PLZbody
							})
						}

						{selectedColumns.find((item) => item.field === "Ort")?.visible === true && columnVisibility.Ort &&
							OrtColumn({
								selectedOrt: selectedOrt,
								Ort: Ort,
								setSelectedOrt: setSelectedOrt,
								datatableref: datatableref
							})
						}
						
						{selectedColumns.find((item) => item.field === "E-Mail")?.visible === true && columnVisibility.Email &&
							EmailColumn({
								selectedMail: selectedMail,
								Mail: Mail,
								setSelectedMail: setSelectedMail,
								datatableref: datatableref,
								body: Emailbody
							})
						}

						{selectedColumns.find((item) => item.field === "Telefon")?.visible === true && columnVisibility.Telefonnummer &&
							TelefonColumn({
								selectedTelefon: selectedTelefon,
								Telefon: Telefon,
								setSelectedTelefon: setSelectedTelefon,
								datatableref: datatableref,
								body: Telefonbody
							})
						}
						{selectedColumns.find((item) => item.field === "Mobil")?.visible === true && columnVisibility.Handynummer &&
							MobilColumn({
								selectedMobil: selectedMobil,
								Mobil: Mobil,
								setSelectedMobil: setSelectedMobil,
								datatableref: datatableref,
								body: Mobilbody
							})
						}

						{selectedColumns.find((item) => item.field === "Fax-DW")?.visible === true && columnVisibility.FaxDW &&
							FaxDWColumn({
								selectedFaxDW: selectedFaxDW,
								FaxDW: FaxDW,
								setSelectedFaxDW: setSelectedFaxDW,
								datatableref: datatableref,
								body: FaxDWBody
							})
						}

						{selectedColumns.find((item) => item.field === "Kundentyp")?.visible === true && columnVisibility.Typ &&
							KundentypColumn({
								selectedKundenTyp: selectedKundenTyp,
								setSelectedKundenTyp: setSelectedKundenTyp,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Kundennummer")?.visible === true && columnVisibility.Kundennummer &&
						 	KundennummerColumn({
								selectedKundennummer: selectedKundennummer,
								Kundennummer: Kundennummer,
								setSelectedKundennummer: setSelectedKundennummer,
								datatableref: datatableref,
								body: KundenNummerbody
							}) 
						}

						<Column frozen={isLargeScreen} alignFrozen='right' header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: "E-Mail senden", icon: "pi pi-envelope", command: () => onEmailSend(rowData) },
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => { editKunde(rowData)}},
							{ label: 'Bericht erstellen', icon: 'pi pi-chart-bar', command: () => { setShowBericht(true); setSelectedRow(rowData)}},
							{ label: 'Löschen', icon: 'pi pi-trash', command: () => { setShowDelete(true); setDeletData(rowData);}},
							//{ label: "Verkäufe", icon: "pi pi-euro", command: () => showVerkäufe(rowData) },
							//{ label: "Rechnungen", icon: "pi pi-file-o"},
						]}/>}
						style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
				<Divider/>
				<div className='col-12'>
					
				</div>
			<div className="col-12" style={{display:'flex', flexDirection: 'column'}}>
				<div className={'col-12'}>
					<Card 
						style={{width: '100%', height: '100%'}}
						title={
						<React.Fragment>
							Monats Vergleich Einkäufe 
							<br />
							<span style={{fontSize: '1rem', color: '#555',}}>Kunden auswählen um Daten zu laden (gemäß oben ausgewählter Liste) </span>
							{isLargeScreen && ( 
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: isLargeScreen ? "-3rem" : "1rem"}} >
								<Button
									icon="pi pi-table"
									onClick={getTableData}
									label='Ergebnissliste laden'
									style={{marginLeft:"0.5rem"}}
									/>
								<Dropdown 
										value={selectedMonateMenge} 
										options={[2, 3, 6, 9, 12]} 
										onChange={e => setSelectedMonateMenge(e.target.value)} 
										valueTemplate={w => 
											<React.Fragment>{w} Monate</React.Fragment>
										} 
										style={{float: "right", marginLeft:"0.3rem"}}
										/>

								<MultiSelect 
									value={selectedKassen1} 
									options={kassen} 
									onChange={e => setSelectedKassen1(e.target.value)} 
									placeholder="Alle Kassen" 
									optionLabel="KassenID" 
									optionValue="KassenID"
									style={{float: "right", marginLeft:"0.3rem"}}
									/>
								</div>
							)}
							{!isLargeScreen && ( 
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginTop: isLargeScreen ? "-3rem" : "1rem"}} >
								<Button
									icon="pi pi-table"
									onClick={getTableData}
									label='Ergebnissliste laden'
									style={{marginTop:"0.5rem"}}
									/>
								<Dropdown 
										value={selectedMonateMenge} 
										options={[2, 3, 6, 9, 12]} 
										onChange={e => setSelectedMonateMenge(e.target.value)} 
										valueTemplate={w => 
											<React.Fragment>{w} Monate</React.Fragment>
										} 
										style={{float: "right", marginTop:"0.5rem"}}
										/>

								<MultiSelect 
									value={selectedKassen1} 
									options={kassen} 
									onChange={e => setSelectedKassen1(e.target.value)} 
									placeholder="Alle Kassen" 
									optionLabel="KassenID" 
									optionValue="KassenID"
									style={{float: "right", marginTop:"0.5rem"}}
									/>
								</div>
							)}
						</React.Fragment>}>
						<ZoomPopup 
							header="Monats Vergleich" 
							footer={
								<Button 
									label="Download" 
									icon="pi pi-image" 
									onClick={() => download(monateVergleichChartRef.current.getBase64Image(), 'MonatsVergleich.png')} 
									/>
							}>


							<Chart
								ref={monateVergleichChartRef} 
								type="bar"
								data={{
									labels: monateVergleichData.labels,
									datasets: monateVergleichData.datasets
								  }} 
								options={{animation: {duration: 0}, scales: {x: {stacked:false}, y: {stacked:false}}, style: {marginTop:"10rem"}, plugins: {legend: {position: 'bottom' }}}} 
								/>
								
						</ZoomPopup>
					</Card>
				</div>

				<div className={'col-12'}>
					<Card 
						style={{width: '100%', height: '100%'}}
						title={
						<React.Fragment>
							Monats Vergleich Kunden
							{isLargeScreen && (
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: isLargeScreen ? "-1.2rem" : "1rem"}}>
									<Dropdown 
										value={selectedMonateMenge2} 
										options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} 
										onChange={e => setSelectedMonateMenge2(e.target.value)}
										style={{float: "right", marginRight:"0.5rem"}}/>

									<Dropdown 
										value={selectedJahrMenge2} 
										options={jahresOptions} 
										onChange={e => setSelectedJahrMenge2(e.target.value)}
										style={{float: "right", marginRight:"0.5rem"}}/>

									<MultiSelect 
										value={selectedKassen2} 
										options={kassen} 
										onChange={e => setSelectedKassen2(e.target.value)} 
										placeholder="Alle Kassen" 
										optionLabel="KassenID" 
										optionValue="KassenID"
										style={{float: "right"}}/>
								</div>
							 )}
							 {!isLargeScreen && (
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginTop: isLargeScreen ? "-1.2rem" : "1rem"}}>
									<Dropdown 
										value={selectedMonateMenge2} 
										options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} 
										onChange={e => setSelectedMonateMenge2(e.target.value)}
										style={{float: "right", marginTop:"0.5rem"}}/>

									<Dropdown 
										value={selectedJahrMenge2} 
										options={jahresOptions} 
										onChange={e => setSelectedJahrMenge2(e.target.value)}
										style={{float: "right", marginTop:"0.5rem"}}/>

									<MultiSelect 
										value={selectedKassen2} 
										options={kassen} 
										onChange={e => setSelectedKassen2(e.target.value)} 
										placeholder="Alle Kassen" 
										optionLabel="KassenID" 
										optionValue="KassenID"
										style={{float: "right", marginTop:"0.5rem"}}/>
								</div>
							 )}
						</React.Fragment>}>
						<ZoomPopup 
							header="Monats Vergleich" 
							footer={
								<Button 
									label="Download" 
									icon="pi pi-image" 
									onClick={() => download(monateVergleichChartRef.current.getBase64Image(), 'MonatsVergleich.png')} 
									/>
							}>


							<Chart
								ref={monateVergleichChartRef}
								type="bar"
								data={monateVergleichKundenData}
								options={{
									animation: { duration: 0 },
									scales: { x: { stacked: false }, y: { stacked: false } },
									style: { marginTop: "10rem" },
									plugins: { legend: { position: 'bottom' } },
								}}
								/>
								
						</ZoomPopup>
					</Card>
				</div>
			</div>

			<Dialog 
				header="Einstellungen" 
				visible={einstellungenVisible} 
				style={{ width: isLargeScreen ? '15vw' : '80vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setEinstellungenVisble(false);}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
				}}
				>
					<div className="grid">
						{['ID', 'Status', 'Adresse', 'Land', 'PLZ', 'Ort', 'Email', 'Telefonnummer', 'Handynummer', 'Typ', 'FaxDW',  'Vorname' , 'Nachname', 'Firmenname', 'Firmenbuchnummer', 'UIDNummer', 'ZVRNummer', 'Kundennummer'].map((field) => (
						<div className="col-12" key={field}>
							<Checkbox 
							value={field} 
							checked={columnVisibility[field]} 
							className="mr-3"
							onChange={(e) => {
								setColumnVisibility(prev => ({
								...prev,
								[field]: e.checked,
								}));
							}}
							/>
							<label 
								htmlFor={`checkbox-${field}`} 
								className="mr-3" 
								onClick={(e) => {
									setColumnVisibility(prev => ({
										...prev,
										[field]: !prev[field],
									}));
								}}>
								{field}
							</label>
						</div>
						))}
					</div>
			</Dialog>

			<Dialog 
				visible={showDelete} 
				style={{ width: isLargeScreen ? '20vw' : "90vw"}} 
				modal 
				header={deletData?.KundenNummer + ", " + deletData?.Vorname+ " " + deletData?.Nachname}
				footer={dialogFooterDelete} 
				onHide={() => setShowDelete(false)}
				>
				<span>Willst du diesen Kunden wirklich löschen?</span>
			</Dialog>

			<Dialog 
				visible={showBericht} 
				style={{ width: isLargeScreen ? '20vw' : "90vw"}} 
				modal 
				header={selectedRow?.KundenNummer + ", " + selectedRow?.Vorname+ " " + selectedRow?.Nachname}
				footer={dialogFooterBericht} 
				onHide={() => setShowBericht(false)}
				>
				<h2>Bericht erstellen:</h2>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className='col-2'>
						<span>Von: </span>
					</div>
					<div className='col-10'>
						<Calendar 
							id="vonBericht" 
							value={vonBericht}
							showIcon={true} 
							dateFormat="dd.mm.yy"
							onChange={e => {
								setVonBericht(e.value)
							}}	
							/>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className='col-2'>
						<span>Bis: </span>
					</div>

					<div className='col-10'>
						<Calendar 
							id="bisBericht" 
							value={bisBericht}
							showIcon={true} 
							dateFormat="dd.mm.yy"
							onChange={e => {
								setBisBericht(e.value)
							}}	
							/>
					</div>

				</div>
			</Dialog>

			<Dialog 
				visible={showBerichtTabelle} 
				style={{ width: isLargeScreen ? '20vw' : "90vw"}} 
				modal 
				header={"Bericht erstellen"}
				footer={dialogFooterBerichtTabelle} 
				onHide={() => setShowBerichtTabelle(false)}
				>
				<h2>Bericht erstellen:</h2>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className='col-2'>
						<span>Von: </span>
					</div>
					<div className='col-10'>
						<Calendar 
							id="vonBericht" 
							value={vonBericht}
							showIcon={true} 
							dateFormat="dd.mm.yy"
							onChange={e => {
								setVonBericht(e.value)
							}}	
							/>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div className='col-2'>
						<span>Bis: </span>
					</div>

					<div className='col-10'>
						<Calendar 
							id="bisBericht" 
							value={bisBericht}
							showIcon={true} 
							dateFormat="dd.mm.yy"
							onChange={e => {
								setBisBericht(e.value)
							}}	
							/>
					</div>

				</div>
			</Dialog>
		</React.Fragment>
	);
}