import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function FirmenbezeichnungColumn(props) {

	const onFirmennameChange = (e) => {
		props.setSelectedFirmenname(e.target.value); 
		props.datatableref.current.filter(e.target.value, "Firmenbezeichnung", "in"); 
	}

	return(
		<Column header="Firmenname" field="Firmenbezeichnung" style={{width: "200px", maxWidth:"15rem"}} filter sortable filterMatchMode="contains"
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		filterElement={
			<MultiSelect value={props.selectedFirmenname} options={props.Firmenbezeichnung} optionLabel="Firmenname" optionValue='Firmenname' onChange={onFirmennameChange}
			placeholder={"Alle"} filter/>
		}
		/>
	)
}