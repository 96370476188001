import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function FaxDWColumn(props) {

	const onFaxDWChange = (e) => {
		props.setSelectedFaxDW(e.target.value);
		props.datatableref.current.filter(e.target.value, "Fax", "in");
	}

	return(
		<Column header="Fax-DW" field="FaxDW" style={{width: "200px", maxWidth:"7rem"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedFaxDW} options={props.FaxDW} optionLabel="FaxDW" optionValue='FaxDW' onChange={onFaxDWChange}
			placeholder={"Alle"} filter/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={props.body}
		/>
	)
}