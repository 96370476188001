import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { SelectButton } from "primereact/selectbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PopupMenu } from "./PopupMenu";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { KassenService } from "../service/KassenService";
import { AktionService } from "../service/AktionService";
import { ProduktService } from "../service/ProduktService";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";



export function Aktionen() {
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [eingelöst, setEingelöst] = useState(2);
	const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [selectedGruppen, setSelectedGruppen] = useState(null);
    const [selectedKassen, setSelectedKassen] = useState(null);
    const [selectedBezeichnung, setSelectedBezeichnung] = useState(null);
    const [selectedWiederkehrend, setSelectedWiederkehrend] = useState(null);
    const [selectedTyp, setSelectedTyp] = useState(null);
	const [kassen, setKassen] = useState([]);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);
	const [typOptions, setTypOptions] = useState(null);
	const [tempData, setTempData] = useState([]);
	const [rowProdukte, setRowProdukte] = useState([]);
	const [selectedProdukt, setSelectedProdukt] = useState([]);
	const [allProdukte, setAllProdukte] = useState([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [deleteData, setDeleteData] = useState(null);
	const [selectedDatumsbereichStart, setSelectedDatumsbereichStart] = useState(null);
	const [selectedDatumsbereichEnd, setSelectedDatumsbereichEnd] = useState(null);
	const [einstellungenVisble, setEinstellungenVisble] = useState(null);
	const [showNotAktivierbar, setShowNotAktivierbar] = useState(null);
	const [showNoPermission, setShowNoPermission] = useState(null);
	const [loading01, setLoading01] = useState(null);
	const [columnVisibility, setColumnVisibility] = useState({
		ID: false,
		Von: true,
		Bis: true,
		Bezeichnung: true,
		Typ: true,
		Serienmuster: true,
		Gruppe: true,
		Produkt: true,
		Standort: true,
	  });


    const history = useHistory();	
    let dt = useRef(null);



	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1500);
	};

	
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);



	const kassenService = new KassenService();
	const aktionService = new AktionService();
	const produktService = new ProduktService();


	
	  

	const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
                    { label: 'bearbeiten', icon: 'pi pi-pencil', command: () => onEditClick(rowData) },
                    { label: 'kopieren', icon: 'pi pi-copy', command: () => onCopyClick(rowData) },
					{ label: 'löschen', icon: 'pi pi-trash', command: async () => onDeleteClick(rowData) }
				]}
                />
            </React.Fragment>
        );
    };

	const onEditClick = (e) => {
		if(e.Permission == true){
			history.push({
				pathname: `/neueAktion/`,
				state: {aktionsID: e.iD, art: "edit"
				}
			});
		}
		else{
			setShowNoPermission(true);
		}
    }

    const onCopyClick = (e) => {
		if(e.Permission == true){
			history.push({
				pathname: `/neueAktion/`,
				state: {aktionsID: e.iD, art: "copy"
				}
			});
		}
		else{
			setShowNoPermission(true);
		}
    }

	const onDeleteClick = (e) => {
		if(e.Permission == true){
			setShowDeleteDialog(true);
			setDeleteData(e);
		}
		else{
			setShowNoPermission(true);
		}
    }



	const onRealDelete = (e) => {
		aktionService.deleteAktion(e.iD).then(res => {
			aktionService.getAktionen().then(data => {
				setTempData(data);
				data.forEach(row => {
					aktionService.getProdukte(row.kategorie, row.produktID, row.akProzent, row.akText)
					.then(produktData => {
						setTempData(prevData => {
						return prevData.map(item => {
							if (item.produktID === row.produktID) {
							return { ...item, ProduktData: produktData };
							}
							return item;
						});
						});
					})
					.catch(error => {
						console.error("Fehler beim Abrufen der Produktdaten: ", error);
					});
				});
			});
		});
    }

	const handleAktivChange = (id, value) => {
		if(value == false){
			setTempData(prevData =>
				prevData.map(item =>
					item.iD === id ? { ...item, aktiv: value } : item
				)
			);
			aktionService.changeAktiv(id,value).then(data => console.log(data));
		}
		else if(value == true){
			aktionService.getAktionByGuid(id).then(data => {
				aktionService.alreadyAktion(data[0], id).then(data2 => {
					const updatedList = data2.filter(entry => entry.iD !== id);
					if(updatedList.length > 0){
						setShowNotAktivierbar(true);
					}
					else{
						setTempData(prevData =>
							prevData.map(item =>
								item.iD === id ? { ...item, aktiv: value } : item
							)
						);
						aktionService.changeAktiv(id,value).then(data => console.log(data));
					}
				})
			});
		}
	};

	const onNeueAktionClick = () => {
        history.push("neueAktion");
    }

	const onInfoClick = (row) => {
		setRowData(row);
		aktionService.getProdukte(row.kategorie, row.produktID, row.akProzent, row.akText, row.iD).then(data => setRowProdukte(data));
        setVisible(true);
	}

	const dialogFooter = (
        <Button label="Schließen" icon="pi pi-times" onClick={() => {setVisible(false)}} autoFocus />
    );
	const dialogFooterDelete = (
		<>
			<Button label="Abbrechen" icon="pi pi-times" onClick={() => {setShowDeleteDialog(false)}} autoFocus />
			<Button label="Löschen" icon="pi pi-trash" onClick={() => {onRealDelete(deleteData); setShowDeleteDialog(false)}} style={{backgroundColor: "red", color: "white", border: "none", boxShadow: "none", height:"2.3rem"}}autoFocus />
		</>
    );

	useEffect(() => {
		setGlobalState({...globalState, page: 'Aktionen'});
		setLoading01(true);

		kassenService.getKassen().then(data => setKassen(data));

		aktionService.getAktionsTypen().then(data => setTypOptions(data));

		aktionService.getAktionen().then(data => {
			setTempData(data);
		  
			const produktPromises = data.map(row => {
			  return aktionService.getProdukte(row.kategorie, row.produktID, row.akProzent, row.akText, row.iD)
				.then(produktData => {
				  return { ...row, ProduktData: produktData };
				})
				.catch(error => {
				  console.error("Fehler beim Abrufen der Produktdaten: ", error);
				  return { ...row, ProduktData: [] };
				});
			});
		  
			Promise.all(produktPromises)
			  .then(updatedData => {
				const filteredData = updatedData.filter(row => row.ProduktData && row.ProduktData.length > 0);

				async function addPermissions() {
					const updatedFilteredData = await Promise.all(
					  filteredData.map(async (row) => {
						const permissionResult = await aktionService.hasPermission(row.iD); 
						row.Permission = permissionResult; 
						return row;
					  })
					);
				  
					setTempData(updatedFilteredData);
					setLoading01(false);
				}
				addPermissions();

			});
		});


		produktService.getProdukte().then(data => setAllProdukte(data));


		// eslint-disable-next-line
	}, []);


	const renderGruppenFilterOptions = () => {
        return (
            <MultiSelect 
				className="column-filter" 
				value={selectedGruppen} 
				options={[
					{ label: "Produkt", value: "Produkt" },
					{ label: "Artikelgruppe", value: "Artikelgruppe" },
					{ label: "Warengruppe", value: "Warengruppe" },
					{ label: "Kategorie", value: "Kategorie" },
					{ label: "Sortiment", value: "Sortiment" },
					{ label: "frei", value: "frei" },
				]}
				onChange={e => { 
					dt.current.filter(e.target.value, 'kategorie', 'in');
        			setSelectedGruppen(e.target.value); 
				}}                
				placeholder="Alle"
                optionLabel="label"
                optionValue="value"
                filter/>
        );
    };


	const renderProduktFilterOptions = () => {
		return (
			<MultiSelect 
				className="column-filter" 
				value={selectedProdukt} 
				options={allProdukte}
				onChange={e => {
					const aktionsIDs = tempData
						.filter(item => item.ProduktData.some(product => e.target.value.includes(product.ProduktID)))
						.map(item => item.iD);
					dt.current.filter(aktionsIDs, 'iD', 'in');
					setSelectedProdukt(e.target.value);
				}}  
	
				placeholder="Alle"
				optionLabel="Bezeichnung"
				optionValue="ID"
				filter
			/>
		);
	};
	

	const renderStandortFilterOptions = () => {
		if (!kassen || kassen.length === 0) {
			return <div>Loading...</div>;
		}

		return (
			<MultiSelect
				className="column-filter"
				value={selectedKassen} 
				options={kassen} 
				onChange={e => {
					const sortedValues = [...e.value].sort();

					setSelectedKassen(e.value);
					dt.current.filter(sortedValues, 'kassen', 'contains');
				}}
				placeholder="Alle" 
				optionLabel="KassenID"
				optionValue="KassenID" 
				maxSelectedLabels={3}
			/>
		);
	}

	const renderWiederkehrendFilterOptions = () => {
		return (
			<MultiSelect
				className="column-filter"
				value={selectedWiederkehrend} 
				options={[
					{ label: "Täglich", value: "Täglich" },
					{ label: "Wöchentlich", value: "Wöchentlich" },
					{ label: "Monatlich", value: "Monatlich" },
					{ label: "Jährlich", value: "Jährlich" },
				]}				
				onChange={e => { 
					setSelectedWiederkehrend(e.target.value); 
					dt.current.filter(e.target.value, 'serienMuster', 'in'); 
				}}
				placeholder="Alle" 
				optionLabel="label"
				optionValue="value" 
				maxSelectedLabels={3}
			/>
		);
	}

	const renderTypFilterOptions = () => {
		return (
			<MultiSelect
				className="column-filter"
				value={selectedTyp} 
				options={typOptions} 
				onChange={e => { 
					setSelectedTyp(e.target.value); 
					dt.current.filter(e.target.value, 'akTypenBezeichnung', 'in'); 
				}}
				placeholder="Alle" 
				optionLabel="aktionsTypBezeichnung"
				optionValue="aktionsTypBezeichnung"
				maxSelectedLabels={3}
			/>
		);
	}

	const renderBezeichnungFilterOptions = () => {
        return (
            <InputText 
				value={selectedBezeichnung} 
				placeholder="Bezeichnung"
				onValueChange={e => setSelectedBezeichnung(e.value)}
				onChange={(e) => {                
					const value = e.target.value;
					dt.current.filter(value, 'bezeichnung', 'contains');
				}}/>
        );
    }

	
	const renderDatumFilterStartOptions = () => {
		return (
			<Calendar
				id="datumsbereich"
				className="column-filter"
				value={selectedDatumsbereichStart}
				onChange={onDateChange}
				showIcon={true}
				dateFormat="dd.mm.yy"
				placeholder="Zeitraum wählen"/>
		);
	};
	const onDateChange = (e) => {
        setSelectedDatumsbereichStart(e.value);

		const filteredData = dt.current.filter((row) => {
			const startDate = new Date(row.zeitraumEnde);

			const result = startDate > e.value;
			return result;
		});
		dt.current.filter(filteredData);

    };

	const renderDatumFilterEndOptions = () => {
		return (
			<Calendar
				id="datumsbereich"
				className="column-filter"
				value={selectedDatumsbereichEnd}
				onChange={onDateChange2}
				showIcon={true}
				dateFormat="dd.mm.yy"
				placeholder="Zeitraum wählen"/>
		);
	};
	const onDateChange2 = (e) => {
        setSelectedDatumsbereichEnd(e.value);
		const filteredData = dt.current.filter((row) => {
			const endDate = new Date(row.zeitraumStart);

			const result = endDate < e.value;
			return result;
		});
		
		dt.current.filter(filteredData);
    };

	const renderDatumFilterStart = renderDatumFilterStartOptions();
	const renderDatumFilterEnde = renderDatumFilterEndOptions();






	const renderTypFilter = renderTypFilterOptions();
	const renderBezeichnungFilter = renderBezeichnungFilterOptions();
	const renderWiederkehrendFilter = renderWiederkehrendFilterOptions();
	const renderGruppenFilter = renderGruppenFilterOptions();
	const renderProduktFilter = renderProduktFilterOptions();
	const renderStandortFilter = renderStandortFilterOptions();


	return (
		<React.Fragment>
			<div className='grid'>
				<div className="col-6">
					<Button label="neue Aktion" icon="pi pi-plus-circle" className="mr-3" onClick={onNeueAktionClick} style={{width:isLargeScreen ? "" : "10rem"}} />
				</div>
                <div className='col-12 p-component'>
					{isLargeScreen &&(
						<div className="flex justify-content-between align-items-center">
							<SelectButton 
								value={eingelöst} 
								options={[
									{ value: 2, name: `aktiv (${tempData.filter(item => item.aktiv === true && new Date(item.zeitraumEnde) > new Date()).length})` },
									{ value: 1, name: `inaktiv (${tempData.filter(item => item.aktiv === false && new Date(item.zeitraumEnde) > new Date()).length})` },
									{ value: 0, name: `abgeschlossen (${tempData.filter(item => new Date(item.zeitraumEnde) < new Date()).length})` }
								]}
								optionLabel="name" 
								optionValue="value"
								onChange={(e) => setEingelöst(e.value)} 

								/>

							<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
						</div>
					)}

					{!isLargeScreen &&(
						<>
							<div className="flex justify-content-between align-items-center">
								<SelectButton 
									value={eingelöst} 
									options={[{value: 2, name: "aktiv"},{ value: 1, name: "inaktiv"},{ value: 0, name: "abgeschlossen"}]}
									optionLabel="name" 
									optionValue="value"
									onChange={(e) => setEingelöst(e.value)} 
									style={{width:"18rem", maxWidth:"18rem", minwidth:"18rem", marginTop:"1rem"}}
									/>
								
								<Button icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)} style={{float:"right", marginTop:"1rem"}}/>

							</div>

						</>

					)}
                </div>
				
                <div className="col-12 p-component">
					<div style={{ position: 'relative', width: '100%' }}>

					{loading01 && (
						<div style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 9999,
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
						}}>
							<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', zIndex: 10000 }} />
						</div>
					)}
					<DataTable
						ref={dt} 
						value={tempData.filter(item => {
							const startDate = new Date(item.zeitraumStart);
							const isAfterSelectedStart = selectedDatumsbereichStart ? new Date(startDate) > new Date(selectedDatumsbereichStart) : true;

							const ende = new Date(item.zeitraumEnde);
							const isBeforeSelectedEnd = selectedDatumsbereichEnd ? new Date(ende) < new Date(selectedDatumsbereichEnd) : true;

							if (eingelöst === 2) {
								var endDate = new Date(item.zeitraumEnde)
								return (isAfterSelectedStart || isBeforeSelectedEnd) && (endDate > new Date() && item.aktiv === true);
							} 
							else if (eingelöst === 1) {
								var endDate = new Date(item.zeitraumEnde)
								return (isAfterSelectedStart || isBeforeSelectedEnd) && (endDate > new Date() && item.aktiv === false);
							} 
							else if (eingelöst === 0) {
								var endDate = new Date(item.zeitraumEnde)
								return endDate < new Date();
							}
							return isAfterSelectedStart || isBeforeSelectedEnd;
						})}			
						filterDisplay="row"
						dataKey="ID"
						scrollable scrollDirection="both" scrollHeight='75vh' className="p-datatable-produkte p-datatable-striped" 
						rowHover 
						paginator rows={20} 
						emptyMessage="keine Rabatte gefunden" 
						currentPageReportTemplate='({first} von {totalRecords})'
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[20,30,40]} 
                        >
						<Column
							field="aktionsID"
							header="ID"
							showFilterMenu={false}
							sortable  
							body={row => <span>{row.aktionsID}</span>}
							style={{
								maxWidth: "12rem",
								wordWrap: "break-word",
								whiteSpace: "normal"
							}}
							hidden={!columnVisibility.ID}
							/>
									
						<Column field="zeitraumStart" header="Von"
							style={{maxWidth: "16em", width: "16em"}}
							sortable
                            filter
							showFilterMenu={false}
                            filterElement={renderDatumFilterStart}
							body={row => {
								var startDate = new Date(row.zeitraumStart );
							
								const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}.${(startDate.getMonth() + 1).toString().padStart(2, '0')}.${startDate.getFullYear()} ${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
							
								return <span>{formattedStartDate}</span>;
							}}
							hidden={!columnVisibility.Von}
							/>
						<Column field="zeitraumEnde" header="Bis"
							style={{maxWidth: "16em", width: "16em"}}
							sortable
                            filter
							showFilterMenu={false}
                            filterElement={renderDatumFilterEnde}
							body={row => {
								var startEnd = new Date(row.zeitraumEnde );
							
								const formattedEndDate = `${startEnd.getDate().toString().padStart(2, '0')}.${(startEnd.getMonth() + 1).toString().padStart(2, '0')}.${startEnd.getFullYear()} ${startEnd.getHours().toString().padStart(2, '0')}:${startEnd.getMinutes().toString().padStart(2, '0')}`;
							
								return <span>{formattedEndDate}</span>;
							}}
							hidden={!columnVisibility.Bis}
							/>

						

						<Column
							field="bezeichnung"
							header="Bezeichnung"
							filter
							filterPlaceholder="Bezeichnung eingeben"
							showFilterMenu={false}
							filterElement={renderBezeichnungFilter}
							filterClear={() => {
								setSelectedBezeichnung("");
								dt.current.filter(null, 'bezeichnung', 'contains');
							}}
							sortable  
							body={row => <span>{row.bezeichnung}</span>}
							style={{
								maxWidth: "12rem",
								wordWrap: "break-word",
								whiteSpace: "normal"
							}}
							hidden={!columnVisibility.Bezeichnung}
							/>

						<Column
                            field="akTypenBezeichnung" 
							header="Typ"
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.akTypenBezeichnung}</span>}
                            filter sortable 
							filterElement={renderTypFilter}
							filterClear={() => setSelectedTyp(null)} 
							showFilterMenu={false} 
							hidden={!columnVisibility.Typ}
                            />
						<Column
                            field="serienMuster" 
							header="Serienmuster"
                            filter 
                            filterPlaceholder="Serienmuster"
							filterElement={renderWiederkehrendFilter} 
							showFilterMenu={false}
							filterClear={() => {setSelectedWiederkehrend(null);}} 
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.serienMuster}</span>}
                            sortable 
							hidden={!columnVisibility.Serienmuster}
                            />
                        <Column
                            field="kategorie" header="Gruppe"
                            filter 
							showFilterMenu={false}
                            filterElement={renderGruppenFilter}
							filterClear={() => {setSelectedGruppen(null);}} 
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.kategorie}</span>}
                            sortable 
							hidden={!columnVisibility.Gruppe}
                            />
						
						<Column
                            field="produktID" header="Produkt"
                            filter 
                            showFilterMenu={false} 
                            sortable
							filterElement={renderProduktFilter}
							filterClear={() => null} 
							body={row => (
								<span>
								  {row.ProduktData && row.ProduktData.length > 0 
									? `${row.ProduktData[0].Bezeichnung}${row.ProduktData.length > 1 ? ` + ${row.ProduktData.length - 1} weitere` : ''}`
									: 'No Data'}
								</span>
							)}
							style={{maxWidth:"15rem"}}
							hidden={!columnVisibility.Produkt}
							/>
							 
                        <Column
                            field="kassen" 
							header="Standort"
                            filter 
							filterPlaceholder="Kassa eingeben"
							filterElement={renderStandortFilter} 
							showFilterMenu={false} 
                            sortable 
							body={row => {
								const kassen = row.kassen;
								const kassenCount = kassen.length;
								if (kassenCount > 1) {
									return <span>{kassen[0]} + {kassenCount - 1} weitere</span>;
								} else {
									return <span>{kassen[0]}</span>;
								}
							}}
							hidden={!columnVisibility.Standort}
							/>

						<Column
                            field="info"
                            body={row => 
								<i 
									className="pi pi-info-circle" 
									style={{
										fontSize:"1.3rem",
										cursor: "pointer",
										transition: "transform 0.2s, color 0.3s"
									}} 
									title={'ID:' + row.aktionsID + "\nKassen: "+ row.kassen}
									data-pr-tooltip="Information über Aktionen" 
									onClick={() => onInfoClick(row)}
									onMouseEnter={(e) => {
										e.target.style.transform = "scale(1.1)";
									}}
									onMouseLeave={(e) => {
										e.target.style.transform = "scale(1)";
									}}
								/>
							}
                            showFilterMenu={false} 
							style={{width:"1rem", maxWidth:"1.4rem"}}
                            />
						<Column
                            field="changeAktiv"
							header="Aktiv"
							body={row => 
								<InputSwitch                                 
									checked={row.aktiv} 
									onChange={(e) => handleAktivChange(row.iD, e.value)}
									autoFocus={false} 
									style={{ scale: "0.8",  marginTop:'0rem', marginLeft:"0.5rem"}}/>
							}
                            showFilterMenu={false} 
							style={{width:"0.5rem", maxWidth:"4rem"}}
                            />
						<Column
							body={actionBodyTemplate}
                            bodyStyle={{textAlign: 'center', overflow: 'visible', maxWidth: '7em'}}
                            style={{maxWidth: '7em', minWidth:"10rem"}}
                            showFilterMenu={false}
                            />
					</DataTable>
				</div>
				</div>
				<Divider/>
                <div className="grid p-component">
					<div style={{ marginLeft: "1em", marginRight: "1em", marginTop:"1rem" }}>
						<h2 style={{ fontWeight: "bold" }}>Statistik: </h2>	
						<table className="mt-3 mb-4">
							<tr>
								<th style={{ textAlign: "left", paddingRight: "10px" }}>Aktive Aktionen:</th>
								<td style={{ textAlign: "right" }}>{tempData.filter(item => item.aktiv === true && new Date(item.zeitraumEnde) > new Date()).length}</td>
							</tr>
							<tr>
								<th style={{ textAlign: "left", paddingRight: "10px" }}>Inaktive Aktionen:</th>
								<td style={{ textAlign: "right" }}>{tempData.filter(item => item.aktiv === false && new Date(item.zeitraumEnde) > new Date()).length}</td>
							</tr>
							<tr>
								<th style={{ textAlign: "left", paddingRight: "10px" }}>abgeschlossene Aktionen:</th>
								<td style={{ textAlign: "right" }}>{tempData.filter(item => new Date(item.zeitraumEnde) < new Date()).length}</td>
							</tr>
						</table>
					</div>
				</div>
            </div>
			<Dialog 
                visible={visible} 
                style={{ width: isLargeScreen ? '50vw' : '80vw'}} 
				header={rowData?.aktionsID + ", " + rowData?.bezeichnung}
                modal 
                footer={dialogFooter} 
                onHide={() => setVisible(false)}
            >
                {rowData && (
					<>
					{/**TODO -> Daten einfügen */}
                    <div className="field grid">
						<Accordion style={{width:"100%"}}>
							<AccordionTab header={
										<React.Fragment>
											<span style={{verticalAlign: "middle"}}>Sortiment </span>		
										</React.Fragment>
									}>
									{isLargeScreen ? (
										<div style={{ display: 'block', flexDirection: 'column' }}>
											<p><strong>Gruppe: </strong> {rowData.kategorie}</p>
											<p><strong>Betrifft: </strong>

													<DataTable 
														value={rowProdukte} 
														scrollHeight="300px" 
														scrollable 
														style={{width:"35rem", marginLeft: "5rem", marginTop: "-1.2rem"}}
														>
														<Column 
															field="Bezeichnung" 
															header="Bezeichnung" 
															/>
														<Column 
															field="LieferantName" 
															header="Lieferant"
															/>
														<Column 
															field="NeuerVK" 
															header="Preis"
															body={(rowData) => {
																const formattedPrice = rowData.NeuerVK.toFixed(2).replace('.', ',')+ " €";
																return formattedPrice;
															}} 
															style={{minWidth:"6rem"}}
															/>
														<Column 
															field="NeuerDB" 
															header="DB"
															body={(rowData) => {
																const formattedPrice = rowData.NeuerDB.toFixed(2).replace('.', ',')+ " €";
																return formattedPrice;
															}} 
															style={{minWidth:"6rem"}}
															/>
													</DataTable> 
												</p>
										</div>
									):(
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<p style={{ margin: '0rem', marginTop:"0.4rem" }}><strong>Gruppe: </strong> {rowData.kategorie}</p>
											<p style={{ margin: '0rem', marginTop:"0.4rem" }}><strong>ID: </strong> {rowData.produktID}</p>

											<p style={{ margin: '0rem', marginTop:"0.4rem" }}><strong>Betrifft: </strong></p>
											<p style={{ margin: '0rem', marginTop:"0.4rem" }}>
												<DataTable 
													value={rowProdukte} 
													scrollHeight="300px" 
													scrollable 
													style={{width:"14rem", marginLeft:"1rem"}}
													>
													<Column 
														field="Bezeichnung" 
														header="Bezeichnung" 
														/>
													<Column 
														field="LieferantName" 
														header="Lieferant"
														/>
													<Column 
														field="NeuerVK" 
														header="Preis"
														body={(rowData) => {
															const formattedPrice = rowData.NeuerVK.toFixed(2).replace('.', ',')+ " €";
															return formattedPrice;
														}} 
														style={{minWidth:"6rem"}}
														/>
													<Column 
														field="NeuerDB" 
														header="DB"
														body={(rowData) => {
															const formattedPrice = rowData.NeuerDB.toFixed(2).replace('.', ',')+ " €";
															return formattedPrice;
														}} 
														style={{minWidth:"6rem"}}
														/>
												</DataTable> 
											</p>
										</div>
									)}
							</AccordionTab>
							<AccordionTab header={
										<React.Fragment>
											<span style={{verticalAlign: "middle"}}>Aktion </span>		
										</React.Fragment>
									}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<p><strong>Typ: </strong> {rowData.akTypenBezeichnung}</p>
										<p><strong>Aktionsart: </strong> {["Rabatt", "Draufgabe", "Dreingabe", "Mengenrabatt"][rowData.akArten] || "Unbekannter Wert"}</p>
										<p><strong>Aktionswert: </strong> 
											{rowData.akText} 
											{rowData.akArten == 0 ? " %" : (rowData.akArten == 3 ? " €" : "")}
											</p>									
									</div>
							</AccordionTab>
							<AccordionTab header={
										<React.Fragment>
											<span style={{verticalAlign: "middle"}}>Kassen </span>		
										</React.Fragment>
									}>
									<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'block' : 'none',  }}>
										<div className="col" style={{ width: '100%' }}>
		
											<div style={{ display: 'flex' ,marginTop:"1rem"}}>
												
												{/* Erste Spalte für die ersten 4 Kassen */}
												<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
													{kassen.slice(0, 4).map((kasse) => (
													<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
														<Checkbox
															checked={rowData.kassen.includes(kasse.KassenID)} 
															/>
														<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
													</div>
													))}
												</div>
		
												{/* Zweite Spalte für alle weiteren Kassen */}
												<div style={{ width: '50%', minWidth:"5rem"  }}>
													{kassen.slice(4,8).map((kasse) => (
													<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
														<Checkbox
															checked={rowData.kassen.includes(kasse.KassenID)} 
															/>
														<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
													</div>
													))}
												</div>
		
												{/* Dritte Spalte für alle weiteren Kassen */}
												<div style={{ width: '50%', minWidth:"5rem" }}>
													{kassen.slice(8,12).map((kasse) => (
													<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
														<Checkbox
															checked={rowData.kassen.includes(kasse.KassenID)} 
															/>
														<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
													</div>
													))}
												</div>
		
												{/* Vierte Spalte für alle weiteren Kassen */}
												<div style={{ width: '50%', minWidth:"5rem" }}>
													{kassen.slice(12,16).map((kasse) => (
													<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
														<Checkbox
															checked={rowData.kassen.includes(kasse.KassenID)} 
															/>
														<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
													</div>
													))}
												</div>
											</div>
										</div>
									</div>


									<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'none' : 'block',  }}>
										<div className="col" style={{ width: '100%' }}>		
											<div style={{ display: 'flex' ,marginTop:"1rem"}}>
												<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
													{kassen.slice(0,16).map((kasse) => (
													<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
														<Checkbox
															checked={rowData.kassen.includes(kasse.KassenID)} 
															/>
														<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
													</div>
													))}
												</div>
											</div>
										</div>
									</div>
							</AccordionTab>
							<AccordionTab header={
										<React.Fragment>
											<span style={{verticalAlign: "middle"}}>Serien </span>		
										</React.Fragment>
									}>
									
							</AccordionTab>
							<AccordionTab header={
										<React.Fragment>
											<span style={{verticalAlign: "middle"}}>Verkaufsstatistik </span>		
										</React.Fragment>
									}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<p><strong>Anzahl verkauft:<br/></strong> {rowData.AnzahlVerkauft}</p>
										<p><strong>Umsatz:<br/></strong> {rowData.Umsatz}</p>
										<p><strong>Weitere Info:<br/></strong> {rowData.WeitereInfo}</p>
									</div>
							</AccordionTab>
						</Accordion>
					</div>
					</>
                )}
            </Dialog>

			<Dialog 
				visible={showDeleteDialog} 
				style={{ width: '20vw', display: isLargeScreen ? 'block' : 'none'}} 
				header={deleteData?.aktionsID + ", " + deleteData?.bezeichnung}
				modal 
				footer={dialogFooterDelete} 
				onHide={() => setShowDeleteDialog(false)}
			>
				<span>Willst du diese Aktion wirklich löschen?</span>
			</Dialog>

			<Dialog 
				header="Einstellungen" 
				visible={einstellungenVisble} 
				style={{ width: isLargeScreen ? '15vw' : '60vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setEinstellungenVisble(false);}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
				}}
				>
					<div className="grid">
						{['ID', 'Von', 'Bis', 'Bezeichnung', 'Typ', 'Serienmuster', 'Gruppe', 'Produkt', 'Standort'].map((field) => (
						<div className="col-12" key={field}>
							<Checkbox 
							value={field} 
							checked={columnVisibility[field]} 
							className="mr-3"
							onChange={(e) => {
								setColumnVisibility(prev => ({
								...prev,
								[field]: e.checked,
								}));
							}}
							/>
							{field}
						</div>
						))}
					</div>
			</Dialog>

			<Dialog 
				header="Fehler: Aktion kann nicht aktiviert werden!" 
				visible={showNotAktivierbar} 
				style={{  width: isLargeScreen ? '30vw' : '80vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowNotAktivierbar(false);}} />
					</div>
				}
				onHide={e => {
					setShowNotAktivierbar(false);
				}}
				>
					<div className="grid">
						<span style={{ marginLeft:"0.5rem"}}>Aktion Überschneidet sich mit einer anderen Aktiven Aktion.</span>
					</div>
			</Dialog>

			<Dialog 
				header="Fehler: Aktion kann nicht bearbeitet, kopiert oder gelöscht werden!" 
				visible={showNoPermission} 
				style={{  width: isLargeScreen ? '30vw' : '80vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowNoPermission(false);}} />
					</div>
				}
				onHide={e => {
					setShowNoPermission(false);
				}}
				>
					<div className="grid">
						<span style={{ marginLeft:"0.5rem"}}>In dieser Aktion befinden sich Produkte worauf Sie keinen Zugriff haben.</span>
					</div>
			</Dialog>
			
			
		</React.Fragment>
	);
}

export default Aktionen;