import React, {useContext, useEffect, useState} from 'react';
import { GlobalState } from "./components/GlobalState";
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import AppMenu from './AppMenu';
import {AppProfile} from './AppProfile';
import {Route, Switch, useHistory} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Belege from './components/Belege';
import Produktstammdaten from './components/Produktstammdaten';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './layout/layout.scss';
import './App.scss';
import Produkt from './components/Produkt';
import Produktverlauf from './components/Produktverlauf';
import Produktauswertungen from './components/Produktauswertungen';
import Login from './components/Login';
import Warenbestand from './components/Warenbestand';
import Wareneingangsjournal from './components/Wareneingangsjournal';
import Gutscheine from './components/Gutscheine';
import Wareneingang3 from './components/Wareneingang3';
import Lieferanten from './components/Lieferanten';
import Lieferant from './components/Lieferant';
import Monatsberichte from './components/Monatsberichte';
import Tagesberichte from './components/Tagesberichte';
import Jahresbericht from './components/Jahresbericht';
import Konto from './components/Konto';
import Wareneingang2 from './components/Wareneingang2';
import Wochenberichte from './components/Wochenberichte';
import { AuthService } from './service/AuthService';
import Kunden from './components/Kunden';
import Kunde from './components/Kunde';
import { Downloads } from './components/Downloads';
import { Benutzer } from './components/Benutzer';
import { Benutzer2 } from './components/Benutzer2';
import Produzenten from './components/Produzenten';
import Produzent from './components/Produzent';
import Warenbestand2 from './components/Warenbestand2';
import Kategorien from './components/Kategorien';
import WarenbestandDetails from './components/WarenbestandDetails';
import Befuellungsliste from './components/Befuellungsliste';
import Tagesumsaetze from './components/Tagesumsaetze';
import Auswertungen from './components/Auswertungen';
import GutscheineAlt from './components/GutscheineAlt';
import Rabatte from './components/Rabatte';
import Aktionen from './components/Aktionen';
import AktionenNeu from './components/AktionenNeu';
import { unregister } from './serviceWorker';
import Logs from './components/Logs';
import Lieferscheine from './components/Lieferscheine';
import Kassasturz from './components/Kassasturz';
import LogsZusammengefasst from './components/LogsZusammengefasst';

import CONFIG from 'config';
import Einstellungen from './components/Einstellungen';
import Wareneingangsjournaltest from './components/Wareneingangsjournaltest';

function App(_) {
	const [globalState] = useContext(GlobalState);
	const [layoutMode] = useState('static');
	const [layoutColorMode] = useState('dark');
	const [staticMenuInactive, setStaticMenuInactive] = useState(false);
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [navigationPfad] = useState('Home');
	const [roles, setRoles] = useState([]);
	const [menu, setMenu] = useState([]);
	const [kassaMenu, setKassaMenu] = useState([]);

	const authService = new AuthService();

	const history = useHistory();
	
	useEffect(() => {
		if (mobileMenuActive)
			addClass(document.body, 'body-overflow-hidden');
		else
			removeClass(document.body, 'body-overflow-hidden');
		
		authService.getTokenInfo().then(info => {
			setRoles(info?.roles.split(';') ?? []);
		});

		unregister();
		// eslint-disable-next-line
	}, [mobileMenuActive]);

	const onToggleMenu = (event) => {
		if (isDesktop()) {
			if (layoutMode === 'overlay') {
				setOverlayMenuActive(!overlayMenuActive);
			}
			else if (layoutMode === 'static') {
				setStaticMenuInactive(!staticMenuInactive);
			}
		}
		else {
			setMobileMenuActive(!mobileMenuActive);
		}
	   
		event.preventDefault();
	}

	const onMenuItemClick = (event) => {
		if(!event.item.items) {
			setOverlayMenuActive(false);
			setMobileMenuActive(false);
		}
	}
	
	const onLogin = () => {
		authService.getTokenInfo().then(info => {
			try {
				console.log(info);
				setRoles(info.roles.split(';'));
			} catch (e) {console.log(e)}
			history.push('/');
			
		});
	}

	useEffect(() => {
		authService.getTokenInfo().then(info => {
			setRoles(info?.roles.split(';') ?? []);
		});
		// eslint-disable-next-line
	}, [globalState]);

	useEffect(() => {
		setMenu(createMenu());
		setKassaMenu(createKassaMenu());
		// eslint-disable-next-line
	}, [roles]);

	function createMenu() {
		return ([
			{label: 'Dashboard', icon: 'pi pi-fw pi-home',  to: '/', items: [
				{label: 'Auswertungen', icon: 'pi pi-fw pi-ticket',  to: '/auswertungen', visible: roles.includes('Chef')},
				{label: 'Produktauswertungen', icon: 'pi pi-fw pi-ticket',  to: '/produktauswertungen'}
			] },
			{label: 'Produktstammdaten', icon: 'pi pi-fw pi-book',  to: '/produktstammdaten', items: [
				{label: 'Kategorien', icon: 'pi pi-fw pi-ticket',  to: '/kategorien', visible: roles.includes('Chef')}
			] },
			{label: 'Rabatte', icon: 'pi pi-fw pi-percentage', to: '/rabatte', visible: CONFIG.RabattModul, items: [
				{label: 'Aktionen', icon: 'pi pi-fw pi-percentage',  to: '/aktionen', visible: CONFIG.RabattModul},
			]},
			{label: 'Gutscheine', icon: 'pi pi-fw pi-ticket',  to: '/gutscheine', visible: roles.includes('Chef') },
			{label: 'GutscheineAlt', icon: 'pi pi-fw pi-ticket',  to: '/gutscheinealt', visible: CONFIG.GutscheineAltAktiviert && roles.includes('Chef') },
			{label: 'Lieferanten', icon: 'pi pi-fw pi-users',  to: '/lieferanten', visible: roles.includes('Chef') },
			{label: 'Kunden', icon: 'pi pi-fw pi-users',  to: '/kunden', visible: CONFIG.KundenAktiviert && roles.includes('Chef') },
			{label: 'Produzenten', icon: 'pi pi-fw pi-id-card',  to: '/produzenten', visible: roles.includes('Chef') },
			{label: 'Belege', icon: 'pi pi-fw pi-money-bill',  to: '/belege', visible: roles.includes('Chef'), items: [
				{ label: 'Tagesumsätze', icon: 'pi pi-fw pi-angle-right', to: '/belege/tagesumsaetze' },
				{ label: 'Kassensturz', icon: 'pi pi-fw pi-angle-right', to: '/belege/kassasturz' }
			]},
			{label: 'Lieferscheine', icon: 'pi pi-fw pi-id-card',  to: '/lieferscheine', visible: CONFIG.LieferscheineAktiviert && roles.includes('Chef') },
			{label: 'Lager', icon: 'pi pi-fw pi-inbox', items: [
				{ label: 'Warenbestand', icon: 'pi pi-fw pi-angle-right', to: '/lager/warenbestand', items: [
					{label: 'Details', icon: 'pi pi-fw pi-chart-line',  to: '/lager/warenbestand/details', visible: roles.includes('Chef')}
				] },
				{ label: 'Wareneingang', icon: 'pi pi-fw pi-angle-right', to: '/lager/wareneingang' },
				{ label: 'Wareneingangsjournal', icon: 'pi pi-fw pi-angle-right', to: '/lager/wareneingangsjournal' },
				//{ label: 'DataTable Test', icon: 'pi pi-fw pi-angle-right', to: '/lager/wareneingangsjournaltest' }
			] },
			{label: 'Berichte', icon: 'pi pi-fw pi-chart-bar', items: [
				{ label: 'Verkaufsberichte', items: [
					{ label: 'Tagesberichte', icon: 'pi pi-fw pi-angle-right', to: '/verkaufsberichte/tagesberichte' },
					{ label: 'Wochenberichte', icon: 'pi pi-fw pi-angle-right', to: '/verkaufsberichte/wochenberichte' },
					{ label: 'Monatsberichte', icon: 'pi pi-fw pi-angle-right', to: '/verkaufsberichte/monatsberichte' },
					{ label: 'Jahresbericht', icon: 'pi pi-fw pi-angle-right', to: '/verkaufsberichte/jahresbericht' }
				] },
				{ label: 'Bestandsberichte', items: [
					{ label: 'Bestandsliste', icon: 'pi pi-fw pi-angle-right', to: '/bestandsberichte/bestandsliste' },
					{ label: 'Befüllungsliste', icon: 'pi pi-fw pi-angle-right', to: '/bestandsberichte/befuellungsliste' },
					{ label: 'Bestellliste', icon: 'pi pi-fw pi-angle-right', to: '/bestandsberichte/bestellliste', visible: roles.includes('Chef') }
				] },
				{label: 'Fehlerübersicht', to: '/logs', visible: roles.includes('Chef'), items: [
					{label: 'Fehlerübersicht zusammengefasst',  to: '/logsZusammengefasst', visible: roles.includes('Chef')},
				]},
			] },
			{label: 'Mein Konto', icon: 'pi pi-fw pi-user', to: '/konto', visible: roles.includes('Chef')},
			/* {label: 'Einstellungen', icon: 'pi pi-fw pi-cog', to: '/einstellungen', visible: roles.includes('Chef')}, */
			{label: 'Benutzer', icon: 'pi pi-fw pi-users', to: '/benutzer', visible: roles.includes('Chef')},
			{label: 'Downloads', icon: 'pi pi-fw pi-cloud-download', to: '/downloads', visible: roles.includes('Chef')}
		]);
	}

	function createKassaMenu() {
		return ([
			{ label: 'Wareneingang', icon: 'pi pi-fw pi-home', to: '/' },
			{ label: 'Warenbestand', icon: 'pi pi-fw pi-inbox', to: '/warenbestand' },
		]);
	}


	function addClass(element, className) {
		if (element.classList)
			element.classList.add(className);
		else
			element.className += ' ' + className;
	}

	function removeClass(element, className) {
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	function isDesktop() {
		return window.innerWidth > 1024;
	}

	const wrapperClass = classNames('layout-wrapper', {
		'layout-overlay': layoutMode === 'overlay',
		'layout-static': layoutMode === 'static',
		'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
		'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
		'layout-mobile-sidebar-active': mobileMenuActive
	});

	const sidebarClassName = classNames("layout-sidebar", {
		'layout-sidebar-dark': layoutColorMode === 'dark',
		'layout-sidebar-light': layoutColorMode === 'light'
	});

	return (
		<React.Fragment>
			{(CONFIG.Typ === "Kassa") &&
				<React.Fragment>
					<div className={wrapperClass}>
						{(!authService.isLogedIn()) &&
						<div class="p-p-5">
							<Switch>
								<Route path="/">
									<Login onLogin={onLogin} />
								</Route>
							</Switch>
						</div>
						}
						{(authService.isLogedIn()) &&
							<React.Fragment>
								<AppTopbar onToggleMenu={onToggleMenu} />

								<div className={sidebarClassName}>
									{CONFIG.LogoSidebar !== undefined && CONFIG.LogoSidebar !== null && <img className="sidebarTopLogo" alt="Logo" src={CONFIG.LogoSidebar}/>}
									<AppProfile />
									<AppMenu model={kassaMenu} onMenuItemClick={onMenuItemClick} />
									<div className="sidebarFiller"></div>
									<div className="sidebarBottom">
										<img className="sidebarBottomLogo" alt="Logo" src="/assets/logo3.svg"/>
										<div className="sidebarHotline p-component" style={{fontSize: '1em'}}>
											Bei Fragen wenden Sie sich bitte an die mysoft Kassen - Hotline unter <a href="tel:+43255485421100">+43 2554/85 421-100</a>
										</div>
									</div>
								</div>
								<div className="layout-main">
									<Switch>
										<Route path="/warenbestand">
											<Warenbestand2/>
										</Route>

										<Route path="/gutscheine/">
											<Gutscheine/>
										</Route>

										<Route path="/wareneingang/:code">
											<Wareneingang3/>
										</Route>
										
										<Route path="/wareneingang/">
											<Wareneingang3/>
										</Route>
										
										<Route path="/">
											<Wareneingang3/>
										</Route>
									</Switch>
								</div>
							</React.Fragment>
						}
						<div className="layout-mask"></div>
					</div>
				</React.Fragment>
			}
			{(CONFIG.Typ === "Server") &&
				<div className={wrapperClass}>
					{(!authService.isLogedIn()) &&
					<div class="p-p-5">
						<Switch>
							<Route path="/">
								<Login onLogin={onLogin} />
							</Route>
						</Switch>
					</div>
					}
					{(authService.isLogedIn()) &&
						<React.Fragment>
							<AppTopbar header={navigationPfad} onToggleMenu={onToggleMenu} />

							<div className={sidebarClassName}>
								{CONFIG.LogoSidebar !== undefined && CONFIG.LogoSidebar !== null && <img className="sidebarTopLogo" alt="Logo" src={CONFIG.LogoSidebar}/>}
								<AppProfile />
								<AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
								<div className="sidebarFiller"></div>
								<div className="sidebarBottom">
									<img className="sidebarBottomLogo" alt="Logo" src="/assets/logo3.svg"/>
									<div className="sidebarHotline p-component" style={{fontSize: '1em'}}>
										Bei Fragen wenden Sie sich bitte an die mysoft Kassen - Hotline unter <a href="tel:+43255485421100">+43 2554/85 421-100</a>
									</div>
								</div>
							</div>
						<div className="layout-main">
						<Switch>
							<Route path="/produkt/bearbeiten/:id">
								<Produkt action="bearbeiten" />
							</Route>
							<Route path="/produkt/kopieren/:id">
								<Produkt action="kopieren" />
							</Route>
							<Route path="/produkt/neu">
								<Produkt action="neu" />
							</Route>
							<Route path="/produktstammdaten/">
								<Produktstammdaten/>
							</Route>

							<Route path="/produktverlauf/:id">
								<Produktverlauf/>
							</Route>

							<Route path="/belege/tagesumsaetze">
								<Tagesumsaetze/>
							</Route>
							<Route path="/belege/kassasturz">
								<Kassasturz/>
							</Route>
							<Route path="/belege/">
								<Belege/>
							</Route>

							<Route path="/benutzer/neu/">
								<Benutzer2 action="neu"/>
							</Route>
							<Route path="/benutzer/:mitarbeiterNr/">
								<Benutzer2 action="bearbeiten"/>
							</Route>
							<Route path="/benutzer/">
								<Benutzer/>
							</Route>

							<Route path="/gutscheine/">
								<Gutscheine/>
							</Route>
							<Route path="/gutscheinealt/">
								<GutscheineAlt/>
							</Route>

							<Route path="/lieferanten/">
								<Lieferanten/>
							</Route>
							<Route path="/lieferant/neu">
								<Lieferant action="neu"/>
							</Route>
							<Route path="/lieferant/bearbeiten/:id">
								<Lieferant action="bearbeiten"/>
							</Route>

							<Route path="/verkaufsberichte/tagesberichte">
								<Tagesberichte/>
							</Route>
							<Route path="/verkaufsberichte/wochenberichte">
								<Wochenberichte/>
							</Route>
							<Route path="/verkaufsberichte/monatsberichte">
								<Monatsberichte/>
							</Route>
							<Route path="/verkaufsberichte/jahresbericht">
								<Jahresbericht/>
							</Route>

							<Route path="/bestandsberichte/bestandsliste">
								<Befuellungsliste type="Bestandsliste"/>
							</Route>
							<Route path="/bestandsberichte/befuellungsliste">
								<Befuellungsliste type="Befüllungsliste"/>
							</Route>
							<Route path="/bestandsberichte/bestellliste">
								<Befuellungsliste type="Bestellliste"/>
							</Route>

							<Route path="/lager/warenbestand/dashboard">
								<Warenbestand dashboard/>
							</Route>
							<Route path="/lager/warenbestand/details/">
								<WarenbestandDetails/>
							</Route>
							<Route path="/lager/warenbestand/">
								<Warenbestand/>
							</Route>

							<Route path="/lager/wareneingang/">
								<Wareneingang2/>
							</Route>

							<Route path="/lager/wareneingangsjournal/">
								<Wareneingangsjournal/>
							</Route>

							<Route path="/lager/wareneingangsjournaltest/">
								<Wareneingangsjournaltest />
							</Route>

							<Route path="/konto/">
								<Konto/>
							</Route>

							<Route path="/einstellungen/">
								<Einstellungen />
							</Route>

							<Route path="/kategorien">
								<Kategorien/>
							</Route>

							<Route path="/lieferscheine">
								<Lieferscheine/>
							</Route>

							<Route path="/kunden/">
								<Kunden/>
							</Route>
							<Route path="/kunde/neu">
								<Kunde action="neu"/>
							</Route>
							<Route path="/kunde/bearbeiten/:id">
								<Kunde action="bearbeiten"/>
							</Route>

							<Route path="/produzenten/">
								<Produzenten/>
							</Route>
							<Route path="/produzent/neu">
								<Produzent action="neu"/>
							</Route>
							<Route path="/produzent/bearbeiten/:produzentenId">
								<Produzent action="bearbeiten"/>
							</Route>

							<Route path="/downloads/">
								<Downloads/>
							</Route>

							<Route path="/rabatte">
								<Rabatte />
							</Route>

							<Route path="/aktionen">
								<Aktionen />
							</Route>

							<Route path="/neueAktion">
								<AktionenNeu />
							</Route>

							
							<Route path="/logs/">
								<Logs/>
							</Route>

							<Route path="/logsZusammengefasst/">
								<LogsZusammengefasst/>
							</Route>

							<Route path="/auswertungen">
								<Auswertungen/>
							</Route>

							<Route path="/produktauswertungen">
								<Produktauswertungen/>
							</Route>
							
							{
								(() => {
									return (
										<Route path="/">
											<Dashboard />
										</Route>
									);
								})()
							}
						</Switch>
						</div>
						</React.Fragment>
					}
					<div className="layout-mask"></div>
				</div>
			}
		</React.Fragment>
	);
}

export default App;
