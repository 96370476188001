import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function OrtColumn(props) {
	
	const onOrtChange = (e) => {
		props.setSelectedOrt(e.target.value);
		props.datatableref.current.filter(e.target.value, "ReOrt", "in");
	}

	return (
		<Column header="Ort" field="ReOrt" style={{width: "200px", maxWidth:"9rem"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedOrt} options={props.Ort} optionLabel="ReOrt" optionValue='ReOrt' onChange={onOrtChange} filter
			placeholder={"Alle"} />
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		/>
	)
}
	