import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function NachnameColumn(props) {

	const onNachnameChange = (e) => {
		props.setSelectedNachname(e.target.value); 
		props.datatableref.current.filter(e.target.value, "Nachname", "in");
	}

	return(
		<Column header="Nachname" field="ErstePersonNachname" style={{width: "200px", maxWidth:"15rem"}} filter sortable filterMatchMode="contains"
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		filterElement={
			<MultiSelect value={props.selectedNachname} options={props.Nachname} optionLabel="Nachname" optionValue='Nachname' onChange={onNachnameChange}
			placeholder={"Alle"} filter/>
		}
		body={props.body}
		/>
	)
}