import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { KassenService } from '../service/KassenService';
import { UmsatzService } from '../service/UmsatzService';
import { DashboardService } from '../service/DashboardService';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WarenbestandService } from '../service/WarenbestandService';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast';
import { ZoomPopup } from './ZoomPopup';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from "primereact/calendar";
import { addDE } from "./LocalDE";
import { useLocalStorage } from "../helper/localStorageState";
import { LogAuswertung } from './LogAuswertung';

function Auswertungen(_) {
	const monthLookup = {
		0: "Jänner",
		1: "Februar",
		2: "März",
		3: "April",
		4: "Mai",
		5: "Juni",
		6: "Juli",
		7: "August",
		8: "September",
		9: "Oktober",
		10: "November",
		11: "Dezember"
	}

	const kategorienUmsatzTypeLookup = {
		0: ["Umsatz Kategorien", "UmsatzKategorien"],
		1: ["Umsatz Warengruppen", "UmsatzWarengruppen"],
		2: ["Umsatz Artikelgruppen", "UmsatzArtikelgruppen"]
	}

	addDE();

	const kassenService = new KassenService();
	const umsatzService = new UmsatzService();
	const warenbestandService = new WarenbestandService();
	const exportService = new ExportService();
	const dashboardService = new DashboardService();
	const history = useHistory();
	const dt = useRef(null);
	const toastRef = useRef(null);
	const schwundVerderbBeschaedigtRef = useRef(null);
	const jahresumsaetzeChartRef = useRef(null);
	const kategorienUmsatzChartRef = useRef(null);
	const wochenVergleichChartRef = useRef(null);
	const monateVergleichChartRef = useRef(null);

	const [kassen, setKassen] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [selectedKassen4, setSelectedKassen4] = useState([]);
	const [hideZero, setHideZero] = useState(true);
	const [warenbestand, setWarenbestand] = useState([]);
	const [warenbestandDisplay, setWarenbestandDisplay] = useState([]);
	const [showExportSpinner, setShowExportSpinner] = useState(false);
	const [selectedKassen7, setSelectedKassen7] = useState([]);
	const [selectedKassen8, setSelectedKassen8] = useState([]);
	const [dashboardSchwundVerderbBeschaedigtData, setDashboardSchwundVerderbBeschaedigtData] = useState({});
	const [dashboardSchwundVerderbBeschaedigtVerlauf, setDashboardSchwundVerderbBeschaedigtVerlauf] = useState([]);
	const [dashboardSchwundVerderbBeschaedigtVerlaufMonatMenge, setdashboardSchwundVerderbBeschaedigtVerlaufMonatMenge] = useState(3)
	const [jahresumsaetze, setJahresumsaetze] = useState([]);
	const [selectedKassen9, setSelectedKassen9] = useState([]);
	const [netto3] = useLocalStorage("netto3", true);
	const [loading07, setLoading07] = useState(false);
	const [loading08, setLoading08] = useState(false);
	const [loading09, setLoading09] = useState(false);
	const [loading10, setLoading10] = useState(false);
	const [loading11, setLoading11] = useState(false);
	const [netto5, setNetto5] = useLocalStorage("netto5", true);
	const [selectedKassen10, setSelectedKassen10] = useState([]);
	const [kategorienUmsatzTarget, setKategorienUmsatzTarget] = useState([0, null]);
	const [kategorienUmsatzData, setKategorienUmsatzData] = useState(null);
	const [kategorienUmsatzDatumsbereich, setKategorienUmsatzDatumsbereich] = useState([new Date(0), new Date(0)]);
	const [kategorienUmsatzIds, setKategorienUmsatzIds] = useState([]);
	const [selectedKassen11, setSelectedKassen11] = useState([]);
	const [loading12, setLoading12] = useState(false);
	const [selectedWochenMenge, setSelectedWochenMenge] = useState(4);
	const [wochenVergleichData, setWochenVergleichData] = useState({});
	const [selectedKassen12, setSelectedKassen12] = useState([]);
	const [loading13, setLoading13] = useState(false);
	const [dashboardRegionalData, setDashboardRegionalData] = useState({});
	const [loading15, setLoading15] = useState(false);
	const [selectedKassen13, setSelectedKassen13] = useState([]);
	const [selectedVergleichsJahr, setSelectedVergleichsJahr] = useState([]);
	const [selectedMonateMenge, setSelectedMonateMenge] = useState(3);
	const [monateVergleichData, setMonateVergleichData] = useState({});

	const currentYear = new Date().getFullYear()-1;
	const years = Array.from({ length: currentYear - 2020 }, (_, i) => {
		const year = 2021 + i;
		return {
		  value: currentYear - year + 1,
		  label: year.toString()
		};
	  });

	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	function SimpleStringHash(str) {//https://stackoverflow.com/questions/17083316/hashing-a-string-between-two-integers-with-a-good-distribution-uniform-hash
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash += str.charCodeAt(i);
		}
		return hash;
	}

	const onBefuellungslisteClick = () => {
		let kassenIds = selectedKassen4;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) kassenIds = kassen.map(kasse => kasse.KassenID);

		setShowExportSpinner(true);
		exportService.downloadBefuellungsliste('Befüllungsliste', kassenIds, hideZero).then(() => {
			setShowExportSpinner(false);
		}).catch(() => {
			setShowExportSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
	}

	useEffect(() => {
		let kassenIds = selectedKassen7;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		setLoading07(true);
		dashboardService.GetDashboardSchwundVerderbBeschaedigtData(kassenIds, undefined, netto3).then(setDashboardSchwundVerderbBeschaedigtData).finally(() => setLoading07(false));
		// eslint-disable-next-line
	}, [selectedKassen7, kassen, netto3]);

	useEffect(() => {
		async function fetchData() {
			let kassenIds = selectedKassen8;
			if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
				kassenIds = kassen.map(k => k.KassenID);
			}
			const now = new Date();
			/*
			const months = [
				now.getMonth() - 5,
				now.getMonth() - 4,
				now.getMonth() - 3,
				now.getMonth() - 2,
				now.getMonth() - 1,
				now.getMonth()
			]*/
			const months = Array.from(
				new Array(dashboardSchwundVerderbBeschaedigtVerlaufMonatMenge),
				(_, i) => now.getMonth() + 1 - dashboardSchwundVerderbBeschaedigtVerlaufMonatMenge + i);
			setLoading08(true);
			const data = await Promise.allSettled(
				months.map(m =>dashboardService.GetDashboardSchwundVerderbBeschaedigtData(kassenIds, new Date(now.getFullYear(), m), true)))
				.then(data => data.map(d => d.value));

			setLoading08(false);
			const schwund = data.map(d => d?.schwundMonat);
			const verderb = data.map(d => d?.verderbMonat);
			const beschaedigt = data.map(d => d?.beschaedigtMonat);

			setDashboardSchwundVerderbBeschaedigtVerlauf({
				labels: months.map(m => new Date(now.getFullYear(), m).toLocaleString('default', { month: 'long' })),
				datasets: [
					{
						type: "bar",
						label: "Schwund",
						backgroundColor: "#bd2f2f",
						data: schwund
					},
					{
						type: "bar",
						label: "Verderb",
						backgroundColor: "#7a5430",
						data: verderb
					},
					{
						type: "bar",
						label: "Beschädigt",
						backgroundColor: "#9c8992",
						data: beschaedigt
					}
				]
			});
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen8, kassen, dashboardSchwundVerderbBeschaedigtVerlaufMonatMenge]);

	useEffect(() => {
		let kassenIds = selectedKassen9;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		setLoading10(true);
		umsatzService.getJahresumsaetze(kassenIds).then(jahresumsaetze => {
			setJahresumsaetze({
				labels: jahresumsaetze.Jahre,
				datasets: jahresumsaetze.Umsaetze.map(umsatz => ({
					type: "bar",
					label: "Umsatz " + umsatz.KassenID,
					backgroundColor: "hsl(" + SimpleStringHash(umsatz.KassenID) * 100 + ", 90%, 45%)",
					data: umsatz.Umsaetze
				}))
			});
		}).finally(() => setLoading10(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen9, kassen]);

	useEffect(() => {
			async function fetchData() {
			if (!Array.isArray(kategorienUmsatzDatumsbereich) || !(kategorienUmsatzDatumsbereich[0] instanceof Date) || !(kategorienUmsatzDatumsbereich[1] instanceof Date)) return;

			let kassenIds = selectedKassen10;
			if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
				kassenIds = kassen.map(k => k.KassenID);
			}
			setLoading11(true);
			let umsatzData;
			switch (kategorienUmsatzTarget[0]) {
				case 0:
					umsatzData = await dashboardService.GetKategorienUmsatz(kassenIds, kategorienUmsatzDatumsbereich[0], kategorienUmsatzDatumsbereich[1], netto5);
					break;
				case 1:
					umsatzData = await dashboardService.GetWarengruppenUmsatz(kassenIds, kategorienUmsatzTarget[1], kategorienUmsatzDatumsbereich[0], kategorienUmsatzDatumsbereich[1], netto5);
					break;
				case 2:
					umsatzData = await dashboardService.GetArtikelgruppenUmsatz(kassenIds, kategorienUmsatzTarget[1], kategorienUmsatzDatumsbereich[0], kategorienUmsatzDatumsbereich[1], netto5);
					break;
				default:
					break;
			}
			setLoading11(false);
			setKategorienUmsatzIds(umsatzData.map(u => u.ID));
			setKategorienUmsatzData({
				labels: umsatzData.map(u => u.Bezeichnung),
				datasets: [
					{
						label: "Umsatz",
						data: umsatzData.map(u => u.Umsatz),
						backgroundColor: "#229954"
					}
				]
			});
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen10, kassen, netto5, kategorienUmsatzTarget, kategorienUmsatzDatumsbereich]);

	useEffect(() => {
		let kassenIds = selectedKassen11;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		setLoading12(true);
		umsatzService.getWochenumsaetze(kassenIds, selectedWochenMenge).then(data => {
			setWochenVergleichData({
				labels: Array.from(new Array(selectedWochenMenge), (_, i) => i === selectedWochenMenge - 1 ? "Aktuelle Woche" : ("Woche " + (-selectedWochenMenge + i + 1).toString())),
				datasets: data.map((umsaetze, i) => ({
					label: kassenIds[i],
					data: umsaetze,
					backgroundColor: "hsl(" + SimpleStringHash(kassenIds[i]) * 100 + ", 90%, 45%)"
				}))
			});
		}).finally(() => setLoading12(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen11, kassen, selectedWochenMenge]);

	useEffect(() => {
		let kassenIds = selectedKassen13;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		const month = new Date().getMonth();

		setLoading15(true);

		const promises = [
			umsatzService.getMonatsumsaetze(kassenIds, selectedMonateMenge)
		];
		if (selectedVergleichsJahr.length > 0) {
			selectedVergleichsJahr.forEach(jahr => {
				promises.push(umsatzService.getMonatsumsaetze(kassenIds, selectedMonateMenge + (12 * jahr)));

			});
		}

		Promise.all(promises).then((results) => {
			console.log(results)
					
			const labels = Array.from(new Array(selectedMonateMenge), (_, i) => monthLookup[(12 + month + (-selectedMonateMenge + i + 1)) % 12]);
			let datasets = [];
			const nowYear = new Date().getFullYear(); 
			let noDataYear = null;

			results.forEach((data, dataIndex) => {
				console.log(data)
				if (data.length === 0) {
					noDataYear = nowYear - dataIndex;
				} else {
					data.forEach((umsaetze, i) => {
						datasets.push({
							label: `${kassenIds[i]} - Data${nowYear - dataIndex}`,
							data: labels.map((_, monthIndex) => umsaetze[monthIndex % umsaetze.length]),
							backgroundColor: `hsl(${SimpleStringHash(kassenIds[i]) * 100 + (dataIndex * 20)}, 90%, 45%)`
						});
					});
				}
			});

			if (noDataYear !== null) {
				console.log(`Das Jahr, in dem keine Werte vorhanden sind: ${noDataYear}`);
			}

			setMonateVergleichData({
				labels: labels,
				datasets: datasets
			});
		}).finally(() => setLoading15(false));


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen13, kassen, selectedMonateMenge, selectedVergleichsJahr]);

	useEffect(() => {
		let kassenIds = selectedKassen12;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}

		setLoading13(true);
		dashboardService.GetDashboardRegionalData(kassenIds).then(setDashboardRegionalData).finally(() => setLoading13(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedKassen12, kassen]);

	useEffect(() => {
		setLoading09(true);
		warenbestandService.getAll().then(data => setWarenbestand(data)).finally(() => setLoading09(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kassen]);

	useEffect(() => {
		let bestand = warenbestand;
		if (hideZero) bestand = bestand.filter(ware => ware.SollBestand !== 0);
		setWarenbestandDisplay([...bestand.filter(ware => ware.Bestand < ware.WarnBestand && (selectedKassen4.length === 0 || selectedKassen4.includes(ware.KassenID))).reduce((res, val) => {
			let storedVal = res.get(val.ArtNr);
			if (storedVal === undefined) storedVal = val;
			else storedVal.Bestand += val.Bestand;
			res.set(val.ArtNr, storedVal);
			return res;
		}, new Map()).values()].sort((ware1, ware2) => (ware2.SollBestand - ware2.Bestand) - (ware1.SollBestand - ware1.Bestand)).map(ware => ({...ware, AufzMenge: (ware.SollBestand - ware.Bestand).toLocaleString('de')})));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warenbestand, selectedKassen4, hideZero]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Auswertungen'});
		kassenService.getKassen().then(data => setKassen(data));

		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		setKategorienUmsatzDatumsbereich([new Date(year, month, day - 29), now])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); 

	return(
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid">
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading07 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={
						<React.Fragment>Schwund/Verderb/Beschädigt
							<MultiSelect value={selectedKassen7} options={kassen} onChange={e => setSelectedKassen7(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/>
						</React.Fragment>} style={{width: '100%', height: '100%'}}>
						<table style={{width: "100%", borderCollapse: "collapse"}}>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Schwund:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardSchwundVerderbBeschaedigtData.schwundMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Verderb:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardSchwundVerderbBeschaedigtData.verderbMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left"}}>Beschädigt:</th>
								<td style={{textAlign: "right"}}>&euro; {dashboardSchwundVerderbBeschaedigtData.beschaedigtMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
						</table>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading08 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={
						<React.Fragment>
							Schwund/Verderb/Beschädigt Verlauf
							<MultiSelect value={selectedKassen8} options={kassen} onChange={e => setSelectedKassen8(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/>
							<Dropdown value={dashboardSchwundVerderbBeschaedigtVerlaufMonatMenge} options={[2, 3, 6, 9, 12]} onChange={e => setdashboardSchwundVerderbBeschaedigtVerlaufMonatMenge(e.target.value)} valueTemplate={w => <React.Fragment>{w} Monate</React.Fragment>} style={{float: "right"}}/>
						</React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Schwund/Verderb/Beschädigt" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(schwundVerderbBeschaedigtRef.current.getBase64Image(), 'SchwundVerderbBeschaedigt.png')}/>}>
							<Chart ref={schwundVerderbBeschaedigtRef} type="bar" data={dashboardSchwundVerderbBeschaedigtVerlauf} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, plugins: {legend: {position: 'bottom'}}}}/>
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading11 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>{kategorienUmsatzTypeLookup[kategorienUmsatzTarget[0]][0]}<MultiSelect value={selectedKassen10} options={kassen} onChange={e => setSelectedKassen10(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/><Calendar value={kategorienUmsatzDatumsbereich} onChange={e => setKategorienUmsatzDatumsbereich(e.value)} locale="de" showIcon={true} selectionMode="range"/><SelectButton value={netto5} onChange={e => setNetto5(e.value)} options={[{label: "Brutto", value: false}, {label: "Netto", value: true}]} style={{float: "right", marginRight: "0.5rem"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header={kategorienUmsatzTypeLookup[kategorienUmsatzTarget[0]][0]} footer={<Button label="Download" icon="pi pi-image" onClick={() => download(kategorienUmsatzChartRef.current.getBase64Image(), kategorienUmsatzTypeLookup[kategorienUmsatzTarget[0]][1] + '.png')}/>}>
							<Chart ref={kategorienUmsatzChartRef} type="bar" data={kategorienUmsatzData} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, plugins: {legend: {position: 'bottom'}}, onClick: (e, elements) => {
								if (elements.length > 0) {
									if (kategorienUmsatzTarget[0] >= 2) return;
									setKategorienUmsatzTarget([kategorienUmsatzTarget[0] + 1, kategorienUmsatzIds[elements[0].index]]);
								} else {
									if (kategorienUmsatzTarget[0] <= 0) return;
									setKategorienUmsatzTarget([0, null]);
								}
							}}}/>
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading12 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={
						<React.Fragment>
							Wochen Vergleich
							<MultiSelect value={selectedKassen11} options={kassen} onChange={e => setSelectedKassen11(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/>
							<Dropdown value={selectedWochenMenge} options={[2, 4, 6, 8]} onChange={e => setSelectedWochenMenge(e.target.value)} valueTemplate={w => <React.Fragment>{w} Wochen</React.Fragment>} style={{float: "right"}}/>
						</React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Wochen Vergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(wochenVergleichChartRef.current.getBase64Image(), 'WochenVergleich.png')}/>}>
							<Chart ref={wochenVergleichChartRef} type="bar" data={wochenVergleichData} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, plugins: {legend: {position: 'bottom'}}}}/>
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading15 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={
						<React.Fragment>
							Monats Vergleich
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop:"-2rem"}}>
								<Dropdown value={selectedMonateMenge} options={[2, 3, 6, 9, 12]} onChange={e => setSelectedMonateMenge(e.target.value)} valueTemplate={w => <React.Fragment>{w} Monate</React.Fragment>} style={{float: "right"}}/>
								<MultiSelect value={selectedKassen13} options={kassen} onChange={e => setSelectedKassen13(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/>
							</div>
							<div style={{ marginTop: '0.5rem' }}>
								<MultiSelect
									value={selectedVergleichsJahr} 
									options={years} 
									onChange={e => setSelectedVergleichsJahr(e.target.value)}
									placeholder="Vergleichsjahr"
									maxSelectedLabels={5} 
									optionValue='value'
									optionLabel='label'
									style={{float: "right"}}
									/>
							</div>

						</React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Monats Vergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(monateVergleichChartRef.current.getBase64Image(), 'MonatsVergleich.png')} />}>
							<Chart ref={monateVergleichChartRef} type="bar" data={monateVergleichData} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, style: {marginTop:"10rem"}, plugins: {legend: {position: 'bottom'}}}} />
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading10 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Jahres Vergleich<MultiSelect value={selectedKassen9} options={kassen} onChange={e => setSelectedKassen9(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Jahres Vergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(jahresumsaetzeChartRef.current.getBase64Image(), 'JahresVergleich.png')}/>}>
							<Chart ref={jahresumsaetzeChartRef} type="bar" data={jahresumsaetze} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, plugins: {legend: {position: 'bottom'}}}}/>
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading13 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Regional Anteil <MultiSelect value={selectedKassen12} options={kassen} onChange={e => setSelectedKassen12(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
					<table style={{width: "100%", borderCollapse: "collapse"}}>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Gesamt Anzahl an Produkten:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardRegionalData.artikelAnzahl?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Anzahl an Regionalen Produkten:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardRegionalData.artikelAnzahlRegional?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Regionaler Anteil an Produkten</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardRegionalData.artikelAnteilRegional?.toLocaleString('de', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Gesamt Menge an verkaufen Produkten</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardRegionalData.verkauft?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Menge an verkaufen Regionalen Produkten</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardRegionalData.verkauftRegional?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left"}}>Regionaler Anteil an verkaufen Produkten:</th>
								<td style={{textAlign: "right"}}>{dashboardRegionalData.verkauftAnteilRegional?.toLocaleString('de', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
						</table>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading09 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Kritische Warenbestände <MultiSelect value={selectedKassen4} options={kassen} onChange={e => {setSelectedKassen4(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/></React.Fragment>} style={{width: '100%', height: '100%'}} footer={<React.Fragment><Button label="Befüllungsliste" icon="pi pi-download" onClick={onBefuellungslisteClick}/>{showExportSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}<br/><Checkbox className="ml-2 mt-2" style={{alignSelf: 'center'}} inputId="hideZero" checked={hideZero} onChange={e => setHideZero(e.target.checked)}/><label className="ml-1" style={{alignSelf: 'center'}} htmlFor="hideZero">keine Warenbestände mit einem Soll-Bestand von 0 anzeigen</label></React.Fragment>}>
						<DataTable className="p-datatable-striped p-datatable-sm tableCursorPointer" scrollable ref={dt} value={warenbestandDisplay} scrollHeight="20vh" style={{overflow: 'visible'}} onRowClick={() => history.push('/lager/warenbestand/dashboard')}>
							<Column field="Bezeichnung" header="Bezeichnung"/>
							<Column field="AufzMenge" style={{textAlign: "right"}} header="Aufz. Menge"/>
						</DataTable>
					</Card>
				</div>
				<LogAuswertung kassen={kassen} />
			</div>
		</React.Fragment>
	);
}

export default Auswertungen;