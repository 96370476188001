import React, { useState, useEffect, useContext, useRef } from "react";
import { LogService } from "../service/LogService";
import { GlobalState } from './GlobalState';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { KassenService } from '../service/KassenService'
import { ProduktService } from "../service/ProduktService";
import { Card } from 'primereact/card';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { ZoomPopup } from './ZoomPopup';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from "primereact/checkbox";
import { DataTable } from 'primereact/datatable';
import { WarenbestandService } from "../service/WarenbestandService";
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { useParams } from 'react-router-dom';
import { ProduktverlaufService } from "../service/ProduktverlaufService";
import { ExportService } from "../service/ExportService";
import { DashboardService } from "../service/DashboardService";
import { useLocation } from 'react-router-dom';
import { BelegeService } from "../service/BelegeService";

        


function Produktverlauf(props) {
	const logService = new LogService();
    const kassenService = new KassenService();
	const produktService = new ProduktService();
    const warenbestandService = new WarenbestandService();
    const verlaufService = new ProduktverlaufService();
    const exportService = new ExportService();
    const dashboardService = new DashboardService();
    const belegeService = new BelegeService();
    const location = useLocation();
    const { id } = useParams();
    
	const produktumsaetzeChartRef = useRef(null);
	const artikelvergleichChartRef = useRef(null);
	const lineChartRef = useRef(null);
	const dt = useRef(null);
    const toast = useRef(null);

    const [kassen, setKassen] = useState([]);
    const [selectedKassen, setSelectedKassen] = useState([]);
    const [produktVK, setProduktVK] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [datumsbereich, setDatumsbereich] =  useState(null);
    const [checked, setChecked] = useState(false);
	const [loading01, setLoading01] = useState(false);
	const [loading06, setLoading06] = useState(false);
	const [loading10, setLoading10] = useState(false);
	const [loading11, setLoading11] = useState(false);
	const [selectedMethode, setSelectedMethode] = useState("Monate");
	const [selectedMenge, setSelectedMenge] = useState(2);
    const [selectedVergleichsProdukte, setSelectedVergleichsProdukte] = useState(null);
	const [tagesverlaufBereich, settagesverlaufBereich] = useState();
	const [warenbestandDetailsOffen, setWarenbestandDetailsOffen] = useState(false);
	const [filters, setFilters] = useState({});
	const [vergleichsprodukte, setVergleichsprodukte] = useState([]);
	const [buchungszeilenansicht, setBuchungszeilenansicht] = useState(false);
    const [produktData, setProduktData] = useState([]);
    const [verlaufsData, setVerlaufsData] = useState([]);
	const [chartYData, setChartYData] = useState([]);
	const [testDatasets, setTestDatasets] = useState([]);
	const [BelegTabelle, setBelegTabelle] = useState([]);
	const [BelegTabelleTag, setBelegTabelleTag] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [Istbestand, setIstbestand] = useState([]);
	const [selectedTableMethode, setSelectedTableMethode] = useState("Tage");
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
	const [exportOptions, setExportOptions] = useState({});
	const [switchProduktGewinn, setSwitchProduktGewinn] = useState(false);
	const [switchArtikelgruppenGewinn, setSwitchArtikelgruppenGewinn] = useState(false);
	const [produktgruppendata, SetProduktgruppendata] = useState({});
	const [artikelgruppenvergleich, setArtikelgruppenvergleich] = useState({});
	const [netto5, setNetto5] = useState(false);
    const [liveBestandEinzeln, setliveBestandEinzeln] = useState(false);
    const [sollBestandEinzeln, setSollBestandEinzeln] = useState(false);
    const [sollbestand, setSollbestand] = useState(false);
    const [preise, setPreise] = useState({});
    const [aktuellePreise, setaktuellePreise] = useState({});
    const [steuer, setSteuer] = useState({});
    const [belegDetailsOffen, setBelegDetailsOffen] = useState(false);	
    const [selectedBeleg, setSelectedBeleg] = useState({});
    const [belegHtml, setBelegHtml] = useState('');
    const [warebestandsDetailstemp, setWarebestandsDetailstemp] = useState('');
    const [warebestandsDetails, setWarebestandsDetails] = useState('');
    const [verkaufsstart, setVerkaufsstart] = useState([]);
    const [loadAll, setLoadAll] = useState([]);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700);


    const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 700);
	};
	
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

    const downloadBeleg = () => {
		exportService.downloadBeleg(selectedBeleg.ID);
	}

    const onBelegDetailsHide = () => {
		setSelectedBeleg({});
		setBelegDetailsOffen(false);
	}

    const onRowClicked = async e => {
        if(buchungszeilenansicht &&
            e.data.BelegID >= 0
        ){
            const dataPromise = belegeService.getDetails(e.data.BelegID);
            const htmlPromise = exportService.belegHtml(e.data.BelegID);

            const data = await dataPromise;
            const html = await htmlPromise;
            setSelectedBeleg(data);
            setBelegHtml(html);

            setBelegDetailsOffen(true);
        }
        else {
            warenbestandService.getDetails(id).then(data => {
                setWarenbestandDetailsOffen(true);
            });
            if(selectedTableMethode==="Tage"){
                verlaufService.GetBelegzeilenDataTage(id,e.data.Datum,e.data.KassenID,selectedTableMethode).then(data => setBelegTabelleTag(data));
            }
            if(selectedTableMethode==="Wochen"){
                verlaufService.GetBelegzeilenDataWochen(id,e.data.Datum,e.data.KassenID,selectedTableMethode).then(data => setBelegTabelleTag(data));
            }
            if(selectedTableMethode==="Monate"){
                verlaufService.GetBelegzeilenDataMonat(id,e.data.Datum,e.data.KassenID,selectedTableMethode).then(data => setBelegTabelleTag(data));
            }
            setSelectedRow(e.data);
        }
	}

    const InitFilters = () => {
        setFilters({
            KassenID: { value: null, matchMode: FilterMatchMode.IN },
            Datum: { value: null, matchMode: FilterMatchMode.DATE_IS },
            Bezeichnung: { value: null, matchMode: FilterMatchMode.IN },
            Lieferant: { value: null, matchMode: FilterMatchMode.IN },
            Grund: { value: null, matchMode: FilterMatchMode.IN },
			ArtNr: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        });
    };

    const kassenFilter = (options) => {
		return (
			<MultiSelect
				className="column-filter"
				value={options.value}
				options={kassen}
				onChange={e => { options.filterApplyCallback(e.value); }}
				placeholder="Alle"
				optionLabel="KassenID"
				optionValue="KassenID"
				maxSelectedLabels={1}
			/>
		);
	};

    const onWarenbestandDetailsHide = () => {
		setWarenbestandDetailsOffen(false);
	};

    const months = [
        "Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];

    const onDatumsbereichChange = e => {
		let _target = [new Date(e.target.value[0].setHours(0,0,0)), e.target.value[1] === null ? null : new Date(e.target.value[1]?.setHours(0,0,0))];
        setDatumsbereich(_target);
	}
    
    const buchungstabelleExportieren = () => {
        setShowSpinner(true);
		verlaufService.downloadBuchungstabelle(BelegTabelle ,Object.entries(exportOptions).filter(option => option[1]).map(option => option[0])).then(() => {
			setShowSpinner(false);
            setExportDialogVisible(false);
		}).catch(() => {
			setShowSpinner(false);
			toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
    };

    function generateColorForId(id) {
        const str = id.toString();
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = ((hash << 5) - hash) + str.charCodeAt(i);
            hash |= 0;
        }
        const color = (hash & 0x00FFFFFF).toString(16).padStart(6, '0');
        return `#${color}`;
    }

	useEffect(() => {
        setGlobalState({...globalState, page: 'Produktverlauf'});
        produktService.getProdukt(id).then(data => {
            console.log(data)
            setProduktData(data)
            setPreise(data.Preise);
            setLoadAll(true);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                console.log("nicht berechtigt")
                setLoadAll(false);
            } else {
                console.log(error)
            }
        });
    },[])

    useEffect(() =>{
        console.log("jetzt");
        if(loadAll === true){
        verlaufService.GetProduktgruppeninfo(id).then(data => SetProduktgruppendata(data));
		produktService.getProduktVK(id).then(data => setProduktVK(data));
        produktService.getProdukte().then(data => {
            setVergleichsprodukte(data)
        });
        
        verlaufService.GetSteuern(id).then(data => setSteuer(data[0].faktor));

        

        //TODO
        //Live Ist-Bestand
        kassenService.getKassen().then(data => { 
            const updatedKassen = [];
            let remainingRequests = data.length;
            let totalBestand = 0;
            let totalSollBestand = 0;
            

            data.forEach(kasse => {
                let updatedKasse = {
                    ...kasse,
                    SollBestand: 0,
                    Bestand: 0
                };
            
                warenbestandService.getBestand(kasse.url, id)
                    .then(bestand => {
                        updatedKasse.Bestand = bestand.Bestand;
                        totalBestand += bestand.Bestand;
                        if (remainingRequests === 0) {
                            setKassen(updatedKassen);
                            setIstbestand(totalBestand);
                        }
                    })
                    .catch(() => {
                        updatedKasse.Bestand = 0;
                        totalBestand += 0;
            
                        if (remainingRequests === 0) {
                            setKassen(updatedKassen);
                            setIstbestand(totalBestand);
                        }
                    });
            
                warenbestandService.getBestaende(id, kasse.KassenID)
                    .then(bestand => {
                        updatedKasse.SollBestand = bestand.Sollbestand;
                        totalSollBestand += bestand.Sollbestand;
            
                        if (remainingRequests === 0) {
                            setKassen(updatedKassen);
                            setSollbestand(totalSollBestand);
                        }
                    })
                    .catch(() => {
                        updatedKasse.SollBestand = 0;
                        totalSollBestand += 0;
            
                        if (remainingRequests === 0) {
                            setKassen(updatedKassen);
                            setSollbestand(totalSollBestand);
                        }
                    });
                remainingRequests -= 1;
            
                updatedKassen.push(updatedKasse);
            });
        });
         

        const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		setDatumsbereich([new Date(year, month, day-30), now]);


        const tempmonth = location.state?.month;
        const tempyear = location.state?.year;
        const start = new Date(tempyear,tempmonth-1,1);
        const end = new Date(tempyear,tempmonth,0);

        if (start instanceof Date && !isNaN(start) && end instanceof Date && !isNaN(end)) {
            setDatumsbereich([start,end])
        }

        verlaufService.getVerkaufsstart(id).then(data => {
                setVerkaufsstart(data.datum)
            }
        )


		InitFilters();
        }
    }, [loadAll]); // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        if (datumsbereich && Array.isArray(preise)) {
            const [startDatum, endDatum] = datumsbereich;
            let preisVorStartDatum = null;
    
            const preisImBereich = preise.find(preis => {
                const preisDatum = new Date(preis.Datum);
                const start = new Date(startDatum);
                const end = endDatum ? new Date(endDatum) : new Date();
    
                return preisDatum >= start && preisDatum <= end;
            });
    
            if (preisImBereich) {
                setaktuellePreise(preisImBereich);
            } else {
                preisVorStartDatum = preise.filter(preis => new Date(preis.Datum) < new Date(startDatum))
                    .sort((a, b) => new Date(b.Datum) - new Date(a.Datum))[0];
    
                if (preisVorStartDatum) {
                    setaktuellePreise(preisVorStartDatum);
                }
            }
        }

        if(datumsbereich &&
            datumsbereich.length === 2 &&
            datumsbereich.every(d => d != null)){
            warenbestandService.warenbestandDetailsProdukt(id,datumsbereich[0], datumsbereich[1]).then(data => {
                setWarebestandsDetailstemp(data)
                }
            );
        }
    }, [preise, datumsbereich]);

    useEffect(() => {
        if(warebestandsDetailstemp){
            setWarebestandsDetails(
            warebestandsDetailstemp.map(w => ({...w, avgVerbrauch7: w.avgVerbrauch * 7, avgVerbrauch30: w.avgVerbrauch * 30, lagerumschlagshaeufigkeit: w.avgBestand === 0 ? null : w.verbrauch / w.avgBestand})))
        }
    },[warebestandsDetailstemp])
    

    useEffect(() => {
        let kassenIds;
        setLoading11(true);
        if (
            kassen.length > 0 &&
            produktgruppendata[0]?.WarengruppenID !== undefined &&
            datumsbereich.length === 2 &&
            datumsbereich.every(d => d != null)        
        ) {
            if(selectedKassen.length > 0){
                setLoading11(true);
                dashboardService.GetProduktUmsatz(selectedKassen, produktgruppendata[0].ArtikelgruppenID, datumsbereich[0], datumsbereich[1], netto5)
                    .then(data => {
                        setArtikelgruppenvergleich(data);
                        setLoading11(false);
                    });
            }
            else{
                kassenIds = kassen.map(k => k.KassenID);
                setLoading11(true);
                dashboardService.GetProduktUmsatz(kassenIds, produktgruppendata[0].ArtikelgruppenID, datumsbereich[0], datumsbereich[1], netto5)
                    .then(data => {
                        setArtikelgruppenvergleich(data)
                        setLoading11(false);
                    });
            }
        }
        setLoading11(false);
    }, [kassen, produktgruppendata, datumsbereich, selectedKassen]);

    useEffect(() => {
        function getDateWeek(date) {
            const currentDate = 
                (typeof date === 'object') ? date : new Date();
            const januaryFirst = 
                new Date(currentDate.getFullYear(), 0, 1);
            const daysToNextMonday = 
                (januaryFirst.getDay() === 1) ? 0 : 
                (7 - januaryFirst.getDay()) % 7;
            const nextMonday = 
                new Date(currentDate.getFullYear(), 0, 
                januaryFirst.getDate() + daysToNextMonday);
        
            return (currentDate < nextMonday) ? 52 : 
            (currentDate > nextMonday ? Math.ceil(
            (currentDate - nextMonday) / (24 * 3600 * 1000) / 7) : 1);
        }
        

		function fetchData() {
			const now = new Date();

            let selected = null;
            let day = new Date().getDate();
            let start = null;

            if (selectedMethode === "Jahre") {
                selected = Array.from(
                    new Array(selectedMenge),
                    (_, i) => now.getFullYear()+1 - selectedMenge + i
                );

                start = selected.map(year => new Date(selected[0], 0, 1));
            }

            if (selectedMethode === "Monate") {
                const currentMonth = now.getMonth();
                const currentYear = now.getFullYear();
            
                selected = Array.from(
                    new Array(selectedMenge),
                    (_, i) => {
                        const monthIndex = (currentMonth+1 - selectedMenge + i + 12) % 12;
                        return months[monthIndex];
                    }
                );
            
                start = selected.map((month, i) => {
                    const monthIndex = months.indexOf(month);
                    const yearAdjustment = Math.floor((currentMonth+1 - selectedMenge + i) / 12);
                    const year = currentYear + yearAdjustment;
                    return new Date(year, monthIndex, 1);
                });
            }
            
            if(selectedMethode === "Wochen") {
                selected = Array.from(
                    new Array(selectedMenge),
                    (_, i) => getDateWeek() + 1 - selectedMenge + i);
                selected = selected.map(m => "Woche " + m);

                start = selected.map(week => {
                    const weekNumber = parseInt(week.split(" ")[1]);
                    const startOfYear = new Date(now.getFullYear(), 0, 1);
                    const firstWeekStart = startOfYear.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000;
                    return new Date(firstWeekStart);
                });
            }
            setChartYData(selected.map((value, index) => ({ x: index, y: value })));            

            if(selectedKassen.length > 0){
                setLoading10(true)
                verlaufService.GetProduktvergleich(id, start[0], new Date(), selectedMethode, selectedKassen, switchProduktGewinn).then(data => {

                    const mainDataset = {
                        label: produktData?.Bezeichnung, 
                        data: data[0] ? data[0].data : [],
                        backgroundColor: generateColorForId(id)
                    };
                    
                    if (checked && selectedVergleichsProdukte !== null && selectedVergleichsProdukte !== undefined) {
                        const requests = selectedVergleichsProdukte.map(produkt => {
                            return verlaufService.GetProduktvergleich(produkt.ID, start[0], new Date(), selectedMethode, selectedKassen, switchProduktGewinn);
                        });

                        Promise.all(requests).then(responses => {
                            const comparisonDatasets = responses.map((data, index) => ({
                                label: selectedVergleichsProdukte[index].Bezeichnung || `Produkt ${index + 1}`,
                                data: data[0] ? data[0].data : [],
                                backgroundColor: generateColorForId(selectedVergleichsProdukte[index].ID*10),
                            }));
                            
                            const finalDatasets = [mainDataset, ...comparisonDatasets];
                            setTestDatasets(finalDatasets);
                        });
                        setLoading10(false)
                    } else {
                        setTestDatasets([mainDataset]);
                        setLoading10(false)
                    }
                });
            }
            else if (kassen.length > 0){
                const selectedKassen = kassen.map(kasse => kasse.KassenID);
                setLoading10(true)
                verlaufService.GetProduktvergleich(id, start[0], new Date(), selectedMethode, selectedKassen, switchProduktGewinn).then(data => {

                    const mainDataset = {
                        label: produktData?.Bezeichnung, 
                        data: (data[0] ? data[0].data.map(item => item) : []),
                        backgroundColor: generateColorForId(id)
                    };
                    
                    if (checked && selectedVergleichsProdukte !== null && selectedVergleichsProdukte !== undefined) {
                        const requests = selectedVergleichsProdukte.map(produkt => {
                            return verlaufService.GetProduktvergleich(produkt.ID, start[0], new Date(), selectedMethode, selectedKassen, switchProduktGewinn);
                        });

                        Promise.all(requests).then(responses => {
                            const comparisonDatasets = responses.map((data, index) => ({
                                label: selectedVergleichsProdukte[index].Bezeichnung || `Produkt ${index + 1}`,
                                data: data[0] ? data[0].data : [],
                                backgroundColor: generateColorForId(selectedVergleichsProdukte[index].ID*10),
                            }));
                            const finalDatasets = [mainDataset, ...comparisonDatasets];
                            setTestDatasets(finalDatasets);
                            setLoading10(false);
                        });
                    } else {
                        setTestDatasets([mainDataset]);
                        setLoading10(false)
                    }
                });
            }
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMenge, selectedMethode, checked, selectedVergleichsProdukte, produktData, selectedKassen, kassen, switchProduktGewinn]);

    useEffect(() => {
        if (datumsbereich !== undefined && 
            datumsbereich !== null &&
            datumsbereich[0] !== undefined && 
            datumsbereich[0] !== null &&
            datumsbereich[1] !== undefined && 
            datumsbereich[1] !== null )
            {
                setLoading06(true);
                logService.getLogsVonBis(
                    datumsbereich[0],
                    datumsbereich[1]).
                    then(data => {
                        if (data === undefined || data === null)
                            return data;
                        let logs = data.logs.map(log =>
                        ({...log,
                            Datum: new Date(log.Timestamp).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                            Zeit: new Date(log.Timestamp).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                        }));
                        let ret = {...data, logs: logs};
                    });
                
                warenbestandService.warenbestandDetailsVerlauf(id,datumsbereich[0],datumsbereich[1]).then(
                    data => {
                        if (data === undefined || data === null)
                            return data;
                        setVerlaufsData({
                        eingelagertVerlauf: [data.eingelagertVerlauf.reduce((acc, val) => acc + val, 0)],
                        verkaufVerlauf: [data.verkaufVerlauf.reduce((acc, val) => acc + val, 0)],
                        schwundVerlauf: [data.schwundVerlauf.reduce((acc, val) => acc + val, 0)],
                        verderbVerlauf: [data.verderbVerlauf.reduce((acc, val) => acc + val, 0)],
                        beschaedigtVerlauf: [data.beschaedigtVerlauf.reduce((acc, val) => acc + val, 0)]})
                    }); 
                if(selectedKassen.length > 0)
                {
                    verlaufService.GetTagesverlauf(id,datumsbereich[0],datumsbereich[1], selectedKassen).then(data => {
                        settagesverlaufBereich(data);
                        setLoading06(false);
                    });
                }
                else if (kassen.length > 0){
                    const selectedKassen = kassen.map(kasse => kasse.KassenID);
                    verlaufService.GetTagesverlauf(id,datumsbereich[0],datumsbereich[1], selectedKassen).then(data => {
                        settagesverlaufBereich(data);
                        setLoading06(false);
                    });
                }
            }
        setLoading06(false);
    }, [datumsbereich, selectedKassen, kassen])

    useEffect(() => {
        if(datumsbereich !== undefined && 
            datumsbereich !== null && 
            datumsbereich[0] !== undefined &&
            datumsbereich[0] !== null &&
            datumsbereich[1] !== undefined &&
            datumsbereich[1] !== null 
        ){
            setLoading01(true)
            if(buchungszeilenansicht){
                verlaufService.GetBelegzeilen(id,datumsbereich[0],datumsbereich[1]).then(data => setBelegTabelle(data));
                setLoading01(false)
            }
            else if(selectedTableMethode === "Tage"){
                verlaufService.GetBelegzeilenTage(id,datumsbereich[0],datumsbereich[1]).then(data => setBelegTabelle(data));
                setLoading01(false)
            }
            else if(selectedTableMethode === "Wochen"){
                verlaufService.GetBelegzeilenWochen(id,datumsbereich[0],datumsbereich[1]).then(data => setBelegTabelle(data));
                setLoading01(false)
            }
            else if(selectedTableMethode === "Monate"){
                verlaufService.GetBelegzeilenMonate(id,datumsbereich[0],datumsbereich[1]).then(data => setBelegTabelle(data));
                setLoading01(false)
            }
        }
    }, [buchungszeilenansicht, selectedTableMethode, datumsbereich])


    return (
        <React.Fragment>
             {loadAll && (
            <>
            <div className="col-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontWeight: "bold", marginRight: '10px' }}>Zeitraum: </h1>
                    <Calendar 
                        id="datumsbereich" 
                        className="column-filter" 
                        value={datumsbereich} 
                        onChange={onDatumsbereichChange} 
                        //locale="de"
                        dateFormat="dd.mm.yy"
                        showIcon={true} 
                        selectionMode="range" 
                        style={{ maxWidth: "15rem", minWidth:"14rem" }} 
                    />
                </div>
            </div>

            <div className="grid p-component">
                <div style={{ marginLeft: "1em", marginRight: "1em", marginTop:"1rem"}}>
                    <h2 style={{ fontWeight: "bold" }}>Verkaufsstatistik</h2>
                    <table className="mt-3 mb-4" style={{ width: isLargeScreen ? 'auto' : '90vw', display: 'block', overflowX: 'auto', tableLayout: 'auto' }}>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Aktueller Verkaufspreis:</th>
                            <td style={{ textAlign: "right" }}>{Number(aktuellePreise.VK).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Gesamt Einkauf:</th>
                            <td style={{ textAlign: "right" }}>{(verlaufsData?.eingelagertVerlauf * aktuellePreise.EKBrutto ?? aktuellePreise.EK * steuer).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Gesamt Verkauf:</th>
                            <td style={{ textAlign: "right" }}>{(verlaufsData?.verkaufVerlauf * aktuellePreise.VK).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Gesamt-DB:</th>
                            <td style={{ textAlign: "right" }}>{(verlaufsData?.verkaufVerlauf * aktuellePreise.VK - verlaufsData?.eingelagertVerlauf * aktuellePreise.EKBrutto ??aktuellePreise.EK * steuer).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>

                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Gesamt Schwund/Verderb/Beschädigt/Eigenverbrauch:</th>
                            <td style={{ textAlign: "right" }}>{((
                                                                (Number(verlaufsData?.schwundVerlauf ?? 0)) + 
                                                                (Number(verlaufsData?.verderbVerlauf ?? 0)) + 
                                                                (Number(verlaufsData?.beschaedigtVerlauf ?? 0)))*aktuellePreise.EKBrutto ?? aktuellePreise.EK * steuer).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px", position: 'relative'  }}>Netto-DB: 
                                <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Der Netto-DB ist der Gesamt-DB abzüglich des Gesamtschwunds/-Verderbs/-Beschädigungs und Eigenverbrauchs"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                            </th>
                            <td style={{ textAlign: "right" }}>{(
                                            (Number(verlaufsData?.verkaufVerlauf) * Number(aktuellePreise.VK) - 
                                             Number(verlaufsData?.eingelagertVerlauf) * Number(aktuellePreise.EKBrutto ?? aktuellePreise.EK * steuer))-
                                            (( 
                                                Number(verlaufsData?.schwundVerlauf) + 
                                                Number(verlaufsData?.verderbVerlauf) + 
                                                Number(verlaufsData?.beschaedigtVerlauf)
                                            ) * Number(aktuellePreise.EKBrutto ?? aktuellePreise.EK * steuer))
                                                ).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            </td>
                            <td style={{ textAlign: "right" }}>{"€"}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Ranking:</th>
                            <td style={{ textAlign: "right" }}>{"Gold ??"}</td>
                        </tr>
                    </table>
                </div>
                <div style={{ marginLeft: "1em", marginRight: "1em", marginTop:"1rem"  }}>
                    <h2 style={{ fontWeight: "bold" }}>Bestandsstatistik</h2>
                    <table className="mt-3 mb-4" style={{ width: isLargeScreen ? 'auto' : '90vw', display: 'block', overflowX: 'auto', tableLayout: 'auto' }}>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Eingelagert:</th>
                            <td style={{ textAlign: "right" }}>{(verlaufsData?.eingelagertVerlauf)}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Verkauft:</th>
                            <td style={{ textAlign: "right" }}>{(verlaufsData?.verkaufVerlauf)}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Schwund/Verderb/Beschädigt/Eigenverbrauch:</th>
                            <td style={{ textAlign: "right" }}>{parseInt(verlaufsData?.schwundVerlauf) + parseInt(verlaufsData?.verderbVerlauf) + parseInt(verlaufsData?.beschaedigtVerlauf)}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Live Ist-Bestand (über alle Kassen):</th>
                            <td style={{ textAlign: "right" }}>{Istbestand}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                            <td style={{ textAlign: "right" }}><Button icon="pi pi-plus" severity="plus" aria-label="plus" style={{marginBottom:"-0.1rem",fontSize:"0.2rem", width:"1.3rem", height:"1.3rem"}} onClick={() => {setliveBestandEinzeln("true")}} /></td>
                        </tr>
                        <Dialog visible={liveBestandEinzeln} onHide={() => setliveBestandEinzeln(false)} header="Live Ist-Bestände">
                        <table className="tablePadding" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                <td style={{ fontWeight: '500', textAlign: 'center' }}>Standort</td>
                                <td style={{ fontWeight: '500', textAlign: 'center' }}>Live Ist-Bestand</td>
                                </tr>
                            </thead>
                            <tbody>
                                {kassen.map((kasse, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{kasse.KassenID}</td>
                                    <td style={{ textAlign: 'center' }}>{kasse.Bestand}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        </Dialog>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Lagerumschlagshäufigkeit: </th>
                            <td style={{ textAlign: "right" }}>{warebestandsDetails[0]?.lagerumschlagshaeufigkeit?.toFixed(2).replace('.', ',')}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Ø Verbrauch pro Tag:  </th>
                            <td style={{ textAlign: "right" }}>{warebestandsDetails[0]?.avgVerbrauch.toFixed(2).replace('.', ',')}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Ø Verbrauch pro 7 Tage: </th>
                            <td style={{ textAlign: "right" }}>{warebestandsDetails[0]?.avgVerbrauch7.toFixed(2).replace('.', ',')}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Ø Verbrauch pro 30 Tage: </th>
                            <td style={{ textAlign: "right" }}>{warebestandsDetails[0]?.avgVerbrauch30.toFixed(2).replace('.', ',')}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                        </tr>
                        

                        <Dialog visible={sollBestandEinzeln} onHide={() => setSollBestandEinzeln(false)} header="Soll-Bestände">
                        <table className="tablePadding" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                <td style={{ fontWeight: '500', textAlign: 'center' }}>Standort</td>
                                <td style={{ fontWeight: '500', textAlign: 'center' }}>Soll-Bestand</td>
                                </tr>
                            </thead>
                            <tbody>
                                {kassen.map((kasse, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{kasse.KassenID}</td>
                                    <td style={{ textAlign: 'center' }}>{kasse.SollBestand}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </Dialog>
                    </table>
                </div>
                <div style={{ marginLeft: "1em", marginRight: "0em", marginTop:"1rem"  }}>
                    <h2 style={{ fontWeight: "bold" }}>Produktdetails</h2>
                    <table className="mt-3 mb-4" style={{ textAlign: "right", width: isLargeScreen ? 'auto' : '90vw', display: 'block', overflowX: 'auto', tableLayout: 'auto' }}>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Bezeichnung:</th>
                            <td style={{ textAlign: "right" }}>{produktData?.Bezeichnung}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Artikelnummer:</th>
                            <td style={{ textAlign: "right" }}>{produktData?.ArtNr}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Artikelgruppe:</th>
                            <td style={{ textAlign: "right" }}>{produktgruppendata[0]?.Artikelgruppenbezeichnung}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Warengruppe:</th>
                            <td style={{ textAlign: "right" }}>{produktgruppendata[0]?.WarengruppenBezeichnung}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Grundpreiseinheit:</th>
                            <td style={{ textAlign: "right" }}>{produktData?.GPEinheit || "Stück"}</td>
                        </tr>
                        
                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Soll-Bestand:</th>
                            <td style={{ textAlign: "right" }}>{sollbestand}</td>
                            <td style={{ textAlign: "right" }}>{produktData?.Einheit ? produktData.Einheit.replace('Stueck', 'Stück') : ''}</td>
                            <td style={{ textAlign: "right" }}><Button icon="pi pi-plus" severity="plus" aria-label="plus" style={{marginBottom:"-0.1rem",fontSize:"0.2rem", width:"1.3rem", height:"1.3rem"}} onClick={() => {setSollBestandEinzeln("true")}} /></td>
                        </tr>

                        <tr>
                            <th style={{ textAlign: "left", paddingRight: "10px" }}>Verkaufsstart: </th>
                            <td style={{ textAlign: "right" }}>
                                {new Date(verkaufsstart).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}
                            </td>
                        </tr>
                    </table>
                </div>

                
                <Divider />

                <div className="col-12" style={isLargeScreen ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }: {}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox className="ml-2 mt-2" style={{alignSelf: 'center'}} inputId="buchungszeilenansicht" checked={buchungszeilenansicht} onChange={e => setBuchungszeilenansicht(e.target.checked)}/>
                        <label className="ml-1 mt-2" style={{alignSelf: 'center'}} htmlFor="buchungszeilenansicht">Buchungszeilenbezogen</label>
                    </div>
                    <Dialog visible={exportDialogVisible} onHide={() => setExportDialogVisible(false)} header="Buchungstabelle exportieren"
                        footer={
                            <React.Fragment>
                                <Button label="Buchungstabelle exportieren" icon="pi pi-download" onClick={buchungstabelleExportieren}/>
                                {showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                            </React.Fragment>}>
                        <div className="field">
                            <Checkbox inputId="Standort" checked={exportOptions.Standort} onChange={e => setExportOptions({...exportOptions, Standort: e.target.checked})} />
                            <label htmlFor="Standort" className="mb-0 ml-1">Standort</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Datum" checked={exportOptions.Datum} onChange={e => setExportOptions({...exportOptions, Datum: e.target.checked})} />
                            <label htmlFor="Datum" className="mb-0 ml-1">Datum</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="IstBestand" checked={exportOptions.IstBestand} onChange={e => setExportOptions({...exportOptions, IstBestand: e.target.checked})} />
                            <label htmlFor="IstBestand" className="mb-0 ml-1">Ist-Bestand</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Bestandsänderung" checked={exportOptions.Bestandsänderung} onChange={e => setExportOptions({...exportOptions, Bestandsänderung: e.target.checked})} />
                            <label htmlFor="Bestandsänderung" className="mb-0 ml-1">Bestandsänderung</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Verkauft" checked={exportOptions.Verkauft} onChange={e => setExportOptions({...exportOptions, Verkauft: e.target.checked})} />
                            <label htmlFor="Verkauft" className="mb-0 ml-1">Verkauft</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Eingelagert" checked={exportOptions.Eingelagert} onChange={e => setExportOptions({...exportOptions, Eingelagert: e.target.checked})} />
                            <label htmlFor="Eingelagert" className="mb-0 ml-1">Eingelagert</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Umsatz" checked={exportOptions.Umsatz} onChange={e => setExportOptions({...exportOptions, Umsatz: e.target.checked})} />
                            <label htmlFor="Umsatz" className="mb-0 ml-1">Umsatz</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Grund" checked={exportOptions.Grund} onChange={e => setExportOptions({...exportOptions, Grund: e.target.checked})} />
                            <label htmlFor="Grund" className="mb-0 ml-1">Grund</label>
                        </div>
                        <Button label="Alle auswählen" onClick={() => setExportOptions({
                            Standort: true,
                            Datum: true,
                            IstBestand: true,
                            Bestandsänderung: true,
                            Verkauft: true,
                            Eingelagert: true,
                            Umsatz: true,
                            Grund: true
                        })}/>
                        </Dialog>
                        <div style={{
                            display: isLargeScreen ? 'flex' : "flex",
                            alignItems: isLargeScreen ? 'center' : "center",
                            justifyContent: isLargeScreen ? 'flex-start' : 'flex-start',
                            marginLeft: isLargeScreen ? "48vw" : "0rem"
                        }}>
                            <h3 className="mb-0 ml-1" style={{fontWeight: "bold", float: isLargeScreen ?  "right" : "", marginLeft: isLargeScreen ? '1rem' : "0rem", marginTop: isLargeScreen ? "0rem" : "0.5rem"}}>Ansicht:</h3>

                            <Dropdown value={selectedTableMethode} options={["Tage", "Wochen", "Monate"]} onChange={e => setSelectedTableMethode(e.target.value)} valueTemplate={w => 
                                <React.Fragment>{w} </React.Fragment>} style={{float: isLargeScreen ?  "right" : "", marginLeft: isLargeScreen ? '1rem' : "1rem", marginTop: isLargeScreen ? "0rem" : "0.5rem"}}/>
                        </div>
                        <Button label="Buchungstabelle exportieren" icon="pi pi-download" style={{float: isLargeScreen ?  "right" : "", marginLeft: isLargeScreen ? '1rem' : "0rem", marginTop: isLargeScreen ? "0rem" : "0.5rem"}} onClick={() => {setExportOptions({
                            Standort: false,
                            Datum: false,
                            IstBestand: false,
                            Bestandsänderung: false,
                            Verkauft: false,
                            Eingelagert: false,
                            Umsatz: false,
                            Grund: false
                        }); setExportDialogVisible(true)}} />
                    </div>
                    
                    <div  className="col-12">
                    {loading01 && (
                        <div>
                            <i 
                                className="pi pi-spin pi-spinner" 
                                style={{
                                    fontSize: '2rem', 
                                    position: "absolute", 
                                    left: "calc(50% - 1rem)",
                                    top: "calc(90% - 1rem)",
                                    zIndex: 9999 
                                }}
                            />
                            <div style={{
                                position: 'absolute', 
                                width: '100%', 
                                height: '80%', 
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                zIndex: 1
                            }} />
                        </div>
                    )}
                    <div style={{
                        width: '100%',
                        overflowX: 'auto', // Ermöglicht horizontales Scrollen
                        maxWidth: '100%',  // Stellt sicher, dass es die volle Breite nutzt
                        height: '80vh',    // Setzt die maximale Höhe
                      }}>
                        <DataTable
                            //TODO
                            //IST-Bestand

                            ref={dt} value={BelegTabelle}
                            responsive
                            className="p-datatable-warenbestand p-datatable-striped tableCursorPointer"
                            filterDisplay="row"
                            dataKey="id"
                            rowHover paginator rows={20}
                            emptyMessage="keine Warenbestände gefunden"
                            currentPageReportTemplate='({first} von {totalRecords})'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[20,30,40]}
                            onRowClick={onRowClicked}
                            scrollable
                            scrollHeight="80vh"
                            showGridlines={false}
                            filters={filters}
                            disabled={loading01}
                        >
						<Column
							field="KassenID"
							sortable
							filter
							filterElement={kassenFilter}
							header="Standort"
							showFilterMenu={false}
                            alignHeader="left"
							style={{width: "4em", maxWidth:"4em", minWidth:"8em"}}
							/>
                        <Column
                            field="Datum"
                            id="Datum"
                            sortable
                            header="Datum"
                            showFilterMenu={false}
                            style={{ width: "2em", maxWidth: "3em", minWidth:"6em" }}
                            alignHeader="left"
                            body={row => {
                                const datum = row.Datum;
                                if (datum instanceof Date) {
                                    const dateStr = datum.toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric' });
                                    return (
                                        <React.Fragment>
                                            {buchungszeilenansicht 
                                                ? datum.toLocaleString('de') 
                                                : dateStr}
                                        </React.Fragment>
                                    );
                                }
                
                                const datumStr = String(datum);
                                if (datumStr.includes(' - ')) {
                                    const [startDate, endDate] = datumStr.split(' - ');
                                    const start = new Date(startDate);
                                    const end = new Date(endDate);

                                    return (
                                        <React.Fragment>
                                            {buchungszeilenansicht 
                                                ? `${start.toLocaleString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })} - ${end.toLocaleString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })}` 
                                                : `${start.toLocaleDateString('de')} - ${end.toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })}`}
                                        </React.Fragment>
                                    );
                                } 
                                else {
                                    const start = new Date(datumStr);
                                
                                    if (!isNaN(start.getTime())) {
                                        return (
                                            <React.Fragment>
                                                {buchungszeilenansicht
                                                    ? start.toLocaleString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })
                                                    : start.toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                            </React.Fragment>
                                        );
                                    } else {
                                        const [month, year] = datumStr.split('.');
                                        const formattedDate = `${month.padStart(2, '0')}.${year}`;
                                
                                        return <React.Fragment>{formattedDate}</React.Fragment>;
                                    }
                                }
                                
                            }}
                        />

                        {!buchungszeilenansicht && (
                            <Column
                                field="Bestand" 
                                style={{width: "2em", maxWidth: "3em", minWidth:"7rem", textAlign: "right"}} 
                                sortable 
                                header="Ist-Bestand"
                                alignHeader="right"
                                body={
                                    row => <React.Fragment>{row.Bestand?.toLocaleString('de')}</React.Fragment>
                                }
                                showFilterMenu={false}
                                />
                        )}   
						<Column
							field="change" 
                            header="Bestandsänderung" 
                            style={{maxWidth: "3em", width: "2em", minWidth:"12rem", textAlign: "right"}} 
                            sortable
                            alignHeader="right"
							body={row => (
                                <React.Fragment>
                                  {buchungszeilenansicht 
                                    ? row.change?.toLocaleString('de')  
                                    : ((row.eingelagert || 0) - (row.verkauft || 0)).toLocaleString('de')  
                                  }
                                </React.Fragment>
                              )}
							showFilterMenu={false}
                            footer={(
                                <React.Fragment>
                                  {buchungszeilenansicht 
                                    ? BelegTabelle?.reduce((sum, row) => sum + (row.change || 0), 0).toLocaleString('de') 
                                    : BelegTabelle?.reduce((sum, row) => sum + ((row.eingelagert || 0) - (row.verkauft || 0)), 0).toLocaleString('de')
                                  }
                                </React.Fragment>
                              )}
                            
							/>
                        {!buchungszeilenansicht && (
                            <Column
                                field="verkauft" 
                                style={{width: "2em", maxWidth: "3em", minWidth:"10rem", textAlign: "right"}} 
                                sortable 
                                header="Verkauft"
                                alignHeader="right"
                                body={
                                    row => <React.Fragment>{row.verkauft?.toLocaleString('de')}</React.Fragment>
                                }
                                footer={(
                                    <React.Fragment>
                                      {BelegTabelle?.reduce((sum, row) => sum + (row.verkauft || 0), 0).toLocaleString('de')}
                                    </React.Fragment>
                                )}
                                showFilterMenu={false}
                            />
                        )}
                        {!buchungszeilenansicht && (
                            <Column
                                field="eingelagert" 
                                style={{width: "2em", maxWidth: "3em", minWidth:"10rem", textAlign: "right"}} 
                                sortable 
                                header="Eingelagert"
                                alignHeader="right"
                                body={
                                    row => <React.Fragment>{row.eingelagert?.toLocaleString('de')}</React.Fragment>
                                }
                                footer={(
                                    <React.Fragment>
                                      {BelegTabelle?.reduce((sum, row) => sum + (row.eingelagert || 0), 0).toLocaleString('de')}
                                    </React.Fragment>
                                )}
                                showFilterMenu={false}
                            />
                        )}
                        <Column
                            field="Umsatz"
                            style={{ width: "2em", maxWidth: "3em", minWidth:"10rem", textAlign: "right" }}
                            sortable
                            header="Umsatz"
                            alignHeader="right"
                            body={row => {
                                return <React.Fragment>
                                    {row.Umsatz?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.').toLocaleString('de') ?? "0".toLocaleString('de')} €
                                    </React.Fragment>;
                            }}
                            footer={(
                                <React.Fragment>
                                    {
                                        buchungszeilenansicht
                                            ? (BelegTabelle?.reduce((sum, row) => sum + (row.change * produktVK || 0), 0) * -1)
                                                .toFixed(2)
                                                .replace('.', ',')
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                                                .toLocaleString('de') + " €"
                                            : BelegTabelle?.reduce((sum, row) => sum + (row.verkauft * produktVK || 0), 0)
                                                .toFixed(2)
                                                .replace('.', ',')
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                                                .toLocaleString('de') + " €"
                                    }
                                </React.Fragment>
                            )} 
                            showFilterMenu={false}
                        />
					</DataTable>
                    </div>
                    <Dialog header={produktData?.Bezeichnung} visible={warenbestandDetailsOffen} onHide={onWarenbestandDetailsHide} style={{minWidth:"30rem"}}>
                        <table className="tablePadding" style={{width: "100%"}}>
                            <tr>
                                <td style={{paddingLeft: "2em", paddingRight: "2em"}}>
                                    <span style={{fontWeight: "bold"}}>Art.-Nr.</span> 
                                </td>
                                <td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Standort</span></td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: "2em", paddingRight: "2em"}}>{produktData?.ArtNr}</td>
                                <td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}>
                                    {selectedRow ? selectedRow.KassenID : "Unbekannt"}
                                </td>                 
                            </tr>
                        </table>
                        <Divider />

                        <table className="tablePadding" style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <td style={{ fontWeight: "500", textAlign: "center" }}>Datum</td>
                                    {selectedTableMethode !== 'Monate' && selectedTableMethode !== 'Wochen' && (
                                        <td style={{ fontWeight: "500", textAlign: "center" }}>Zeit</td>
                                    )}
                                    <td style={{ fontWeight: "500", textAlign: "center" }}>Menge</td>
                                    {selectedTableMethode !== 'Monate' && selectedTableMethode !== 'Wochen' && (
                                        <td style={{ fontWeight: "500", textAlign: "center" }}>Grund</td>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {BelegTabelleTag.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "center" }}>
                                            {new Date(item.Datum).getDate().toString().padStart(2, '0') + '.' +
                                            (new Date(item.Datum).getMonth() + 1).toString().padStart(2, '0') + '.' +
                                            new Date(item.Datum).getFullYear()}
                                        </td>                                      
                                        <td style={{ textAlign: "center" }}>{new Date(item.Datum).toLocaleTimeString()}</td>
                                        <td style={{ textAlign: "center" }}>{item.change}</td>
                                        <td style={{ textAlign: "center" }}>{item.Grund}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </Dialog>
				</div>
                
                <Divider />
                <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <MultiSelect
                        className="column-filter"
                        value={selectedKassen}
                        options={kassen}
                        onChange={e => setSelectedKassen(e.value)}
                        placeholder={selectedKassen.length > 0 ? selectedKassen.join(', ') : "Alle Kassen"}
                        optionLabel="KassenID"
                        optionValue="KassenID"
                        style={{ marginLeft: '0.2rem', width: "10rem", height: "2.5rem" }}
                        disabled={loading01}
                    />
                </div>
                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{position: "relative"}}>
                    {loading06 && (
                        <div>
                            <i 
                                className="pi pi-spin pi-spinner" 
                                style={{
                                    fontSize: '2rem', 
                                    position: "absolute", 
                                    left: "calc(50% - 1rem)",
                                    top: "calc(50% - 1rem)",
                                    zIndex: 9999 
                                }}
                            />
                            <div style={{
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                width: '100%', 
                                height: '100%', 
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                zIndex: 1
                            }} />
                        </div>
                    )}
					<Card title={
                        <React.Fragment>
                            Tagesverlauf 
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                (gemäß ausgewähltem Zeitraum)
                            </span>
                            
                        </React.Fragment>} style={{width: '100%', height: '100%'}}>
                    
						<ZoomPopup header={
                            <div style={{textAlign: "center", position: "relative"}}><span style={{position: "absolute", left: "0px"}}>Tagesverlauf</span>
                                <div style={{display: "inline-block"}}>
                                </div>
                            </div>} 
                            footer={
                                <Button label="Download" icon="pi pi-image" onClick={() => download(lineChartRef.current.getBase64Image(),"Tagesverlauf.png")}/>
                            }>
        
                        <Chart
                            type="line"
                            ref={lineChartRef}
                            data={{
                                labels: Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`),
                                datasets: [{
                                label: 'Durchschnittliche Menge',
                                data: tagesverlaufBereich && tagesverlaufBereich.length > 0 ? tagesverlaufBereich.map(item => item.DurchschnittlicheMenge) : [], 
                                borderColor: 'rgba(75, 192, 192, 1)',
                                tension: 0.4,
                                }],
                            }}
                            options={{
                                animation: { duration: 0 },
                                scales: {
                                x: {
                                    stacked: false,
                                    title: {
                                    display: true,
                                    text: 'Stunde'
                                    }
                                },
                                y: {
                                    stacked: false,
                                    title: {
                                    display: true,
                                    text: 'Durchschnittliche Menge'
                                    }
                                }
                                },
                                plugins: {
                                legend: { position: 'bottom' }
                                }
                            }}
                            />

						</ZoomPopup>
					</Card>
				</div>

                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{position: "relative"}}>
                    {loading11 && (
                                <div>
                                    <i 
                                        className="pi pi-spin pi-spinner" 
                                        style={{
                                            fontSize: '2rem', 
                                            position: "absolute", 
                                            left: "calc(50% - 1rem)",
                                            top: "calc(50% - 1rem)",
                                            zIndex: 9999 
                                        }}
                                    />
                                    <div style={{
                                        position: 'absolute', 
                                        top: 0, 
                                        left: 0, 
                                        width: '100%', 
                                        height: '100%', 
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        zIndex: 1
                                    }} />
                                </div>
                            )}
					<Card title={
                        <React.Fragment>
                            Gewichtung in der Artikelgruppe
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                (gemäß ausgewähltem Zeitraum)
                            </span>
                            <div style={{float: "right"}}>
                                <span style={{ fontSize: '0.6em', color: '#555', marginLeft:'0.5rem' }}>
                                    Stück
                                </span>
                                <InputSwitch                                
                                checked={switchArtikelgruppenGewinn} onChange={(e) => setSwitchArtikelgruppenGewinn(e.value)} autoFocus={false} style={{ scale: "0.8",  marginTop:'0rem',marginBottom:'-0.5rem'}}/>
                                <span style={{ fontSize: '0.6em', color: '#555'}}>
                                    Euro
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '0.6em', color: '#555', marginLeft:'0.5rem' }}>
                                Platzierung innerhalb der Artikelgruppe: 
                                </span>
                            </div>
                        </React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Artikelgruppenvergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(artikelvergleichChartRef.current.getBase64Image(), 'Artikelgruppenvergleich.png')}/>} >
                            <Chart
                                ref={artikelvergleichChartRef}
                                type="bar"
                                resize="true"
                                data={{
                                    labels: [produktgruppendata[0]?.Artikelgruppenbezeichnung],
                                    datasets: Array.isArray(artikelgruppenvergleich) ? artikelgruppenvergleich.map(item => ({
                                        label: item.Bezeichnung,
                                        data: [switchArtikelgruppenGewinn ? item.Umsatz : item.Menge],
                                        backgroundColor: generateColorForId(item.ID*10),
                                    })) : []
                                }}
                                options={{
                                    animation: { duration: 0 },
                                    scales: {
                                        x: { stacked: true },
                                        y: {
                                            stacked: true,
                                            title: {
                                                display: true,
                                                text: switchArtikelgruppenGewinn ?   'Umsatz in Euro' : 'Verkaufte Anzahl in Stück'
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: { position: 'bottom' }
                                    }
                                }}
                                style={{ position: 'relative', marginTop: '1rem' }}
                            />
						</ZoomPopup>
					</Card>
				</div>

                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{position: "relative"}}>
                {loading10 && (
                                <div>
                                    <i 
                                        className="pi pi-spin pi-spinner" 
                                        style={{
                                            fontSize: '2rem', 
                                            position: "absolute", 
                                            left: "calc(50% - 1rem)",
                                            top: "calc(50% - 1rem)",
                                            zIndex: 9999 
                                        }}
                                    />
                                    <div style={{
                                        position: 'absolute', 
                                        top: 0, 
                                        left: 0, 
                                        width: '100%', 
                                        height: '100%', 
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        zIndex: 1
                                    }} />
                                </div>
                            )}

					<Card title={
                        <React.Fragment>
                            Produktvergleich
                            <div>
                                <div style={{float: "right", marginTop: "0.5rem"}}>
                                    <span style={{ fontSize: '0.6em', color: '#555', marginLeft:'0.5rem' }}>
                                        Stück
                                    </span>
                                    <InputSwitch 
                                    checked={switchProduktGewinn} onChange={(e) => setSwitchProduktGewinn(e.value)} autoFocus="false" style={{ scale: "0.8", marginBottom:'-0.5rem'}} />
                                    <span style={{ fontSize: '0.6em', color: '#555'}}>
                                        Euro
                                    </span>
                                </div>
                            
                                <div style={{float: "right", marginTop: "0.5rem"}}>
                                    <Dropdown value={selectedMethode} options={["Wochen", "Monate", "Jahre"]} onChange={e => setSelectedMethode(e.target.value)} valueTemplate={w => <React.Fragment>{w}
                                    </React.Fragment>} style={{float: "right", marginLeft:'0.5rem', minWidth:"10rem"}}/>
                                    <Dropdown value={selectedMenge} options={[2, 4, 7, 10, 13]} onChange={e => setSelectedMenge(e.target.value)} valueTemplate={w => 
                                        <React.Fragment>{w} </React.Fragment>} style={{float: "right", marginLeft:'0.5rem'}}/>
                                </div>

                                <div style={{float: "right", marginTop: "0.5rem"}}>
                                    <MultiSelect value={selectedVergleichsProdukte} onChange={(e) => setSelectedVergleichsProdukte(e.value)} options={vergleichsprodukte} optionLabel="Bezeichnung" 
                                        filter placeholder="Vergleichsprodukt" maxSelectedLabels={1} style={{float: "right", marginLeft:'0.5rem'}} id="produkt" selectionLimit={5} />

                                    <Checkbox onChange={e => setChecked(e.checked)} checked={checked} style={{float: "right", marginTop:'0.5rem'}}></Checkbox>
                                </div>
                            </div>
                        </React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header="Produktvergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(produktumsaetzeChartRef.current.getBase64Image(), 'Produktvergleich.png')}/>} >
                            
                        <Chart
                            ref={produktumsaetzeChartRef}
                            type="bar"
                            resize="true"
                            data={{
                                labels: chartYData && chartYData.length > 0 ? chartYData.map(d => d.y) : [],
                                datasets: testDatasets || [],
                            }}
                            options={{
                                animation: { duration: 0 },
                                scales: {
                                x: { stacked: false },
                                y: {
                                    stacked: false, 
                                    title: {
                                        display: true,
                                        text: switchProduktGewinn ? 'Umsatz in Euro' : 'Verkaufte Anzahl in Stück'
                                    }
                                }
                                },
                                plugins: {
                                legend: { position: 'bottom' }
                                }
                            }}
                            
                            style={{ position: 'relative',marginTop: isLargeScreen ? '3rem' : "5rem"  }}
                            />

						</ZoomPopup>
					</Card>
				</div>
                <Divider/>
            </div>
            <Dialog header={
                <React.Fragment>
                    <span style={{fontSize: '1.25em'}}>Beleg Details</span>
                    <Button 
                        style={{marginLeft: '1em', marginRight: '1em', float: 'right'}} 
                        icon="pi pi-file-pdf" 
                        label="Download" 
                        onClick={downloadBeleg}/>
                    {selectedBeleg?.Storno === true && 
                        <React.Fragment>
                            <br/>Stornierungs-Grund:<br/>{selectedBeleg?.StornoGrund}
                        </React.Fragment>}
                </React.Fragment>}
				visible={belegDetailsOffen} 
                onHide={onBelegDetailsHide}>
				<div style={{fontFamily: 'monospace'}} dangerouslySetInnerHTML={{__html: belegHtml}}></div>
			</Dialog>
            </>
            )}
        </React.Fragment>
    );
}

export default Produktverlauf;
