import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class AktionService {
	async getProdukteRabattierbar(selectedGruppe, kategorie) {
		let url;
		if(selectedGruppe.length > 0){
			url = CONFIG.ServerUrl + "/Aktionen/Produkte/rabattierbar/"+ kategorie +"/"+selectedGruppe;
		}
		else{
			url = CONFIG.ServerUrl + "/Aktionen/Produkte/rabattierbar/"+ kategorie +"/"+null;
		}
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async getAlleProdukteRabattierbar() {
        let url = CONFIG.ServerUrl + "/Aktionen/Produkte/AlleRabattierbar/";
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async getAllUngültigenProdukte() {
        let url = CONFIG.ServerUrl + "/Aktionen/GetAllUngültigenProdukte/";
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async getAllUngültigenProdukteLieferant(kategorie, id) {
        let url = CONFIG.ServerUrl + "/Aktionen/GetAllUngültigenProdukteLieferant/"+ kategorie + "/" + id;
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async getAnzahlProdukte(kategorie, kategorieBez, warengruppeBez, artikelgruppeBez){
		let url = CONFIG.ServerUrl + "/Aktionen/Produkte/anzahl/"+ kategorie +"/"+ kategorieBez +"/"+ warengruppeBez +"/"+artikelgruppeBez;
		//console.log(url);
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async getNiedrigerVK(kategorie, gruppe, art, wert){
		let url = CONFIG.ServerUrl + "/Aktionen/niedrigerVK/"+ kategorie +"/"+ gruppe+"/"+art+"/"+wert;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async istBerechtigt(kategorie, gruppe){
		let url = CONFIG.ServerUrl + "/Aktionen/istBerechtigt/"+ kategorie +"/"+ gruppe;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async neuerTyp(neuerTyp) {
		let url = CONFIG.ServerUrl + "/Aktionen/neuerTyp/";
		//console.log(url)
		return await axios.post(url, neuerTyp, await GetAxiosConfig()).then(res => res.data);
    }

	async getAktionsTypen(){
		let url = CONFIG.ServerUrl + "/Aktionen/Aktionstypen/";
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async setNewAktion(temp){
		let url = CONFIG.ServerUrl + "/Aktionen/neueAktion/";
		//console.log(url)
		return await axios.post(url, temp, await GetAxiosConfig()).then(res => res.data);
	}

	async getAktionen(){
		let url = CONFIG.ServerUrl + "/Aktionen/Aktionen/";
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async changeRabattierbar(kategorie, id){
		let url = CONFIG.ServerUrl + "/Aktionen/changeRabattierbar/"+kategorie+"/"+id;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);

	}

	async getNextID(){
		let url = CONFIG.ServerUrl + "/Aktionen/getNextID/";
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res =>res.data);
	}

	async getProdukte(kategorie, produkte, prozent, text, id){
		let url = CONFIG.ServerUrl + "/Aktionen/GetProdukte/"+kategorie+"/"+produkte+"/"+prozent+"/"+text+"/"+id;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res =>res.data);
	}

	async alreadyAktion(temp, id){
		let url = CONFIG.ServerUrl + "/Aktionen/alreadyAktion/"+ id;
		//console.log(url)
		return await axios.post(url, temp, await GetAxiosConfig()).then(res => res.data);
	}

	async changeAktiv(id, value){
		let url = CONFIG.ServerUrl + "/Aktionen/changeAktiv/"+id+"/"+value;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async getAktionByGuid(id){
		let url = CONFIG.ServerUrl + "/Aktionen/getAktionByGuid/"+id;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async hasPermission(id){
		let url = CONFIG.ServerUrl + "/Aktionen/hasPermission/"+id;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async getGutscheinData(id){
		let url = CONFIG.ServerUrl + "/Aktionen/getGutscheinData/"+id;
		//console.log(url)
		return await axios.get(url, await GetAxiosConfig()).then(res => res.data);
	}

	async changeAktion(aktion, id){
		let url = CONFIG.ServerUrl + "/Aktionen/changeAktion/"+id;
		//console.log(url)
		return await axios.post(url, aktion, await GetAxiosConfig()).then(res => res.data);
	}

	async deleteAktion(id){
		let url = CONFIG.ServerUrl + "/Aktionen/deleteAktion/";
		//console.log(url)
		return await axios.post(url, id, await GetAxiosConfig()).then(res => res.data);
	}

	async getImage(id) {
        if (id === -1)
            return;
        let url = CONFIG.ServerUrl + '/Aktionen/' + id + '/Bild'
		//console.log(url)
        return axios.get(url, await GetAxiosConfig()).then(res => res.data).catch(e => console.log(e));
    }

	async getProdukteUngültig(selectedGruppe, kategorie) {
        let url = CONFIG.ServerUrl + "/Aktionen/Produkte/ungültig/"+ kategorie +"/"+selectedGruppe;
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }
	
	async getGruppennamen(kategorie, id) {
        let url = CONFIG.ServerUrl + "/Aktionen/Gruppennamen/"+ kategorie +"/"+id;
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async getProdukteLaufendeAktion(id) {
        let url = CONFIG.ServerUrl + "/Aktionen/laufendeAktion/" + id;
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

	async notIncludeProduct(aktion, id) {
		console.log(id)
        let url = CONFIG.ServerUrl + "/Aktionen/notInclude/" + aktion + "/" + id;
		//console.log(url);

        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }
	
	async getAktionsGutscheinCodes(anzahl) {
        let url = CONFIG.ServerUrl + "/Aktionen/AktionsgutscheinCodes/" + anzahl;
		//console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }
}

export default AktionService;