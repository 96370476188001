import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function KundentypColumn(props) {

	const KundenTypen = [{KundenTyp: "Privat", Value: 0},
						 {KundenTyp: "mehrere Privat", Value: 2},
						 {KundenTyp: "Firma", Value: 1}];

	const onKundenTypChange = (e) => {
		const values = e.target.value;
		props.setSelectedKundenTyp(values);

    	if (!values.length || values[0] == null) {
			props.setSelectedKundenTyp(null);
			props.datatableref.current.filter('', "Firma", "contains"); 
			return;
		}
	
		let filterConditions = [];

		values.forEach(value => {
			if (value === 0 || value === 2) {
				filterConditions.push(false);
			}
			if (value === 1) {
				filterConditions.push(true);
			}
		});

		if (filterConditions.length > 0) {
			props.datatableref.current.filter(filterConditions, "Firma", "in");
		} else {
			props.datatableref.current.filter('', "Firma", "contains");
		}
	}
	
	

	const KundentypBodyTemplate = (rowData) => { 
		if(rowData.Firma === false && rowData.Anrede2){
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-users"}></div>
				</div>
			) 
		}
		else if(rowData.Firma === false){ 
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-user"}></div>
				</div>
			)
		}
		else{
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-building"}></div>
				</div>
			)
		}
	}

	const KundentypFilterTemplate = (rowData) => {
		if (rowData === undefined){
			return;
		}
		else{
		return(<div><i className={rowData.KundenTyp === "mehrere Privat" ? "pi pi-users" : rowData.KundenTyp === "Privat" ? "pi pi-user" : "pi pi-building"} /><span className='ml-2'>{rowData.KundenTyp}</span></div>);
		}
	}

	const selectedKundentypFilterTemplate = (rowData) => { 
		if(rowData === undefined){
			return;
		}
		else if (rowData === 0){
			return(<div className='pi pi-user'> Privat</div>)
		}
		else if (rowData === 1){
			return(<div className='pi pi-building'> Firma</div>)
		}
		else if (rowData === 2){
			return(<div className='pi pi-users'> mehrere Privat</div>)
		}
	}

	return(
		<Column header="Typ" field="KundenArt" style={{width: "150px", maxWidth:"7rem"}} filter sortable filterMatchMode="contains" body={KundentypBodyTemplate}
		filterElement={
			<MultiSelect value={props.selectedKundenTyp} options={KundenTypen} optionLabel="KundenTyp" optionValue="Value"
				placeholder={"Alle"} 
				onChange={onKundenTypChange} itemTemplate={KundentypFilterTemplate} selectedItemTemplate={selectedKundentypFilterTemplate}/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		/>
	)
}