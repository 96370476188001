import React, { useState, useEffect, useContext, useRef } from "react";
import { LogService } from "../service/LogService";
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { KassenService } from '../service/KassenService'
import LogDiagramm from "./LogDiagramm";
import LogJahresdiagramm from "./LogJahresdiagramm";
import { FilterService } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from "../service/ExportService";

function Logs(props) {
    const logService = new LogService();
    const kassenService = new KassenService();
    const exportService = new ExportService();
    const [selectedKassen, setSelectedKassen] = useState(null);
    const [selectedDetails, setSelectedDetails] = useState(null);
    const [kassen, setKassen] = useState([]);
    const [logs, setLogs] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [datumsbereich, setDatumsbereich] = useState(null);
    const [meldung, setMeldung] = useState(null);
    const [detailsOffen, setDetailsOffen] = useState(false);
    const [selectedMeldung, setSelectedMeldung] = useState([]);
    const [dataSetJahr, setDataSetJahr] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [zeit, setZeit] = useState('');
    const [zeitOption, setZeitOption] = useState(null);
	const [exportOptions, setExportOptions] = useState({});
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const dt = useRef(null);
    const toast = useRef(null);

    const meldungen = [
        {label: 'Zahlung Fehlgeschlagen', value: 'Zahlung Fehlgeschlagen'},
        {label: 'Drucken Fehlgeschlagen', value: 'Drucken Fehlgeschlagen'},
        {label: 'Barcode nicht gefunden', value: 'Barcode nicht gefunden'},
    ];

    FilterService.register("timeFilter", (a, b) => {
		if (a === null || a === undefined || zeit === null || zeit === undefined || zeit.length === 0)
			return true;
		let value = a;
		let filter = zeit;
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	});

    const filters = {
        'Zeit': { value: null, matchMode: "timeFilter" }
    };

    useEffect(() => {
		setGlobalState({...globalState, page: 'Fehlerübersicht'});
        setDatumsbereich([new Date(), new Date()]);
        kassenService.getKassen().then(data => setKassen(data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (datumsbereich !== undefined && datumsbereich !== null)
            logService.getLogsVonBis(
                datumsbereich[0],
                datumsbereich[1]).
                then(data => {
                    if (data === undefined || data === null)
                        return data;
                    let logs = data.logs.map(log =>
                    ({...log,
                        Datum: new Date(log.Timestamp).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                        Zeit: new Date(log.Timestamp).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                    }));
                    let ret = {...data, logs: logs};
                    setLogs(ret);
                });
    }, [datumsbereich])

	const renderDatumFilter = () => {
		return (
			<Calendar id="datumsbereich" className="column-filter" value={datumsbereich} onChange={onDatumsbereichChange}  dateFormat="dd.mm.yy"  showIcon={true} selectionMode="range"/>
		);
	}

    const onDatumsbereichChange = e => {
		let _target = [new Date(e.target.value[0].setHours(0,0,0)), e.target.value[1] === null ? null : new Date(e.target.value[1]?.setHours(0,0,0))];
        setDatumsbereich(_target);
	}

    const renderKassenFilter = () => {
		return (
			<MultiSelect
                className="column-filter"
                value={selectedKassen}
                options={kassen}
                onChange={e =>
                    {
                        setSelectedKassen(e.target.value);
                        dt.current.filter(e.target.value, 'KassenID', 'in');
                    }}
                placeholder="Alle"
                optionLabel="KassenID"
                optionValue="KassenID"
                maxSelectedLabels={1}
            />
		);
	};

    const renderMeldungFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedMeldung} options={meldungen}
            onChange={e => { setSelectedMeldung(e.target.value); dt.current.filter(e.target.value, 'Meldung', 'in'); }} placeholder="Alle"/>
		);
	};

    const formatDate = (value) => {
        return value?.toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'});
    }

    const formatTimestring = (value) => {
        return value?.toLocaleTimeString('de');
    }

    const onRowClicked = (e) => {
        setMeldung(e.data.Details);
		setDetailsOffen(true);
	}

    const datumFilter = renderDatumFilter();
    const kassenFilter = renderKassenFilter();
    const meldungFilter = renderMeldungFilter();

    const zeitFilter = (option) => {
		return <InputText value={zeit} onChange={(e) => {
			setZeit(e.target.value);
			setZeitOption(option);
		}} />
	}
    const fehlerlisteExportieren = () => {
        setShowSpinner(true);
		logService.getLogsExport(datumsbereich[0],datumsbereich[1],Object.entries(exportOptions).filter(option => option[1]).map(option => option[0])).then(() => {
			setShowSpinner(false);
            setExportDialogVisible(false);
		}).catch(() => {
			setShowSpinner(false);
			toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
    };

    useEffect(() => {
		zeitOption?.filterCallback(zeit);
		zeitOption?.filterApplyCallback()
	}, [zeit])


    return (
        <React.Fragment>
            <div className="grid p-component">
                <div className="col-12">
                    <DataTable ref={dt}
                        value={logs?.logs}
                        responsive="true"
                        className="p-datatable-produkte p-datatable-striped tableCursorPointer" dataKey="id"
                        rowHover paginator rows={20}
                        filters={filters}
                        emptyMessage="keine Logs gefunden"
                        currentPageReportTemplate='({first} von {totalRecords})'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]} sortField={props.dashboard !== undefined ? 'AufzMenge' : undefined}
                        sortOrder={props.dashboard !== undefined ? -1 : undefined}
                        onRowClick={onRowClicked}
                        filterDisplay="row"
                        /* expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} */
                        groupRowsBy="Bezeichnung"
                        scrollable scrollHeight="60vh">
                        <Column
                            field="Timestamp"
                            showFilterMenu={false}
                            body={row =>  formatDate(new Date(row.Timestamp))}
                            style={{maxWidth: "16em", width: "16em"}}
                            sortable
                            filter
                            filterElement={datumFilter}
                            header="Datum"
                            />
                        <Column
                            field="Zeit"
                            showFilterMenu={false}
                            header="Zeitpunkt"
                            filter
                            sortable
                            style={{maxWidth: "9em", width: "9em"}}
                            filterElement={zeitFilter}
                            />
                        <Column
                            field="KassenID"
                            showFilterMenu={false}
                            header="Standort"
                            sortable
                            filter
                            style={{maxWidth: "10em", width: "10em"}}
                            filterElement={kassenFilter}
                            />
                        <Column
                            field="Meldung"
                            showFilterMenu={false}
                            header="Fehlertyp"
                            sortable
                            filter
                            style={{maxWidth: "20em", width: "20em"}}
                            filterElement={meldungFilter}
                            />
                        <Column
                            field="Details"
                            showFilterMenu={false}
                            sortable
                            filter
                            header="Details"
                            />
                    </DataTable>
                    <div  style={{textAlign: "right"}}>
                        <Button label="Fehlerliste exportieren" icon="pi pi-download" style={{marginTop: "1rem"}} onClick={() => {setExportOptions({
                            ID:false,
                            Datum: false,
                            Standort: false,
                            Fehlertyp: false,
                            Details: false
                        }); setExportDialogVisible(true)}} />
                    </div>
                    <Dialog visible={exportDialogVisible} onHide={() => setExportDialogVisible(false)} header="Fehlerliste exportieren"
                        footer={
                            <React.Fragment>
                                <Button label="Fehlerliste exportieren" icon="pi pi-download" onClick={fehlerlisteExportieren}/>
                                {showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                            </React.Fragment>}>
                        <div className="field">
                            <Checkbox inputId="ID" checked={exportOptions.ID} onChange={e => setExportOptions({...exportOptions, ID: e.target.checked})} />
                            <label htmlFor="ID" className="mb-0 ml-1">ID</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Datum" checked={exportOptions.Datum} onChange={e => setExportOptions({...exportOptions, Datum: e.target.checked})} />
                            <label htmlFor="Datum" className="mb-0 ml-1">Datum</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Standort" checked={exportOptions.Standort} onChange={e => setExportOptions({...exportOptions, Standort: e.target.checked})} />
                            <label htmlFor="Standort" className="mb-0 ml-1">Standort</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Fehlertyp" checked={exportOptions.Fehlertyp} onChange={e => setExportOptions({...exportOptions, Fehlertyp: e.target.checked})} />
                            <label htmlFor="Fehlertyp" className="mb-0 ml-1">Fehlertyp</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Details" checked={exportOptions.Details} onChange={e => setExportOptions({...exportOptions, Details: e.target.checked})} />
                            <label htmlFor="Details" className="mb-0 ml-1">Details</label><br/>
                        </div>
                        
                        <Button label="Alle auswählen" onClick={() => setExportOptions({
                            ID: true,
                            Datum: true,
                            Standort: true,
                            Fehlertyp: true,
                            Details: true
                        })}/>
                    </Dialog>
                </div>
                <Divider />
                <div className="col-12 md:col-6">
                    <h2 style={{fontWeight: "bold"}}>Übersicht über ausgewählten Zeitraum</h2>
					<table className="mt-3 mb-5">
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Fehler Gesamt:</th>
							<td style={{textAlign: "right"}}>{logs?.Kennzahlen?.Gesamt}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Drucken Fehlgeschlagen:</th>
							<td style={{textAlign: "right"}}>{logs?.Kennzahlen?.AnzahlDruckenFehlgeschlagen}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Barcode nicht gefunden:</th>
							<td style={{textAlign: "right"}}>{logs?.Kennzahlen?.AnzahlBarcodeNichtGefunden}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Zahlung fehlgeschlagen:</th>
							<td style={{textAlign: "right"}}>{logs?.Kennzahlen?.AnzahlZahlungFehlgeschlagen}</td>
						</tr>
					</table>
                    <LogDiagramm kassen={kassen} selectedKassen={selectedKassen} umsatzVerlaufBereich={datumsbereich}
                        setUmsatzVerlaufBereich={setDatumsbereich}/>
                </div>
                <div className="col-12 md:col-6">
                    <h2 style={{fontWeight: "bold"}} className="mb-5">Jahresübersicht</h2>
					<table className="mt-3 ml-4 mb-5">
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Fehler Gesamt:</th>
							<td style={{textAlign: "right"}}>{dataSetJahr?.Kennzahlen?.Gesamt}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Drucken Fehlgeschlagen:</th>
							<td style={{textAlign: "right"}}>{dataSetJahr?.Kennzahlen?.DruckenFehlgeschlagen}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Barcode nicht gefunden:</th>
							<td style={{textAlign: "right"}}>{dataSetJahr?.Kennzahlen?.Barcodenichtgefunden}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left", paddingRight: "10px"}}>Anzahl Zahlung fehlgeschlagen:</th>
							<td style={{textAlign: "right"}}>{dataSetJahr?.Kennzahlen?.ZahlungFehlgeschlagen}</td>
						</tr>
					</table>
                    <LogJahresdiagramm kassen={kassen} selectedKassen={selectedKassen}
                        dataSet={dataSetJahr} setDataSet={setDataSetJahr}
                    />
                </div>
            </div>
            <Dialog header={<React.Fragment><span style={{fontSize: '1.25em'}}>Details</span></React.Fragment>}
                visible={detailsOffen}
                onHide={(e) => setDetailsOffen(false)}
                >
                <div style={{width: '600px', fontFamily: 'monospace'}}>
                    {meldung}
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default Logs;