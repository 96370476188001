import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function VornameColumn(props) {

	const onVornameChange = (e) => {
		props.setSelectedVorname(e.target.value); 
		props.datatableref.current.filter(e.target.value, "Vorname", "in"); 
	}

	return(
		<Column header="Vorname" field="ErstePersonVorname" style={{width: "200px", maxWidth:"15rem"}} filter sortable filterMatchMode="contains"
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		filterElement={
			<MultiSelect value={props.selectedVorname} options={props.Vorname} optionLabel="Vorname" optionValue='Vorname' onChange={onVornameChange}
			placeholder={"Alle"} filter/>
		}
		body={props.body}

		/>
	)
}