import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function KundennummerColumn(props) {

	const onKundennummerChange = (e) => {
		props.setSelectedKundennummer(e.target.value); 
		props.datatableref.current.filter(e.target.value, "KundenNummer", "in"); 
	}

	return(
		<Column alignHeader="center" header="KundenNr." field="Kundennummer" style={{width: "150px", maxWidth:"7rem"}} filter sortable filterMatchMode="contains"
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		filterElement={
			<MultiSelect value={props.selectedKundennummer} options={props.Kundennummer} optionLabel="Kundennummer" optionValue='Kundennummer' onChange={onKundennummerChange}
			placeholder={"Alle"} filter/>
		}
		body={props.body}
		/> 
	)
}