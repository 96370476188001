import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function PLZColumn(props) {
	
	const onPLZChange = (e) => {
		props.setSelectedPLZ(e.target.value);
		props.datatableref.current.filter(e.target.value, "RePLZ", "in");
	}
	
	return (<Column header="PLZ" field="RePLZ" style={{width: "120px", maxWidth:"6rem"}} filter sortable filterMatchMode="in"
		filterElement={
			<MultiSelect value={props.selectedPLZ} options={props.PLZ} optionLabel="RePLZ" optionValue='RePLZ' onChange={onPLZChange}
			placeholder={"Alle"} filter/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={props.body}
		/>
	);
}