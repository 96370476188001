import React, {useContext, useEffect, useRef, useState} from 'react';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import {ProduktauswertungenService} from '../service/ProduktauswertungenService'
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { KassenService } from '../service/KassenService';
import { WarengruppenService } from '../service/Warengruppenservice';
import { Chart } from 'primereact/chart';
import { ZoomPopup } from './ZoomPopup';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { DashboardService } from '../service/DashboardService';
import { InputSwitch } from 'primereact/inputswitch';
import { useHistory } from "react-router-dom";
import { LieferantenService } from '../service/LieferantenService';
import { AuthService } from '../service/AuthService';





function Produktauswertungen(props){
	const produktauswertungsService = new ProduktauswertungenService();
    const kassenService = new KassenService();
    const warengruppenService = new WarengruppenService();
    const lieferantenService = new LieferantenService();
    const dashboardService = new DashboardService();
    const authService = new AuthService();
    const history = useHistory();
    

    const [produkte, setProdukte] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
    const [globalFilter, setGlobalFilter] = useState(null);
	const [loading01, setLoading01] = useState(true);
	const [loading02, setLoading02] = useState(true);
	const [loading03, setLoading03] = useState(true);
    const [selectedBezeichnung, setSelectedBezeichnung] = useState(null);
    const [filteredBezeichnungen, setFilteredBezeichnungen] = useState([]);
    const [filteredProdukte, setFilteredProdukte] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [selectedYear, setSelectedYear] = useState((new Date().getFullYear()).toString());
    const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth()+1).toString());
    const [monatOptions, setMonatOptions] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);
    const [jahrOptions, setJahrOptions] = useState(["2024","2023"]);
	const [kassen, setKassen] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState([]);
    const [produktgruppen, setProduktgruppen] = useState([]);
    const [lieferanten, setLieferanten] = useState([]);
    const [einheiten, setEinheiten] = useState([]);
    const [selectedProduktgruppen, setSelectedProduktgruppen] = useState(null);
    const [selectedLieferanten, setSelectedLieferanten] = useState(null);
    const [selectedEinheit, setSelectedEinheit] = useState(null);
    const [monatsDB, setMonatsDB] = useState(null);    
    const [showSchwundVerderbBeschaedigtEigenverbrauch, setShowSchwundVerderbBeschaedigtEigenverbrauch] = useState(false);
    const [produkteFinish, setProdukteFinish] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [chartDataDB, setChartDataDB] = useState(null);
    const [monatsUmsatz, setMonatsUmsatz] = useState(null);
	const [exportOptions, setExportOptions] = useState({});
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [netto, setNetto] = useState(false);
    const [pieChartData, setPieChartData] = useState({});
    const [pieChartData2, setPieChartData2] = useState({});
    const [pieChartDataDB, setPieChartDataDB] = useState({});
    const [pieChartDataDB2, setPieChartDataDB2] = useState({});
    const [warengruppendata, setWarengruppendata] = useState([]);
    const [warengruppendata2, setWarengruppendata2] = useState([]);
    const [warengruppendata3, setWarengruppendata3] = useState([]);
    const [warengruppendata4, setWarengruppendata4] = useState([]);
    const [switchStückEuro, setSwitchStückEuro] = useState(false);
    const [umsatzverteilungsHeader, setUmsatzverteilungsHeader] = useState(false);
    const [umsatzverteilungsHeader2, setUmsatzverteilungsHeader2] = useState(false);
    const [umsatzverteilungsHeader3, setUmsatzverteilungsHeader3] = useState(false);
    const [umsatzverteilungsHeader4, setUmsatzverteilungsHeader4] = useState(false);
    const [currentLevelDB, setCurrentLevelDB] = useState(1);
    const [currentLevelDB2, setCurrentLevelDB2] = useState(1);
    const [currentLevelUmsatz, setCurrentLevelUmsatz] = useState(1);
    const [currentLevelUmsatz2, setCurrentLevelUmsatz2] = useState(1);
    const [kategorienDB, setKategorienDB] = useState([]);
    const [warengruppenDB, setWarengruppenDB] = useState([]);
    const [artikelgruppenDB, setArtikelgruppenDB] = useState([]);
    const [kategorienDB2, setKategorienDB2] = useState([]);
    const [warengruppenDB2, setWarengruppenDB2] = useState([]);
    const [artikelgruppenDB2, setArtikelgruppenDB2] = useState([]);
    const [kategorienUmsatz, setKategorienUmsatz] = useState([]);
    const [warengruppenUmsatz, setWarengruppenUmsatz] = useState([]);
    const [artikelgruppenUmsatz, setArtikelgruppenUmsatz] = useState([]);
    const [kategorienUmsatz2, setKategorienUmsatz2] = useState([]);
    const [warengruppenUmsatz2, setWarengruppenUmsatz2] = useState([]);
    const [artikelgruppenUmsatz2, setArtikelgruppenUmsatz2] = useState([]);
    const [selectedOperatorMenge, setSelectedOperatorMenge] = useState(">");
    const [selectedOperatorGewicht, setSelectedOperatorGewicht] = useState(">");
    const [selectedOperatorUmsatz, setSelectedOperatorUmsatz] = useState(">");
    const [selectedOperatorMarge, setSelectedOperatorMarge] = useState(">");
    const [selectedOperatorMargePro, setSelectedOperatorMargePro] = useState(">");
    const [selectedOperatorAnteilMonat, setSelectedOperatorAnteilMonat] = useState(">");
    const [selectedOperatorNettoDB, setSelectedOperatorNettoDB] = useState(">");
    const [selectedMenge, setSelectedMenge] = useState(null);
    const [selectedGewicht, setSelectedGewicht] = useState(null);
    const [selectedUmsatz, setSelectedUmsatz] = useState(null);
    const [selectedMarge, setSelectedMarge] = useState(null);
    const [selectedMargePro, setSelectedMargePro] = useState(null);
    const [selectedAnteilMonat, setSelectedAnteilMonat] = useState(null);
    const [selectedNettoDB, setSelectedNettoDB] = useState(null);
	const [roles, setRoles] = useState([]);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);

	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1500);
	};

	
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);


    //const [abortController, setAbortController] = useState(null);



    const filterOperators = ['>','<','-'];

    const onMengeChange = (e) => {
        setSelectedMenge(e.target.value);
    };
    const onGewichtChange = (e) => {
        setSelectedGewicht(e.target.value);
    };
    const onUmsatzChange = (e) => {
        setSelectedUmsatz(e.target.value);
    };
    const onMargeChange = (e) => {
        setSelectedMarge(e.target.value);
    };
    const onMargeProChange = (e) => {
        setSelectedMargePro(e.target.value);
    };
    const onAnteilMonatChange = (e) => {
        setSelectedAnteilMonat(e.target.value);
    };
    const onNettoDBChange = (e) => {
        setSelectedNettoDB(e.target.value);
    };

    useEffect(() => {
        if (!selectedMenge) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedMenge);
    
        if (selectedMenge.includes('-')) {
            const range = selectedMenge.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.Menge >= range[0] && produkt.Menge <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorMenge) {
                    case '>':
                        return produkt.Menge > temp;
                    case '<':
                        return produkt.Menge < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedMenge, selectedOperatorMenge])

    useEffect(() => {
        if (!selectedGewicht) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedGewicht);
    
        if (selectedGewicht.includes('-')) {
            const range = selectedGewicht.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.Gewicht >= range[0] && produkt.Gewicht <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorGewicht) {
                    case '>':
                        return produkt.Gewicht > temp;
                    case '<':
                        return produkt.Gewicht < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedGewicht, selectedOperatorGewicht])

    useEffect(() => {
        if (!selectedUmsatz) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedUmsatz);
    
        if (selectedUmsatz.includes('-')) {
            const range = selectedUmsatz.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.Umsatz >= range[0] && produkt.Umsatz <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorUmsatz) {
                    case '>':
                        return produkt.Umsatz > temp;
                    case '<':
                        return produkt.Umsatz < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedUmsatz, selectedOperatorUmsatz])

    useEffect(() => {
        if (!selectedMarge) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedMarge);
    
        if (selectedMarge.includes('-')) {
            const range = selectedMarge.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.MargeAbs >= range[0] && produkt.MargeAbs <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorMarge) {
                    case '>':
                        return produkt.MargeAbs > temp;
                    case '<':
                        return produkt.MargeAbs < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedMarge, selectedOperatorMarge])

    useEffect(() => {
        if (!selectedMargePro) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedMargePro);
    
        if (selectedMargePro.includes('-')) {
            const range = selectedMargePro.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.MargePro >= range[0] && produkt.MargePro <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorMargePro) {
                    case '>':
                        return produkt.MargePro > temp;
                    case '<':
                        return produkt.MargePro < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedMargePro, selectedOperatorMargePro])

    useEffect(() => {
        if (!selectedAnteilMonat) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedAnteilMonat);
    
        if (selectedAnteilMonat.includes('-')) {
            const range = selectedAnteilMonat.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkteFinish.filter(produkt => {
                    return produkt.AnteilMonatsDB >= range[0] && produkt.AnteilMonatsDB <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkteFinish.filter(produkt => {
                switch (selectedOperatorAnteilMonat) {
                    case '>':
                        return produkt.AnteilMonatsDB > temp;
                    case '<':
                        return produkt.AnteilMonatsDB < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedAnteilMonat, selectedOperatorAnteilMonat])

    useEffect(() => {
        if (!selectedNettoDB) {
            setFilteredProdukte(produkte);
            return;
        }
    
        var temp = parseFloat(selectedNettoDB);
    
        if (selectedNettoDB.includes('-')) {
            const range = selectedNettoDB.split('-').map(str => parseFloat(str.trim()));
            if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                const filtered = produkte.filter(produkt => {
                    return produkt.NettoDB >= range[0] && produkt.NettoDB <= range[1];
                });
                setFilteredProdukte(filtered);
            } else {
                setFilteredProdukte(produkte);
            }
        } else if (temp && !isNaN(temp)) {
            const filtered = produkte.filter(produkt => {
                switch (selectedOperatorNettoDB) {
                    case '>':
                        return produkt.NettoDB > temp;
                    case '<':
                        return produkt.NettoDB < temp;
                    default:
                        return true;
                }
            });
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
    },[selectedNettoDB, selectedOperatorNettoDB])


	const barChartRefUmsatz = useRef(null);
	const barChartRefDB = useRef(null);
	const pieChartRefUmsatz = useRef(null);
	const pieChartRefUmsatz2 = useRef(null);
	const pieChartRefDB = useRef(null);
	const pieChartRefDB2 = useRef(null);
    const toast = useRef(null);
    let dt = useRef(null);

    const startMonth = new Date(selectedYear, selectedMonth-1, 1);
    const endMonth = new Date(selectedYear, selectedMonth, 1);
    


    const handleChartClick = (e) => {
        async function fetchData() {
            let activePoints=[];
            let temp = [];
            setLoading03(true);
            if (e && e.chart && e.chart.getElementsAtEventForMode) {
                activePoints = e.chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false)
            }

            if(!e){
                if (currentLevelUmsatz <= 3) {
                    temp = kategorienUmsatz
                    setWarengruppendata(kategorienUmsatz)
                    setCurrentLevelUmsatz(2)
                }
                if (currentLevelUmsatz === 4) {
                    temp = warengruppenUmsatz
                    setWarengruppendata(warengruppenUmsatz)
                    setCurrentLevelUmsatz(3)
                }
                if (currentLevelUmsatz === 5) {
                    temp = artikelgruppenUmsatz
                    setWarengruppendata(artikelgruppenUmsatz)
                    setCurrentLevelUmsatz(4)
                }
            } else
            if (activePoints.length > 0 && selectedMonth != "Alle") {
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                setUmsatzverteilungsHeader(label)
                const element = warengruppendata.find(item => item.Bezeichnung === label);

                if(!currentLevelUmsatz){
                    setCurrentLevelUmsatz(2);
                }

                let newLevel = currentLevelUmsatz;

                if (currentLevelUmsatz === 1) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienUmsatz(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienUmsatz(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata(temp);
                    setKategorienUmsatz(temp)
                    newLevel = 2;
                }
                else if(currentLevelUmsatz === 2) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetWarengruppenUmsatz(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetWarengruppenUmsatz(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    setWarengruppendata(temp);
                    setWarengruppenUmsatz(temp)

                    newLevel = 3;
                }
                else if(currentLevelUmsatz === 3){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetArtikelgruppenUmsatz(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetArtikelgruppenUmsatz(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    setWarengruppendata(temp);
                    setArtikelgruppenUmsatz(temp)
                    newLevel = 4;
                }
                else if(currentLevelUmsatz === 4){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetProduktUmsatz(selectedKassen, element.ID, startMonth, endMonth, netto);

                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetProduktUmsatz(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    setWarengruppendata(temp);
                    newLevel = 5;

                } 
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelUmsatz(newLevel);
            }
            else if(activePoints.length > 0  && selectedMonth == "Alle"){
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                setUmsatzverteilungsHeader(label)
                const element = warengruppendata.find(item => item.Bezeichnung === label);

                if(!currentLevelUmsatz){
                    setCurrentLevelUmsatz(2);
                }

                let newLevel = currentLevelUmsatz;

                if (currentLevelUmsatz === 1) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienUmsatzAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienUmsatzAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    setKategorienUmsatz(temp)
                    newLevel = 2;
                }
                else if(currentLevelUmsatz === 2) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetWarengruppenUmsatzAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetWarengruppenUmsatzAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    setWarengruppenUmsatz(temp)

                    newLevel = 3;
                }
                else if(currentLevelUmsatz === 3){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetArtikelgruppenUmsatzAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetArtikelgruppenUmsatzAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    setArtikelgruppenUmsatz(temp)
                    newLevel = 4;
                }
                else if(currentLevelUmsatz === 4){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetProduktUmsatzAll(selectedKassen, element.ID, selectedYear, netto);

                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetProduktUmsatzAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    newLevel = 5;

                } 
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelUmsatz(newLevel);
            }
            else {
                if(selectedMonth == "Alle"){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienUmsatzAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienUmsatzAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    setKategorienUmsatz(temp)
                    setCurrentLevelUmsatz(2);
                }
                else{
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienUmsatz(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienUmsatz(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata(temp);
                    setKategorienUmsatz(temp)
                    setCurrentLevelUmsatz(2);
                }
            }


            setPieChartData({
                labels: temp.map(u => u.Bezeichnung),
                datasets: [
                    {
                        label: "Umsatz",
                        data: temp.map(u => u.Umsatz),
                        backgroundColor: temp.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]),
                    }
                ]
            });
            
            setLoading03(false);
        }
        fetchData();
    };
    
    function onClickBackDBKategorie() {
        handleChartClickDB();
    }
    function onClickBackDBGesamt() {
        handleChartClickDB2();
    }
    function onClickBackUmsatzKategorie() {
        handleChartClick();
    }
    function onClickBackUmsatzGesamt() {
        handleChartClick2();
    }


    const handleChartClickDB = (e) => {
        
        async function fetchData() {
            setLoading03(true);
            let activePoints=[];
            let temp = [];
            if (e) {
               activePoints = e.chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false)
            }

            if(!e){
                if (currentLevelDB <= 3) {
                    temp = kategorienDB
                    setWarengruppendata3(kategorienDB)
                    setCurrentLevelDB(2)
                }
                if (currentLevelDB === 4) {
                    temp = warengruppenDB
                    setWarengruppendata3(warengruppenDB)
                    setCurrentLevelDB(3)
                }
                if (currentLevelDB === 5) {
                    temp = artikelgruppenDB
                    setWarengruppendata3(artikelgruppenDB)
                    setCurrentLevelDB(4)
                }
            } else
            if (activePoints.length > 0 && selectedMonth != "Alle") {
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                setUmsatzverteilungsHeader3(label)
                const element = warengruppendata3.find(item => item.Bezeichnung === label);

                if(!currentLevelDB){
                    setCurrentLevelDB(2);
                }

                let newLevel = currentLevelDB;

                if (currentLevelDB === 1) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDB(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata3(temp);
                    setKategorienDB(temp)
                    newLevel = 2;
                }
                else if(currentLevelDB === 2) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetWarengruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetWarengruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    setWarengruppendata3(temp);
                    setWarengruppenDB(temp)

                    newLevel = 3;
                }
                else if(currentLevelDB === 3){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetArtikelgruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetArtikelgruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    setWarengruppendata3(temp);
                    setArtikelgruppenDB(temp)
                    newLevel = 4;
                }
                else if(currentLevelDB === 4){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetProduktDB(selectedKassen, element.ID, startMonth, endMonth, netto);

                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetProduktDB(KassenIDS, element.ID, startMonth, endMonth, netto);

                    }
                    setWarengruppendata3(temp);
                    newLevel = 5;

                } 
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelDB(newLevel);
            }
            else if(activePoints.length > 0  && selectedMonth == "Alle"){
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                setUmsatzverteilungsHeader(label)
                const element = warengruppenDB.find(item => item.Bezeichnung === label);

                if(!currentLevelDB){
                    setCurrentLevelDB(2);
                }

                let newLevel = currentLevelDB;

                if (currentLevelDB === 1) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata3(temp);
                    setKategorienDB(temp)
                    newLevel = 2;
                }
                else if(currentLevelDB === 2) {
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetWarengruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetWarengruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata3(temp);
                    setWarengruppenDB(temp)

                    newLevel = 3;
                }
                else if(currentLevelDB === 3){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetArtikelgruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetArtikelgruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata3(temp);
                    setArtikelgruppenDB(temp)
                    newLevel = 4;
                }
                else if(currentLevelDB === 4){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetProduktDBAll(selectedKassen, element.ID, selectedYear, netto);

                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetProduktDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    setWarengruppendata3(temp);
                    newLevel = 5;

                } 
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelDB(newLevel);
            }
            else {
                if(selectedMonth == "Alle"){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata3(temp);
                    setKategorienDB(temp)
                    setCurrentLevelDB(2);
                }
                else{
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDB(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata3(temp);
                    setKategorienDB(temp)
                    setCurrentLevelDB(2);
                }
            }
            setPieChartDataDB({
                labels: temp.map(u => u.Bezeichnung),
                datasets: [
                    {
                        label: "Deckungsbeitrag",
                        data: temp.map(u => u.DB),
                        backgroundColor: temp.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]),
                    }
                ]
            });
            setLoading03(false);
        }
        fetchData();
    };

    const handleChartClickDB2 = (e) => {
        async function fetchData() {                    
            setLoading03(true);

            let activePoints=[];
            let temp = [];
            let updatedData = [...warengruppendata4];
            let newData = [];
            if (e) {
               activePoints = e.chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false)
            }
            if(!e){
                if (currentLevelDB2 <= 3) {
                    temp = kategorienDB2
                    setWarengruppendata4(kategorienDB2)
                    setCurrentLevelDB2(2)
                }
                if (currentLevelDB2 === 4) {
                    temp = warengruppenDB2
                    setWarengruppendata4(warengruppenDB2)
                    setCurrentLevelDB2(3)
                }
                if (currentLevelDB2 === 5) {
                    temp = artikelgruppenDB2
                    setWarengruppendata4(artikelgruppenDB2)
                    setCurrentLevelDB2(4)
                }

                setPieChartDataDB2({
                    labels: temp.map(u => u.Bezeichnung),
                    datasets: [
                        {
                            label: "DB",
                            data: temp.map(u => u.DB),
                            backgroundColor: temp.map((u, index) => 
                                u.Bezeichnung === "Andere" ? "lightgrey" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]
                            ),
                        }
                    ]
                });                    
                setLoading03(false);

                return;

            } else
            if (activePoints.length > 0 && selectedMonth != "Alle") {
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                
                if (label === "Andere") {                    
                    setLoading03(false);

                    return; 
                }
                setUmsatzverteilungsHeader4(label)
                const element = warengruppendata4.find(item => item.Bezeichnung === label);

                if(!currentLevelDB2){
                    setCurrentLevelDB2(2);
                }

                let newLevel = currentLevelDB2;
                let allData = [];


                if (currentLevelDB2 === 1) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetKategorienDB(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] };
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]               
                    setKategorienDB2(allData)
                    newLevel = 2;
                }
                else if(currentLevelDB2 === 2) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetWarengruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetWarengruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });    
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray"});            
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData];
                    setWarengruppenDB2(allData)

                    newLevel = 3;
                }
                else if(currentLevelDB2 === 3){
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetArtikelgruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetArtikelgruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }

                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });

                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray" });                    
                   
                    
                    allData=[otherData, ...newData]
                    setArtikelgruppenDB2(allData)
                    newLevel = 4;
                }
                else{                    
                    setLoading03(false);

                    return;
                }
                setCurrentLevelDB2(newLevel);
                setWarengruppendata4(allData);

                setPieChartDataDB2({
                    labels: allData.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "DB",
                                data: allData.map(u => u.DB),
                                backgroundColor: allData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(allData.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                });                 
            }
            else if(activePoints.length > 0  && selectedMonth == "Alle"){
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                
                if (label === "Andere") {                    
                    setLoading03(false);

                    return; 
                }
                setUmsatzverteilungsHeader4(label)
                const element = warengruppendata4.find(item => item.Bezeichnung === label);

                if(!currentLevelDB2){
                    setCurrentLevelDB2(2);
                }

                let newLevel = currentLevelDB2;
                let allData = [];


                if (currentLevelDB2 === 1) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] };
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]               
                    setKategorienDB2(allData)
                    newLevel = 2;
                }
                else if(currentLevelDB2 === 2) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetWarengruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetWarengruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });    
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray"});            
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData];
                    setWarengruppenDB2(allData)

                    newLevel = 3;
                }
                else if(currentLevelDB2 === 3){
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetArtikelgruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetArtikelgruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.DB += item.DB;
                        return acc;
                    }, { Bezeichnung: "Andere", DB: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]
                    setArtikelgruppenDB2(allData)
                    newLevel = 4;
                }
                else{                    
                    setLoading03(false);

                    return;
                }
                setCurrentLevelDB2(newLevel);
                setWarengruppendata4(allData);

                setPieChartDataDB2({
                    labels: allData.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "DB",
                                data: allData.map(u => u.DB),
                                backgroundColor: allData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(allData.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                });
            }
            else {
                if(selectedMonth == "Alle"){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata4(temp);
                    setKategorienDB2(temp)
                    setCurrentLevelDB2(2);
                }
                else{
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDBA(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata4(temp);
                    setKategorienDB2(temp)
                    setCurrentLevelDB(2);
                }
                
                setPieChartDataDB2({
                    labels: temp.map(u => u.Bezeichnung),
                    datasets: [
                        {
                            label: "DB",
                            data: temp.map(u => u.DB),
                            backgroundColor: temp.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]),
                        }
                    ]
                });
            }
            setLoading03(false);

        }
        fetchData();
    };

    
    const handleChartClick2 = (e) => {
        async function fetchData() {
            let activePoints=[];
            let temp = [];
            let updatedData = [...warengruppendata2];
            let newData = [];
            setLoading03(true);
            if (e) {
               activePoints = e.chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false)
            }
            if(!e){
                if (currentLevelUmsatz2 <= 3) {
                    temp = kategorienUmsatz2
                    setWarengruppendata2(kategorienUmsatz2)
                    setCurrentLevelUmsatz2(2)
                }
                if (currentLevelUmsatz2 === 4) {
                    temp = warengruppenUmsatz2
                    setWarengruppendata2(warengruppenUmsatz2)
                    setCurrentLevelUmsatz2(3)
                }
                if (currentLevelUmsatz2 === 5) {
                    temp = artikelgruppenUmsatz2
                    setWarengruppendata2(artikelgruppenUmsatz2)
                    setCurrentLevelUmsatz2(4)
                }

                setPieChartData2({
                    labels: temp.map(u => u.Bezeichnung),
                    datasets: [
                        {
                            label: "Umsatz",
                            data: temp.map(u => u.Umsatz),
                            backgroundColor: temp.map((u, index) => 
                                u.Bezeichnung === "Andere" ? "lightgrey" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]
                            ),
                        }
                    ]
                });
                setLoading03(false);
                return;

            } else
            if (activePoints.length > 0 && selectedMonth != "Alle") {

                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                
                if (label === "Andere") {
                    setLoading03(false);
                    return; 
                }
                setUmsatzverteilungsHeader2(label)
                const element = warengruppendata2.find(item => item.Bezeichnung === label);

                if(!currentLevelUmsatz2){
                    setCurrentLevelUmsatz2(2);
                }

                let newLevel = currentLevelUmsatz2;
                let allData = [];


                if (currentLevelUmsatz2 === 1) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetKategorienDB(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] };
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]               
                    setKategorienUmsatz2(allData)
                    newLevel = 2;
                }
                else if(currentLevelUmsatz2 === 2) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetWarengruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetWarengruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });    
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray"});            
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData];
                    setWarengruppenUmsatz2(allData)

                    newLevel = 3;
                }
                else if(currentLevelUmsatz2 === 3){
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetArtikelgruppenDB(selectedKassen, element.ID, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetArtikelgruppenDB(KassenIDS, element.ID, startMonth, endMonth, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });
              
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray" });

                    allData=[otherData, ...newData]
                    setArtikelgruppenUmsatz2(allData)
                    newLevel = 4;
                }
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelUmsatz2(newLevel);
                setWarengruppendata2(allData);

                setPieChartData2({
                    labels: allData.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "Umsatz",
                                data: allData.map(u => u.Umsatz),
                                backgroundColor: allData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(allData.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                });
            }
            else if(activePoints.length > 0  && selectedMonth == "Alle"){
            
                const clickedIndex = activePoints[0].index;
                const label = e.chart.data.labels[clickedIndex];
                
                if (label === "Andere") {
                    setLoading03(false);
                    return; 
                }
                setUmsatzverteilungsHeader2(label)
                const element = warengruppendata2.find(item => item.Bezeichnung === label);

                if(!currentLevelUmsatz2){
                    setCurrentLevelUmsatz2(2);
                }

                let newLevel = currentLevelUmsatz2;
                let allData = [];


                if (currentLevelUmsatz2 === 1) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] };
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]               
                    setKategorienUmsatz2(allData)
                    newLevel = 2;
                }
                else if(currentLevelUmsatz2 === 2) {
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetWarengruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetWarengruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });    
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray"});            
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData];
                    setWarengruppenUmsatz2(allData)

                    newLevel = 3;
                }
                else if(currentLevelUmsatz2 === 3){
                    if (selectedKassen.length > 0) {
                        newData = await dashboardService.GetArtikelgruppenDBAll(selectedKassen, element.ID, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        newData = await dashboardService.GetArtikelgruppenDBAll(KassenIDS, element.ID, selectedYear, netto);
                    }
                    updatedData = updatedData.map(item => {
                        if (item.Bezeichnung === label) {
                            return { ...newData[0] }; 
                        } else {
                            return { ...item };
                        }
                    });
                    const nonClickedData = updatedData.filter(item => item.Bezeichnung !== label);
                    const otherData = nonClickedData.reduce((acc, item) => {
                        acc.Umsatz += item.Umsatz;
                        return acc;
                    }, { Bezeichnung: "Andere", Umsatz: 0, backgroundColor: "lightgray" });
                    updatedData = updatedData.filter(item => item.Bezeichnung === label).concat(otherData);
                    allData=[...updatedData, ...newData]
                    setArtikelgruppenUmsatz2(allData)
                    newLevel = 4;
                }
                else{
                    setLoading03(false);
                    return;
                }
                setCurrentLevelUmsatz2(newLevel);
                setWarengruppendata2(allData);

                setPieChartData2({
                    labels: allData.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "Umsatz",
                                data: allData.map(u => u.Umsatz),
                                backgroundColor: allData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(allData.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                });
            }
            else {
                if(selectedMonth == "Alle"){
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDBAll(selectedKassen, selectedYear, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto);
                    }
                    setWarengruppendata(temp);
                    setKategorienUmsatz2(temp)
                    setCurrentLevelUmsatz2(2);
                }
                else{
                    if (selectedKassen.length > 0) {
                        temp = await dashboardService.GetKategorienDB(selectedKassen, startMonth, endMonth, netto);
                    } else if (kassen.length > 0) {
                        const KassenIDS = kassen.map(kasse => kasse.KassenID);
                        temp = await dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto);
                    }
                    setWarengruppendata2(temp);
                    setKategorienUmsatz2(temp)
                    setCurrentLevelUmsatz2(2);
                }
                
                setPieChartData2({
                    labels: temp.map(u => u.Bezeichnung),
                    datasets: [
                        {
                            label: "Umsatz",
                            data: temp.map(u => u.Umsatz),
                            backgroundColor: temp.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(temp.map(u => u.Bezeichnung))[index]),
                        }
                    ]
                });
            }
            setLoading03(false);
        }

        fetchData();
    };

    
    const getUniqueColors = (labels) => {
        
        const uniqueColors = [];
        const totalColors = primeColors.length;
    
        labels.forEach((label, index) => {
            const colorIndex = index % totalColors;
            uniqueColors.push(primeColors[colorIndex]);
        });
    
        return uniqueColors;
    };
    const primeColors = [
        "#FFB800", // Amber
        "#FF6363", // Red
        "#4CAF50", // Green
        "#2196F3", // Blue
        "#9C27B0", // Purple
        "#FF4081", // Pink
        "#00BCD4", // Cyan
        "#8BC34A", // Light Green
        "#FFC107", // Yellow
        "#673AB7", // Deep 
        "#E91E63", // Pink
        "#00FF00", // Lime
        "#FF5722", // Deep Orange
        "#03A9F4", // Light Blue
        "#FF9800", // Orange
        "#9E9E9E", // Grey
        "#607D8B", // Blue Grey
        "#795548", // Brown
        "#CDDC39", // Lime Green
        "#F44336"  // Red
    ];

    
    const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	const renderHeader = () => {
        return (
            <div>
                <div className="grid">
                    <InputText
                        className="col"
                        type="search"
                        onInput={(e) => {
                            setFilteredProdukte(produkte)
                            const value = e.target.value;
                            setGlobalFilter(value)
                        }}
                        placeholder="z.B.: Bezeichnung, Artikelnummer, Warengruppe, ..."
                    />
                </div>
            </div>
        );
    };
    
	const bezeichnungFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedBezeichnung} suggestions={filteredBezeichnungen} completeMethod={filterBezeichnung}
            placeholder="Bezeichnung" minLength={1} onChange={onBezeichnungChange}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    };

	const filterBezeichnung = (event) => {
        const query = event.query.toLowerCase();
        let results = produkte.filter(produkt =>
            produkt.Bezeichnung.toLowerCase().includes(query)
        );
        results = results.map(produkt => produkt.Bezeichnung);
        setFilteredBezeichnungen(results);
    };

	const onBezeichnungChange = (event) => {
        setFilteredProdukte(produkte);
        const value = event.value;
        setSelectedBezeichnung(value); 
        if (!value) {
            setFilteredProdukte(produkte);
        } else {
            dt.current.filter(value, 'Bezeichnung', 'contains');
        }
    };

    const renderWarengruppenFilter = () => {
        return (
            <MultiSelect className="column-filter" value={selectedProduktgruppen} options={produktgruppen}
                onChange={onProduktgruppenFilterChange}
                placeholder="Alle"
                optionLabel="Bezeichnung"
                optionValue="Bezeichnung"
                filter
                disabled={loading01}/>
        );
    };
    const renderLieferantenFilter = () => {
        return (
            <MultiSelect className="column-filter" value={selectedLieferanten} options={lieferanten}
                onChange={onLieferantenChange}
                placeholder="Alle"
                optionLabel="Name"
                optionValue="Name"
                filter
                disabled={loading01}/>
        );
    };
    const handleButtonClickMenge = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorMenge);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorMenge(filterOperators[nextIndex]);
      };
    const handleButtonClickGewicht = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorGewicht);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorGewicht(filterOperators[nextIndex]);
    };
    const handleButtonClickUmsatz = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorUmsatz);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorUmsatz(filterOperators[nextIndex]);
    };
    const handleButtonClickMarge = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorMarge);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorMarge(filterOperators[nextIndex]);
    };
    const handleButtonClickMargePro = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorMargePro);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorMargePro(filterOperators[nextIndex]);
    };
    const handleButtonClickAnteilMonat = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorAnteilMonat);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorAnteilMonat(filterOperators[nextIndex]);
    };
    const handleButtonClickNettoDB = () => {
        const currentIndex = filterOperators.indexOf(selectedOperatorNettoDB);
        const nextIndex = (currentIndex + 1) % filterOperators.length;
        setSelectedOperatorNettoDB(filterOperators[nextIndex]);
    };
    const renderMengenFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickMenge}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                >
                    {selectedOperatorMenge}
                </button>
                <InputText
                    placeholder={selectedOperatorMenge === "-" ? "1 - 5" : "1,00 €"}
                    value={selectedMenge}
                    onChange={onMengeChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                />
            </div>
        );
    };
    const renderGewichtsFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickGewicht}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorGewicht}
                </button>
                <InputText
                    placeholder={selectedOperatorGewicht === "-" ? "1 - 5" : "1,00 KG"}
                    value={selectedGewicht}
                    onChange={onGewichtChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderUmsatzFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickUmsatz}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorUmsatz}
                </button>
                <InputText
                    placeholder={selectedOperatorUmsatz === "-" ? "1 - 5" : "1,00 €"}
                    value={selectedUmsatz}
                    onChange={onUmsatzChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderMargeAbsFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickMarge}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorMarge}
                </button>
                <InputText
                    placeholder={selectedOperatorMarge === "-" ? "1 - 5" : "1,00 €"}
                    value={selectedMarge}
                    onChange={onMargeChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderMargeProFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickMargePro}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorMargePro}
                </button>
                <InputText
                    placeholder={selectedOperatorMargePro === "-" ? "1 - 5" : "1 %"}
                    value={selectedMargePro}
                    onChange={onMargeProChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderAnteilMonatFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickAnteilMonat}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorAnteilMonat}
                </button>
                <InputText
                    placeholder={selectedOperatorAnteilMonat === "-" ? "1 - 5" : "1 %"}
                    value={selectedAnteilMonat}
                    onChange={onAnteilMonatChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderNettoDBFilter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                    className="column-filter"
                    onClick={handleButtonClickNettoDB}
                    style={{ height: "2rem", width:"2rem", marginRight: "0.1rem" }}
                    title={`> = größer als \n< = kleiner als\n - = von - bis`}
                >
                {selectedOperatorNettoDB}
                </button>
                <InputText
                    placeholder={selectedOperatorNettoDB === "-" ? "1 - 5" : "1 €"}
                    value={selectedNettoDB}
                    onChange={onNettoDBChange}
                    style={{ maxWidth: "4rem", width:"4rem", height:"2.2rem" }}
                    >
                </InputText>
            </div>
        );
    };
    const renderEinheitenFilter = () => {
        return (
            <MultiSelect
                className="column-filter"
                value={selectedEinheit}
                options={einheiten}
                onChange={onEinheitenChange}
                placeholder="Alle"
                optionLabel="Einheit"
                optionValue="Einheit"
                filter
                disabled={loading01}
            />
        );
    }
    
    const onProduktgruppenFilterChange = (event) => {
        if (event.value && event.value.length > 0) {
            const filtered = produkte.filter(produkt =>
                event.value.includes(produkt.WarengruppenBezeichnung)
            );
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
        setSelectedProduktgruppen(event.value);
    };

    const onLieferantenChange = (event) => {
        if (event.value && event.value.length > 0) {
            const filtered = produkte.filter(produkt =>
                event.value.includes(produkt.Lieferanten)
            );
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
        setSelectedLieferanten(event.value);
    };
    
    const onEinheitenChange = (event) => {
        if (event.value && event.value.length > 0) {
            const filtered = produkte.filter(produkt =>
                event.value.includes(produkt.Einheit)
            );
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte(produkte);
        }
        setSelectedEinheit(event.value);
    };
    
    
    
	const bezeichnungsFilter = bezeichnungFilter();
    const warengruppenFilter = renderWarengruppenFilter();
    const lieferantenFilter = renderLieferantenFilter();
    const einheitenFilter   = renderEinheitenFilter();
    const mengenFilter = renderMengenFilter();
    const gewichtsFilter = renderGewichtsFilter()
    const umsatzFilter = renderUmsatzFilter();
    const margeAbsFilter = renderMargeAbsFilter();
    const margeProFilter = renderMargeProFilter();
    const anteilMonatFilter = renderAnteilMonatFilter();
    const nettoDBFilter = renderNettoDBFilter();



	useEffect(() => {
		setGlobalState({...globalState, page: 'Produktauswertungen'});
        setLoading01(true);
		kassenService.getKassen().then(data => {
            setKassen(data);
        });
        warengruppenService.getAll().then(data => setProduktgruppen(data));
        lieferantenService.getAll().then(data => setLieferanten(data));
        produktauswertungsService.GetEinheiten().then(data => setEinheiten(data));
        setLoading01(false);
        authService.getTokenInfo().then(info => {
			setRoles(info?.roles.split(';') ?? []);
		});
        produktauswertungsService.GetZeitraum()
            .then(data => {
                const years = [...new Set(data.map(item => item.Jahr))].map(year => ({
                    label: year.toString(),
                    value: year.toString(),
                }));
                setJahrOptions(years);

                const firstYear = years.length > 0 ? years[0].value : null;
                if (selectedYear == null && firstYear) {
                    setSelectedYear(firstYear);
                }

                const monthsForSelectedYear = data
                .filter(item => item.Jahr.toString() === selectedYear)
                .map(item => item.Monat);

                const months = [...new Set(monthsForSelectedYear)].map(monat => ({
                    label: monat.toString().padStart(2, '0'),
                    value: monat.toString().padStart(2, '0'),
                }));
                const allOption = {
                    label: 'Alle',
                    value: 'Alle',
                };
                const monthOptionsWithAll = [allOption, ...months];

                setMonatOptions(monthOptionsWithAll);


                if (selectedMonth == null || !months.some(month => month.value === selectedMonth)) {
                    const lastMonth = months.length > 0 ? months[months.length-1].value : null;
                    setSelectedMonth(lastMonth);
                }
            })
	},[]);

    useEffect(() => {
        const test = new Date;
        if(selectedMonth == "Alle"){
            test.setFullYear(selectedYear);
            test.setMonth(1);
        }
        else{
            test.setFullYear(selectedYear);
            test.setMonth(selectedMonth-1);
        }
        const KassenIDS = kassen.map(kasse => kasse.KassenID);
        setLoading02(true);
        produktauswertungsService.GetUmsatzdata(netto, test, KassenIDS)
            .then(data => {
                if(selectedMonth == "Alle"){
                    setMonatsDB(data.dbJahr);
                    setMonatsUmsatz(data.umsatzJahr);
                    setLoading02(false);
                }
                else{
                    setMonatsDB(data.dbMonat);
                    setMonatsUmsatz(data.umsatzMonat);
                    setLoading02(false);
                }
            
            })
        handleChartClick();
        handleChartClick2();
        handleChartClickDB();
        handleChartClickDB2();
    }, [netto])

    useEffect(() => {
        setLoading02(true)
        if(monatsUmsatz && monatsDB && chartData && chartDataDB ){
            setLoading02(false)
        }

    },[monatsUmsatz, monatsDB, chartData, chartDataDB])

    useEffect(() => {
        const test = new Date;
        if(selectedMonth == "Alle"){
            test.setFullYear(selectedYear);
            test.setMonth(1);
        }
        else{
            test.setFullYear(selectedYear);
            test.setMonth(selectedMonth-1);
        }

        if (kassen.length > 0) {
            const KassenIDS = kassen.map(kasse => kasse.KassenID);
            setLoading02(true);
            produktauswertungsService.GetUmsatzdata(netto, test, KassenIDS)
                .then(data => {
                    if(selectedMonth == "Alle"){
                        setMonatsDB(data.dbJahr);
                        setMonatsUmsatz(data.umsatzJahr);
                        setLoading02(false);
                    }
                    else{
                        setMonatsDB(data.dbMonat);
                        setMonatsUmsatz(data.umsatzMonat);
                        setLoading02(false);
                    }
            })
            

            
            setLoading03(true);

            if(selectedMonth == "Alle"){
                dashboardService.GetKategorienUmsatzAll(KassenIDS, selectedYear, netto)
                .then(data => {
                    setPieChartData({
                        labels: data.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "Umsatz",
                                data: data.map(u => u.Umsatz),
                                backgroundColor: data.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(data.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                    });
                    setPieChartData2({
                        labels: data.map(u => u.Bezeichnung),
                        datasets: [
                            {
                                label: "Umsatz",
                                data: data.map(u => u.Umsatz),
                                backgroundColor: data.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(data.map(u => u.Bezeichnung))[index]),
                            }
                        ]
                    });
                    setWarengruppendata(data);
                    setWarengruppendata2(data);
                });
                

                dashboardService.GetKategorienDBAll(KassenIDS, selectedYear, netto)
                    .then(data => {
                        const tempData = data.sort((a, b) => b.DB - a.DB);

                        setPieChartDataDB({
                            labels: tempData.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "DB",
                                    data: tempData.map(u => u.DB),
                                    backgroundColor: tempData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(tempData.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setKategorienDB(data)
                        setPieChartDataDB2({
                            labels: tempData.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "Umsatz",
                                    data: tempData.map(u => u.DB),
                                    backgroundColor: tempData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(tempData.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setWarengruppendata3(tempData);
                        setWarengruppendata4(tempData);                    
                        
                        setLoading03(false);
                    });
            }
            else{
                dashboardService.GetKategorienUmsatz(KassenIDS, startMonth, endMonth, netto)
                    .then(data => {
                        setPieChartData({
                            labels: data.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "Umsatz",
                                    data: data.map(u => u.Umsatz),
                                    backgroundColor: data.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(data.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setPieChartData2({
                            labels: data.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "Umsatz",
                                    data: data.map(u => u.Umsatz),
                                    backgroundColor: data.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(data.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setWarengruppendata(data);
                        setWarengruppendata2(data);
                    });
                    

                dashboardService.GetKategorienDB(KassenIDS, startMonth, endMonth, netto)
                    .then(data => {
                        const tempData = data.sort((a, b) => b.DB - a.DB);

                        setPieChartDataDB({
                            labels: tempData.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "DB",
                                    data: tempData.map(u => u.DB),
                                    backgroundColor: tempData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(tempData.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setKategorienDB(data)
                        setPieChartDataDB2({
                            labels: tempData.map(u => u.Bezeichnung),
                            datasets: [
                                {
                                    label: "Umsatz",
                                    data: tempData.map(u => u.DB),
                                    backgroundColor: tempData.map((u, index) => u.Bezeichnung === "Andere" ? "lightgray" : getUniqueColors(tempData.map(u => u.Bezeichnung))[index]),
                                }
                            ]
                        });
                        setWarengruppendata3(tempData);
                        setWarengruppendata4(tempData);                    
                        
                        setLoading03(false);
                    });
                }
        }
      }, [kassen, selectedMonth, selectedYear])

    
    useEffect(() => {
        setFilteredProdukte([]);
        if(selectedMonth != "Alle"){
            produktauswertungsService.GetZeitraum()
            .then(data => {
                const years = [...new Set(data.map(item => item.Jahr))].map(year => ({
                    label: year.toString(),
                    value: year.toString(),
                }));
                setJahrOptions(years);

                const firstYear = years.length > 0 ? years[0].value : null;
                if (selectedYear == null && firstYear) {
                    setSelectedYear(firstYear);
                }

                const monthsForSelectedYear = data
                .filter(item => item.Jahr.toString() === selectedYear)
                .map(item => item.Monat);
                console.log(monthsForSelectedYear)

                const months = [...new Set(monthsForSelectedYear)].map(monat => ({
                    label: monat.toString().padStart(2, '0'),
                    value: monat.toString().padStart(2, '0'),
                }));
                const allOption = {
                    label: 'Alle',
                    value: 'Alle',
                };
                const monthOptionsWithAll = [allOption, ...months];

                setMonatOptions(monthOptionsWithAll);


                if (selectedMonth == null || !months.some(month => month.value === selectedMonth)) {
                    const lastMonth = months.length > 0 ? months[months.length-1].value : null;
                    setSelectedMonth(lastMonth);
                }
            })
        }
        if (!selectedMonth || 
            !selectedYear || 
            !selectedKassen || 
            (!selectedProduktgruppen && (!selectedLieferanten || selectedLieferanten.length === 0)) || 
            (isNaN(selectedMonth) && selectedMonth !== "Alle") ||
            isNaN(selectedYear)) {
            return;
        }
        setLoading01(true);
        var lieferTemp=0;
        if (selectedLieferanten && selectedLieferanten.length > 0) {
            lieferTemp = selectedLieferanten;
        }
        else{
            lieferTemp = lieferanten.map(lieferant => lieferant.Name);
        }

        var tempAll = false;
        var tempMonth = 0;

       

        if(selectedMonth == "Alle"){
            tempAll = true;
            tempMonth = 0;
        }
        else{
            if(selectedYear == new Date().getFullYear()){
                if(selectedMonth > new Date().getMonth()+1){
                    tempMonth =  new Date().getMonth()+1;
                }
                else{
                    tempMonth = selectedMonth
                }
            }
            else{
                tempMonth = selectedMonth
            }
        }

        if (selectedProduktgruppen && selectedProduktgruppen.length > 0) {
            if (selectedKassen.length > 0) {
                produktauswertungsService.GetProduktauswertungenWarengruppe(tempMonth, selectedYear, selectedKassen,selectedProduktgruppen,lieferTemp, tempAll)
                    .then(data => {
                        setProdukte(data);
                        setFilteredProdukte(data);
                        setLoading01(false);
                    })
                    .catch(() => {
                        setLoading01(false);
                    }
                );
            } else if (kassen.length > 0) {
                const KassenIDS = kassen.map(kasse => kasse.KassenID);
                produktauswertungsService.GetProduktauswertungenWarengruppe(tempMonth, selectedYear, KassenIDS, selectedProduktgruppen,lieferTemp, tempAll)
                    .then(data => {
                        setProdukte(data);
                        setFilteredProdukte(data);
                        setLoading01(false);
                    })
                    .catch(() => setLoading01(false));
            }
            else {
                setFilteredProdukte([]);
                setLoading01(false);
            }
        }
        else {
            const selectedProduktgruppenTemp  = produktgruppen.map(gruppe => gruppe.Bezeichnung);

            if (selectedKassen.length > 0) {
                produktauswertungsService.GetProduktauswertungenWarengruppe(tempMonth, selectedYear, selectedKassen, selectedProduktgruppenTemp,lieferTemp, tempAll)
                    .then(data => {
                        setProdukte(data);
                        setFilteredProdukte(data);
                        setLoading01(false);
                    })
                    .catch(() => {
                        setLoading01(false);
                    }
                );
            } else if (kassen.length > 0) {
                const KassenIDS = kassen.map(kasse => kasse.KassenID);
                produktauswertungsService.GetProduktauswertungenWarengruppe(tempMonth, selectedYear, KassenIDS, selectedProduktgruppenTemp,lieferTemp, tempAll)
                    .then(data => {
                        setProdukte(data);
                        setFilteredProdukte(data);
                        setLoading01(false);
                    })
                    .catch(() => setLoading01(false));
            }
            else {
                setFilteredProdukte([]);
                setLoading01(false);
            }
        }
    }, [selectedProduktgruppen, selectedMonth, selectedYear, selectedKassen, selectedLieferanten])


    

    useEffect(() =>{
        if(
            selectedYear != null &&
            selectedYear != undefined &&
            selectedMonth != null &&
            selectedMonth != undefined &&
            selectedKassen != null &&
            selectedKassen != undefined
        ){
            //setLoading01(true);
            setLoading02(true);
            const test = new Date;
            test.setFullYear(selectedYear);

            if(selectedMonth == "Alle"){
                test.setMonth(0);
            }
            else{
                test.setMonth(selectedMonth-1);
            }

            if (selectedKassen.length > 0) {
                produktauswertungsService.GetUmsatzdata(netto,test,selectedKassen)
                    .then(data => {
                        if(selectedMonth == "Alle"){
                            setMonatsDB(data.dbJahr);
                            setMonatsUmsatz(data.umsatzJahr);
                            //setLoading01(false);
                            setLoading02(false);
                        }
                        else{
                            setMonatsDB(data.dbMonat);
                            setMonatsUmsatz(data.umsatzMonat);
                            //setLoading01(false);
                            setLoading02(false);
                        }
                    })
                    .catch(() => setLoading01(false));
            } else if (kassen.length > 0) {
                const KassenIDS = kassen.map(kasse => kasse.KassenID);
                produktauswertungsService.GetUmsatzdata(netto,test,KassenIDS)
                    .then(data => {
                        if(selectedMonth == "Alle"){
                            setMonatsDB(data.dbJahr);
                            setMonatsUmsatz(data.umsatzJahr);
                            //setLoading01(false);
                            setLoading02(false);
                        }
                        else{
                            setMonatsDB(data.dbMonat);
                            setMonatsUmsatz(data.umsatzMonat);
                            //setLoading01(false);
                            setLoading02(false);
                        }
                    })
                    .catch(() => setLoading01(false));
            } else {
                setMonatsDB(0);
                //setLoading01(false);
            }
        }
    },[selectedYear, selectedMonth, selectedKassen])
    
    useEffect(() => {
        if (selectedProduktgruppen && selectedProduktgruppen.length > 0) {
            const filtered = produkte.filter(produkt =>
                selectedProduktgruppen.includes(produkt.WarengruppenBezeichnung)
            );
            setFilteredProdukte(filtered);
        } else {
            setFilteredProdukte([]);
        }
    }, [selectedProduktgruppen, produkte]);

    useEffect(() => {
        if (selectedLieferanten && selectedLieferanten.length > 0) {
            const filtered = produkte.filter(produkt =>
                selectedLieferanten.includes(produkt.Lieferanten)
            );
            setFilteredProdukte(filtered);
        }
    }, [selectedLieferanten, produkte]);

    function changeAnsicht(temp){
        setShowSchwundVerderbBeschaedigtEigenverbrauch(temp);
    }



    useEffect(() => {
        var gesamt=0;
        const updatedData = filteredProdukte
          .filter(p => !showAll || p.Menge !== 0)
          .map(p => {
            const marge = p.MargeAbs || 0;
            const monatDB = monatsDB || 0;
            const anteil = monatDB > 0 ? (marge / monatDB) * 100 : 0;
            gesamt += anteil;
            return { ...p, AnteilMonatsDB: anteil };
          });
        setProdukteFinish(updatedData);
      }, [filteredProdukte, monatsDB, showAll]);


      
    //Produkte Vergleich -> Umsatz
    useEffect(() => {
        setLoading02(true);
        const sortedData = produkteFinish.sort((a, b) => b.Umsatz - a.Umsatz);
        const top10Products = sortedData.slice(0, 10);
    
        const chartDataTemp = {
            labels: ["Top 10 Produkte", "Gesamtumsatz"],
            datasets: [
                ...top10Products.map((product, index) => ({
                    label: product.Bezeichnung,
                    data: [product.Umsatz,0],
                    backgroundColor: `hsl(${(index * 360) / top10Products.length}, 70%, 50%)`
                })),
                {
                    label: 'Gesamtumsatz',
                    data: [0,monatsUmsatz],
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                }
            ]
        };
        setChartData(chartDataTemp);
        setLoading02(false);
    }, [produkteFinish, monatsUmsatz]);

    //Produkte Vergleich -> DB
    useEffect(() => {
        const sortedData = produkteFinish.sort((a, b) => b.MargeAbs - a.MargeAbs);
        const top10Products = sortedData.slice(0, 10);
    
        const chartDataTemp = {
            labels: ["Top 10 Produkte", "GesamtDB"],
            datasets: [
                ...top10Products.map((product, index) => ({
                    label: product.Bezeichnung,
                    data: [product.MargeAbs,0],
                    backgroundColor: `hsl(${(index * 360) / top10Products.length}, 70%, 50%)`
                })),
                {
                    label: 'GesamtDB',
                    data: [0,monatsDB],
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                }
            ]
        };
        setChartDataDB(chartDataTemp);
    }, [produkteFinish]);


    function handleButtonClickProduktverlauf(id) {
        history.push({
            pathname: `/produktverlauf/${id}`,
            state: {month: selectedMonth,
                    year: selectedYear
             }
        });
    }

    const produktauswertungenExportieren = () => {
        setShowSpinner(true);
        if (!selectedMonth || 
            !selectedYear || 
            !selectedKassen || 
            !selectedProduktgruppen || 
            (isNaN(selectedMonth) && selectedMonth !== "Alle") ||
            isNaN(selectedYear)) {
            return;
        }

        var tempAll = false;
        var tempMonth = 0;
        
        if(selectedMonth == "Alle") {
            tempMonth = 0;
            tempAll = true;
        }
        else{
            tempMonth = new Date().getMonth()+1
        }

        var lieferTemp=0;
        if (selectedLieferanten && selectedLieferanten.length > 0) {
            lieferTemp = selectedLieferanten;
        }
        else{
            lieferTemp = lieferanten.map(lieferant => lieferant.Name);
        }

        if(selectedProduktgruppen.length > 0){
            if (selectedKassen.length > 0) {
                produktauswertungsService.downloadProduktauswertungen(tempMonth, selectedYear, selectedKassen, selectedProduktgruppen,lieferTemp, Object.entries(exportOptions).filter(option => option[1]).map(option => option[0]),tempAll).then(e => {
                    setShowSpinner(false);
                    setExportDialogVisible(false);
                }).catch(() => {
                    setShowSpinner(false);
                    toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
                });
            } else if (kassen.length > 0) {
                const KassenIDS = kassen.map(kasse => kasse.KassenID);
                produktauswertungsService.downloadProduktauswertungen(tempMonth, selectedYear, KassenIDS, selectedProduktgruppen, lieferTemp , Object.entries(exportOptions).filter(option => option[1]).map(option => option[0]),tempAll).then(e => {
                    setShowSpinner(false);
                    setExportDialogVisible(false);
                }).catch(() => {
                    setShowSpinner(false);
                    toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
                });
            }
            else {
                setShowSpinner(false);
                toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
            }
        }
        else {
            setShowSpinner(false);
            toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
        }
    };


/**
    //TODO abbrechen
    const handleAbort = () => {
        if (abortController) {
            abortController.abort();
            setLoading01(false);
        }
    };
    const handleStart = () => {
        const controller = new AbortController();
        setAbortController(controller);
        fetchData(controller.signal);
    };

    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController]);

    const fetchData = (cancelToken) => {
        const KassenIDS = kassen.map(kasse => kasse.KassenID);
        const selectedProduktgruppenTemp  = produktgruppen.map(gruppe => gruppe.Bezeichnung);
        const lieferTemp = lieferanten.map(lieferant => lieferant.Name);

        console.log("fetch")
        try {
            setLoading01(true); 

            //Testabfrage, welche alle Kassen und alle Produktgruppen zu allen Lieferanten abfrägt
            produktauswertungsService
                .GetProduktauswertungenWarengruppeTest(10, 2024, KassenIDS, selectedProduktgruppenTemp, lieferTemp, true, cancelToken)
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('Anfrage abgebrochen');
                    } else {
                        console.error('Fehler bei der Anfrage:', error);
                    }
                })
                .finally(() => {
                    setLoading01(false);
                });
        }
        catch (error) {
            setLoading01(false);
            console.error("Error", error);
        }
    };
 */


	return (
		<React.Fragment>
            {/**
            <div>
                <Button onClick={handleAbort}>Abbruch</Button>
                <Button onClick={handleStart}>Start</Button>
            </div>
             */}



            <div className="col-12">
                <div style={{ float: "right", display: "flex", alignItems: "center", marginTop:'0rem' }}>
                    <Button label="Produktauswertungen exportieren" icon="pi pi-download" style={{float: "right", marginLeft:'1rem'}} onClick={() => {setExportOptions({
                        Bezeichnung: false,
                        Warengruppe: false,
                        Menge: false,
                        Gewicht: false,
                        Einheit: false,
                        Umsatz: false,
                        MargeAbs: false,
                        MargePro: false,
                        NettoDB: false,
                        Schwund: false,
                        Verderb: false,
                        Beschädigt: false,
                        Eigenverbrauch: false,
                    }); setExportDialogVisible(true)}} />
                    <Dialog 
                        visible={exportDialogVisible} 
                        onHide={() => setExportDialogVisible(false)} 
                        header="Produktauswertungen exportieren"
                        footer={
                            <React.Fragment>
                                <Button label="Produktauswertungen exportieren" icon="pi pi-download" onClick={produktauswertungenExportieren}/>
                                {showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                            </React.Fragment>}>
                        <div className="field">
                            <Checkbox inputId="Bezeichnung" checked={exportOptions.Bezeichnung} onChange={e => setExportOptions({...exportOptions, Bezeichnung: e.target.checked})} />
                            <label htmlFor="Bezeichnung" className="mb-0 ml-1">Bezeichnung</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Warengruppe" checked={exportOptions.Warengruppe} onChange={e => setExportOptions({...exportOptions, Warengruppe: e.target.checked})} />
                            <label htmlFor="Warengruppe" className="mb-0 ml-1">Warengruppe</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Menge" checked={exportOptions.Menge} onChange={e => setExportOptions({...exportOptions, Menge: e.target.checked})} />
                            <label htmlFor="Menge" className="mb-0 ml-1">Menge</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Gewicht" checked={exportOptions.Gewicht} onChange={e => setExportOptions({...exportOptions, Gewicht: e.target.checked})} />
                            <label htmlFor="Gewicht" className="mb-0 ml-1">Gewicht</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Einheit" checked={exportOptions.Einheit} onChange={e => setExportOptions({...exportOptions, Einheit: e.target.checked})} />
                            <label htmlFor="Einheit" className="mb-0 ml-1">Einheit</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Umsatz" checked={exportOptions.Umsatz} onChange={e => setExportOptions({...exportOptions, Umsatz: e.target.checked})} />
                            <label htmlFor="Umsatz" className="mb-0 ml-1">Umsatz</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="MargeAbs" checked={exportOptions.MargeAbs} onChange={e => setExportOptions({...exportOptions, MargeAbs: e.target.checked})} />
                            <label htmlFor="MargeAbs" className="mb-0 ml-1">MargeAbs</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="MargePro" checked={exportOptions.MargePro} onChange={e => setExportOptions({...exportOptions, MargePro: e.target.checked})} />
                            <label htmlFor="MargePro" className="mb-0 ml-1">MargePro</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="NettoDB" checked={exportOptions.NettoDB} onChange={e => setExportOptions({...exportOptions, NettoDB: e.target.checked})} />
                            <label htmlFor="NettoDB" className="mb-0 ml-1">NettoDB</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Schwund" checked={exportOptions.Schwund} onChange={e => setExportOptions({...exportOptions, Schwund: e.target.checked})} />
                            <label htmlFor="Schwund" className="mb-0 ml-1">Schwund</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Verderb" checked={exportOptions.Verderb} onChange={e => setExportOptions({...exportOptions, Verderb: e.target.checked})} />
                            <label htmlFor="Verderb" className="mb-0 ml-1">Verderb</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Beschädigt" checked={exportOptions.Beschädigt} onChange={e => setExportOptions({...exportOptions, Beschädigt: e.target.checked})} />
                            <label htmlFor="Beschädigt" className="mb-0 ml-1">Beschädigt</label>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Eigenverbrauch" checked={exportOptions.Eigenverbrauch} onChange={e => setExportOptions({...exportOptions, Eigenverbrauch: e.target.checked})} />
                            <label htmlFor="Eigenverbrauch" className="mb-0 ml-1">Eigenverbrauch</label>
                        </div>
                        <Button 
                            label="Alle auswählen" 
                            onClick={() => setExportOptions({
                                Bezeichnung: true,
                                Warengruppe: true,
                                Menge: true,
                                Gewicht: true,
                                Einheit: true,
                                Umsatz: true,
                                MargeAbs: true,
                                MargePro: true,
                                NettoDB: true,
                                Schwund: true,
                                Verderb: true,
                                Beschädigt: true,
                                Eigenverbrauch: true,
                        })}/>
                    </Dialog>
                </div>

                {isLargeScreen && (
                <div className="checkbox-container" style={{ display: 'flex', flexDirection: 'column'  }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                        <Checkbox 
                            className="ml-2 mt-2" 
                            style={{alignSelf: 'center'}} 
                            inputId="showAll" 
                            checked={showAll} 
                            onChange={e => setShowAll(e.target.checked)}
                        />
                        <label 
                            className="ml-1 mt-2" 
                            style={{alignSelf: 'center'}} 
                            htmlFor="showAll"
                        >
                            Nicht verkaufte Produkte ausblenden
                        </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            className="ml-2 mt-2" 
                            style={{alignSelf: 'center'}}
                            inputId="showSchwundVerderbBeschaedigtEigenverbrauch"
                            checked={showSchwundVerderbBeschaedigtEigenverbrauch} 
                            onChange={e => changeAnsicht(e.target.checked)}
                        />
                        <label 
                            className="ml-1 mt-2" 
                            style={{alignSelf: 'center'}}
                            htmlFor="showSchwundVerderbBeschaedigtEigenverbrauch"
                        >
                            Schwund, Verderb, Beschädigt & Eigenverbrauch in einzelnen Spalten anzeigen
                        </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop:"0.5rem", marginLeft: "2rem"}}>
                        <label>
                        Gesamt Schwund, Verderb, Beschädigt, Eigenverbrauch Anzeigeart:     
                        </label>
                        <span style={{ color: '#555', marginLeft: '2rem' }}>
                            Stück
                        </span>
                        <InputSwitch 
                            checked={switchStückEuro} 
                            onChange={(e) => setSwitchStückEuro(e.value)} 
                            autoFocus={false} 
                            style={{ 
                                scale: "0.8",  
                                marginTop:'0rem',
                                marginBottom:'0rem'
                            }}/>
                        <span style={{ color: '#555'}}>
                            Euro
                        </span>
                    </div>
                </div>
                )}

                {!isLargeScreen && (
                <div className="checkbox-container" style={{ display: 'flex', flexDirection: 'column'  }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', marginTop:"4.5rem", width:"20rem" }}>
                        <Checkbox 
                            className="ml-2 mt-2" 
                            style={{alignSelf: 'center'}} 
                            inputId="showAll" 
                            checked={showAll} 
                            onChange={e => setShowAll(e.target.checked)}
                        />
                        <label 
                            className="ml-1 mt-2" 
                            style={{alignSelf: 'center', width:"20rem"}} 
                            htmlFor="showAll"
                        >
                            Nicht verkaufte Produkte ausblenden
                        </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width:"20rem" }}>
                        <Checkbox
                            className="ml-2 mt-2" 
                            style={{alignSelf: 'center'}}
                            inputId="showSchwundVerderbBeschaedigtEigenverbrauch"
                            checked={showSchwundVerderbBeschaedigtEigenverbrauch} 
                            onChange={e => changeAnsicht(e.target.checked)}
                        />
                        <label 
                            className="ml-1 mt-2" 
                            style={{alignSelf: 'center'}}
                            htmlFor="showSchwundVerderbBeschaedigtEigenverbrauch"
                        >
                            Schwund, Verderb, Beschädigt & Eigenverbrauch in einzelnen Spalten anzeigen
                        </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop:"1rem", marginLeft: "2rem", width:"20rem"}}>
                        <label>
                        Gesamt Schwund, Verderb, Beschädigt, Eigenverbrauch Anzeigeart:     
                        </label>
                        
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom:"8rem", minWidth:"10rem", marginLeft:"10rem", marginTop:"0.3rem"}}>
                        <span style={{ color: '#555'}}>
                            Stück
                        </span>
                        <InputSwitch 
                            checked={switchStückEuro} 
                            onChange={(e) => setSwitchStückEuro(e.value)} 
                            autoFocus={false} 
                            style={{ 
                                scale: "0.8", 
                            }}/>
                        <span style={{ color: '#555'}}>
                            Euro
                        </span>
                    </div>
                </div>
                )}

                
                <div style={{ float: "right", display: "flex", alignItems: "center", marginTop:'-1.5rem', marginRight:"-0.5rem" }}>
                    {monatOptions && jahrOptions ? (
                        <>
                            <Dropdown 
                                value={selectedMonth} 
                                options={monatOptions} 
                                onChange={e => setSelectedMonth(e.target.value)} 
                                valueTemplate={w => w ? <React.Fragment>{w.label}</React.Fragment> : null} 
                                disabled={loading01}
                            />
                            <Dropdown 
                                value={selectedYear} 
                                options={jahrOptions} 
                                onChange={e => setSelectedYear(e.target.value)} 
                                valueTemplate={w => w ? <React.Fragment>{w.label}</React.Fragment> : null} 
                                style={{ marginLeft: '0.2rem' }} 
                                disabled={loading01}
                            />
                            <MultiSelect 
                                className="column-filter"
                                value={selectedKassen} 
                                options={kassen} 
                                onChange={e => setSelectedKassen(e.value)} 
                                placeholder={selectedKassen.length > 0 ? selectedKassen.join(', ') : "Alle Kassen"} 
                                optionLabel="KassenID" 
                                optionValue="KassenID" 
                                style={{ marginLeft: '0.2rem' }} 
                                disabled={loading01}
                            />
                        </>
                    ) : (
                        <p>Loading options...</p>
                    )}
                </div>
                <div >
                    <SelectButton 
                        value={netto} 
                        onChange={e => setNetto(e.value)} 
                        options={[{label: "Brutto", value: false}, {label: "Netto", value: true}]} 
                        style={{float: "right", marginRight: isLargeScreen ? "0.5rem" : "-0.5rem", marginTop: isLargeScreen ? "-1.5rem" : "-5rem"}}/>
                </div>
                
            </div>

            <div style={{ position: 'relative' }}>
            {loading01 && (
                <div>
                    <i 
                        className="pi pi-spin pi-spinner" 
                        style={{
                            fontSize: '2rem', 
                            position: "absolute", 
                            left: "calc(50% - 1rem)",
                            top: "calc(50% - 1rem)",
                            zIndex: 9999 
                        }}
                    />
                    <div style={{
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1
                    }} />
                </div>
            )}
            <DataTable
                scrollable
                scrollHeight="70vh"
                ref={dt}
                value={produkteFinish}
                filterDisplay="row"
                tableClassName='p-datatable-produktstammdaten'
                onValueChange={filteredData => setFilteredProdukte(filteredData)}
                header={renderHeader}
                responsive="true"
                className="p-datatable-produkte p-datatable-striped"
                dataKey="id"
                rowHover
                globalFilter={globalFilter}
                paginator
                rows={20}
                emptyMessage="Bitte eine Warengruppe, Lieferanten oder Kassa auswählen"
                currentPageReportTemplate='({first} von {totalRecords})'
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[20, 30, 40]}
                style={{
                    marginTop: '1rem',
                    opacity: loading01 ? 0.5 : 1,
                    pointerEvents: loading01 ? 'none' : 'auto',
                }}
            >
                <Column
                    field="ProduktID" 
                    sortable 
                    filter 
                    header="ID"
                    bodyStyle={{display: "none"}}
                    headerStyle={{display:"none"}}
                    filterHeaderStyle={{display:"none"}}
                    showFilterMenu={false}
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                />
                <Column
                    field="Artikelnummer" 
                    sortable 
                    filter 
                    header="Artikelnummer"
                    bodyStyle={{display: "none"}}
                    headerStyle={{display:"none"}}
                    filterHeaderStyle={{display:"none"}}
                    showFilterMenu={false}
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                />
                <Column 
                    field="Bezeichnung" 
                    filter 
                    filterPlaceholder="Bezeichnung eingeben"
                    sortable 
                    header="Bezeichnung" 
                    filterElement={bezeichnungsFilter}
                    showFilterMenu={false}
                    onFilterClear={() => setSelectedBezeichnung(null)}
                    disabled={loading01}
                    style={{
                        maxWidth: '14rem',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    headerStyle={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    bodyStyle={{
                        overflow: 'hidden',
                    }}
                />
                <Column
                    field="WarengruppenBezeichnung" 
                    sortable 
                    header="Warengruppe"
                    filter
                    filterElement={warengruppenFilter}
                    style={{
                        maxWidth: '8.5rem',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    headerStyle={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    bodyStyle={{
                        overflow: 'hidden',
                    }}
                    showFilterMenu={false}              
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                />
                <Column
                    field="Lieferanten" 
                    sortable 
                    header="Lieferanten"
                    filter
                    filterElement={lieferantenFilter}
                    style={{
                        maxWidth: '8.5rem',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    headerStyle={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    bodyStyle={{
                        overflow: 'hidden',
                    }}
                    showFilterMenu={false}              
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                />
                <Column 
                    field="Menge" 
                    filter 
                    bodyStyle={{overflow: 'hidden'}}
                    sortable 
                    header="Verkaufte Menge" 
                    filterElement={mengenFilter}
                    disabled={loading01}
                    onFilterClear={[]}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right"
                    }}
                    showFilterMenu={false}
                />
                <Column 
                    field="Gewicht" 
                    filter 
                    bodyStyle={{overflow: 'hidden'}}
                    sortable 
                    header="Verkauftes Gewicht" 
                    filterElement={gewichtsFilter}
                    showFilterMenu={false}
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                    body={(produkte) => {
                        return <span>{produkte.Gewicht?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0}</span>;
                    }}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }} 
                />
                <Column 
                    field="Einheit" 
                    filter 
                    bodyStyle={{overflow: 'hidden'}}
                    sortable 
                    header="Einheit" 
                    filterElement={einheitenFilter}
                    showFilterMenu={false}
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                    style={{
                        maxWidth:"6rem",
                        minWidth: "6rem"
                    }}
                />
                <Column 
                    field="Umsatz"
                    filter
                    sortable
                    header={
                        <>
                            Umsatz 
                            <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Verkaufte Menge * Verkaufspreis zum Zeitpunkt des getätigten Einkaufs"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                        </>
                    }
                    showFilterMenu={false}
                    filterElement={umsatzFilter}
                    body={(produkte) => {
                        return (
                            <span>
                                {netto ? 
                                    (produkte.UmsatzNetto?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0) : 
                                    (produkte.Umsatz?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0)} €
                            </span>
                        );
                    }}
                    
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }} 
                />
                <Column 
                    field="MargeAbs"
                    filter
                    sortable 
                    header={
                        <>
                            Marge absolut 
                            <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Marge wertmäßig"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                        </>
                    } 
                    filterElement={margeAbsFilter}
                    showFilterMenu={false}
                    onFilterClear={() => [](null)}
                    body={(produkte) => {
                        return (
                            <span>
                                {netto ? 
                                    (produkte.MargeAbsNetto?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0) : 
                                    (produkte.MargeAbs?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0)} €
                            </span>
                        );
                    }}
                    disabled={loading01}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }} 
                />

                <Column
                    field="MargePro" 
                    sortable
                    header={
                        <>
                            Marge in %
                            <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Marge prozentmäßig"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                        </>
                    } 
                    filter
                    filterElement={margeProFilter}
                    showFilterMenu={false}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }}
                    disabled={loading01}
                    onFilterClear={() => [](null)}
                    body={(produkte) => {
                        return <span>{produkte.MargePro || 0} %</span>;
                    }}
                />
                <Column
                    field="AnteilMonatsDB" 
                    sortable 
                    header={
                        <>
                            Anteil - Monats-DB 
                            <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Prozentueller Anteil des Deckungsbeitrags am Gesamtmonatsdeckungsbeitrag"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                        </>
                    } 
                    filter
                    filterElement={anteilMonatFilter}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }}                    
                    disabled={loading01}
                    showFilterMenu={false}
                    body={(rowData) => {
                        const anteil = rowData.AnteilMonatsDB || 0;
                        return <span>{anteil.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0} %</span>;
                    }}
                    onFilterClear={() => [](null)}
                />

                <Column
                    field="NettoDB" 
                    sortable 
                    header={
                        <>
                            Netto-DB 
                            <i 
                                className="pi pi-info-circle" 
                                style={{ marginLeft: '5px', transform: 'translateY(0.2rem)' }} 
                                title="Deckungsbeitrag abzüglich Schwund, Verderb, Beschädigt und Eigenverbrauch"
                                data-pr-tooltip="Information über Bezeichnung" 
                            />
                        </>
                    } 
                    filter
                    filterElement={nettoDBFilter}
                    style={{
                        maxWidth:"7rem",
                        minWidth: "7rem",
                        textAlign: "right",
                    }}                   
                    disabled={loading01}
                    showFilterMenu={false}
                    onFilterClear={() => [](null)}
                    body={(produkte) => {
                        return (
                            <span>
                                {netto ? 
                                    (produkte.NettoDBNetto?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0) : 
                                    (produkte.NettoDB?.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0)} €
                            </span>
                        );
                    }}
                />
                <Column
                    field="Schwund" 
                    filter
                    sortable 
                    header="Schwund"
                    style={{
                        maxWidth: "6rem",
                        textAlign: "right",
                    }} 
                    filterElement={[]}
                    onFilterClear={() => []}
                    showFilterMenu={false}
                    disabled={loading01}
                    hidden={!showSchwundVerderbBeschaedigtEigenverbrauch} 
                    body={(data) => (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            {switchStückEuro ? `${data.SchwundWert.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` : `${data.Schwund}`}
                        </div>
                    )}
                />

                <Column
                    field="Verderb" 
                    filter
                    sortable 
                    header="Verderb"
                    style={{
                        maxWidth: "5.5rem",
                        textAlign: "right",
                    }} 
                    filterElement={[]}
                    onFilterClear={() => [](null)}
                    showFilterMenu={false}
                    disabled={loading01}
                    hidden={!showSchwundVerderbBeschaedigtEigenverbrauch} 
                    body={(data) => (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            {switchStückEuro ? `${data.VerderbWert.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` : `${data.Verderb}`}
                        </div>
                    )}
                />
                <Column
                    field="Beschaedigt" 
                    filter
                    sortable 
                    header="Beschädigt"
                    style={{
                        maxWidth: "7rem",
                        textAlign: "right",
                    }} 
                    filterElement={[]}
                    onFilterClear={() => [](null)}
                    showFilterMenu={false}
                    disabled={loading01}
                    hidden={!showSchwundVerderbBeschaedigtEigenverbrauch}
                    body={(data) => (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            {switchStückEuro ? `${data.BeschaedigtWert.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` : `${data.Beschaedigt}`}
                        </div>
                    )}
                />
                <Column
                    field="Eigenverbrauch" 
                    filter
                    sortable 
                    header="Eigenverbrauch"
                    style={{
                        maxWidth: "9rem",
                        textAlign: "right",
                    }} 
                    filterElement={[]}
                    onFilterClear={() => [](null)}
                    showFilterMenu={false}
                    disabled={loading01}
                    hidden={!showSchwundVerderbBeschaedigtEigenverbrauch} 
                    body={(data) => (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            {switchStückEuro ? `${data.EigenverbrauchWert.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` : `${data.Eigenverbrauch}`}
                        </div>
                    )}
                />
                <Column
                    field="GesamtSchwundVerderbBeschaedigtEigenverbrauchMenge"
                    header="Gesamt Schwund, Verderb, Beschädigt, Eigenverbrauch"
                    sortable
                    filter
                    filterElement={[]} 
                    onFilterClear={() => [](null)}
                    showFilterMenu={false}
                    style={{
                        maxWidth: "7rem",
                        textAlign: "right",
                    }} 
                    disabled={loading01}
                    hidden={showSchwundVerderbBeschaedigtEigenverbrauch}
                    body={(data) => (
                        <div
                            title={`Schwund: ${data.Schwund} = ${(data.SchwundWert ?? 0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €\nVerderb: ${data.Verderb} = ${(data.VerderbWert ?? 0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €\nBeschädigt: ${data.Beschaedigt} = ${(data.BeschaedigtWert ?? 0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €\nEigenverbrauch: ${data.Eigenverbrauch} = ${(data.EigenverbrauchWert ?? 0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €`}
                            style={{ width: '100%', textAlign: 'right' }}
                        >
                            <span>
                                {switchStückEuro
                                    ? `${(data.GesamtSchwundVerderbBeschaedigtEigenverbrauchWert ?? 0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €`
                                    : data.GesamtSchwundVerderbBeschaedigtEigenverbrauchMenge}
                            </span>
                        </div>
                    )}
                    
                    bodyClassName="cell-with-tooltip"
                />
                <Column
                    field="ProduktID"
                    style={{
                        maxWidth: "7rem",
                        textAlign: "right",
                    }} 
                    showFilterMenu={false}
                    disabled={loading01}
                    body={(data) => (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Button
                                icon="pi pi-chart-line" 
                                onClick={() => handleButtonClickProduktverlauf(data.ProduktID)}
                                disabled={loading01}
                                style={{ padding: '5px', fontSize: '1.2em' }}
                            />
                        </div>
                    )}
                />
            </DataTable>
        </div>

            <Divider/>
            

        {roles.includes('Chef') && (
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{position: "relative"}}>
                {loading02 && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)" }} />}
                    <Card title = {
                        <React.Fragment>
                            Die 10 besten Produkte nach Umsatz
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                (gemäß ausgewähltem Zeitraum, Warengruppen und Kassen)
                            </span>
                        </React.Fragment>} style={{width: '100%', height: '100%'}}>
                        
                        <ZoomPopup 
                            header={
                                <div style={{textAlign: "center", position: "relative"}}>
                                    <span style={{position: "absolute", left: "0px"}}>Die 10 besten Produkte nach Umsatz</span>
                                </div>
                            } 
                            footer={
                                <Button label="Download" icon="pi pi-image" onClick={() => download(barChartRefUmsatz.current.getBase64Image(),"Top10Produkte-Umsatz.png")}/>
                            }>
    
                            <Chart type="bar" 
                                data={chartData} 
                                ref={barChartRefUmsatz}
                                options={{  indexAxis: 'y',
                                            maintainAspectRatio: false,
                                            aspectRatio: 0.8,
                                            plugins: {
                                                legend: { position: 'bottom' }
                                            },
                                            scales: {
                                                x: {
                                                    stacked: true,
                                                },
                                                y: {
                                                    stacked: true,
                                                }
                                            },
                                        }}
                            />
                        </ZoomPopup>
                    </Card>
                </div>


                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{position: "relative"}}>
                {loading02 && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)" }} />}
                    <Card title = {
                        <React.Fragment>
                            Die 10 besten Produkte nach DB
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                (gemäß ausgewähltem Zeitraum, Warengruppen und Kassen)
                            </span>
                        </React.Fragment>} style={{width: '100%', height: '100%'}}>
                        
                        <ZoomPopup 
                            header={
                                <div style={{textAlign: "center", position: "relative"}}>
                                    <span style={{position: "absolute", left: "0px"}}>Die 10 besten Produkte nach DB</span>
                                </div>
                            } 
                            footer={
                                <Button label="Download" icon="pi pi-image" onClick={() => download(barChartRefDB.current.getBase64Image(),"Top10Produkte-DB.png")}/>
                            }>
                            <Chart type="bar" 
                                data={chartDataDB} 
                                ref={barChartRefDB}
                                options={{  indexAxis: 'y',
                                            maintainAspectRatio: false,
                                            aspectRatio: 0.8,
                                            plugins: {
                                                legend: { position: 'bottom' }
                                            },
                                            scales: {
                                                x: {
                                                    stacked: true,
                                                },
                                                y: {
                                                    stacked: true,
                                                }
                                            },
                                        }}
                            />
                        </ZoomPopup>
                    </Card>
                </div>
                

                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{ position: "relative", maxWidth:"50rem", marginRight:"1.2rem", width: isLargeScreen ? '50.2rem' : '20.5rem', }}>
                    <Card 
                        title={
                        <React.Fragment>{"Umsatzverteilung nach Kategorienumsatz"}
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                    (gemäß oben ausgewählter Methode: Brutto oder Netto)
                            </span>
                        </React.Fragment>} 
                        style={{width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '20.5rem', }}>
                        <ZoomPopup 
                            header={ umsatzverteilungsHeader || "Umsatzverteilung nach Kategorienumsatz"} 
                            footer={
                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                <Button label="Zurück" icon="pi pi-angle-left" onClick={onClickBackUmsatzKategorie} style={{ marginTop: "1rem" }} />
                                <Button label="Download" icon="pi pi-image" onClick={() => download(pieChartRefDB.current.getBase64Image(), "Umsatzverteilung nach Kategorienumsatz.png")} style={{ marginTop: "1rem", marginLeft: "auto" }} />
                            </div>    
                            }
                            style={{ width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '20rem' }}

                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isLargeScreen ? 'row' : 'column'}}>

                                <Chart 
                                    ref={pieChartRefUmsatz} 
                                    type="pie" 
                                    data={pieChartData} 
                                    options={{
                                    animation: { duration: 0 }, 
                                    scales: {
                                        x: { stacked: true, display: false }, 
                                        y: { stacked: true, display: false }
                                    }, 
                                    plugins: { legend: { position: 'bottom' } }, 
                                    onClick: (e) => handleChartClick(e)
                                    }}
                                    style={{
                                        maxWidth: '40rem',
                                        minWidth: '20rem',
                                        maxHeight: '40rem',
                                        minHeight: '20rem',
                                        objectFit: 'contain',
                                    }}
                                />
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        {pieChartData && pieChartData.labels && pieChartData.datasets && pieChartData.datasets[0].data
                                        ? pieChartData.labels
                                            .map((label, index) => ({
                                                label,
                                                amount: pieChartData.datasets[0].data[index]
                                            }))
                                            .sort((a, b) => b.amount - a.amount)
                                            .map((item, index, array) => {
                                                const total = array.reduce((sum, currentItem) => sum + currentItem.amount, 0);
                                                const percentage = total > 0 ? ((item.amount / total) * 100).toFixed(2) : '00.00';


                                                return (
                                                <tr key={index}>
                                                    <td style={{ padding: '5px' }}><strong>{item.label}:</strong></td>
                                                    <td style={{ textAlign: 'right', padding: '5px', minWidth: '7rem' }}>
                                                        <div>
                                                            {item.amount
                                                                .toFixed(2)
                                                                .replace('.', ',')
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                            {' €'}
                                                        </div>
                                                        <div>
                                                            ({percentage.replace('.', ',')} %)
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                                );
                                            })
                                        : <tr><td colSpan="2" style={{ textAlign: 'center', padding: '5px' }}>Loading chart data...</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </ZoomPopup>
                    </Card>
                </div>

                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{ position: "relative", maxWidth:"50rem", width: isLargeScreen ? '50.2rem' : '20.5rem', }}>
                    <Card 
                        title={<React.Fragment>{"Umsatzverteilung nach Gesamtumsatz"}
                        <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                            (gemäß oben ausgewählter Methode: Brutto oder Netto)
                        </span>
                        </React.Fragment>} 
                        style={{width: '100%', height: '100%', width: isLargeScreen ? '50rem' : '20.5rem', }}>
                        <ZoomPopup 
                            header={umsatzverteilungsHeader2 || "Umsatzverteilung nach Gesamtumsatz"} 
                            footer={
                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                <Button label="Zurück" icon="pi pi-angle-left" onClick={onClickBackUmsatzGesamt} style={{ marginTop: "1rem" }} />
                                <Button label="Download" icon="pi pi-image" onClick={() => download(pieChartRefDB.current.getBase64Image(), "Umsatzvertielung nach Gesamtumsatz.png")} style={{ marginTop: "1rem", marginLeft: "auto" }} />
                            </div>    
                            }
                            style={{ width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '20rem' }}

                        >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isLargeScreen ? 'row' : 'column' }}>

                            <Chart 
                                ref={pieChartRefUmsatz2} 
                                type="pie" 
                                data={pieChartData2} 
                                options={{
                                animation: { duration: 0 }, 
                                scales: {
                                    x: { stacked: true, display: false}, 
                                    y: { stacked: true, display: false}
                                }, 
                                plugins: { legend: { position: 'bottom' } }, 
                                onClick: (e) => handleChartClick2(e)
                                }}
                                style={{
                                    maxWidth: '40rem',
                                    minWidth: '20rem',
                                    maxHeight: '40rem',
                                    minHeight: '20rem',
                                    objectFit: 'contain'
                                }}
                            />
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <tbody>
                                    {pieChartData2 && pieChartData2.labels && pieChartData2.datasets && pieChartData2.datasets[0].data
                                    ? pieChartData2.labels
                                        .map((label, index) => ({
                                            label,
                                            amount: pieChartData2.datasets[0].data[index]
                                        }))
                                        .sort((a, b) => b.amount - a.amount)
                                        .map((item, index, array) => {
                                            const total = array.reduce((sum, currentItem) => sum + currentItem.amount, 0);
                                            const percentage = total > 0 ? ((item.amount / total) * 100).toFixed(2) : '00.00';


                                            return (
                                                <tr key={index}>
                                                <td style={{ padding: '5px' }}><strong>{item.label}:</strong></td>
                                                <td style={{ textAlign: 'right', padding: '5px', minWidth: '7rem' }}>
                                                    <div>
                                                        {item.amount
                                                            .toFixed(2)
                                                            .replace('.', ',')
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                        {' €'}
                                                    </div>
                                                    <div>
                                                        ({percentage.replace('.', ',')} %)
                                                    </div>
                                                </td>
                                            </tr>
                                            );
                                        })
                                    : <tr><td colSpan="2" style={{ textAlign: 'center', padding: '5px' }}>Loading chart data...</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        </ZoomPopup>
                    </Card>
                </div>


                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{ position: "relative", maxWidth:"50rem", marginRight:"1.2rem", marginTop:"0.5rem", width: isLargeScreen ? '50.2rem' : '21.5rem',   }}>
                {loading03 && (
                    <div>
                        <i 
                            className="pi pi-spin pi-spinner" 
                            style={{
                                fontSize: '2rem', 
                                position: "absolute", 
                                left: "calc(50% - 1rem)",
                                top: "calc(50% - 1rem)",
                                zIndex: 9999 
                            }}
                        />
                        <div style={{
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            width: '150%', 
                            height: '100%', 
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1
                        }} />
                    </div>
                )}
                    <Card 
                        title={
                        <React.Fragment>{"DB-Verteilung nach Kategorienumsatz"}
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                    (gemäß oben ausgewählter Methode: Brutto oder Netto)
                            </span>
                        </React.Fragment>} 
                        style={{width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '21.5rem', }}>
                        <ZoomPopup 
                            header={umsatzverteilungsHeader3 || "DB-Verteilung nach Kategorienumsatz"} 
                            footer={
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                        <Button label="Zurück" icon="pi pi-angle-left" onClick={onClickBackDBKategorie} style={{ marginTop: "1rem" }} />
                                        <Button label="Download" icon="pi pi-image" onClick={() => download(pieChartRefDB.current.getBase64Image(), "DB-Verteilung.png")} style={{ marginTop: "1rem", marginLeft: "auto" }} />
                                </div>       
                            }
                            style={{ width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '20rem' }}
                        >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isLargeScreen ? 'row' : 'column' }}>
                        
                        <Chart 
                            ref={pieChartRefDB} 
                            type="pie" 
                            data={pieChartDataDB} 
                            options={{
                            animation: { duration: 0 }, 
                            scales: {
                                x: { stacked: true, display: false }, 
                                y: { stacked: true, display: false }
                            }, 
                            plugins: { legend: { position: 'bottom' } }, 
                            onClick: (e) => handleChartClickDB(e)
                            }}
                            style={{
                                maxWidth: '40rem',
                                minWidth: '20rem',
                                maxHeight: '40rem',
                                minHeight: '20rem',
                                objectFit: 'contain'
                            }}
                        />
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                {pieChartDataDB?.labels && pieChartDataDB?.datasets?.[0]?.data ? (
                                pieChartDataDB.labels
                                    .map((label, index) => ({
                                    label,
                                    data: pieChartDataDB.datasets[0].data[index],
                                    }))
                                    .sort((a, b) => b.data - a.data)
                                    .map((item, index, array) => {
                                    const total = array.reduce((sum, currentItem) => sum + currentItem.data, 0);
                                    const percentage = total > 0 ? ((item.data / total) * 100).toFixed(2) : '00.00';


                                    return (
                                        <tr key={index}>
                                            <td style={{ padding: '5px' }}><strong>{item.label}:</strong></td>
                                            <td style={{ textAlign: 'right', padding: '5px', minWidth: '7rem' }}>
                                                <div>
                                                    {item.data
                                                        .toFixed(2)
                                                        .replace('.', ',')
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                    {' €'}
                                                </div>
                                                <div>
                                                    ({percentage.replace('.', ',')} %)
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                    })
                                ) : (
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'center', padding: '5px' }}>
                                    Loading chart data...
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>


                        </div>
                        </ZoomPopup>
                    </Card>
                </div>

                <div className="col-12 md:col-6 lg:col-6 p-p-1" style={{ position: "relative", maxWidth:"50rem", marginTop:"0.5rem", width: isLargeScreen ? '50.2rem' : '21.5rem', }}>
                {loading03 && (
                    <div>
                        <i 
                            className="pi pi-spin pi-spinner" 
                            style={{
                                fontSize: '2rem', 
                                position: "absolute", 
                                left: "calc(50% - 1rem)",
                                top: "calc(50% - 1rem)",
                                zIndex: 9999 
                            }}
                        />
                        <div style={{
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            width: '150%', 
                            height: '100%', 
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1
                        }} />
                    </div>
                )}
                    <Card 
                        title={
                        <React.Fragment>{"DB-Verteilung nach Gesamtumsatz"}
                            <span style={{ fontSize: '0.7em', color: '#555', marginLeft:'0.5rem' }}>
                                    (gemäß oben ausgewählter Methode: Brutto oder Netto)
                            </span>
                        </React.Fragment>} 
                        style={{width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '21.5rem', }}>
                        <ZoomPopup 
                            header={umsatzverteilungsHeader4 || "DB-Verteilung nach Gesamtumsatz"} 
                            footer={
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                    <Button label="Zurück" icon="pi pi-angle-left" onClick={onClickBackDBGesamt} style={{ marginTop: "1rem" }} />
                                    <Button label="Download" icon="pi pi-image" onClick={() => download(pieChartRefDB.current.getBase64Image(), "DB-Verteilung.png")} style={{ marginTop: "1rem", marginLeft: "auto" }} />
                                </div>    
                            }
                            style={{ width: '100%', height: '100%', width: isLargeScreen ? '50.2rem' : '20rem' }}

                        >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isLargeScreen ? 'row' : 'column' }}>

                        <Chart 
                            ref={pieChartRefDB2} 
                            type="pie" 
                            data={pieChartDataDB2} 
                            options={{
                            animation: { duration: 0 }, 
                            scales: {
                                x: { stacked: true, display: false }, 
                                y: { stacked: true, display: false }
                            }, 
                            plugins: { legend: { position: 'bottom' } }, 
                            onClick: (e) => handleChartClickDB2(e)
                            }}
                            style={{
                                maxWidth: '40rem',
                                minWidth: '20rem',
                                maxHeight: '40rem',
                                minHeight: '20rem',
                                objectFit: 'contain'
                            }}
                        />
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                {pieChartDataDB2?.labels && pieChartDataDB2?.datasets?.[0]?.data ? (
                                pieChartDataDB2.labels
                                    .map((label, index) => ({
                                    label,
                                    data: pieChartDataDB2.datasets[0].data[index],
                                    }))
                                    .sort((a, b) => b.data - a.data)
                                    .map((item, index, array) => {
                                    const total = array.reduce((sum, currentItem) => sum + currentItem.data, 0);
                                    
                                    const percentage = total > 0 ? ((item.data / total) * 100).toFixed(2) : '00.00';


                                    return (
                                        <tr key={index}>
                                            <td style={{ padding: '5px' }}><strong>{item.label}:</strong></td>
                                            <td style={{ textAlign: 'right', padding: '5px', minWidth: '7rem' }}>
                                                <div>
                                                    {item.data
                                                        .toFixed(2)
                                                        .replace('.', ',')
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                    {' €'}
                                                </div>
                                                <div>
                                                    ({percentage.replace('.', ',')} %)
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                    })
                                ) : (
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'center', padding: '5px' }}>
                                    Loading chart data...
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>



                        </div>
                        </ZoomPopup>
                    </Card>
                </div>
            </div>
            )}
		</React.Fragment>
	)
}

export default Produktauswertungen;