import axios from "axios";
import { GetAxiosConfig, GetToken } from "./AuthService";
import CONFIG from 'config';

export class ProduktverlaufService {
	async GetSteuern(id) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/steuern", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetProduktvergleich(id,from,to, methode, kassen, EuroStuck) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/From/"+from.toISOString()+"/To/"+to.toISOString()+"/"+methode + "/kassen/"+kassen+"/euroStuck/"+EuroStuck, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilen(id,start,end) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/Buchungszeilen/from/"+start.toISOString()+"/to/"+end.toISOString()+"/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenTage(id,start,end) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenTage/from/"+start.toISOString()+"/to/"+end.toISOString()+"/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenWochen(id,start,end) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenWochen/from/"+start.toISOString()+"/to/"+end.toISOString()+"/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenMonate(id,start,end) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenMonate/from/"+start.toISOString()+"/to/"+end.toISOString()+"/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetTagesverlauf(id, start, end, kassen) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/DurchschnittlicheVerkaufszahlenProStunde/from/"+start.toISOString()+"/to/"+end.toISOString()+"/"+kassen , await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenData(id,datum,KassenID,methode) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenData/"+datum+"/kassenID/"+KassenID+"/methode/"+methode, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenDataTage(id,datum,KassenID,methode) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenDataTage/"+datum+"/kassenID/"+KassenID+"/methode/"+methode, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenDataWochen(id,datum,KassenID,methode) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenDataWochen/"+datum+"/kassenID/"+KassenID+"/methode/"+methode, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetBelegzeilenDataMonat(id,datum,KassenID,methode) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/BuchungszeilenDataMonat/"+datum+"/kassenID/"+KassenID+"/methode/"+methode, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetDetails(id) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/WarenbestandDetails", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async GetProduktgruppeninfo(id) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/Produktgruppen", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	async getVerkaufsstart(id) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktverlauf/"+id+"/Verkaufsstart", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}

	async downloadBuchungstabelle(data, spalten) {
		console.log("hier")
		console.log(data)
		return fetch(CONFIG.ServerUrl + "/Produktverlauf/export/spalten/"+spalten,{
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		}).then(response => {
			console.log(response)
			console.log(response.ok)
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Produktauswertungen.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	downloadBlob(blob, name) {
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = name;
		window.document.body.append(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		a.remove();
	}
}