import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class KundenService {
	async getAll() {
		return axios.get(CONFIG.ServerUrl + '/Kunden', await GetAxiosConfig()).then(res => res.data);
	}

	async new(kunde) {
		return axios.post(CONFIG.ServerUrl + '/Kunde/New', kunde, await GetAxiosConfig()).then(res => res);
	}

	async delete(id) {
		console.log(id)
		return axios.get(CONFIG.ServerUrl + '/Kunde/Delete/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async deactivate(id) {
		return axios.get(CONFIG.ServerUrl + "/Kunde/Deactivate/" + id, await GetAxiosConfig()).then(res => res.data);
	}

	async getNeueKundennummer() {
		return axios.get(CONFIG.ServerUrl + "/Kunde/NeueKundennummer/", await GetAxiosConfig()).then(res => res.data);
	}

	async getKundenBarcode() {
		return axios.get(CONFIG.ServerUrl + "/Kunde/getKundenBarcode/", await GetAxiosConfig()).then(res => res.data);
	}

	async getKundeByGuid(id) {
		return axios.get(CONFIG.ServerUrl + '/KundeByGuid/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async update(kunde) {
		return axios.post(CONFIG.ServerUrl + '/KundeUpdate/',kunde , await GetAxiosConfig()).then(res => res);
	}

	async getProdukte() {
		return axios.get(CONFIG.ServerUrl + '/getProdukte/' , await GetAxiosConfig()).then(res => res.data);
	}

	async getVergleichsDataVerkauf(month, kassa, kunden){
		const requestData = {
			kassa: kassa,
			kunden: kunden
		};

		return axios.post(CONFIG.ServerUrl + '/getVergleichsDataVerkauf/' + month, requestData , await GetAxiosConfig()).then(res => res.data);
	}

	async getMonatsVergleich(month, year, kassa){
		return axios.post(CONFIG.ServerUrl + '/getMonatsVergleich/'+ month + "/" + year , kassa,  await GetAxiosConfig()).then(res => res.data);
	}
}