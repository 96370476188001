import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class GutscheinService {
    async getAll() {
        return axios.get(CONFIG.ServerUrl + '/Gutscheine', await GetAxiosConfig())
                .then(res => res.data);
    }

    async create(menge, betrag){
        console.log(menge);
        return axios.get(CONFIG.ServerUrl + '/Gutscheine/neu/'+menge+'/'+betrag, await GetAxiosConfig()).then(res => res.data);
    }
}