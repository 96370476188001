import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function AdresseColumn(props) {

	const onAdresseChange = (e) => {
		props.setSelectedAdresse(e.target.value);
		props.datatableref.current.filter(e.target.value, 'ReStrasse', 'in');
	}

	return (
		<Column header="Adresse" field="ReStrassen" style={{width: "200px", maxWidth:"12rem"}} filter 	sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedAdresse} options={props.Adressen} optionLabel="ReStrassen" optionValue='ReStrassen' onChange={onAdresseChange}
			placeholder={"Alle"} filter/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body = {props.body}
		/>
	);
}
