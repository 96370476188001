import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { countryOptionTemplate } from '../Laenderdropdown';

export default function LandColumn(props) {


	const onLandChange = (e) => {
		props.setSelectedLand(e.target.value);
		props.datatableref.current.filter(e.target.value, "ReLand", "in");
	}

	return (
		<Column header="Land" field="ReLand" style={{width: "200px", maxWidth:"6rem"}} filter sortable filterMatchMode="in"
		filterElement={
			<MultiSelect value={props.selectedLand} options={props.Laender} optionLabel="code" optionValue='code' onChange={onLandChange}
			placeholder={"Alle"} filter/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={row => {
			if (row.ReLand === null || row.ReLand === undefined){
				return;
			}
			return countryOptionTemplate(row.ReLand);
		}}
		/>
	);
}