import React, { useState, useEffect, useContext, useRef } from "react";
import { LogService } from "../service/LogService";
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { KassenService } from '../service/KassenService'
import LogDiagramm from "./LogDiagramm";
import LogJahresdiagramm from "./LogJahresdiagramm";
import { FilterService } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from "../service/ExportService";

function LogsZusammengefasst(props) {
    const logService = new LogService();
    const kassenService = new KassenService();
    const [kassen, setKassen] = useState([]);
    const [logs, setLogs] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [datumsbereich, setDatumsbereich] = useState(null);
    const dt = useRef(null);


    const filters = {
        'Zeit': { value: null, matchMode: "timeFilter" }
    };

    useEffect(() => {
		setGlobalState({...globalState, page: 'Fehlerübersicht Zusammengefasst'});
        setDatumsbereich([new Date(), new Date()]);
        kassenService.getKassen().then(data => setKassen(data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (datumsbereich !== undefined && datumsbereich !== null)
            logService.getLogsVonBis(
                datumsbereich[0],
                datumsbereich[1]).
                then(data => {
                    if (data === undefined || data === null)
                        return data;
                    let logs = data.logs.map(log =>
                    ({...log,
                        Datum: new Date(log.Timestamp).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                        Zeit: new Date(log.Timestamp).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                    }));
                    let ret = {...data, logs: logs};
                    setLogs(ret);
                });
    }, [datumsbereich])

	const renderDatumFilter = () => {
		return (
			<Calendar id="datumsbereich" className="column-filter" value={datumsbereich} onChange={onDatumsbereichChange} showIcon={true} dateFormat="dd.mm.yy" selectionMode="range"/>
		);
	}

    const onDatumsbereichChange = e => {
		let _target = [new Date(e.target.value[0].setHours(0,0,0)), e.target.value[1] === null ? null : new Date(e.target.value[1]?.setHours(0,0,0))];
        setDatumsbereich(_target);
	}


    const formatDate = (value) => {
        return value?.toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'});
    }


    const datumFilter = renderDatumFilter();



    return (
        <React.Fragment>
            <div className="grid p-component">
                <div className="col-12">
                    <DataTable ref={dt}
                        value={logs?.logs}
                        responsive="true"
                        className="p-datatable-produkte p-datatable-striped tableCursorPointer" dataKey="id"
                        rowHover paginator rows={20}
                        filters={filters}
                        emptyMessage="keine Logs gefunden"
                        currentPageReportTemplate='({first} von {totalRecords})'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]} sortField={props.dashboard !== undefined ? 'AufzMenge' : undefined}
                        sortOrder={props.dashboard !== undefined ? -1 : undefined}
                        //onRowClick={onRowClicked}
                        filterDisplay="row"
                        /* expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} */
                        groupRowsBy="Bezeichnung"
                        scrollable scrollHeight="60vh">
                        <Column
                            field="Timestamp"
                            showFilterMenu={false}
                            body={row =>  formatDate(new Date(row.Timestamp))}
                            style={{maxWidth: "16em", width: "16em"}}
                            sortable
                            filter
                            filterElement={datumFilter}
                            header="Datum"
                            />
                       
                    </DataTable>
                    {/**
                    <div  style={{textAlign: "right"}}>
                        <Button label="Fehlerliste exportieren" icon="pi pi-download" style={{marginTop: "1rem"}} onClick={() => {setExportOptions({
                            ID:false,
                            Datum: false,
                            Standort: false,
                            Fehlertyp: false,
                            Details: false
                        }); setExportDialogVisible(true)}} />
                    </div>
                     */}
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default LogsZusammengefasst;