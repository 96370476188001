import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function MobilColumn(props) {

	const onMobilChange = (e) => {
		props.setSelectedMobil(e.target.value);
		props.datatableref.current.filter(e.target.value, "Mobil", "in");
	}

	return(
		<Column header="Mobil" field="ErstePersonMobil" style={{width: "200px", maxWidth:"15rem"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedMobil} options={props.Mobil} optionLabel="ErstePersonMobil" optionValue='ErstePersonMobil' onChange={onMobilChange} filter
					placeholder={"Alle"} />
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={props.body}
		/>
	)
}