import axios from 'axios';
import { GetToken, GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class SyncService {
	async syncKasse(kasse) {
		return fetch(CONFIG.ServerUrl + '/Sync/SyncKasse/' + kasse, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if(!response.ok) throw new Error('There was an not OK response.');
			return response;
		});
	}

	async syncStatus() {
		return axios.get(CONFIG.ServerUrl + '/Sync/Status', await GetAxiosConfig()).then(res => res.data).catch((e) => {});
	}

	async forceSync(url) {
		console.log(url);
		return axios.get(url + '/Sync/Force', await GetAxiosConfig()).then(res => res.data);
	}
}