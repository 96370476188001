import React, { useState, useEffect, useRef, useContext } from 'react';
import { KassenService } from '../service/KassenService';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { addDE } from './LocalDE';
import { GlobalState } from './GlobalState';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';

function Wochenberichte(props) {
	const [kassen, setKassen] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [berichtDatum, setBerichtDatum] = useState(null);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [showSpinner, setShowSpinner] = useState(false);
	const [kundenSwitch, setKundenSwitch] = useState(false);
	const kassenService = new KassenService();
	const exportService = new ExportService();

	const toastRef = useRef(null);

	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1850);

	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1850);
	};

	useEffect(() => {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
	}, []);

	addDE();

	const onErstelleBerichtClick = () => {
		if(berichtDatum === undefined || berichtDatum === null) return;

		
		if(kundenSwitch === false){
			setShowSpinner(true);
			const bis = new Date(berichtDatum.getTime());
			bis.setDate(bis.getDate() + 6);
			exportService.downloadBericht(berichtDatum, bis, Object.entries(selectedKassen).filter(kasse => kasse[1])
				.map(kasse => kasse[0]), berichtDatum.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('')
				+ "-" + bis.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_Wochenbericht").then(() => {
				setShowSpinner(false);
			}).catch(() => {
				setShowSpinner(false);
				toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
			});
		}
		else if(kundenSwitch === true){
			setShowSpinner(true);
			const bis = new Date(berichtDatum.getTime());
			bis.setDate(bis.getDate() + 6);

			console.log(berichtDatum, bis, Object.entries(selectedKassen).filter(kasse => kasse[1])
			.map(kasse => kasse[0]), berichtDatum.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('')
			+ "-" + bis.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_Wochenbericht")

			exportService.downloadBerichtKunde(berichtDatum, bis, null, null, Object.entries(selectedKassen).filter(kasse => kasse[1])
				.map(kasse => kasse[0]), berichtDatum.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('')
				+ "-" + bis.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_Wochenbericht").then(() => {
				setShowSpinner(false);
			}).catch(() => {
				setShowSpinner(false);
				toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
			});
		}
	};

	useEffect(() => {
		setGlobalState({...globalState, page: 'Wochenberichte'});
		kassenService.getKassen().then(data => setKassen(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid p-component">
				<div className= {isLargeScreen ? "col-2" : "col-12"}>
					<div className="field">Standorte:</div>
					{kassen.map((kassa) => <React.Fragment>
						<div className="field">
							<Checkbox inputId={kassa.KassenID} checked={selectedKassen[kassa.KassenID]} onChange={e => setSelectedKassen({...selectedKassen, [kassa.KassenID]: e.target.checked})}/>
							<label htmlFor={kassa.KassenID} className="mb-0 ml-1">{kassa.KassenID}</label>
						</div>
					</React.Fragment>)}
					<Calendar value={berichtDatum} onChange={(e) => setBerichtDatum(e.value)} disabledDays={[0,2,3,4,5,6]} readOnlyInput selectOtherMonths showWeek={true} showIcon={true} locale="de"/><br/><br/>
					<SelectButton                                
						value={kundenSwitch}
						onChange={(e) => setKundenSwitch(e.value)}
						options={[{label: "Lieferantenbericht", value: false}, {label: "Kundenbericht", value: true}]} 
						autoFocus={false} 
						style={{verticalAlign:"middle", marginBottom:"2rem"}}
						hidden = {isLargeScreen}
						/>
					<Button label="Erstelle Bericht" icon="pi pi-check" onClick={onErstelleBerichtClick}/>
					{showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
				</div>

				{ isLargeScreen && (
					<div className="col" style={{marginTop:"3rem"}}>
						<SelectButton                                
							value={kundenSwitch}
							onChange={(e) => setKundenSwitch(e.value)}
							options={[{label: "Lieferantenbericht", value: false}, {label: "Kundenbericht", value: true}]} 
							autoFocus={false} 
							style={{verticalAlign:"middle", marginLeft:"1rem", marginRight:"1rem"}}
							/>
					</div>
				)}
			</div>
		</React.Fragment>
	);
}
export default Wochenberichte;