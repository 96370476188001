import React, { useState, useRef, useEffect, useContext } from "react";
import { InlineIcon } from '@iconify/react';
import barcodeScanner24Filled from '@iconify-icons/fluent/barcode-scanner-24-filled';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProduktService } from '../service/ProduktService';
import { AutoComplete } from 'primereact/autocomplete';
import { EtikettenService } from "../service/EtikettenService";
import { Wareneingangservice } from "../service/Wareneingangsserice";
import { GlobalState } from './GlobalState';
import { Dialog } from "primereact/dialog";
import { RabattService } from "../service/RabattService";
import { RabattAuswahlDialog } from "./RabattAuswahlDialog"
import { Checkbox } from "primereact/checkbox";
import { BestaendeService } from "../service/BestaendeService";
import { Toast } from "primereact/toast";

import CONFIG from 'config';

function getQueryVariable(variable)
{
	try {
		var query = window.location.toString();
		var query1 = query.split("?");
		var vars = query1[1].split("&");
		for (var i=0;i<vars.length;i++) {
					var pair = vars[i].split("=");
					console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
			if(pair[0] === variable){return pair[1];}
		 }
		 return(false);
	} catch (e) {
		return false;
	}
}

function Wareneingang3(props)
{
	const [Scan, setScan] = useState(true);
	const [Produktsuche, setProduktsuche] = useState(false);
	const [Produkt, setProdukt] = useState(null);
	const [Grund] = useState(null);
	const [produkte, setProdukte] = useState([]);
	const [Gruende, setGruende] = useState([]);
	const [filteredBezeichnungen] = useState([]);
	const [selectedBezeichnung, setSelectedBezeichnung] = useState(null);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [preisEtikettDialog, setPreisEtikettDialog] = useState(false);
	const [regalEtikettDialog, setRegalEtikettDialog] = useState(false);
	const [rabattEtikettDialog, setRabattEtikettDialog] = useState(false);
	const [editSollBestandVisible, setEditSollBestandVisible] = useState(false);
	const [sollBestand, setSollBestand] = useState(0);
	const [warnBestand, setWarnBestand] = useState(0);
	const [editWarnBestandVisible, setEditWarnBestandVisible] = useState(false);
	const [anzahlEtiketten, setAnzahlEtiketten] = useState(1);
	const [rabatt, setRabatt] = useState({});
	const [PreisDrucken, setPreisDrucken] = useState(true);
	const [eingaenge, setEingaenge] = useState([]);
	const [scannedBarcode, setScannedBarcode] = useState('');
	const produktservice = new ProduktService();
	const etikettservice = new EtikettenService();
	const wareneingangsservice = new Wareneingangservice();
	const rabattService = new RabattService();
	const bestaendeService = new BestaendeService();
	let dt = useRef(null);
	const toast = useRef(null);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Wareneingang'});
		let produktPromise = produktservice.getProdukte();
		produktPromise.then(data => setProdukte(data));
		var ean = getQueryVariable('ean');
		if (ean !== false) {
			onScan(ean);
		}
		var qr = getQueryVariable('qr');
		if (qr !== false) {
			onScan(qr);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const Gruende1 = [
		{label: '\u00A0', value: ''},
		{label: 'Schwund', value: 'Schwund'},
		{label: 'Verderb', value: 'Verderb'},
		{label: 'Beschädigt', value: 'Beschaedigt'},
		{label: 'Eigenverbrauch', value: 'Eigenverbrauch'},
		{label: 'Bestandskorrektur', value: 'Bestandskorrektur'},
		{label: 'zurück an Lieferanten', value: 'zurück an Lieferanten'}
	];
	
	const Gruende2 = [
		{label: '\u00A0', value: ''},
		{label: 'nicht vorhanden', value: 'na'},
		{label: 'Bestandskorrektur', value: 'Bestandskorrektur'}
	]
	
	const Gruende3 = [
		{label: '\u00A0', value: ''},
		{label: 'zusätzliche Befüllung', value: 'zusaetzliche Befuellung'},
		{label: 'Bestandskorrektur', value: 'Bestandskorrektur'},
		{label: 'Schwund', value: 'Schwund'},
		{label: 'Verderb', value: 'Verderb'},
		{label: 'Beschädigt', value: 'Beschaedigt'},
		{label: 'Eigenverbrauch', value: 'Eigenverbrauch'},
	]

	const onScan = (e) => {
		if (e.length > 2 && e[e.length-1] == '/' && e[e.length-2] == '#')
			e = e.substring(0, e.length-2);
		console.log("onScan:" + e);
		setProdukt(null);
		setScan(true);
		setProduktsuche(false);

		produktservice.getProduktWareneingangByBarcode(e).then(
			(data) => {
				setProduktData(data);
				setScannedBarcode(e);
			}).catch((x) => 
			{
				alert("Barcode "+ e + " nicht gefunden");
				window.history.replaceState('', 'Wareneingang', CONFIG.FrontendServerUrl+'/wareneingang')
			});
		window.history.replaceState('', 'Wareneingang', CONFIG.FrontendServerUrl+'/wareneingang')
	}

	const onProduktsucheClick = (e) => {
		setScan(false);
		setProduktsuche(true);
	}

	const onKeyPress = (e) => {
		console.log(e);
	}

	const bezeichnungFilter = () => {
		return (
				<AutoComplete
					value={selectedBezeichnung} suggestions={filteredBezeichnungen}
					placeholder="Bezeichnung" minLength={1} onChange={onBezeichnungChange}
					inputStyle={{width: '100%'}}
					style={{width: '100%'}}
					onKeyPress={onKeyPress}
				/>
		);
	}

	const onBezeichnungChange = (event) => {
		dt.current.filter(event.value, 'Bezeichnung', 'contains');
		setSelectedBezeichnung(event.value);
	}

	const onCheckClick = (e) => {
		console.log(e);
		produktservice.getProduktWareneingang(e.ID).then(data => setProduktData(data)).catch(e => {
			setScan(false);
			setProduktsuche(true);
		});
		setScan(false);
		setProduktsuche(false);
	}

	const setProduktData = (data) => {
		setProdukt(data);
		console.log(Produkt);
		setSollBestand(data.Sollbestand ?? 0);
		setWarnBestand(data.Warnbestand ?? 0);
		setScan(false);
		setProduktsuche(false);
	}

	const actionBodyTemplate = (rowData) => {
		return (
			//<Button type="button" icon="pi pi-pencil" className="p-button-secondary" onClick={onEditClick}></Button>
			<Button type="button" icon="pi pi-check" className="p-button-secondary" onClick={() => onCheckClick(rowData)}></Button>
		);
	};

	const onEtikettDruckenClick = (e) => {
		if (Produkt === undefined || Produkt === null) return;

		setPreisEtikettDialog(true);
		setAnzahlEtiketten(1);
	}
	
	const onRegalEtikettDruckenClick = (e) => {
		if (Produkt === undefined || Produkt === null) return;

		setRegalEtikettDialog(true);
		setAnzahlEtiketten(1);
	}

	const onRabattEtikettDruckenClick = () => {
		setRabattEtikettDialog(true);
		return;
	}

	const preisEtikettDrucken = () => {
		etikettservice.printEtikettServer(Produkt.ProduktID, anzahlEtiketten, PreisDrucken);
	}

	const regalEtikettDrucken = () => {
		etikettservice.printRegalEtikettServer(Produkt.ProduktID, anzahlEtiketten);
	}

	const onSucheAbbrechenClick = (e) => {
		setProduktsuche(false);
		setScan(true);
		setProdukt(null);
		setSelectedBezeichnung("");
		setEingaenge([]);
		setScannedBarcode('');
	}

	const onEinlagernClick = (e) => {
		wareneingangsservice.new(Produkt);
		setProdukt(null);
		setSelectedBezeichnung(null);
		setScan(true);
		setProduktsuche(false);
		setEingaenge([]);
		setScannedBarcode('');
	}

	const onShopClick = (e) => {
		window.location="pic2shop://scan?callback="+CONFIG.FrontendServerUrl+"/%23wareneingang/";
		window.close();
	}

	const changeGrund = (_grund, _gruende) =>
	{
		var found = _gruende.find(element => element.value === _grund);
		try {
			if (found === undefined)
				return undefined;
			if (_grund.length === 0)
				return undefined;
			return _grund;
		} catch (e)
		{
			console.log(e);
		}
	}
	
	const onMengeChange = (e) => {
		var _gruende = [];
		if (e.value < 0) {
			_gruende = Gruende1;
			setProdukt({...Produkt, Grund: 'Schwund'});
		} else if (((e.value + Produkt.Bestand) > Produkt.Sollbestand))
			_gruende = Gruende3;
		else if(Produkt.Aufzufuellen < 0)
			_gruende = Gruende1;
		else
			_gruende = Gruende2;
		setGruende(_gruende);
		setProdukt({...Produkt, Aufzufuellen: (e.value), Grund: changeGrund(Produkt.Grund, _gruende)});
		eingangHinzufuegen(Produkt, e.value);
	}

	const eingangHinzufuegen = (selectedProdukt, menge) => {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.Bezeichnung === '') return;

		console.log(selectedProdukt);
		let eingang = {
			Produkt: selectedProdukt.ProduktID,
			Menge: menge,
			Datum: new Date().toISOString(),
			EK: selectedProdukt.EK,
			Grund: selectedProdukt.Grund
		};
		let _eingaenge = [];
		console.log(eingang);
		wareneingangsservice.GetAddWareneingang(eingang, CONFIG.ServerUrl)
		.then(x => {
			console.log(x);
			x.forEach(eingang => {
				let _eingang = {
					Bezeichnung: selectedProdukt.Bezeichnung,
					Menge: eingang.Menge,
					Einheit: selectedProdukt.Einheit,
					Produkt: selectedProdukt.ProduktID,
					Grund: Grund
				}
				_eingaenge.push(_eingang);
			});
			console.log(_eingaenge);
			setEingaenge([..._eingaenge]);
		})
		.catch((e) => {console.log(e)});
		return;		
	};

	const rabattButtonClick = (e) => {
        setRabattEtikettDialog(false);
		var _rabatt = {
            ...rabatt,    // keep all other key-value pairs
            Rabatt: e,
            ProduktID: Produkt.ProduktID,      // update the value of specific key
			Barcode: (scannedBarcode?.length > 0) ? scannedBarcode : Produkt.Barcode, 
        };
        setRabatt(_rabatt);
		console.log("Rabatt: " + _rabatt);
        rabattService.appCreateRabatt(_rabatt);
    }

	const bezeichnungsFilter = bezeichnungFilter();

	const changeSollbestand = () => {
		bestaendeService.changeSollbestand(Produkt.ProduktID, sollBestand).then((e) => {
			setProdukt({...Produkt, Sollbestand: sollBestand});
		})
		.catch((e) => {
			toast.current.show({severity:'error', summary: 'Fehler',
			detail:'Sollbestand konnte nicht geändert werden.', life: 3000});
		})
	
		return;
	}

	const changeWarnbestand = () => {
		bestaendeService.changeWarnbestand(Produkt.ProduktID, warnBestand).then((e) => {
			setProdukt({...Produkt, Warnbestand: warnBestand});
		})
		.catch((e) => {
			toast.current.show({severity:'error', summary: 'Fehler',
			detail:'Warnbestand konnte nicht geändert werden.', life: 3000});
		})
	
		return;
	}

	const GrundAnzeigen = (_grund, menge) => {
		if (_grund === undefined || _grund === null || _grund.length === 0)
		{
			if (menge < 0)
				return "Auslagern";
			else
				return "Einlagern;"
		}
		else
			return _grund;
	}

	return (
		<React.Fragment>
			<Toast ref={toast} />
			<Dialog visible={preisEtikettDialog}
				onHide={() => setPreisEtikettDialog(false)} style={{ width: '350px' }} header="Anzahl?"
				footer={<div><Button className="p-button-rounded p-button-success" label="Drucken" icon="pi pi-check"
					onClick={(e) => {setPreisEtikettDialog(false); preisEtikettDrucken(); }}/><Button className="p-button-rounded p-button-danger" label="Abbrechen" icon="pi pi-times" onClick={(e) => {setPreisEtikettDialog(false)}}/></div>}>
				<div className="grid">
					<div className="col-12">
						<div className="grid">
							<div className="col-12">
								<div className="p-d-block p-pb-4">Wie viele Etiketten sollen gedruckt werden?</div>
							</div>
							<div className="col-12">
								<div className="p-d-block">
									<InputNumber value={anzahlEtiketten}
										onValueChange={(e) => setAnzahlEtiketten(e.target.value)}
										mode="decimal" showButtons
										buttonLayout="vertical" style={{width: '6em'}}
										decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
										incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="grid">
							<div className="p-d-block">
								<Checkbox checked={PreisDrucken} onChange={(e) => setPreisDrucken(e.checked)} />
							</div>
							<div className="p-d-block ml-1 p-pb-4">Preis mitdrucken?</div>
						</div>
					</div>
				</div>
			</Dialog>
			<Dialog visible={regalEtikettDialog}
				onHide={() => setRegalEtikettDialog(false)} style={{ width: '350px' }} header="Anzahl?"
				footer={<div><Button className="p-button-rounded p-button-success" label="Drucken" icon="pi pi-check" onClick={(e) => {setRegalEtikettDialog(false); regalEtikettDrucken(); }}/><Button className="p-button-rounded p-button-danger" label="Abbrechen" icon="pi pi-times" onClick={(e) => {setRegalEtikettDialog(false)}}/></div>}>
				<div className="p-d-block p-pb-4">Wie viele Regaletiketten sollen gedruckt werden?</div>
				<div className="p-d-block">
					<InputNumber value={anzahlEtiketten} onValueChange={(e) => setAnzahlEtiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
					decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
				</div>
				{ Produkt?.Herkunft === 0 &&
					<div className="p-d-block p-pt-4 "><strong style={{color: "red"}}>ACHTUNG!!!</strong><br/>Das Produkt ist Regional! Nicht vergessen das Etikett auf das richtige Regalschild zu kleben.</div>
				}
			</Dialog>
			<Dialog visible={editSollBestandVisible}
				onHide={() => setEditSollBestandVisible(false)}
				header="SOLL-Bestand ändern"
				footer={<div>
					<Button className="p-button-rounded p-button-success" label="Speichern" icon="pi pi-save" 
					onClick={() => {changeSollbestand(); setEditSollBestandVisible(false)}}/>
					<Button className="p-button-rounded p-button-danger" label="Abbrechen" icon="pi pi-times"
					onClick={() => {setEditSollBestandVisible(false)}}/>
					</div>}
				style={{ width: '350px'}}
				>
					SOLL-Bestand:
					<InputNumber
							value={sollBestand}
							onValueChange={(e) => setSollBestand(e.value)}
							mode="decimal" showButtons buttonLayout="horizontal" style={{width: '6em'}}
							decrementButtonClassName="p-button-danger"
							incrementButtonClassName="p-button-success"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							inputStyle={{width: '60px', textAlign: 'center'}}
							className="ml-2"
							/>
			</Dialog>
			<Dialog visible={editWarnBestandVisible}
				onHide={() => setEditWarnBestandVisible(false)}
				header="Warnbestand ändern"
				footer={<div>
					<Button className="p-button-rounded p-button-success" label="Speichern" icon="pi pi-save" 
					onClick={() => {changeWarnbestand(); setEditWarnBestandVisible(false)}}/>
					<Button className="p-button-rounded p-button-danger" label="Abbrechen" icon="pi pi-times"
					onClick={() => {setEditWarnBestandVisible(false)}}/>
					</div>}
				style={{ width: '350px'}}
				>
					Warnbestand:
					<InputNumber
							value={warnBestand}
							onValueChange={(e) => setWarnBestand(e.value)}
							mode="decimal" showButtons buttonLayout="horizontal" style={{width: '6em'}}
							decrementButtonClassName="p-button-danger"
							incrementButtonClassName="p-button-success"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							inputStyle={{width: '60px', textAlign: 'center'}}
							className="ml-2"
							/>
			</Dialog>
			<div className="grid">
				{(Scan)  && 
					<React.Fragment>
						<div className="col-12" style={{textAlign: "center"}}>
							<Button label="Produkt suchen" icon="pi pi-search" onClick={onProduktsucheClick} style={{fontSize: '2em'}} />
						</div>

						<div className="col-12 mt-5" style={{textAlign: "center"}}>
							<Button onClick={onShopClick} style={{fontSize: '2em'}}>
								<i className="pi"><InlineIcon width='2em' height='2em' icon={barcodeScanner24Filled}/></i>
								<span class="p-pl-2">Code scannen</span>
							</Button>
						</div>              
					</React.Fragment>
				}
				{(Produktsuche) &&
				<React.Fragment>
					<div className="col-12">
						<Button label="Abbrechen" icon="pi pi-times" onClick={onSucheAbbrechenClick} />
					</div>
				<div className="col-12">
					<DataTable ref={dt} value={produkte}
								responsive className="p-datatable-produkte" dataKey="id" rowHover
								filterDisplay="row" 
								paginator rows={20} emptyMessage="keine Produkte gefunden" currentPageReportTemplate='({first} von {totalRecords})'
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[20,30,40]}
							>
							<Column field="ID" bodyStyle={{display: "none"}} headerStyle={{display:"none"}} filterHeaderStyle={{display:"none"}} />
							<Column body={actionBodyTemplate} headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
							<Column field="Bezeichnung" bodyStyle={{width: "100%"}} headerStyle={{width:"100%"}} filterHeaderStyle={{width:"100%"}} filter
								filterPlaceholder="Bezeichnung eingeben" header="Bezeichnung" filterElement={bezeichnungsFilter} showFilterMenu={false}
								/>
					</DataTable>
				</div>
				</React.Fragment>
				}
				{(Scan === false) && (Produktsuche === false) && (Produkt != null) &&
					<React.Fragment>
						<RabattAuswahlDialog
							visible={rabattEtikettDialog}
							buttonClick={rabattButtonClick}
							Grund={rabatt.Grund}
							onGrundChange={(e) => setRabatt(prevRabatt => ({...prevRabatt, Grund: e.value }))}
							setVisible={(e) => setRabattEtikettDialog(e)}
							onHide={() => {
								setRabatt(prevRabatt => ({...prevRabatt,
									Grund: undefined,
									Verderbdatum: undefined
								}));							
								setRabattEtikettDialog(false);
							}}
							onAbbrechen={() => {
								setRabatt(prevRabatt => ({...prevRabatt,
									Grund: undefined,
									Verderbdatum: undefined
								}));			
							}}
							Kassa={rabatt.KassenID}
							Verderbdatum={rabatt.Verderbdatum}
							onVerderbDatumChange={(e) => setRabatt({...rabatt, Verderbdatum: e.value})}
							onKassaChange={(e) => setRabatt(prevRabatt => ({...prevRabatt, KassenID: e.value}))}
						/>
						<div className="col-12">
							<h1 style={{textAlign: "center", fontSize: "2em"}}><strong>{Produkt ? Produkt.Bezeichnung : ''}</strong></h1>
						</div>
						<div className="col-6" style={{textAlign: "right", fontSize: "1.5em"}}>
							IST-Bestand:
						</div>
						<div className="col-6" style={{fontSize: "1.2em"}}>
							<strong style={{fontSize: "1.5em"}}>{Produkt ? Produkt.Bestand : ''} ({Produkt.Bestand + eingaenge.reduce((accumulator, object) => { return accumulator + object.Menge;}, 0)} n. Einlagerung)</strong>
						</div>
						<div className="col-6" style={{textAlign: "right", fontSize: "1.2em", alignSelf: "center"}}>
							SOLL-Bestand:
						</div>
						<div className="col-6" style={{fontSize: "1.2em", display: "flex", alignContent: "center"}}>
								<div style={{marginTop: "auto", marginBottom: "auto"}}>{Produkt.Sollbestand}</div>
								<div><Button className="ml-2" icon="pi pi-pencil" aria-label="Sollbestand bearbeiten" onClick={() =>
									{
										setSollBestand(Produkt.Sollbestand);
										eingangHinzufuegen(Produkt, Produkt.Aufzufuellen);
										setEditSollBestandVisible(true);
									}}
								/></div>
						</div>
						<div className="col-6" style={{textAlign: "right", fontSize: "1.2em", alignSelf: "center"}}>
							Warnbestand:
						</div>
						<div className="col-6" style={{fontSize: "1.2em", display: "flex", alignContent: "center"}}>
							<div style={{marginTop: "auto", marginBottom: "auto"}}>{Produkt.Warnbestand ?? 0}</div>
							<div><Button className="ml-2" icon="pi pi-pencil" aria-label="Warnbestand bearbeiten" onClick={() => {
								setWarnBestand(Produkt.Warnbestand);
								eingangHinzufuegen(Produkt, Produkt.Aufzufuellen);
								setEditWarnBestandVisible(true);
								}} /></div>
						</div>
						<div className="col-6" style={{textAlign: "right", fontSize: "1.5em"}}>
							vorg. Einlagerung:
						</div>
						<div className="col-6" style={{fontSize: "1.2em"}}>
							<strong style={{fontSize: "1.5em"}}>{Produkt ? (Produkt.Sollbestand - Produkt.Bestand) : ''}</strong>
						</div>
						<div className="col-6" style={{textAlign: "right", fontSize: "1.5em", alignSelf: "center"}}>
							Einlagerungsmenge:
						</div>
						<div className="col-6" style={{fontSize: "1.2em"}}>
							<InputNumber
							value={Produkt.Aufzufuellen}
							onValueChange={onMengeChange}
							mode="decimal" showButtons buttonLayout="horizontal" style={{width: '6em'}}
							decrementButtonClassName="p-button-danger"
							incrementButtonClassName="p-button-success"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							inputStyle={{width: '60px', textAlign: 'center', fontSize: "1.5em"}} />
						</div>

						<div className="col-12" style={{textAlign: "center", fontSize: "1.2em"}}>
							<div>
							{(Produkt.Aufzufuellen !== 0 && (Produkt.Sollbestand !== (Produkt.Bestand + Produkt.Aufzufuellen))) &&
								<React.Fragment>
									Abweichungsgrund: <Dropdown optionLabel="label" value={Produkt.Grund} options={Gruende}
									onChange={(e) => {
										let _produkt = {...Produkt, Grund: (e.value)};
										setProdukt(_produkt);
										eingangHinzufuegen(_produkt, Produkt.Aufzufuellen);
										}} placeholder="Grund auswählen"/>
								</React.Fragment>
							}
							</div>
						</div>
						
						<div className="col-12" style={{textAlign: "center", fontSize: "1.2em"}}>
							<div className="grid">
								<div className="col-3"></div>
								<div className="col-6">
									<h3>auslösende Lagervorgänge</h3>
								{eingaenge.map((item) => {
										console.log(item);
										if (item.Menge === 0)
											return <React.Fragment></React.Fragment>
										if (item.Menge > 0)
											return <React.Fragment><span>Einlagern: {item.Menge}</span><br/></React.Fragment>
										else
											return <React.Fragment><span>{GrundAnzeigen(Produkt.Grund,item.Menge)}: {item.Menge}</span></React.Fragment>;
									})
								}
								</div>
							</div>
						</div>

						<div className="col-12" style={{textAlign: 'center'}}>
							<Button className="p-button-lg p-button-rounded p-button-success" label="Einlagern" icon="pi pi-check" onClickCapture={onEinlagernClick} style={{fontSize: "2em", width: "250px"}} />
						</div>
						<div className="col-12 mt-5" style={{textAlign: 'center'}}>
							<Button className="p-button-rounded" label="Preisetikett drucken" icon="pi pi-euro" onClick={onEtikettDruckenClick} style={{fontSize: "1.5em", width: "250px"}} />
						</div>
						{(Produkt.Rabattartikel &&
							Produkt.Einheit === 'Stueck' &&
							(Produkt.Barcode?.length === 13  ||
							 (Produkt.Barcode?.length === 7 && scannedBarcode?.length === 13 && (Produkt.BarcodePreisAbhaengig === true || Produkt.BarcodeGewichtsAbhaengig === true)) ||
							 (Produkt.Barcode?.length > 0 && Produkt?.BarcodeGewichtsAbhaengig === false && Produkt?.BarcodePreisAbhaengig === false)
							) &&
							CONFIG.RabattModul === true) &&
							<div className="col-12 mt-1" style={{textAlign: 'center'}}>
								<Button className="p-button-rounded"  label="Rabattetikett drucken" icon="pi pi-percentage" onClick={onRabattEtikettDruckenClick} style={{fontSize: "1.5em", width: "250px"}} />
							</div>
						}

						<div className="col-12 mt-1" style={{textAlign: 'center'}}>
							<Button className="p-button-rounded" label="Regaletikett drucken" icon="pi pi-tag" onClick={onRegalEtikettDruckenClick} style={{fontSize: "1.5em", width: "250px"}} />
						</div>
						<div className="col-12 mt-1" style={{textAlign: 'center'}}>
							<Button className="p-button-danger p-button-rounded p-button-lg" label="Abbrechen" icon="pi pi-times" onClickCapture={onSucheAbbrechenClick} style={{fontSize: "1.5em", width: "250px"}} />
						</div>
					</React.Fragment>
				}
			</div>
		</React.Fragment>
	);
};

export default Wareneingang3;