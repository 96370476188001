import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class MitarbeiterService {
	async getMitarbeiter(id) {
		if (id === undefined || id === null) {
			return axios.get(CONFIG.ServerUrl + '/Mitarbeiter', await GetAxiosConfig()).then(res => res.data);
		} else {
			return axios.get(CONFIG.ServerUrl + '/Mitarbeiter/' + id, await GetAxiosConfig()).then(res => res.data);
		}
	}

	async mitarbeiterLoeschen(id) {
		return axios.get(CONFIG.ServerUrl + '/Mitarbeiter/Loeschen/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async mitarbeiterUpdate(mitarbeiter) {
		return axios.post(CONFIG.ServerUrl + '/Mitarbeiter/Update', mitarbeiter, await GetAxiosConfig()).then(res => res.data);
	}

	async getMitarbeiterMitBenutzername(benutzername) {
		return axios.get(CONFIG.ServerUrl + "/Mitarbeiter/Benutzername/" + benutzername, await GetAxiosConfig()).then(res => res);
	}
}