import React, { useContext, useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { KassensturzService } from "../service/KassensturzService";
import { KassenService } from "../service/KassenService";
import { GlobalState } from './GlobalState';
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Divider } from 'primereact/divider';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from "../service/ExportService";

export default function Kassasturz(props)
{

    const [stuerze, setStuerze] = useState([]);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [kassen, setKassen] = useState([]);
    const [selectedSturz, setSelectedSturz] = useState();
    const [selectedKassen, setSelectedKassen] = useState();
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [selectedDatumsBereich, setSelectedDatumsBereich] = useState([new Date(), new Date()]);
    const [displayStuerze, setDisplayStuerze] = useState();
    const [filteredDatumsBereich, setFilteredDatumsbereich] = useState(null);
    const [haben0DialogVisible, setHaben0DialogVisible] = useState(false);
	const [exportOptions, setExportOptions] = useState({});
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const toast = useRef(null);
    const kassensturzService = new KassensturzService();
    const kassenService = new KassenService();
    const exportService = new ExportService();
    let dt = useRef(null);
    

    useEffect(() => {
		setGlobalState({...globalState, page: 'Kassenstürze'});
		kassenService.getKassen().then(data => setKassen(data));
        kassensturzService.getKassenstuerze().then(data => setStuerze(data.map(
            d => ({...d,
                Datum: new Date(d.Datum),
                Zeit: new Date(d.Datum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                Differenz: d.Haben - d.Soll
        }))));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisplayStuerze(stuerze);
    },[stuerze]);

    const kassensturzExportieren = () => {
        setShowSpinner(true);
        //Backend Download Kassensturz fehlt
		exportService.downloadKassensturz(Object.entries(exportOptions).filter(option => option[1]).map(option => option[0])).then(() => {
			setShowSpinner(false);
            setExportDialogVisible(false);
		}).catch(() => {
			setShowSpinner(false);
			toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
    };

	const actionBodyTemplate = rowData => {
		return (
			<React.Fragment>
				<Button tooltipOptions={{position: 'bottom'}} tooltip="bearbeiten" type="button" icon="pi pi-pencil" className="p-button-secondary mr-1" onClick={() => onEditClick(rowData)}></Button>
			</React.Fragment>
		);
	};

    const onEditClick = (e) => {
        e.Differenz = e.Haben - e.Soll;
        setSelectedSturz(e);
        setEditDialogVisible(true);
    }

    const onSpeichernClick = async (e) => {
        if (selectedSturz?.Haben === 0)
        {
            setHaben0DialogVisible(true);
            return;
        }
        await kassensturzService.speichern(selectedSturz);
        kassensturzService.getKassenstuerze().then(data => 
            {
                let _stuerze = data.map(
                d => ({...d,
                    Datum: new Date(d.Datum),
                    Zeit: new Date(d.Datum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                    Differenz: d.Haben - d.Soll
                }))
                setStuerze(_stuerze);
                setDisplayStuerze(_stuerze);
            }
        )
        .then(setSelectedSturz(
            {...selectedSturz,
                Haben: 0,
                Kommentar: ''}
        ))
        .then(setEditDialogVisible(false));
    }
    
    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'});
    }

    const DatumBodyTemplate = (rowData) => {
        return formatDate(rowData.Datum);
    }

    const onDatumsBereichChange = e => {
		setSelectedDatumsBereich(e.target.value);
        let _target0 = new Date(e.target.value[0]).setHours(0,0,0);
        let _target1 = new Date(e.target.value[1]).setHours(0,0,0);
        let filteredStuerze = stuerze.filter(function(value) {
            let _datum = new Date(value.Datum).setHours(0,0,0,0,0);
            let ret = _datum >= _target0 &&
                _datum <= _target1;
            return  ret;
        });
        setDisplayStuerze(filteredStuerze.map(d => ({...d,
            Datum: new Date(d.Datum)
        })));
	}

    const renderDatumFilter = () => {
		return (
			<Calendar id="datumsbereich" className="column-filter"
            value={selectedDatumsBereich} onChange={onDatumsBereichChange} locale="de"
            showIcon={true} selectionMode="range"/>
        );
	};

    const renderKassenIDFilter = () => {
		return (
			<MultiSelect className="column-filter"
                value={selectedKassen}
                options={kassen}
                onChange={e => { setSelectedKassen(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }}
                placeholder="Alle"
                optionLabel="KassenID"
                optionValue="KassenID"
                maxSelectedLabels={3}/>
		);
	};

    const kassenIDFilter = renderKassenIDFilter();
    const datumFilter = renderDatumFilter();

    const timeFilterFunction = (value, filter) => {
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	};

    const formatNumber = (e) => {
        return e?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    }

    return (
        <div className="grid p-component">
            <div className="col-12">
                <DataTable
                    filterDisplay="row"
                    ref={dt} value={displayStuerze} rowHover responsive
                    className="p-datatable-kassasturz editable-cells-table" 
                    paginator rows={10}
                    editMode="cell"
                    emptyMessage="keine Kassastürze vorhanden"
                    >
                    <Column field="KassenID" style={{width: "20em"}} filter filterPlaceholder="Kassa eingeben"
                        sortable header="Standort"
                        filterMatchMode="contains" filterElement={kassenIDFilter} showFilterMenu={false}
                        />
                    <Column field="Datum" body={DatumBodyTemplate} header="Datum" style={{maxWidth: '18em', width: '18em'}} sortable
                        filter filterElement={datumFilter} showFilterMenu={false}
                        />
                    <Column field="Zeit" header="Zeit" filter sortable style={{width: "9em"}} showFilterMenu={false}/>
                    <Column field="Soll" header="Soll-BAR" style={{maxWidth: '8em', width: '8em'}}
                        body={row => <React.Fragment>&euro;<span style={{float: "right"}}>{formatNumber(row.Soll)}</span></React.Fragment>}
                        showFilterMenu={false}
                        />
                    <Column field="Haben" header="Ist-BAR" style={{maxWidth: '8em', width: '8em'}} showFilterMenu={false}
                        body={row => <React.Fragment>&euro;<span style={{float: "right"}}>{formatNumber(row.Haben)}</span></React.Fragment>} />
                    <Column field="Differenz" header="Differenz" style={{maxWidth: '8em', width: '8em'}}
                        body={row => <React.Fragment>&euro;<span style={{float: "right"}}><span style={row?.Differenz >= 0 ? {color: 'green'} : {color: 'red'}}>{formatNumber(row.Differenz)}</span></span></React.Fragment>}
                        showFilterMenu={false}
                        />
                    <Column field="Kommentar" header="Kommentar" />
                    <Column header="" field="" body={actionBodyTemplate} style={{width: "4em"}} />
                </DataTable>
                
            <div  style={{textAlign: "right", marginTop:"1rem"}}>
                        <Button label="Kassensturz exportieren" icon="pi pi-download" onClick={() => {setExportOptions({
                            Standort: false,
                            Datum: false,
                            Soll: false,
                            Ist: false,
                            Differenz: false,
                            Kommentar: false
                        }); setExportDialogVisible(true)}} />
                    </div>
                    <Dialog visible={exportDialogVisible} onHide={() => setExportDialogVisible(false)} header="Kassensturz exportieren"
                        footer={
                            <React.Fragment>
                                <Button label="Kassensturz exportieren" icon="pi pi-download" onClick={kassensturzExportieren}/>
                                {showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                            </React.Fragment>}>
                        <div className="field">
                            <Checkbox inputId="Standort" checked={exportOptions.Standort} onChange={e => setExportOptions({...exportOptions, Standort: e.target.checked})} />
                            <label htmlFor="Standort" className="mb-0 ml-1">Standort</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Datum" checked={exportOptions.Datum} onChange={e => setExportOptions({...exportOptions, Datum: e.target.checked})} />
                            <label htmlFor="Datum" className="mb-0 ml-1">Datum</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Soll" checked={exportOptions.Soll} onChange={e => setExportOptions({...exportOptions, Soll: e.target.checked})} />
                            <label htmlFor="Soll" className="mb-0 ml-1">Soll-BAR</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Ist" checked={exportOptions.Ist} onChange={e => setExportOptions({...exportOptions, Ist: e.target.checked})} />
                            <label htmlFor="Ist" className="mb-0 ml-1">Ist-BAR</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Differenz" checked={exportOptions.Differenz} onChange={e => setExportOptions({...exportOptions, Differenz: e.target.checked})} />
                            <label htmlFor="Differenz" className="mb-0 ml-1">Differenz</label><br/>
                        </div>
                        <div className="field">
                            <Checkbox inputId="Kommentar" checked={exportOptions.Kommentar} onChange={e => setExportOptions({...exportOptions, Kommentar: e.target.checked})} />
                            <label htmlFor="Kommentar" className="mb-0 ml-1">Kommentar</label><br/>
                        </div>
                        
                        <Button label="Alle auswählen" onClick={() => setExportOptions({
                            Standort: true,
                            Datum: true,
                            Soll: true,
                            Ist: true,
                            Differenz: true,
                            Kommentar: true
                        })}/>
                    </Dialog>
                <Dialog visible={editDialogVisible} onHide={() => setEditDialogVisible(false)}
                header="Kassensturz bearbeiten"
                footer={
                    <React.Fragment>
                        <Button label="Abbrechen" icon="pi pi-times" onClick={() => setEditDialogVisible(false)} />
                        <Button label="Speichern" icon="pi pi-save" onClick={() => onSpeichernClick()} />
                    </React.Fragment>
                }
                style={{width: '350x'}}
                >
                    <div className="grid">
                        <div className="col-4">
                            <label className="mr-3">Soll-BAR</label>
                        </div>
                        <div className="col-8">
                            <label>{formatNumber(selectedSturz?.Soll)} €</label>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-4">
                            <label>Ist-BAR</label>
                        </div>
                        <div className="col-8">
                            <InputNumber mode="currency" currency="EUR" locale="de-DE" minFractionDigits={2}
                            value={selectedSturz?.Haben}
                            onChange={(e) => setSelectedSturz({...selectedSturz, Haben: e.value, Differenz: (e.value - selectedSturz.Soll)})} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-4">
                            <label className="mr-3">Differenz</label>
                        </div>
                        <div className="col-8" >
                            <label style={selectedSturz?.Differenz >= 0 ? {color: 'green'} : {color: 'red'}}>{formatNumber(selectedSturz?.Differenz)} €</label>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-4">
                            <label>Kommentar</label>
                        </div>
                        <div className="col-8">
                            <InputText value={formatNumber(selectedSturz?.Kommentar)}
                            onChange={e => setSelectedSturz({...selectedSturz, Kommentar: e.target.value})} />
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={haben0DialogVisible} onHide={() => setHaben0DialogVisible(false)}
                    header="Speichern nicht möglich"
                    footer={
                        <div>
                            <Button label="Ok" onClick={() => setHaben0DialogVisible(false)} />
                        </div>
                    }
                >
                    Das Ist-Bar Feld darf nicht leer sein.
                </Dialog>
            </div>
        </div>
    );
}