import axios from "axios";
import { GetAxiosConfig, GetToken } from "./AuthService";
import CONFIG from 'config';

export class ProduktauswertungenService {
	/*
	async GetProduktauswertungen(month,year,kasse) {
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktauswertungen/month/"+month+"/year/"+year+"/kasse/"+kasse+"/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
		*/


		async GetProduktauswertungenWarengruppe(month, year, kasse, warengruppe, lieferanten, tempAll) {
			try {
				const url = CONFIG.ServerUrl + "/Produktauswertungen/month/" + month + "/year/" + year + "/kasse/" + kasse + "/"+tempAll;
				const body = {
					warengruppe: warengruppe,
					lieferanten: lieferanten
				};
		
				return axios.post(url, body, await GetAxiosConfig()).then(res => res.data);
			} catch (e) {
				console.log(e);
			}
		}

		async GetProduktauswertungenWarengruppeTest(month, year, kasse, warengruppe, lieferanten, tempAll, cancelToken) {
			console.log('Abbruchsignal:', cancelToken);

			try {
				const url = CONFIG.ServerUrl + "/Produktauswertungen/month/" + month + "/year/" + year + "/kasse/" + kasse + "/"+tempAll+"/Test";
				const body = {
					warengruppe: warengruppe,
					lieferanten: lieferanten
				};
				const cancelTokenSource = axios.CancelToken.source();


				const response = await axios.post(url, body, { signal: cancelTokenSource.token })
					.then(response => {
						console.log(response);
					})
					.catch(error => {
						if (axios.isCancel(error)) {
							console.log('Request canceled', error.message);
						} else {
							console.log(error);
						}
					});

				cancelTokenSource.cancel("Anfrage abgebrochen");
		
				return response.data;
			} catch (e) {
				if (axios.isCancel(e)) {
					console.log('Request canceled', e.message);
				} else {
					console.log(e);
				}
			}
		}

	
		

	async GetZeitraum(){
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktauswertungen/Zeitraum/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}

	async GetUmsatzdata(netto,day,kassen){
		try {
			return axios.post(CONFIG.ServerUrl + "/Dashboard/Umsatzdata/"+ netto+"/"+day.toISOString()+"/",kassen, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}

	async GetEinheiten(){
		try {
			return axios.get(CONFIG.ServerUrl + "/Produktauswertungen/Einheiten/", await GetAxiosConfig()).then(res => res.data);
		} catch (e) {
			console.log(e);
		}
	}
	
	async downloadPreisliste(spalten) {
		return fetch(CONFIG.ServerUrl + '/Export/Preisliste', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(spalten)
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Preisliste.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}
	
	async downloadProduktauswertungen(month,year,kasse,warengruppe, lieferanten, spalten, all) {

		try {
			const url = CONFIG.ServerUrl + "/Produktauswertungen/month/" + month + "/year/" + year + "/kasse/" + kasse + "/export/" + spalten + "/" + all;
			const body = {
			warengruppe: warengruppe,
			lieferanten: lieferanten
			};

			return fetch(url, {
				method: 'POST',
				headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			}).then(response => {
				if (response.ok) {
					return response.blob().then(blob => {
						if (blob.size === 0) throw new Error("The response was empty.");
						this.downloadBlob(blob, 'Produktauswertungen.xlsx');
					});
				} else {
					throw new Error("There was an not OK response.");
				}
			});
		}
		catch (e) {
			console.log(e);
		}
	}

	downloadBlob(blob, name) {
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = name;
		window.document.body.append(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		a.remove();
	}

}