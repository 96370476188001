import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Divider } from "primereact/divider";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import { ListBox } from "primereact/listbox";
import { ArtikelgruppenService } from "../service/ArtikelgruppenService";
import { ProduktService } from "../service/ProduktService";
import { KassenService } from "../service/KassenService";
import { Toast } from "primereact/toast";
import { SelectButton } from "primereact/selectbutton";
import { useLocation } from 'react-router-dom';
import { AktionService } from "../service/AktionService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { FileUpload } from "primereact/fileupload";
import { ImportService } from "../service/ImportService";


import CONFIG from 'config';
import { AuthService } from "../service/AuthService";


export function AktionenNeu() {
	const [roles, setRoles] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [selectedID, setSelectedID] = useState(1);
	const [selectedBezeichnung, setSelectedBezeichnung] = useState(null);
	const [selectedTyp, setSelectedTyp] = useState(null);
	const [selectedAktiv, setSelectedAktiv] = useState(false);
	const [selectedVoucher, setSelectedVoucher] = useState(false);
	const [selectedVoucherEinmalig, setSelectedVoucherEinmalig] = useState(0);
	const [selectedVoucherAnzahl, setSelectedVoucherAnzahl] = useState(0);
	const [gutscheinData, setGutscheinData] = useState(0);
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [kategorienVisible, setKategorienVisible] = useState(false);
	const [startZeit, setStartZeit] = useState(new Date());
	const [endZeit, setEndZeit] = useState(() => {
		const date = new Date();
		date.setHours(date.getHours() + 1);
		return date;
	});	
	const [serienMuster, setSerienMuster] = useState("'Wöchentlich'");
	const [serienDauer, setSerienDauer] = useState('nachEnd');
	const [serienCount, setSerienCount] = useState(10);
	const [serienDauerStart, setSerienDauerStart] = useState(new Date());
	const [serienDauerEnd, setSerienDauerEnd] = useState(() => {
		const date = new Date();
		date.setMonth(date.getMonth() + 1);
		return date;
	});	
	const[jedeWoche, setJedeWoche] = useState(0);
	const[jedeMonat, setJedeMonat] = useState(0);
	const[jedeMonatTag, setJedeMonatTag] = useState(0);
	const[jährlichTag, setJährlichTag] = useState(0);
	const[jährlichMonat, setJährlichMonat] = useState(0);
	const[jährlichJahr, setJährlichJahr] = useState(0);
	const[montag, setMontag] = useState(false);
	const[dienstag, setDienstag] = useState(false);
	const[mittwoch, setMittwoch] = useState(false);
	const[donnerstag, setDonnerstag] = useState(false);
	const[freitag, setFreitag] = useState(false);
	const[samstag, setSamstag] = useState(false);
	const[sonntag, setSonntag] = useState(false);
	const[kategorien, setKategorien] = useState(false);
	const[warengruppen, setWarengruppen] = useState(false);
	const[artikelgruppen, setArtikelgruppen] = useState(false);
	const[selectedKategorien, setSelectedKategorien] = useState(false);
	const[selectedWarengruppen, setSelectedWarengruppen] = useState(false);
	const[selectedArtikelgruppen, setSelectedArtikelgruppen] = useState(false);
	const[warengruppenOptions, setWarengruppenOptions] = useState(null);
	const[artikelgruppenOptions, setArtikelgruppenOptions] = useState(null);
	const[produkte, setProdukte] = useState([]);
	const[selectedGruppeID, setSelectedGruppeID] = useState([]);
	const[selectedGruppe, setSelectedGruppe] = useState([]);
	const[selectedProduct, setSelectedProduct] = useState(null);
	const[kassen, setKassen] = useState([]);
	const[selectedProzent, setSelectedProzent] = useState(0);
	const[selectedProzentText, setSelectedProzentText] = useState(0);
	const[selectedEuroText, setSelectedEuroText] = useState(0);
	const[selectedDraufgabe, setSelectedDraufgabe] = useState(null);
	const[selectedDraufgabeText, setSelectedDraufgabeText] = useState(null);
	const[selectedDraufgabeText1, setSelectedDraufgabeText1] = useState(1);
  	const[selectedDraufgabeText2, setSelectedDraufgabeText2] = useState(0);
	const[selectedDreingabe, setSelectedDreingabe] = useState(null);
	const[selectedDreingabeText, setSelectedDreingabeText] = useState(null);
	const[selectedDreingabeText1, setSelectedDreingabeText1] = useState(1);
	const[selectedDreingabeText2, setSelectedDreingabeText2] = useState(0);
	const[selectedKategorienGruppe, setSelectedKategorienGruppe] = useState("Produkt");
	const[sortiment, setSortiment] = useState(null);
	const[ganzesSortiment, setGanzesSortiment] = useState(null);
	const[freiWählbar, setFreiWählbar] = useState(null);
	const[aktion, setAktion] = useState(null);
	const[proWert, setProWert] = useState([]);
	const[proWertIndivi, setProWertIndivi] = useState(null);
	const[draufgabeIndivi, setDraufgabeIndivi] = useState(null);
	const[dreingabeIndivi, setDreingabeIndivi] = useState(null);
	const[showSerie, setShowSerie] = useState(false);
	const [activeIndex, setActiveIndex] = useState(null);
	const [selectedProduktData, setSelectedProduktData] = useState(null);
	const [selectedProduktDataAll, setSelectedProduktDataAll] = useState(null);
	const [neuerVK, setNeuerVK] = useState(0);
	const [neuerDB, setNeuerDB] = useState(0);
	const [showRabattierbar, setShowRabattierbar] = useState(false);
	const [showGruppeRabattierbar, setShowGruppeRabattierbar] = useState(false);
	const [nichtRabattierbar, setNichtRabattierbar] = useState([]);
	const [anzahlProdukte, setAnzahlProdukte] = useState(0);
	const [showVKzuNiedrig, setShowVKzuNiedrig] = useState(false);
	const [showVKzuNiedrigGruppe, setShowVKzuNiedrigGruppe] = useState(false);
	const [produkteVKzuNiederig, setProdukteVKzuNiederig] = useState([]);
	const [produkteUngültig, setProdukteUngültig] = useState([]);
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1850);
	const [visibleProduktauswahl, setVisibleProduktauswahl] = useState(false);
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [selectedProduktgruppen, setSelectedProduktgruppen] = useState([]);
	const [selectedEinheit, setSelectedEinheit] = useState([]);
	const [selectedIDFilter, setSelectedIDFilter] = useState([]);
	const [produktRabattierbar, setProduktRabattierbar] = useState(false);
	const [showNeuerTyp, setShowNeuerTyp] = useState(false);
	const [selectedProduktEK, setSelectedProduktEK] = useState(0);
	const [selectedStückAnzahl, setSelectedStückAnzahl] = useState(0);
	const [selectedMengenrabatt, setSelectedMengenrabatt] = useState(null);
	const [showNichtBerechtigt, setShowNichtBerechtigt] = useState(false);
	const [newTyp, setNewTyp] = useState("");
	const [typOptions, setTypOptions] = useState([]); 
	const [newAktion, setNewAktion] = useState(null); 
	const [goToDB, setGoToDB] = useState(false);
	const [produktDataEdit, setProduktDataEdit] = useState(null);
	const [changeAktion, setChangeAktion] = useState(false);
	const [copyAktion, setCopyAktion] = useState(false);
	const [imageData, setImageData] = useState(null);
	const [uploadClearDisabled, setUploadClearDisabled] = useState(true);
	const [serienMusterAuswahl, setSerienMusterAuswahl] = useState("first");
	const [jedeMonatTagDropdown, setJedeMonatTagDropdown] = useState("1");
	const [jedeMonatWochentagDropdown, setJedeMonatWochentagDropdown] = useState("Montag");
	const [jedeMonatDropdown, setJedeMonatDropdown] = useState(0);
	const [monatDropdown, setMonatDropdown] = useState("1");
	const [produkteRabattierbarChange, showProdukteRabattierbarChange] = useState(false);
	const [produkteVKDBzuNiedrigChange, showProdukteVKDBzuNiedrigChange] = useState(false);
	const [überlappungsdata, setÜberlappungsdata] = useState(false);
	const [showAlreadyAktion, setShowAlreadyAktion] = useState(false);
	const [changeAktionID, setChangeAktionID] = useState(0);
	const [loadingBarcodes, setLoadingBarcodes] = useState(false);
	
	const [columnVisibility, setColumnVisibility] = useState({
		ID: false,
		ArtNr: false,
		Bezeichnung: true,
		Warengruppe: true,
		Barcode: true,
		Lieferant: true,
		Produzent: true,
		Einheit: true,
	});

    const einheiten = ["Stueck", "KG"];

	const location = useLocation();
    const id = location.state?.produkt;
    const aktionsID = location.state?.aktionsID;
    const verlinkungsArt = location.state?.art;
    const history = useHistory();	
    const labelWidth = '200px';
    let toast = useRef(null);
    let dt = useRef(null);
	let uploadRef = useRef(null);

	const artikelgruppenService = new ArtikelgruppenService();
	const produktService = new ProduktService();
	const kassenService = new KassenService();
	const aktionService = new AktionService();
	const authService = new AuthService();
	const importService = new ImportService();

	const onBildGeladen = (e) => {
        if (e !== undefined) setImageData("data:image/png;base64," + e.Data);
    }

	const onClick = (itemIndex) => {
    	let _activeIndex = activeIndex ? [...activeIndex] : [];

        if (_activeIndex.length === 0) {
            _activeIndex.push(itemIndex);
        }
        else {
            const index = _activeIndex.indexOf(itemIndex);
            if (index === -1) {
                _activeIndex.push(itemIndex);
            }
            else {
                _activeIndex.splice(index, 1);
            }
        }

        setActiveIndex(_activeIndex);
    }


	useEffect(() => {
		if(aktionsID != undefined){
			aktionService.getAktionByGuid(aktionsID).then(data => {
				if(verlinkungsArt === "edit"){
					setChangeAktion(true);
				}
				else if(verlinkungsArt === "copy"){
					setCopyAktion(true);
				}
				console.log(data);
				setProduktDataEdit(data);
			});
			aktionService.getGutscheinData(aktionsID).then(data => {
				console.log(data)
				if(data.length > 0 ){
					setSelectedVoucherEinmalig(data[0].gutscheinDetails[1].akGutscheinEinmalig === true ? 0 : 1);
					setSelectedVoucherAnzahl(data[0].gutscheinDetails.length);
					onSelecteVoucherAnzahlChange({value: data[0].gutscheinDetails.length});
				}
			});
		}
	},[aktionsID])



	useEffect(() => {
		if(produktDataEdit && produktDataEdit.length > 0){
			console.log(produktDataEdit)
			aktionService.getImage(produktDataEdit[0].iD).then(data => onBildGeladen(data));

			if(changeAktion === true){
				setSelectedID(produktDataEdit[0].aktionsID);
				setChangeAktionID(produktDataEdit[0].iD);
			}
			if(produktDataEdit[0].serienArt === "spezifisch"){
				setSerienMusterAuswahl("second");
				setJedeMonatDropdown(produktDataEdit[0].serienJedeMonat);
				
				const wochentagMapping = {
					Montag: produktDataEdit[0].serienMontag,
					Dienstag: produktDataEdit[0].serienDienstag,
					Mittwoch: produktDataEdit[0].serienMittwoch,
					Donnerstag: produktDataEdit[0].serienDonnerstag,
					Freitag: produktDataEdit[0].serienFreitag,
					Samstag: produktDataEdit[0].serienSamstag,
					Sonntag: produktDataEdit[0].serienSonntag,
				};


				const selectedDay = Object.keys(wochentagMapping).find(day => wochentagMapping[day] === true);
				if (selectedDay) setJedeMonatWochentagDropdown(selectedDay);

				setJedeMonatTagDropdown(produktDataEdit[0].serienJedeTag.toLocaleString())

				setMonatDropdown(produktDataEdit[0].serienJedeMonat.toLocaleString())
			}
			setSelectedBezeichnung(produktDataEdit[0].bezeichnung);
			setSelectedTyp(typOptions.find(option => option.aktionsTypBezeichnung === produktDataEdit[0].akTypenBezeichnung)?.aktionsTypID);
			setSelectedAktiv(produktDataEdit[0].aktiv);
			setSerienDauerStart(new Date(produktDataEdit[0].zeitraumStart ?? produktDataEdit[0].serienDauerBeginn));
			setSerienDauerEnd(new Date(produktDataEdit[0].zeitraumEnde ?? produktDataEdit[0].serienDauerEnd));
			setShowSerie(produktDataEdit[0].serie);
			setSelectedVoucher(produktDataEdit[0].akGutschein);
			
			if(produktDataEdit[0].kategorie === "Produkt"){
				setSortiment(0);
				produktService.getProdukt(produktDataEdit[0].produktID).then(data => setSelectedProduct(data));
			}
			else if(produktDataEdit[0].kategorie !== "Produkt"){
				if(produktDataEdit[0].kategorie === "Sortiment"){
					setGanzesSortiment(true);
				}
				else if(produktDataEdit[0].kategorie === "frei"){
					setFreiWählbar(true);
				}
				else{
					setSortiment(1);
					aktionService.getGruppennamen(produktDataEdit[0].kategorie, produktDataEdit[0].produktID).then(data => {
						setSelectedGruppe(data[0].bezeichnung);
						setSelectedGruppeID(data[0].iD);
						setSelectedKategorienGruppe(produktDataEdit[0].kategorie);
					});
				}

			}
			setAktion(produktDataEdit[0].akArten);
			if(produktDataEdit[0].akArten === 3){
				setSelectedStückAnzahl(produktDataEdit[0].akAbAnzahlProdukte)
				setSelectedMengenrabatt(produktDataEdit[0].akAufAlleProdukte ? 1 : 0);
			}
			setProWert(produktDataEdit[0].akProzent === true ? 0 : 1);
			setProWertIndivi(1);
			setSelectedProzentText(produktDataEdit[0].akText);
			setSelectedEuroText(Number(produktDataEdit[0].akText));
			setSelectedKassen(produktDataEdit[0].kassen);
			if(produktDataEdit[0].serie === true){
				setStartZeit(new Date(0, 0, 0, produktDataEdit[0].serienStartZeit.hours, produktDataEdit[0].serienStartZeit.minutes, produktDataEdit[0].serienStartZeit.seconds));
				setEndZeit(new Date(0, 0, 0, produktDataEdit[0].serienEndZeit.hours, produktDataEdit[0].serienEndZeit.minutes, produktDataEdit[0].serienEndZeit.seconds));
				setSerienMuster(produktDataEdit[0].serienMuster);
				setJedeWoche(produktDataEdit[0].serienJedeWoche ?? 0);
				setMontag(produktDataEdit[0].serienMontag);
				setDienstag(produktDataEdit[0].serienDienstag);
				setMittwoch(produktDataEdit[0].serienMittwoch);
				setDonnerstag(produktDataEdit[0].serienDonnerstag);
				setFreitag(produktDataEdit[0].serienFreitag);
				setSamstag(produktDataEdit[0].serienSamstag);
				setSonntag(produktDataEdit[0].serienSonntag);
				setJedeMonatTag(produktDataEdit[0].serienJedeTag ?? 0);
				setJedeMonat(produktDataEdit[0].serienJedeMonat ?? 0);
				setJährlichTag(produktDataEdit[0].serienJedeTag ?? 0);
				setJährlichMonat(produktDataEdit[0].serienJedeMonat ?? 0);
				setJährlichJahr(produktDataEdit[0].serienJedeJahr ?? 0);
				setSerienDauer(produktDataEdit[0].serienDauer);
				setSerienCount(produktDataEdit[0].serienDauerCount ?? 0);
			}
		}
	},[produktDataEdit])


	const handleCheckboxChange = (id) => {
		setSelectedKassen((prevSelected) =>
		  prevSelected.includes(id)
			? prevSelected.filter((kassenId) => kassenId !== id)
			: [...prevSelected, id]
		);
	};

	const handleCheckboxChangeArtikel = (e, rowData) => {
        const updatedData = produkteVKzuNiederig.map(product => {
            if (product === rowData) {
                return { ...product, Artikelrabattieren: e.checked };
            }	
            return product;
        });
        setProdukteVKzuNiederig(updatedData);
    };

	const onallArtikelHinzufügenClick = () => {
		const temp = produkteVKzuNiederig.map(product => {
			return { ...product, Artikelrabattieren: true };
		})
		setProdukteVKzuNiederig(temp);
	}

	const onallArtikelEntfernenClick = () => {
		const temp = produkteVKzuNiederig.map(product => {
			return { ...product, Artikelrabattieren: false };
		})
		setProdukteVKzuNiederig(temp);
	}

	const handleCheckboxChangeNeuRabattartikel = (e, rowData) => {
        const updatedData = nichtRabattierbar.map(product => {
            if (product === rowData) {
                return { ...product, neuRabattierbar: e.checked };
            }	
            return product;
        });
        setNichtRabattierbar(updatedData);
    };

	const handleSpeicherNeueRabattartikel = () => {
		nichtRabattierbar.map(product => {
			if(product.neuRabattierbar === true){
				aktionService.changeRabattierbar("Produkt", product.produkt).then(data => {
					//console.log(data)
				})
			}
		})
		aktionService.getProdukteRabattierbar(selectedGruppe, ganzesSortiment ? "Sortiment" : selectedKategorienGruppe).then(data => setNichtRabattierbar(data));
	}

	const refreshBarcodes = () => {
		setLoadingBarcodes(true);
		onSelecteVoucherAnzahlChange({value:selectedVoucherAnzahl})
	}

	const onallArtikelRabattierbarClick = (e) => {
		const temp = nichtRabattierbar.map(product => {
			aktionService.changeRabattierbar("Produkt", product.produkt).then(data => {console.log(data)})
			return {...product, neuRabattierbar: true};
		})
		setNichtRabattierbar(temp);
	}

	const onallArtikelNichtRabattierbarClick = (e) => {
		const temp = nichtRabattierbar.map(product => {
			aktionService.changeRabattierbar("Produkt", product.produkt).then(data => {console.log(data)})
			return {...product, neuRabattierbar: false};
		})
		setNichtRabattierbar(temp);
	}
		
	const onSpeichernClick = async () => {
		try {
			var data = 0;
			let error = false;
			let messages = [];
			let missingFields = [];

			if((selectedKategorienGruppe && selectedGruppe && selectedGruppe.length > 0)){
				data = await aktionService.istBerechtigt(selectedKategorienGruppe, selectedGruppe);
			}
			else{
				data = true
			}
	
			if (data === false) {
				setShowNichtBerechtigt(true);
				return;
			} 
			else if (data === true) {
				var tempData = {};
				if (selectedID === undefined || selectedID === null) {
					missingFields.push('ID');
				}
				else {
					tempData.AktionsID = selectedID;
				}
				if (selectedBezeichnung === null || selectedBezeichnung === undefined || selectedBezeichnung === '') {
					missingFields.push('keine Bezeichnung angegeben');
				}
				else {
					tempData.Bezeichnung = selectedBezeichnung;
				}
				if (selectedTyp === undefined || selectedTyp === null) {
					missingFields.push('kein Typ angegeben');
				}
				else {
					tempData.Typ = selectedTyp;
				}
				if (selectedAktiv === undefined || selectedAktiv === null) {
					missingFields.push('Aktiv nicht gesetzt');
				}
				else {
					tempData.Aktiv = selectedAktiv;
				}
				if (selectedKassen.length < 1) {
					missingFields.push('Kassen nicht ausgewählt');
				}
				else {
					tempData.Kassen = selectedKassen;
				}
				if(serienDauerStart === undefined || serienDauerStart === null){
					missingFields.push('Bitte Startzeit auswählen');
				}
				else {
					let zeitStart = serienDauerStart.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
					tempData.zeitraumStart = zeitStart;
				}
				if(serienDauerEnd === undefined || serienDauerEnd === null){
					missingFields.push('Bitte Startzeit auswählen');
				}
				else {
					let zeitEnde = serienDauerEnd.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
					tempData.zeitraumEnde = zeitEnde;
				}
				if(showSerie === undefined || showSerie === null){
					missingFields.push('Bitte Serie auswählen');
				}
				else {
					tempData.serie = showSerie;
				}
				if(sortiment === 0 || sortiment === 1){
					if(sortiment === 0 && selectedProduct !== null && selectedProduct !== undefined){
						tempData.produktID = selectedProduct.ID;
						tempData.kategorie = "Produkt";
					}
					else if(sortiment === 1){
						tempData.produktID = selectedGruppeID;
						tempData.kategorie = selectedKategorienGruppe;
					}
					else{
						missingFields.push('Bitte ein Produkt oder eine Gruppe auswählen');
					}
				}
				else if(ganzesSortiment === true){
					tempData.kategorie = "Sortiment";
					tempData.produktID = 0;
				}
				else if(freiWählbar === true){
					tempData.kategorie = "frei";
					tempData.produktID = 0;
				}
				else{
					missingFields.push('Bitte ein Produkt oder eine Gruppe auswählen');
				}
				if(aktion===0){
					if(proWert === 0){
						if(proWertIndivi === 0 && parseInt(selectedProzent) > 0){
							var temp = {
								"Art": aktion,
								"Text" : parseInt(selectedProzent),
								"Prozent": true,
							}
							tempData.Aktion = temp;
						}
						else if(proWertIndivi === 1 && selectedProzentText > 0) {
							var temp = {
								"Art": aktion,
								"Text" : selectedProzentText,
								"Prozent": true,
							}
							tempData.Aktion = temp;
						}
						else{
							missingFields.push('Bitte Aktionswert auswählen!');
						}
						
					}
					else if (proWert === 1 && selectedEuroText > 0){
						var temp = {
							"Art": aktion,
							"Text" : selectedEuroText,
							"Prozent": false,
						}
						tempData.Aktion = temp;
					}
					else{
						missingFields.push('Bitte Aktionswert auswählen!');
					}
				}else if(aktion===1){
					if(draufgabeIndivi === 0 && selectedDraufgabe != null){
						var temp = {
							"Art": aktion,
							"Text" : selectedDraufgabe,
							"Prozent": false,
						}
						tempData.Aktion = temp;
					}
					else if(draufgabeIndivi === 1 && selectedDraufgabeText != null){
						var temp = {
							"Art": aktion,
							"Text" : selectedDraufgabeText,
							"Prozent": false,
						}
						tempData.Aktion = temp;
					}
					else{
						missingFields.push('Bitte Aktionswert auswählen!');
					}
				}else if(aktion===2){
					if(dreingabeIndivi === 0 && selectedDreingabe != null){
						var temp = {
							"Art": aktion,
							"Text" : selectedDreingabe,
							"Prozent": false,
						}
						tempData.Aktion = temp;
					}
					else if(dreingabeIndivi === 1 && selectedDreingabeText != null){
						var temp = {
							"Art": aktion,
							"Text" : selectedDreingabeText,
							"Prozent": false,
						}
						tempData.Aktion = temp;
					}
					else{
						missingFields.push('Bitte Aktionswert auswählen!');
					}
				}else if(aktion===3){
					if(proWert === 0 && selectedStückAnzahl > 0 && selectedProzentText > 0 && selectedMengenrabatt != null){
						var temp = {
							"Art": aktion,
							"Text" : selectedProzentText,
							"Prozent": true,
							"AbAnzahlProdukte" : selectedStückAnzahl,
							"AufAlleProdukte":  selectedMengenrabatt,
						}
						tempData.Aktion = temp;
					}
					else if (proWert === 1 && selectedStückAnzahl > 0 && selectedEuroText > 0 && selectedMengenrabatt != null){						
						var temp = {
							"Art": aktion,
							"Text" : selectedEuroText,
							"Prozent": false,
							"AbAnzahlProdukte" : selectedStückAnzahl,
							"AufAlleProdukte":  selectedMengenrabatt,
						}
						tempData.Aktion = temp;
					}
					else {
						missingFields.push('Bitte einen gültigen Aktionswert auswählen!');
					}
				}
				else{
					missingFields.push('Bitte einen gültigen Aktionswert auswählen!');
				}
				if(showSerie === true){
					let startZeitFormatted = startZeit.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
					let EndZeitFormatted = endZeit.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });

					let serienData = {
						"SerienMuster": serienMuster,
						"StartZeit": startZeitFormatted,
						"EndZeit": EndZeitFormatted,
						"SerienDauer" : serienDauer
					};
					if(serienMuster === "Täglich"){
						serienData.JedeTag = jedeMonatTag;

					}
					else if(serienMuster === "Wöchentlich"){
						serienData.JedeWoche = jedeWoche;
						serienData.Montag = montag;
						serienData.Dienstag = dienstag;
						serienData.Mittwoch = mittwoch;
						serienData.Donnerstag = donnerstag;
						serienData.Freitag = freitag;
						serienData.Samstag = samstag;
						serienData.Sonntag = sonntag;
					}
					else if(serienMuster === "Monatlich"){
						if(serienMusterAuswahl === 'first'){
							serienData.SerienArt = "normal"
							serienData.JedeTag = jedeMonatTag;
							serienData.JedeMonat = jedeMonat;
						}
						if(serienMusterAuswahl === 'second'){
							serienData.SerienArt = "spezifisch"
							serienData.JedeTag = jedeMonatTagDropdown;
							serienData.JedeWochenTag = jedeMonatWochentagDropdown;
							if (jedeMonatWochentagDropdown === "Montag") {
								serienData.Montag = true;
							  } else if (jedeMonatWochentagDropdown === "Dienstag") {
								serienData.Dienstag = true;
							  } else if (jedeMonatWochentagDropdown === "Mittwoch") {
								serienData.Mittwoch = true;
							  } else if (jedeMonatWochentagDropdown === "Donnerstag") {
								serienData.Donnerstag = true;
							  } else if (jedeMonatWochentagDropdown === "Freitag") {
								serienData.Freitag = true;
							  } else if (jedeMonatWochentagDropdown === "Samstag") {
								serienData.Samstag = true;
							  } else if (jedeMonatWochentagDropdown === "Sonntag") {
								serienData.Sonntag = true;
							  }
							serienData.JedeMonat = jedeMonatDropdown;
						}
					}
					else if(serienMuster === "Jährlich"){
						if(serienMusterAuswahl === 'first'){
							serienData.SerienArt = "normal"
							serienData.JedeTag = jährlichTag;
							serienData.JedeMonat = jährlichMonat;
							serienData.JedeJahr = jährlichJahr;
						}
						if(serienMusterAuswahl === 'second'){
							serienData.SerienArt = "spezifisch"
							serienData.JedeTag = jedeMonatTagDropdown;
							serienData.JedeWochenTag = jedeMonatWochentagDropdown;
							if (jedeMonatWochentagDropdown === "Montag") {
								serienData.Montag = true;
							  } else if (jedeMonatWochentagDropdown === "Dienstag") {
								serienData.Dienstag = true;
							  } else if (jedeMonatWochentagDropdown === "Mittwoch") {
								serienData.Mittwoch = true;
							  } else if (jedeMonatWochentagDropdown === "Donnerstag") {
								serienData.Donnerstag = true;
							  } else if (jedeMonatWochentagDropdown === "Freitag") {
								serienData.Freitag = true;
							  } else if (jedeMonatWochentagDropdown === "Samstag") {
								serienData.Samstag = true;
							  } else if (jedeMonatWochentagDropdown === "Sonntag") {
								serienData.Sonntag = true;
							  }
							serienData.JedeMonat = monatDropdown;
							serienData.JedeJahr = 1;
						}
					}
					else{
						missingFields.push('Bitte eine gültige Serie auswählen!');
					}
					let zeitStart = serienDauerStart.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
					tempData.zeitraumStart = zeitStart;
					if(serienDauer === "nachEnd" && serienCount > 0){
						serienData.SerienDauerCount = serienCount;
						if(serienMuster === "Täglich"){
							let temp = new Date(serienDauerStart);
							temp.setDate(temp.getDate() + serienCount * serienData.JedeTag);
							let zeitEnde = temp.toLocaleString('de-DE', {
								year: 'numeric', 
								month: '2-digit', 
								day: '2-digit', 
								hour: '2-digit', 
								minute: '2-digit', 
								second: '2-digit', 
								hour12: false
							});
							tempData.zeitraumEnde = zeitEnde				
						}
						else if(serienMuster === "Wöchentlich"){
							let temp = new Date(serienDauerStart);
							temp.setDate(temp.getDate() + (serienCount * 7 * serienData.JedeWoche));
							let zeitEnde = temp.toLocaleString('de-DE', {
								year: 'numeric', 
								month: '2-digit', 
								day: '2-digit', 
								hour: '2-digit', 
								minute: '2-digit', 
								second: '2-digit', 
								hour12: false
							});
							tempData.zeitraumEnde = zeitEnde				
						}
						else if(serienMuster === "Monatlich"){
							let temp = new Date(serienDauerStart);
							temp.setMonth(temp.getMonth() + (serienCount * serienData.JedeMonat));
							let zeitEnde = temp.toLocaleString('de-DE', {
								year: 'numeric', 
								month: '2-digit', 
								day: '2-digit', 
								hour: '2-digit', 
								minute: '2-digit', 
								second: '2-digit', 
								hour12: false
							});	
							tempData.zeitraumEnde = zeitEnde				
						}
						else if(serienMuster === "Jährlich"){
							let temp = new Date(serienDauerStart);
							temp.setFullYear(temp.getFullYear() + (serienCount * (serienData.JedeJahr ?? 1)));
							let zeitEnde = temp.toLocaleString('de-DE', {
								year: 'numeric', 
								month: '2-digit', 
								day: '2-digit', 
								hour: '2-digit', 
								minute: '2-digit', 
								second: '2-digit', 
								hour12: false
						});
							tempData.zeitraumEnde = zeitEnde				
						}
					}
					else if(serienDauer === "amEnd"){
						let zeitEnde = serienDauerEnd.toLocaleString('de-DE', {
							year: 'numeric', 
							month: '2-digit', 
							day: '2-digit', 
							hour: '2-digit', 
							minute: '2-digit', 
							second: '2-digit', 
							hour12: false
						});
						tempData.zeitraumEnde = zeitEnde					
					}
					else {
						missingFields.push('Bitte eine gültige Serie auswählen!');
					}

					tempData.SerienData = serienData;

				}

				if(serienDauerEnd < serienDauerStart){
					missingFields.push('Bitte einen gültigen Zeitraum auswählen!');
				}

				var updatedGutscheinData;
				
				tempData.GutscheinData = [];  
				if(selectedVoucher === true){
					console.log(gutscheinData)
					if(selectedVoucherEinmalig === false){
						updatedGutscheinData = gutscheinData?.map(item => ({
							...item,
							Einmalig: true
						}));
						tempData.GutscheinData = updatedGutscheinData;	
					}
					else{
						updatedGutscheinData = gutscheinData?.map(item => ({
							...item,
							Einmalig: false
						}));
						tempData.GutscheinData = updatedGutscheinData;	


					}
					tempData.Gutscheine = true;
				}
				else{
					tempData.Gutscheine = false;
				}


				
		





				if (missingFields.length > 0) {
					messages.push({
						severity: 'error',
						summary: 'Das Produkt konnte nicht gespeichert werden',
						detail: 'Nicht alle Pflichtfelder wurden ausgefüllt: ' + missingFields.join(', '),
						life: 5000
					});
					error = true;
				}
				if (error === true) {
					toast.current.show(messages);
					return;
				}

				if(sortiment === 1){
					aktionService.getProdukteRabattierbar(selectedGruppe, selectedKategorienGruppe).then(data => {
						//console.log(data)
						setNichtRabattierbar(data);
						if(data.length>0){
							setShowGruppeRabattierbar(true);
							return;
						}
						else{
							istZuBillig();
						}
					})
				}
				else if(sortiment === 0){
					produktService.getProdukt(selectedProduct.ID).then(data => {
						//console.log(data)
						if(data.Rabattartikel === true){
							setShowRabattierbar(false);
							istZuBillig();
						}
						else{
							setShowRabattierbar(true);
						}
					})
				}
				else if(ganzesSortiment){

					aktionService.getAlleProdukteRabattierbar().then(data => {
						setNichtRabattierbar(data);
						if(data.length>0){
							setShowGruppeRabattierbar(true);
							return;
						}
						else{
							istZuBillig();
						} 
						
					})
				}
				else if(freiWählbar){
					aktionService.getAlleProdukteRabattierbar().then(data => {
						setNichtRabattierbar(data);
						if(data.length>0){
							setShowGruppeRabattierbar(true);
							return;
						}
						else{
							istZuBillig();
						} 
						
					})
				}

				setNewAktion(tempData);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der Berechtigung:", error);
		}
	}


	useEffect(() => {
		let error = false;
		let messages = [];
		let missingFields = [];
		

		if(goToDB){
			const idsNichtRabattierbar = nichtRabattierbar.filter(product => product.neuRabattierbar === false).map(product => product.produkt);
			const idsProdukteVKzuNiederig = produkteVKzuNiederig.filter(product => product.Artikelrabattieren === false).map(product => product.ID);
			const idsUngültig = produkteUngültig.map(product => product.produkt);
			var allGanzesSortiment = [];

			var alleIDs = null;
			
			async function fetchAndProcessData() {
				if(freiWählbar){
					alleIDs = [];
				} else if (ganzesSortiment) {
					try {
						allGanzesSortiment = await aktionService.getAllUngültigenProdukte();
						allGanzesSortiment = allGanzesSortiment === false ? [] : allGanzesSortiment;
			
						alleIDs = [...new Set([...allGanzesSortiment, ...idsNichtRabattierbar, ...idsProdukteVKzuNiederig])];
					} catch (error) {
						console.error("Fehler beim Abrufen der ungültigen Produkte:", error);
					}
				} else if ((newAktion.Aktion.Art === 0 || newAktion.Aktion.Art === 3) && newAktion.Aktion.Prozent === true) {
					alleIDs = [...new Set([...idsNichtRabattierbar, ...idsProdukteVKzuNiederig])];


					try {
						allGanzesSortiment = await aktionService.getAllUngültigenProdukteLieferant(newAktion.kategorie, newAktion.produktID);
						allGanzesSortiment = allGanzesSortiment === false ? [] : allGanzesSortiment;

						alleIDs = [...new Set([...allGanzesSortiment, ...idsNichtRabattierbar, ...idsProdukteVKzuNiederig])];
					} catch (error) {
						console.error("Fehler beim Abrufen der ungültigen Produkte:", error);
					}


				} else {
					alleIDs = [...new Set([...idsNichtRabattierbar, ...idsProdukteVKzuNiederig, ...idsUngültig])];


					try {
						allGanzesSortiment = await aktionService.getAllUngültigenProdukteLieferant(newAktion.kategorie, newAktion.produktID);
						const ids = allGanzesSortiment?.map(item => item.iD);
	
						alleIDs = [...new Set([...allGanzesSortiment, ...idsNichtRabattierbar, ...idsProdukteVKzuNiederig, ...idsUngültig].map(item => JSON.stringify(item)))];
						alleIDs = alleIDs.map(item => JSON.parse(item));
					
					} catch (error) {
						console.error("Fehler beim Abrufen der ungültigen Produkte:", error);
					}
				}
				console.log(alleIDs);
				newAktion.alleProdukteAußer = alleIDs;
			}
			
			async function runAsyncFunction() {
				await fetchAndProcessData();

				if (newAktion) {
					console.log(newAktion);
					
					
					if(newAktion.Aktiv === true){

						
						aktionService.alreadyAktion(newAktion, changeAktionID).then(data => {
							if(data && data.length > 0){
								setÜberlappungsdata(data);
								console.log(data)
								setShowAlreadyAktion(true);
							}
							else{
								if(changeAktion === false){
									aktionService.setNewAktion(newAktion).then(data =>{
										if(data.isInserted === false){
											//console.log(data.isInserted+" es wurde bereits eine Aktion gefunden! -> oder Fehler");
										}
										else if(data.isInserted === true){
											//console.log(data.isInserted+" Aktion wurde gespeichert!");
											
											try {
												let url = CONFIG.ServerUrl + "/Aktionen/"+data.iD+"/upload";
												//console.log(url)
														
												importService.upload(uploadRef.current.getFiles()?.[0], uploadRef.current.props.name, url);
												onCancelClick();
											} catch (e) {
												console.log(e);
											}
											
										}
									});
								}
								else if(changeAktion === true){
									aktionService.changeAktion(newAktion, changeAktionID).then(data =>{
										if(data.isInserted === false){
											//console.log(data.isInserted+" es wurde bereits eine Aktion gefunden! -> oder Fehler");
										}
										else if(data.isInserted === true){
											//console.log(data.isInserted+" Aktion wurde gespeichert!");
											
											try {
												let url = CONFIG.ServerUrl + "/Aktionen/"+data.iD+"/upload";
												//console.log(url)
														
												importService.upload(uploadRef.current.getFiles()?.[0], uploadRef.current.props.name, url);
												onCancelClick();
											} catch (e) {
												console.log(e);
											}
											
										}
									});
									
								}
							}
						}
						);
					}
					else{
						if(changeAktion === false){
							aktionService.setNewAktion(newAktion).then(data =>{
								if(data.isInserted === false){
									//console.log(data.isInserted+" es wurde bereits eine Aktion gefunden! -> oder Fehler");
								}
								else if(data.isInserted === true){
									//console.log(data.isInserted+" Aktion wurde gespeichert!");
									
									try {
										let url = CONFIG.ServerUrl + "/Aktionen/"+data.iD+"/upload";
										//console.log(url)
												
										importService.upload(uploadRef.current.getFiles()?.[0], uploadRef.current.props.name, url);
										onCancelClick();
									} catch (e) {
										console.log(e);
									}
									
								}
							});
						}
						else if(changeAktion === true){
							aktionService.changeAktion(newAktion, changeAktionID).then(data =>{
								if(data.isInserted === false){
									//console.log(data.isInserted+" es wurde bereits eine Aktion gefunden! -> oder Fehler");
								}
								else if(data.isInserted === true){
									//console.log(data.isInserted+" Aktion wurde gespeichert!");
									
									try {
										let url = CONFIG.ServerUrl + "/Aktionen/"+data.iD+"/upload";
										//console.log(url)
												
										importService.upload(uploadRef.current.getFiles()?.[0], uploadRef.current.props.name, url);
										onCancelClick();
									} catch (e) {
										console.log(e);
									}
									
								}
							});
						}
					}
	
				
				}
				else{
					missingFields.push('Es wurde keine neue Aktion gefunden!');
				}
			}
			runAsyncFunction();
		}
	  
		

		if (missingFields.length > 0) {
			messages.push({
				severity: 'error',
				summary: 'Das Produkt konnte nicht gespeichert werden',
				detail: missingFields.join(', '),
				life: 5000
			});
			error = true;
		}
		if (error === true) {
			toast.current.show(messages);
			return;
		}

		setGoToDB(false) 

	},[goToDB]) 


	const onCancelClick = () => {
        history.push("../aktionen");
	}

	const handleChange = (event) => {
		setSerienMuster(event.target.value);
		setSerienCount(0);
	};

	const handleChangeMusterAuswahl = (event) => {
		setSerienMusterAuswahl(event.target.value);
	};

	const handleChangeSerienDauer = (event) => {
		setSerienDauer(event.target.value);
	};

	const onAllKassen = () => {
		const allKassenIDs = kassen.map(kasse => kasse.KassenID);
    	setSelectedKassen(allKassenIDs);
	}

	const renderFooter = () => {
        return (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => setKategorienVisible(false)} className="p-button-text" />
                <Button label="Ok" icon="pi pi-check" onClick={() => onGruppenAuswahl()} autoFocus />
            </div>
        );
    }


	const renderFooterFehler = () => {
        return (
            <div>
                <Button label="Abbrechen" onClick={() => {
					setShowRabattierbar(false);
					setShowGruppeRabattierbar(false);
				}} autoFocus />

				<Button label="Speichern" onClick={() => {
					if (showGruppeRabattierbar){
						setShowRabattierbar(false);
						setShowGruppeRabattierbar(false);
						istZuBillig();
					}
					else if(showRabattierbar){
						setShowRabattierbar(false);
						setShowGruppeRabattierbar(false);
						istZuBillig();
					}
				}} autoFocus 
				/>

            </div>
        );
    }
	const renderFooterFehler2 = () => {
        return (
            <div>
                <Button label="Abbrechen" onClick={() => {
					setShowVKzuNiedrig(false);
					setShowVKzuNiedrigGruppe(false);
					setGoToDB(false);
				}} autoFocus />

				<Button label="Speichern" onClick={() => {
					if (showVKzuNiedrigGruppe) {
						setShowVKzuNiedrig(false);
						setShowVKzuNiedrigGruppe(false);
						setGoToDB(true);
					} else if (showVKzuNiedrig) {
						setShowVKzuNiedrig(false);
						setShowVKzuNiedrigGruppe(false);
						setGoToDB(true);
					}
				}} autoFocus />

            </div>
        );
    }

	const onGruppenAuswahl = () => {
        setSelectedProduct(null);
		var tempGruppe = "";	
		var tempGruppeID = "";	
		var tempKategorienGruppe = "";
		let _kategorien = [];
		let _warengruppe = [];
		let _artikelgruppe = [];


		if(selectedKategorien || selectedKategorien === 0){
			_kategorien = kategorien.filter(function(x){
				return x.ID === selectedKategorien;
			});
			tempGruppe = _kategorien[0].Bezeichnung
			tempGruppeID = _kategorien[0].ID
			tempKategorienGruppe = "Kategorie";
			if(selectedWarengruppen){
				_warengruppe = warengruppen.filter(function(x){
					return x.ID === selectedWarengruppen;
				});
				tempGruppe = _warengruppe[0].Bezeichnung;
				tempGruppeID = _warengruppe[0].ID
				tempKategorienGruppe = "Warengruppe";

				if(selectedArtikelgruppen){
					_artikelgruppe = artikelgruppen.filter(function(x){
						return x.ID === selectedArtikelgruppen;
					});
					tempGruppe = _artikelgruppe[0].Bezeichnung;
					tempGruppeID = _artikelgruppe[0].ID
					tempKategorienGruppe = "Artikelgruppe";
					

				}
			}
		}


		setSelectedGruppe(tempGruppe);
		setSelectedGruppeID(tempGruppeID);
		setSelectedKategorienGruppe(tempKategorienGruppe);
		aktionService.getAnzahlProdukte(
			tempKategorienGruppe, 
			_kategorien.length > 0 ? _kategorien[0].Bezeichnung : null,
			_warengruppe.length > 0 ? _warengruppe[0].Bezeichnung : null,
			_artikelgruppe.length > 0 ? _artikelgruppe[0].Bezeichnung : null
		).then(data => setAnzahlProdukte(data));

		setKategorienVisible(false)
	}

	const handleResize = () => {
		setIsLargeScreen(window.innerWidth > 1850);
	};

	
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		produktService.getProdukte().then(data => {
			setProdukte(data);
		});
		kassenService.getKassen().then(data => {
			setKassen(data);
		})
		aktionService.getAktionsTypen().then(data => {
			setTypOptions(data);
		});
		authService.getTokenInfo().then(info => {
			setRoles(info?.roles.split(';') ?? []);
		});

		// eslint-disable-next-line
	},[]);

	useEffect(() => {
		if(changeAktion === false && copyAktion === false){
			setGlobalState({...globalState, page: ' Aktionen > neue Aktion'});
		}
		if(changeAktion === true && copyAktion === false){
			setGlobalState({...globalState, page: ' Aktionen > Aktion bearbeiten'});
		}
		if(changeAktion === false && copyAktion === true){
			setGlobalState({...globalState, page: ' Aktionen > Aktion kopieren'});
		}
		if(changeAktion === false){
			aktionService.getNextID().then(data => {
				setSelectedID(isNaN(Number(data + 1)) ? 0 : Number(data + 1));
			})
		}
	},[changeAktion, copyAktion])

	useEffect(() => {
		if(selectedDraufgabeText2 >= selectedDraufgabeText1) {
			setSelectedDraufgabeText2(selectedDraufgabeText1-1)
		}
		if(selectedDraufgabeText1&& selectedDraufgabeText2){
			setSelectedDraufgabe(null)
			setSelectedDraufgabeText(selectedDraufgabeText1+" + "+selectedDraufgabeText2+" Gratis");
		}
	}, [selectedDraufgabeText1, selectedDraufgabeText2])

	useEffect(() => {
		if(selectedDreingabeText2 >= selectedDreingabeText1) {
			setSelectedDreingabeText2(selectedDreingabeText1-1)
		}
		if(selectedDreingabeText1 && selectedDreingabeText1>=0){
			setSelectedDreingabe(null)
			setSelectedDreingabeText("Nimm "+selectedDreingabeText1+", zahl "+selectedDreingabeText2);
		}
	}, [selectedDreingabeText1, selectedDreingabeText2])

	useEffect(() => {
		if(id){
			setSortiment(0);
			produktService.getProdukt(id).then(data => setSelectedProduct(data))
			produktService.getProduktVK(id).then(data => setSelectedProduktData(data))
		}
	},[id])

	useEffect(() => {
		if(proWert === 0){
			const wert =(Number(selectedProduktData)-(Number(selectedProduktData)*selectedProzentText/100));
			setNeuerVK((wert).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
			const wert1 =(wert-Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
			setNeuerDB(wert1);
		}
		else if(proWert === 1) {
			const wert =(Number(selectedProduktData)-(Number(selectedEuroText)));
			setNeuerVK((wert).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
			const wert1 =(wert-Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
			setNeuerDB(wert1);
		}
	},[selectedProduktData, selectedProzentText, selectedEuroText, proWert])

	useEffect(() => {
		var tempKategorienGruppe = 0;
		if(selectedKategorien || selectedKategorien === 0){
			tempKategorienGruppe = "Kategorie";
			if(selectedWarengruppen){
				tempKategorienGruppe = "Warengruppe";
				if(selectedArtikelgruppen){
					tempKategorienGruppe = "Artikelgruppe";
					

				}
			}
		}




		var temp = selectedProzentText > 0 ? selectedProzentText : selectedProzent
		if(tempKategorienGruppe &&  selectedGruppe != undefined && selectedGruppe != null && selectedGruppe.length > 0){
			aktionService.getProdukteRabattierbar(selectedGruppe,tempKategorienGruppe).then(data => {setNichtRabattierbar(data)})
			aktionService.getProdukteUngültig(selectedGruppe,tempKategorienGruppe).then(data => {setProdukteUngültig(data)})
		}
		if(ganzesSortiment){
			aktionService.getProdukteRabattierbar(0,"Sortiment").then(data => {setNichtRabattierbar(data)})
			aktionService.getProdukteUngültig(0,"Sortiment").then(data => {setProdukteUngültig(data)})
		}
		if(ganzesSortiment){
			aktionService.getProdukteRabattierbar(0,"frei").then(data => {setNichtRabattierbar(data)})
			aktionService.getProdukteUngültig(0,"frei").then(data => {setProdukteUngültig(data)})
		}


		if(tempKategorienGruppe &&  selectedGruppe != undefined && selectedGruppe != null && selectedGruppe.length > 0 && (temp || selectedEuroText)){
			if(proWert === 0){
				aktionService.getNiedrigerVK(tempKategorienGruppe, selectedGruppe, "Pro", temp).then(data => setProdukteVKzuNiederig(data));

			}
			else if(proWert === 1) {
				aktionService.getNiedrigerVK(tempKategorienGruppe, selectedGruppe, "Wert", selectedEuroText).then(data => setProdukteVKzuNiederig(data));

			}
		}

		if(ganzesSortiment && (temp || selectedEuroText)){
			if(proWert === 0){
				aktionService.getNiedrigerVK("Sortiment", 0, "Pro", temp).then(data => setProdukteVKzuNiederig(data));

			}
			else if(proWert === 1) {
				aktionService.getNiedrigerVK("Sortiment", 0, "Wert", selectedEuroText).then(data => setProdukteVKzuNiederig(data));

			}
		}
		if(freiWählbar && (temp || selectedEuroText)){
			if(proWert === 0){
				aktionService.getNiedrigerVK("frei", 0, "Pro", temp).then(data => setProdukteVKzuNiederig(data));

			}
			else if(proWert === 1) {
				aktionService.getNiedrigerVK("frei", 0, "Wert", selectedEuroText).then(data => setProdukteVKzuNiederig(data));

			}
		}

	},[selectedGruppe, selectedProzentText, selectedEuroText, selectedProzent, proWert, proWertIndivi, ganzesSortiment, freiWählbar])


	const loadProducts = () => {
        artikelgruppenService.getAll().then(data => {
			setKategorien(data.Kategorien);
			setWarengruppen(data.Warengruppen);
			setArtikelgruppen(data.Artikelgruppen);
			onKategorieChange(0)
		});
	}


	const onKategorieChange = (e) => {
        try {
            if (e !== undefined && e !== null && warengruppen && warengruppen.length > 0)
            {
                let _warengruppen = warengruppen.filter(function(x){
                    return x.KategorieID === e;
                });
                setWarengruppenOptions(_warengruppen);
                setSelectedKategorien(e);
				setSelectedWarengruppen(null);
				setArtikelgruppenOptions(null);
				setSelectedArtikelgruppen(null);
            }
        } catch (e) {
            console.log(e);
        }
    }
	
	const onWarengruppenChange = (e) => {
        try {
            if (e !== undefined && e !== null) {
                let _artikelgruppen = artikelgruppen.filter(function(x){
                    return x.WarengruppenID === e;
                });
                setArtikelgruppenOptions(_artikelgruppen);
                setSelectedWarengruppen(e);
            }
        } catch (e) {
            console.log(e);
        }
    }

	const clearFilterGruppe = () => {
		setSelectedGruppe([])
		setSelectedGruppeID(null)
	};

	const handleProzentButtonClick = (label) => {
		setSelectedProzentText(0)
		setSelectedProzent(label);
	};

	const onProzentChange = (prozent) => {
		setSelectedProzent(0);
		setSelectedProzentText(prozent.value)
	}

	const onEuroChange = (euro) => {
		setSelectedEuroText(euro.value)
	}

	const onSelecteVoucherAnzahlChange = (e) => {
		setSelectedVoucherAnzahl(e.value);
		if (e.value > 0) {
			setLoadingBarcodes(true);
			let allCodes = [];
			let totalCreated = 0;
	
			const fetchCodes = () => {
				const remaining = e.value - totalCreated;
				
				aktionService.getAktionsGutscheinCodes(remaining).then(data => {
					allCodes = allCodes.concat(data.codes);
					totalCreated += data.totalCreated;
	
					console.log(data);
	
					if (totalCreated < e.value) {
						fetchCodes(); 
					} else {
						setGutscheinData(allCodes);
						setLoadingBarcodes(false); 
					}
				}).catch(error => {
					console.log(error);
					setLoadingBarcodes(false); 
				});
			};
	
			fetchCodes(); 
		} else {
			setGutscheinData([]);
			setLoadingBarcodes(false);

		}
	};
		

	const handleDraufgabeClick = (label) => {
		setSelectedDraufgabeText(null)
		setSelectedDraufgabe(label)
	}

	const handleDreingabeClick = (label) => {
		setSelectedDreingabeText(null)
		setSelectedDreingabe(label)
	}

	const ausgewählteWochentage = () => {
		const wochentage = [
			montag && 'Montag',
			dienstag && 'Dienstag',
			mittwoch && 'Mittwoch',
			donnerstag && 'Donnerstag',
			freitag && 'Freitag',
			samstag && 'Samstag',
			sonntag && 'Sonntag'
		];
		const ausgewählteTage = wochentage.filter(Boolean);
		return ausgewählteTage.join(', ');
	}

	const onChangeShowSerie = (e) => {
		onClick(0)
		setShowSerie(e.value);
	}

	const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
            </div>
        )
    }
	const warengruppenFilter = () => {
		return (
            <MultiSelect className="column-filter" 
				value={selectedProduktgruppen} 
				options={warengruppen}
                onChange={onProduktgruppenFilterChange}
                placeholder="Alle"
                optionLabel="Bezeichnung"
                optionValue="Bezeichnung"
                filter/>
        );
	};
	const onProduktgruppenFilterChange = (event) => {
        dt.current.filter(event.value, 'Warengruppe', 'in');
        setSelectedProduktgruppen(event.value);
    };


	const einheitFilter = () => {
		return(
            <MultiSelect placeholder="Alle" 
				options={einheiten}
                value={selectedEinheit}
                onChange={onEinheitChange}
                style={{width: '100%'}}
            />
        );
	};
	const onEinheitChange = (e) => {
        dt.current.filter(e.value, 'Einheit', 'contains');
        setSelectedEinheit(e.value);
    }


	const IDFilter = () => {
		return (
            <InputText
				className="column-filter"  
                onChange={onIDFilterChange}
                value={selectedIDFilter}
                placeholder="Alle"
                optionLabel="Bezeichnung"
                optionValue="Bezeichnung"
                filter/>
        );
	};
	const onIDFilterChange = (e) => {
        dt.current.filter(e.target.value, 'ID', 'equals');
		setSelectedIDFilter(e.target.value);
    }

	
	const IDFilterRender = IDFilter();
	const warengruppenFilterRender = warengruppenFilter();
    const einheitFilterRender = einheitFilter();


	const onRowClickFilterTabelle = (e) => {
		setSelectedGruppe([]);
		setSelectedGruppeID(null);
		setSelectedProduct(e.data)
		setAnzahlProdukte(1)
		produktService.getProduktPreis(e.data.ID).then(data => {
			setSelectedProduktEK(data.EK);
		});
		produktService.getProduktVK(e.data.ID).then(data => {
			setSelectedProduktData(data);
		})
		produktService.getProdukt(e.data.ID).then(data => {
			setSelectedProduktDataAll(data);
		})
	}

	const onProduktRabattierbarChange = (e) => {
		setProduktRabattierbar(e.target.value);
	}

	const onGanzenSortimentChange = (e) => {
		setGanzesSortiment(e.target.value);
		setFreiWählbar(false);
		if(e.target.value === true){
			setSortiment(null);
			aktionService.getAnzahlProdukte("Sortiment", null, null, null).then(data => setAnzahlProdukte(data))
		}
	}

	const onFreiWählbarChange = (e) => {
		setFreiWählbar(e.target.value);
		setGanzesSortiment(false);
		if(e.target.value === true){
			setSortiment(null);
		}
	}

	const onStückAnzahlChange = (e) => {
		setSelectedStückAnzahl(e.value)
	}

	const istZuBillig = () => {
		console.log(produkteVKzuNiederig)

		if(selectedProduct != null && selectedProduct != undefined){
			if(Number(neuerVK.replace(",", ".")) <= 0 || Number(neuerDB.replace(",", ".")) <= 0){
				setShowVKzuNiedrig(true);
			}
			else{
				setGoToDB(true);
			}
		}
		else if(selectedGruppe.length > 0 || ganzesSortiment === true || freiWählbar === true){
			if(produkteVKzuNiederig.filter(product => product.Rabattartikel === true).length > 0){
				setShowVKzuNiedrigGruppe(true);
			}
			else{
				setGoToDB(true);
			}
		}
	}

	const onAddTyp = () => {
		if(newTyp != ""){
			let newTypObjekt = {
				"Bezeichnung": newTyp
			};
			aktionService.neuerTyp(newTypObjekt).then(data => {
				if(data){
					aktionService.getAktionsTypen().then(data => setTypOptions(data));
					setSelectedTyp(newTyp)
				}
				else{
					console.log("Error: Aktionstyp konnte nicht hinzugefügt werden.")
				}
			});
		}
		else{
			let messages = [];
			messages.push({
				severity: 'error',
				summary: 'Der Aktionstyp konnte nicht gespeichert werden!',
				life: 5000
			});

			toast.current.show(messages);

		}

		setShowNeuerTyp(false);
    };

	useEffect(()=>{
		aktionService.getAktionsTypen().then(data => setTypOptions(data));
	},[selectedTyp])


	return (
		<React.Fragment>
			<div className="col-12" style={{width:"100%"}}>                                 
				<div className="field grid">
					<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
					<Button label="Abbrechen" icon="pi pi-times" onClick={onCancelClick} />
				</div>
			</div>
			<div className="col-10">  
				<div className="field grid">
					<label htmlFor="id" className="col-fixed" style={{width: labelWidth, maxWidth:"10rem"}}>ID:  </label>
					<div className="col">
						<InputText 
							id="id" 
							type="text" 
							value={selectedID}
							disabled 
							style={{width: labelWidth, minWidth:"8rem"}}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="bezeichnung" className="col-fixed" style={{width: labelWidth, maxWidth:"10rem"}}>Bezeichnung: *</label>
					<div className="col">
						<InputText 
							value={selectedBezeichnung} 
							id="bezeichnung" 
							type="text" 
							style={{width:'100%', minWidth:"10rem", maxWidth:"12.5rem"}}
							onChange={e => setSelectedBezeichnung(e.target.value)}
							placeholder="Bezeichnung"
						/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="typ" className="col-fixed" style={{width: labelWidth, maxWidth:"10rem"}}>Typ: *</label>
					<div className="col">
					<Dropdown 
						value={selectedTyp} 
						options={typOptions}
						onChange={e => setSelectedTyp(e.value)} 
						optionLabel="aktionsTypBezeichnung"
						optionValue="aktionsTypID"
						id="bezeichnung"
						style={{ width: '100%', minWidth:"8rem", maxWidth:"12.5rem" }} 
						placeholder="Typ" 
						/>
						
					<Button
						icon="pi pi-plus" 
						label="Typ hinzufügen"
						backgroundColor="white"
						onClick={(e) => setShowNeuerTyp(true)}
						style={{marginLeft: window.innerWidth >= 768 ? "1rem": "0rem",
								marginTop: window.innerWidth <= 768 ? "1rem" : "0"
						}}
						/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="aktiv" className="col-fixed" style={{width: labelWidth, maxWidth:"10rem"}}>aktiv: </label>
					<div className="col">
						<InputSwitch                                
							checked={selectedAktiv}
							onChange={(e) => setSelectedAktiv(e.value)}
							autoFocus={false} 
							style={{ scale: "0.8", marginLeft:"-0.3rem", marginTop:"-0.1rem"}}
						/>
					</div>
				</div>
				<div className="field grid" style={{ position: 'relative', zIndex: 10 }}>
					<label className="col-fixed" style={{width: labelWidth, maxWidth:"10rem"}}>Gutschein - Codes: </label>
					
					{roles.includes('Chef') && (
						<div className="col-1">
							<InputSwitch                                
								checked={selectedVoucher}
								onChange={(e) => {setSelectedVoucher(e.value); setShowSerie(false)}}
								autoFocus={false} 
								style={{ scale: "0.8", marginLeft:"-0.3rem", marginTop:"-0.2rem"}}
							/>
						</div>
					)}
					{roles.includes('Lieferant') && (
						<div className="col-1" style={{ display: 'flex', alignItems: 'center' }}>
							<InputSwitch
								autoFocus={false} 
								style={{ scale: "0.8", marginLeft:"-0.3rem", marginTop:"-0.5rem"}}
								disabled={true}
							/>
							<i 
								className="pi pi-info-circle" 
								style={{marginLeft:"0.5rem", scale:"1.2", marginTop:"-0.5rem"}} 
								title={"Sie haben keine Berechtigung um Gutschein - Codes zu erstellen."}
								data-pr-tooltip="Information über Aktionen" 
							/>
						</div>
					)}
				</div>
				<div className="field grid" style={{ position: 'relative', zIndex: 10, width:"40rem" }}>
					<label htmlFor="Zeitraum" className="col-fixed" style={{ display: 'block', maxWidth:"10rem"}}>Zeitraum: </label>
					<div className="col">
						<table cellspacing="10" style={{marginLeft: isLargeScreen ? "4.3rem" : "-5rem", marginTop:isLargeScreen ? "0rem" : "1rem"}}>
							<tr>
								<td>Von: </td>
								<td>
									<Calendar
										id="selectedStart"
										value={serienDauerStart}
										onChange={(e) => setSerienDauerStart(e.value)}
										showTime={true}
										hourFormat="24"
										dateFormat="dd.mm.yy"
										showIcon={true}
										disabled={showSerie}
										style={{minWidth: isLargeScreen ? "8rem" : "2rem", maxWidth:isLargeScreen ? "200rem ": "11rem"}}
										minDate={new Date()}
									/>
								</td>
							</tr>
							<tr>
								<td>Bis: </td>
								<td>
									<Calendar
										id="selectedEnd"
										value={serienDauerEnd}
										onChange={(e) => setSerienDauerEnd(e.value)}
										showTime={true}
										hourFormat="24"
										dateFormat="dd.mm.yy"
										showIcon={true}
										style={{marginTop:"-0.4rem", maxWidth:isLargeScreen ? "200rem ": "11rem"}}
										disabled={showSerie}
										minDate={serienDauerStart}
									/>
								</td>
							</tr>
							<tr title={selectedVoucher ? "Serientermine sind nur verfübar, wenn es keine Gutscheine gibt!" : ""}>
								<td>Als Serie schalten: </td>
								<td>
									<InputSwitch 
										id="showSerie"
										checked={showSerie}
										onChange={(e) => onChangeShowSerie(e)}
										style={{scale: "0.8"}}
										disabled={selectedVoucher}
									/>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="bild" className="col-fixed" style={{ display: 'block', maxWidth:"10rem"}}>Bild: </label>
					<div className="col" style={{ 
						marginLeft: isLargeScreen ? "7.3rem" : "0rem", 
						marginTop:"-0.5rem",
						display: 'flex', 
						flexDirection: 'row',
						alignItems: 'flex-start'
					}}>
						<FileUpload ref={uploadRef} chooseOptions={{label: 'Wähle', icon: 'pi pi-fw pi-upload'}} customUpload name="bild" mode="basic" accept="image/*" 
							maxFileSize={1000000} 
							style={{ display: 'inline-block' }}
							onClear={() => setUploadClearDisabled(true)} 
							onChange={() => setUploadClearDisabled(false)}
							/>
						<Button icon="pi pi-trash" 
							onClick={() => {uploadRef.current.clear(); setUploadClearDisabled(true)}} 
							disabled={uploadClearDisabled} 
							style={{
								backgroundColor: "red",
								color: "white",
								border: "none",
								boxShadow: "none",
								height: "2.3rem",
								width: "2.3rem",
								marginLeft:"0.5rem"
							  }}
							  />
					</div>
					<div className="col" style={{ marginTop: '1rem' }}>
						{produktDataEdit && produktDataEdit.length > 0 && produktDataEdit.FotoID !== null &&
							<img src={imageData} style={{ width: "200px", display: 'block', marginTop: '1rem', marginLeft:"-34vw" }} />
						}
					</div>
				</div>
				{roles.includes('Chef') && (
					<div className="field grid">
						<Accordion style={{width:"100%"}}>
							<AccordionTab 
								header={
									<React.Fragment>
										<span style={{verticalAlign: "middle"}}>Gutschein - Codes</span>
										<i 
											className="pi pi-info-circle" 
											style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
											title={"Einstellungen für die Gutscheine."}
											data-pr-tooltip="Information über Aktionen" 
										/>
										<span style={{ marginRight: "auto", marginLeft: isLargeScreen ? "1rem" : "0.5rem", verticalAlign: "middle", color:"silver" }}>  
											(aktuell ausgewählt: {selectedVoucherAnzahl} Gutscheine, {selectedVoucherEinmalig ? "endlos" : "einmalig"})
										</span>

									</React.Fragment>
								}
								hidden={!selectedVoucher}
								>
								{isLargeScreen && (
									<div className="field grid">
										<div className="col-4">
											<label className="col-fixed" style={{width: labelWidth, maxWidth:"15rem", marginTop:"-0.2rem"}}>Anzahl der Gutscheine:  </label>
											<InputNumber 
												value = {selectedVoucherAnzahl} 
												onChange = {(e) => onSelecteVoucherAnzahlChange(e)} 
												mode = "decimal"
												min = {0}
												max = {10000}
												showButtons
												inputStyle = {{width:"4.5rem", height:"1.9rem"}}
												style = {{width:"4.5rem", height:"1.9rem", marginTop:"1rem"}}
												/>
										</div>
										<div className="col-3">
											{loadingBarcodes && (
												<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
													<i
														className="pi pi-spin pi-spinner"
														style={{
															fontSize: '2rem',
															zIndex: 1000,
															position: 'absolute',
															marginTop:"6rem",
															marginLeft:"-1rem"
														}}
														></i>
												</div>
											)}
											<DataTable 
												value={gutscheinData}
												scrollHeight="200px" 
												scrollable  
												style={{
													width:"14rem", 
													minHeight:"200px", 
													marginTop:"1rem", 
													opacity: loadingBarcodes ? 0.5 : 1, 
													pointerEvents: loadingBarcodes ? 'none' : 'auto'
												}}
												responsive
												className="p-datatable-striped tableCursorPointer"
												rowHover
												scrollDirection="both"
												disabled={loadingBarcodes}
												>
												<Column 
													field={gutscheinData[0]?.akCode ? "akCode" : "code"} 
													header="Barcodes"
												/>				
											</DataTable>

										</div>
										<div className="col-2">
											<Button
												icon="pi pi-refresh" 
												backgroundColor="white"
												onClick={refreshBarcodes}
												label="Barcodes erneuern"
												style={{marginTop:"1rem"}}
												disabled={loadingBarcodes}
											/>
										</div>
										
										<div className="col-12">
											<SelectButton 
												value={selectedVoucherEinmalig} 
												options={[{ value: 0, name: "Einmalig"},{ value: 1, name: "Endlos"}]}
												optionLabel="name" 
												optionValue="value"
												onChange={(e) => {setSelectedVoucherEinmalig(e.value);}}
												style={{marginTop:"-9rem"}}
												/>

										</div>
									</div>
								)}

								{!isLargeScreen && (
									<div className="field grid">

										<div className="col-12">
											<SelectButton 
												value={selectedVoucherEinmalig} 
												options={[{ value: 0, name: "Einmalig"},{ value: 1, name: "Endlos"}]}
												optionLabel="name" 
												optionValue="value"
												onChange={(e) => {setSelectedVoucherEinmalig(e.value);}}
												/>
										</div>

										<div className="col-12" style={{marginTop:"2rem"}}>
											<label className="col-fixed" style={{width: labelWidth, maxWidth:"15rem", marginTop:"2rem"}}>Anzahl der Gutscheine:  </label>
											<InputNumber 
												value = {selectedVoucherAnzahl} 
												onChange = {(e) => onSelecteVoucherAnzahlChange(e)} 
												mode = "decimal"
												min = {0}
												max = {10000}
												showButtons
												inputStyle = {{width:"4rem", height:"1.9rem"}}
												style = {{width:"4.5rem", height:"1.9rem", marginTop:"1rem", marginLeft:"7.5rem"}}
												/>
										</div>

										<div className="col-12">
											{loadingBarcodes && (
												<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
													<i
														className="pi pi-spin pi-spinner"
														style={{
															fontSize: '2rem',
															zIndex: 1000,
															position: 'absolute',
															marginTop:"6rem",
															marginLeft:"-1rem"
														}}
														></i>
												</div>
											)}
											<DataTable 
												value={gutscheinData}
												scrollHeight="200px" 
												scrollable  
												style={{
													width:"14rem", 
													minHeight:"200px", 
													marginTop:"1rem", 
													opacity: loadingBarcodes ? 0.5 : 1, 
													pointerEvents: loadingBarcodes ? 'none' : 'auto'
												}}
												responsive
												className="p-datatable-striped tableCursorPointer"
												rowHover
												scrollDirection="both"
												disabled={loadingBarcodes}
												>
												<Column 
													field={gutscheinData[0]?.akCode ? "akCode" : "code"} 
													header="Barcodes"
												/>				
											</DataTable>

										</div>
										<div className="col-12">
											<Button
												icon="pi pi-refresh" 
												backgroundColor="white"
												onClick={refreshBarcodes}
												label="Barcodes erneuern"
												style={{marginTop:"1rem"}}
												disabled={loadingBarcodes}
											/>
										</div>
									</div>
								)}
							</AccordionTab>
						</Accordion>
					</div>
				)}

				<div className="field grid">
					<Accordion style={{
						width:"100%", 
						marginTop: roles.includes('Chef') ? (selectedVoucher ? "0rem" : "-1rem") : "0rem"

						}}>
						<AccordionTab 
							header={
								<React.Fragment>
									<span style={{verticalAlign: "middle"}}>Sortiment *</span>
									<i 
										className="pi pi-info-circle" 
										style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
										title={"Eine Kategorie oder ein direktes Produkt auswählen, auf welches die Aktion gelten soll."}
										data-pr-tooltip="Information über Aktionen" 
									/>
									<span style={{ marginRight: "auto", marginLeft:"1rem", verticalAlign: "middle", color:"silver" }}>
										{selectedProduct 
												? `(aktuell ausgewählt: ${produkte.find(product => product.ID === selectedProduct.ID)?.Bezeichnung}, betrifft ${anzahlProdukte} Produkt)`
												: `(aktuell ausgewählt: ${selectedGruppe}, betrifft ${anzahlProdukte} Produkte)`
										}
										<i 
												className="pi pi-info-circle" 
												style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
												title={"Gibt an, auf wie viele Produkte diese Aktion zutrifft. (Berechtigt und Rabattartikel)"}
												data-pr-tooltip="Information über Aktionen" 
											/>
									</span>

								</React.Fragment>
							} onClick={loadProducts}>
							<div className="flex" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
								<div style={{ flex: 1, marginRight: '2rem'}}>
									<div style={{ display: 'flex', alignItems: 'center' }}>	
										{isLargeScreen && (
											<>									
											<SelectButton 
												value={sortiment} 
												options={[{ value: 1, name: "Sortiment"},{ value: 0, name: "Produkt"}]}
												optionLabel="name" 
												optionValue="value"
												onChange={(e) => {setSortiment(e.value); setSelectedGruppe([]); setSelectedProduct(null); setAnzahlProdukte(0); setSelectedProduktDataAll(null)}}
												disabled={ganzesSortiment === true || freiWählbar === true}
												style={{width:"18rem", maxWidth:"18rem", minwidth:"18rem"}}
												/>

												<span style={{marginLeft: "1.5rem"}}>Aktion auf ganzes Sortiment: </span>
												<InputSwitch                                 
													checked={ganzesSortiment} 
													onChange={(e) => onGanzenSortimentChange(e)}
													autoFocus={false} 
													style={{ scale: "0.8", verticalAlign:"middle", marginLeft: "0.5rem"}}
												/>

												{roles.includes('Chef') && (
													<>
														<span style={{marginLeft: "2rem"}}>Artikel frei wählbar: </span>
														<InputSwitch                                 
															checked={freiWählbar} 
															onChange={(e) => onFreiWählbarChange(e)}
															autoFocus={false} 
															style={{ scale: "0.8", verticalAlign:"middle", marginLeft: "0.5rem"}}
															/>
													</>
												)}
											</>
										)}
										{!isLargeScreen && (
											<>
												<div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
													
													<span style={{marginLeft: "0rem"}}>Aktion auf ganzes Sortiment: </span>
													<InputSwitch                                 
														checked={ganzesSortiment} 
														onChange={(e) => onGanzenSortimentChange(e)}
														autoFocus={false} 
														style={{ scale: "0.8", verticalAlign:"middle", marginLeft: "13rem", marginTop:"-1.5rem"}}
														/>
													<span style={{marginTop:"1rem"}}>Artikel frei wählbar: </span>
													<InputSwitch                                 
														checked={freiWählbar} 
														onChange={(e) => onFreiWählbarChange(e)}
														autoFocus={false} 
														style={{ scale: "0.8", verticalAlign:"middle",  marginLeft: "13rem", marginTop:"-1.5rem"}}
														/>
													<SelectButton 
														value={sortiment} 
														options={[{ value: 1, name: "Sortiment"},{ value: 0, name: "Produkt"}]}
														optionLabel="name" 
														optionValue="value"
														onChange={(e) => {setSortiment(e.value); setSelectedGruppe([]); setSelectedProduct(null); setAnzahlProdukte(0); setSelectedProduktDataAll(null)}}
														disabled={ganzesSortiment === true || freiWählbar === true}
														style={{width:"18rem", maxWidth:"18rem", minwidth:"18rem", marginTop:"1rem"}}
														/>
												</div>
											</>
										)}
									</div>

							{sortiment === 1 && (
                            <div className="field grid" style={{marginTop:"1rem"}}>
                                <label htmlFor="kategorie" className="col-fixed" style={{width:labelWidth}}>Gruppe: </label>
									<div className="col">
										<InputText id="kategorie" 
											value={selectedGruppe} 
											style={{width: '20em', maxWidth:"14em"}}
											filterBy="Name"
											onClick={(e) => setKategorienVisible(true)}
										/>
										{(selectedKategorien || selectedWarengruppen || selectedArtikelgruppen) && (
											<Button
												icon="pi pi-times" 
												className="p-button-rounded p-button-text"
												backgroundColor="white"
												onClick={clearFilterGruppe}
												style={{marginLeft: "-3rem"}}
											/>
										)}
                                	</div>
                            </div>
							)}


							{sortiment === 0 && (
							<div className="field grid" style={{marginTop:"0rem"}}>
                                <label htmlFor="kategorie" className="col-fixed" style={{width:labelWidth, marginTop:"1rem"}}>Produkt: </label>
									<div className="col">
										<InputText id="produkt" 
											value={selectedProduct ? selectedProduct.Bezeichnung : ''} 
											style={{width: '20em', maxWidth:"14em"}}
											filter={true} 
											filterBy="label"
											disabled
										/>
										<Button label="Suchen" style={{marginTop:"1rem", marginLeft:isLargeScreen ? "10px" : "-0rem"}} icon="pi pi-search" onClick={e => setVisibleProduktauswahl(true)}></Button>
									</div>
							</div>	
							)}


							{selectedProduct?.Rabattartikel === false && (
								<>
								<div class="flex">
									<span>
										Diese Produkt ist aktuell nicht rabattierbar!
									</span>		
								</div>
								<div style={{ marginTop: '10px' }}>	
									<InputSwitch                                 
										checked={produktRabattierbar} 
										onChange={(e) => onProduktRabattierbarChange(e)}
										autoFocus={false} 
										style={{ scale: "0.8", verticalAlign:"middle"}}
									/>
									<span style={{verticalAlign:"middle"}}> Produkt rabattierbar machen </span>		
								</div>
								</>
							)}
							
							 {(selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && !isLargeScreen && (
							<div>
								<Button
										label={`${nichtRabattierbar.length} Produkt/e sind nicht rabattierbar`}
										icon="pi pi-exclamation-triangle"
										style={{width: isLargeScreen ? "105%" : "83%"}}
										onClick={(e) => showProdukteRabattierbarChange(e)}
									/>
							</div>
							)}


 							</div>
							{(selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && nichtRabattierbar.length > 0 && isLargeScreen && (
								
							<div>
								<Button
									label={`${nichtRabattierbar.length} Produkt/e sind nicht rabattierbar`}
									icon="pi pi-exclamation-triangle"
									style={{marginLeft:"2rem", marginTop:"3.35rem", marginLeft:"-40rem"}}
									onClick={(e) => showProdukteRabattierbarChange(true)}
								/>
							</div>
							)}
						</div>


                        </AccordionTab>
					</Accordion>
				</div>

				<div className="field grid">
					<Accordion style={{width:"100%"}}>
						<AccordionTab 
							header={
								<React.Fragment>
									<span>Aktion *</span>
									<i 
										className="pi pi-info-circle" 
										style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
										title={"Art und Höhe der Aktion definieren."}
										data-pr-tooltip="Information über Aktionen" 
									/>
									
									<span style={{ marginRight: "auto", marginLeft:"1rem", verticalAlign: "middle", color:"silver" }}>
										{`(aktuell ausgewählt: 
											${[
												{ value: 0, name: "Rabatt:"},
												{ value: 1, name: "Draufgabe:"}, 
												{ value: 2, name: "Dreingabe:"},
												{ value: 3, name: "Mengenrabatt:"}
												].find(product => product.value === aktion)?.name || ''}  
												${aktion === 0 && proWert === 0
													? selectedProzentText ? `${selectedProzentText} %` : `${selectedProzent} %`
													: aktion === 0 && proWert === 1
													? selectedEuroText ? `${selectedEuroText.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` : ""
													: aktion === 1    
													? selectedDraufgabeText || selectedDraufgabe 
													: aktion === 2 
													? selectedDreingabeText || selectedDreingabe
													: aktion === 3 && proWert === 0
													? selectedProzentText ? `${selectedProzentText}% ab ${selectedStückAnzahl} Stück` : selectedProzent + ` ab ${selectedStückAnzahl} Stück`
													: aktion === 3 && proWert === 1
													? selectedEuroText ? `${selectedEuroText.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} € ab ${selectedStückAnzahl} Stück` : ""
													: ''})`}
										
									</span>
								</React.Fragment>
							}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>	
									<div style={{ flex: 5, marginRight: '20px' }}>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'nowrap'}}> 
											<SelectButton 
												value={aktion}
												options={[
													{ value: 0, name: "Rabatt" },
													...(sortiment === 1 || sortiment === null || sortiment === undefined ||
														(
															(selectedProduktDataAll?.VEEinheit !== "KG" &&
															selectedProduktDataAll?.VEEinheit !== "g" ) &&

															(!selectedProduktDataAll?.BarcodePreisAbhaengig &&
															!selectedProduktDataAll?.BarcodeGewichtsAbhängig &&
															!selectedProduktDataAll?.manuellerPreis
															)
														) 
													  ? [
														{ value: 1, name: "Draufgabe" },
														{ value: 2, name: "Dreingabe" }
													  ] : []),
													{ value: 3, name: "Mengenrabatt" }
												]}								
												optionLabel="name"
												optionValue="value"
												onChange={(e) => setAktion(e.value)} 
											/>
											<i 
												className="pi pi-info-circle" 
												style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
												title={"Rabatt: Das ausgewählte Produkt/Sortiment wird um den gewählten Rabatt wert-/prozentmäßig reduziert. \nDraufgabe: Der Endkäufer kauft 2 Produkte, und bekommt das 3.Produkt gratis dazu. \nDreingabe: Der Endkäufer kauft 3 Produkte, muss aber nur 2 Produkte zahlen."}
												data-pr-tooltip="Information über Aktionen" 
											/>

										</div>
								{aktion === 0 && (
									<>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'nowrap'}}>
										<SelectButton 
											value={proWert} 
											options={[{ value: 0, name: "prozentmäßig"},{ value: 1, name: "wertmäßig"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setProWert(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										<i 
											className="pi pi-info-circle" 
											style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"1rem"}} 
											title={"Der Wertmäßig Rabattbetrag wird in Brutto gerechnet/angegeben."}
											data-pr-tooltip="Information über Aktionen" 
										/>
									</div>
									{proWert === 0 && (
										<>
										<SelectButton 
											value={proWertIndivi} 
											options={[{ value: 0, name: "vordefiniert"},{ value: 1, name: "individuell"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setProWertIndivi(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										{proWertIndivi === 1 && (
											<>
												<div className="input-container" style={{ display: 'flex' , marginTop:"2rem"}}>
													<InputNumber 
														placeholder="Individueller %-Wert" 
														value={selectedProzentText} 
														onChange={(e) => onProzentChange(e)} 
														suffix=" %"
														locale="de-DE" 
														max={100}
														min={0}
														buttonLayout="horizontal"
														showButtons
														incrementButtonIcon="pi pi-plus"
														decrementButtonIcon="pi pi-minus"
														decrementButtonClassName="p-button-danger" 
														incrementButtonClassName="p-button-success" 
														style={{maxWidth:"4rem", height:"40px"}}
														inputStyle={{width: '5em', textAlign: 'center'}}/>
												{(selectedProduct != null) && ganzesSortiment === false && freiWählbar === false && (
													<>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<div style={{ display: 'flex'}}>
															<span style={{ marginLeft:"8rem", marginTop:"0.4rem"}}>
															Neuer Verkaufspreis: {neuerVK}
															</span>
															
															<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
														</div>
														<div style={{ marginLeft: "1rem", marginTop: "0.5rem", fontSize: "1rem", color: "gray", marginRight:"-20rem" }}>
															{selectedProduktData === selectedProduktEK &&(
																<div style={{ display: 'flex', verticalAlign:"middle"}}>
																	<span style={{ marginLeft:"7rem", marginTop:"0.4rem", color:"red", width:"30rem"}}>
																		Der Verkaufspreis ist gleich wie der Einkaufspreis, wodurch der DB möglicherweise nicht stimmt.
																	</span>
																</div>	
															)}	
															{selectedProduktEK === 0 && selectedProduktData != 0 &&(
																<div style={{ display: 'flex', verticalAlign:"middle"}}>
																	<span style={{ marginLeft:"7rem", marginTop:"0.4rem", color:"red"}}>
																		Der Einkaufspreis ist 0,00 €, wodurch der DB möglicherweise nicht stimmt.
																	</span>
																</div>	
															)}	
														</div>
													</div>

													<div style={{ display: 'flex', flexDirection: 'column' }}>

														<div style={{ display: 'flex', verticalAlign:"middle"}}>
															<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
																Neuer DB: {neuerDB}
															</span>
															
															<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
														</div>	
													</div>
													
													
													
																			
													</>
												)}
												</div>
											</>
										)}
										{proWertIndivi === 0 && (
											<>
											<div className="button-container" 
												style={{ 
													display: 'flex', 
													flexWrap: 'wrap', 
													gap: '0.5rem',
													marginTop: '2rem', 
													flexDirection: isLargeScreen ? 'row' : 'column' 
												}}>
												{/* Erste Reihe */}
												<div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', width: '100%', flexDirection: isLargeScreen ? 'row' : 'column' }}>
													{['10', '15', '20', '25', '30', '40'].map((label) => (
													<Button
														key={label}
														label={label + " %"}
														onClick={() => handleProzentButtonClick(label)}
														className={`p-button ${selectedProzent === label ? 'p-button-primary' : 'p-button-secondary'}`}
														style={{width:"5rem" }}
													/>
													))}
												</div>

												{/* Zweite Reihe */}
												<div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', width: '100%', flexDirection: isLargeScreen ? 'row' : 'column'}}>
													{['50', '60', '75', '80', '90', '100'].map((label) => (
													<Button
														key={label}
														label={label + " %"}
														onClick={() => handleProzentButtonClick(label)}
														className={`p-button ${selectedProzent === label ? 'p-button-primary' : 'p-button-secondary'}`}
														style={{maxWidth:"5rem",width:"5rem"}}
													/>
													))}
												</div>
												</div>
											</>
										)}
										</>
									)}
									{proWert === 1 && (
										<div className="input-container" style={{ display: 'flex' , marginTop:"2rem"}}>
											<InputNumber 
												placeholder="Individueller €-Wert" 
												value={selectedEuroText} 
												onChange={(e) => onEuroChange(e)} 
												mode="currency" 
												currency="EUR" 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px"}}/>

										{(selectedProduct != null) && ganzesSortiment === false && freiWählbar === false && (
											<>
											<div style={{ display: 'flex'}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer Verkaufspreis: {(Number(selectedProduktData) - Number(selectedEuroText)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
											<div style={{ display: 'flex', verticalAlign:"middle"}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer DB: {(Number(selectedProduktData) - Number(selectedEuroText)	- Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
										</>
										)}
										</div>
									)}
									</>
								)}
								{aktion === 1 && (
									<>
										<SelectButton 
											value={draufgabeIndivi} 
											options={[{ value: 0, name: "vordefiniert"},{ value: 1, name: "individuell"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setDraufgabeIndivi(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										{draufgabeIndivi === 1 && (
											<>
											<div className="input-container" style={{ display: 'flex', alignItems: 'center', marginTop:"2rem" }}>
												<InputNumber mode="decimal" showButtons buttonLayout="vertical" min={1} max={99} style={{ width: '2rem' }} 
													value={selectedDraufgabeText1} 
													onChange={(e) => setSelectedDraufgabeText1(e.value)}
													incrementButtonIcon="pi pi-plus"
													decrementButtonIcon="pi pi-minus"
													decrementButtonClassName="p-button-danger" 
													incrementButtonClassName="p-button-success" />
												<label style={{marginLeft:"0.5rem"}}>+</label>
												<InputNumber mode="decimal" showButtons buttonLayout="vertical" min={1} max={selectedDraufgabeText1-1} style={{ width: '2rem', marginLeft:"0.5rem" }} 
													value={selectedDraufgabeText2} 
													onChange={(e) => setSelectedDraufgabeText2(e.value)}
													incrementButtonIcon="pi pi-plus"
													decrementButtonIcon="pi pi-minus"
													decrementButtonClassName="p-button-danger" 
													incrementButtonClassName="p-button-success" />
												<label style={{marginLeft:"0.5rem"}}>Gratis</label>
											</div>
											</>
										)}
										{draufgabeIndivi === 0 && (
											<>
											<div className="button-container" style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', marginTop:"2rem"}}>

												{['1 + 1 Gratis', '2 + 1 Gratis', '3 + 1 Gratis'].map((label) => (
													<Button
														key={label}
														label={label}
														onClick={() => handleDraufgabeClick(label)}
														style={{height:"2rem"}}
														className={`p-button ${selectedDraufgabe === label ? 'p-button-primary' : 'p-button-secondary'}`
													}
													/>
												))}												
											</div>
											</>
										)}
										{selectedGruppe.length > 0 &&(
										<div style={{marginTop:"1rem"}}>
											<span style={{ color: 'red'}}>Diese Aktionsart ist nicht gültig auf folgende Produkte: </span>
												<ul style={{ color: 'red', marginTop:"0rem"}}>
													<li>Produkte die Gewichtsanhängig sind, </li>
													<li>Produkte die Preisanhägig sind, </li>
													<li>Produkte bei denen die Verechnugseinheit "Kilogramm" oder "Gramm" ist </li>
												</ul>
										</div>
										)}
									</>
								)}
								
								{aktion === 2 && (
									<>
										<SelectButton 
											value={dreingabeIndivi} 
											options={[{ value: 0, name: "vordefiniert"},{ value: 1, name: "individuell"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setDreingabeIndivi(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										{dreingabeIndivi === 1 && (
											<>
												<div className="input-container" style={{ display: 'flex', alignItems: 'center', marginTop:"2rem" }}>
													<label>Nimm</label>
													<InputNumber mode="decimal" showButtons buttonLayout="vertical" min={1} max={99} style={{ width: '2rem', marginLeft:"0.5rem" }} 
														value={selectedDreingabeText1} 
														onChange={(e) => setSelectedDreingabeText1(e.value)}
														incrementButtonIcon="pi pi-plus"
														decrementButtonIcon="pi pi-minus"
														decrementButtonClassName="p-button-danger" 
														incrementButtonClassName="p-button-success" 
														/>
													<label style={{marginLeft:"0.5rem"}}>, zahl</label>
													<InputNumber mode="decimal" showButtons buttonLayout="vertical" min={1} max={selectedDreingabeText1-1} style={{ width: '2rem', marginLeft:"0.5rem" }} 
														value={selectedDreingabeText2} 
														onChange={(e) => setSelectedDreingabeText2(e.value)}
														incrementButtonIcon="pi pi-plus"
														decrementButtonIcon="pi pi-minus"
														decrementButtonClassName="p-button-danger" 
														incrementButtonClassName="p-button-success" 
														/>
												</div>
											</>
										)}
										{dreingabeIndivi === 0 && (
											<div className="button-container" style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', marginTop:"2rem"}}>
											{['Nimm 2, zahl 1', 'Nimm 3, zahl 2'].map((label) => (
												<Button
													key={label}
													label={label}
													onClick={() => handleDreingabeClick(label)}
													className={`p-button ${selectedDreingabe === label ? 'p-button-primary' : 'p-button-secondary'}`}
													style={{ height: '2rem' }} 
												/>
												))}
										</div>
										)}
										{selectedGruppe.length > 0 &&(
											<div style={{marginTop:"1rem"}}>
												<span style={{ color: 'red'}}>Diese Aktionsart ist nicht gültig auf folgende Produkte: </span>
												<ul style={{ color: 'red', marginTop:"0rem"}}>
													<li>Produkte die Gewichtsanhängig sind, </li>
													<li>Produkte die Preisanhägig sind, </li>
													<li>Produkte bei denen die Verechnugseinheit "Kilogramm" oder "Gramm" ist </li>
												</ul>
											</div>
										)}
									</>
								)}


								{aktion === 3 && (
									
									<div style={{ display: isLargeScreen ? 'block' : 'none' }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'nowrap'}}>
										<SelectButton 
											value={proWert} 
											options={[{ value: 0, name: "prozentmäßig"},{ value: 1, name: "wertmäßig"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setProWert(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										<i 
											className="pi pi-info-circle" 
											style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"1rem"}} 
											title={"Der Wertmäßig Mengenrabattbetrag wird in Brutto gerechnet/angegeben."}
											data-pr-tooltip="Information über Aktionen" 
										/>
									</div>
									{proWert === 0 && (
										<div className="input-container" style={{ display: 'flex' , marginTop:"2rem", verticalAlign:"middle", flexWrap: 'nowrap'}}>
											<span style={{marginTop:"0.5rem"}}> Ab </span>
											<InputNumber 
												placeholder="Stück"
												value={selectedStückAnzahl} 
												onChange={(e) => onStückAnzahlChange(e)} 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px", marginLeft:"1rem"}}/>

											<span style={{marginLeft:"1rem", marginTop:"0.5rem"}}> Stück: </span>

											<InputNumber 
												placeholder="Individueller %-Wert" 
												value={selectedProzentText} 
												onChange={(e) => onProzentChange(e)} 
												suffix=" %"
												locale="de-DE" 
												max={100}
												min={0}
												buttonLayout="horizontal"
												showButtons
												incrementButtonIcon="pi pi-plus"
												decrementButtonIcon="pi pi-minus"
												decrementButtonClassName="p-button-danger" 
												incrementButtonClassName="p-button-success" 
												style={{maxWidth:"2rem", height:"40px", marginLeft:"1rem"}}
												inputStyle={{width: "100px", textAlign: 'center'}}/>

											<span style={{marginLeft:"10rem", marginTop:"0.5rem", width:"5rem"}}> Rabatt, auf  </span>

											<Dropdown 
												value={selectedMengenrabatt} 
												options={[{ value: 0, name: "alle Produkte dieser Art"},{ value: 1, name: "jedes weitere Produkt"}]}
												optionLabel="name" 
												optionValue="value"
												onChange={e => setSelectedMengenrabatt(e.value)} 
												style={{ width: '100%', minWidth:"8rem", maxWidth:"12.5rem", marginLeft:"1rem"}} 
												placeholder="Options" 
											/>
											
											{selectedMengenrabatt === 0 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes Stück um ${selectedProzentText ?? 0} % günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}
											{selectedMengenrabatt === 1 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes weitere Stück um ${selectedProzentText ?? 0} % günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}

										{selectedProduct != null && ganzesSortiment === false && freiWählbar === false  && (
											<>
											<div style={{ display: 'flex'}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer Verkaufspreis: {(Number(selectedProduktData) - Number(selectedEuroText)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
											<div style={{ display: 'flex', verticalAlign:"middle"}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer DB pro Stück: {(Number(selectedProduktData) - Number(selectedEuroText)	- Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
										</>
										)}
										</div>
									)}
									{proWert === 1 && (
										<div className="input-container" style={{ display: 'flex' , marginTop:"2rem", verticalAlign:"middle", flexWrap: 'nowrap'}}>
											<span style={{marginTop:"0.5rem"}}> Ab </span>
											<InputNumber 
												placeholder="Stück"
												value={selectedStückAnzahl} 
												onChange={(e) => onStückAnzahlChange(e)} 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px", marginLeft:"1rem"}}/>

											<span style={{marginLeft:"1rem", marginTop:"0.5rem"}}> Stück: </span>

											<InputNumber 
												placeholder="Individueller €-Wert"
												value={selectedEuroText} 
												onChange={(e) => onEuroChange(e)} 
												mode="currency" 
												currency="EUR" 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px", marginLeft:"1rem"}}/>
											<span style={{marginLeft:"1rem", marginTop:"0.5rem", width:"5rem"}}> Rabatt, auf  </span>


											<Dropdown 
												value={selectedMengenrabatt} 
												options={[{ value: 0, name: "alle Produkte dieser Art"},{ value: 1, name: "jedes weitere Produkt"}]}
												optionLabel="name" 
												optionValue="value"												
												onChange={e => setSelectedMengenrabatt(e.value)} 
												style={{ width: '100%', minWidth:"8rem", maxWidth:"12.5rem", marginLeft:"1rem"}} 
												placeholder="Options" 
											/>
											{selectedMengenrabatt === 0 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes Stück um ${selectedEuroText ?? 0} € günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}
											{selectedMengenrabatt === 1 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes weitere Stück um ${selectedEuroText ?? 0} € günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}

										{selectedProduct != null && ganzesSortiment === false && freiWählbar === false &&(
											<>
											<div style={{ display: 'flex'}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer Verkaufspreis: {(Number(selectedProduktData) - Number(selectedEuroText)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
											<div style={{ display: 'flex', verticalAlign:"middle"}}>
												<span style={{ marginLeft:"1rem", marginTop:"0.4rem"}}>
													Neuer DB pro Stück: {(Number(selectedProduktData) - Number(selectedEuroText)	- Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.4rem"}}> €</span>
											</div>
										</>
										)}
										</div>
									)}
									</div>
								)}
								


								{aktion === 3 && (
									<div style={{ display: isLargeScreen ? 'none' : 'block' }}>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'nowrap'}}>
										<SelectButton 
											value={proWert} 
											options={[{ value: 0, name: "prozentmäßig"},{ value: 1, name: "wertmäßig"}]}
											optionLabel="name" 
											optionValue="value"
											onChange={(e) => setProWert(e.value)}
											style={{marginTop:"1rem"}} 
										/>
										<i 
											className="pi pi-info-circle" 
											style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"1rem"}} 
											title={"Der Wertmäßig Mengenrabattbetrag wird in Brutto gerechnet/angegeben."}
											data-pr-tooltip="Information über Aktionen" 
										/>
									</div>
									{proWert === 0 && (
										<>
										<div className="input-container" style={{ display: 'flex', flexDirection: 'column', marginTop: "2rem", verticalAlign: "middle" }}>
											<span style={{marginTop:"0.5rem"}}> Ab </span>
											<InputNumber 
												placeholder="Stück"
												value={selectedStückAnzahl} 
												onChange={(e) => onStückAnzahlChange(e)} 
												locale="de-DE"
												min={0}
												inputStyle={{width: "20px", height:"40px", marginTop:"0.5rem"}}/>

											<span style={{marginTop:"0.5rem"}}> Stück: </span>

											<InputNumber 
												placeholder="Individueller %-Wert" 
												value={selectedProzentText} 
												onChange={(e) => onProzentChange(e)} 
												suffix=" %"
												locale="de-DE" 
												max={100}
												min={0}
												buttonLayout="horizontal"
												showButtons
												incrementButtonIcon="pi pi-plus"
												decrementButtonIcon="pi pi-minus"
												style={{height:"40px", marginTop:"0.5rem"}}
												inputStyle={{width: "100px"}}/>

											<span style={{marginTop:"0.5rem"}}> Rabatt, auf  </span>
											<Dropdown 
												value={selectedMengenrabatt} 
												options={[{ value: 0, name: "alle Produkte dieser Art"},{ value: 1, name: "jedes weitere Produkt"}]}
												optionLabel="name" 
												optionValue="value"												
												onChange={e => setSelectedMengenrabatt(e.value)} 
												style={{ width: '100%', minWidth:"8rem", maxWidth:"12.5rem", marginTop:"0.5rem"}} 
												placeholder="Options" 
											/>
											{selectedMengenrabatt === 0 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes Stück um ${selectedProzentText ?? 0} % günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}
											{selectedMengenrabatt === 1 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes weitere Stück um ${selectedProzentText ?? 0} % günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}

										{selectedProduct != null && ganzesSortiment === false && freiWählbar === false && (
											<>
											<div style={{ display: 'flex'}}>
												<span style={{marginTop:"1rem"}}>
													Neuer Verkaufspreis: {(Number(selectedProduktData) - Number(selectedEuroText)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"1rem"}}> €</span>
											</div>
											<div style={{ display: 'flex', verticalAlign:"middle"}}>
												<span style={{marginTop:"0.5rem"}}>
													Neuer DB: {(Number(selectedProduktData) - Number(selectedEuroText)	- Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.5rem"}}> €</span>
											</div>
										</>
										)}
										</div>
										</>
									)}
									{proWert === 1 && (
										<div className="input-container" style={{ display: 'flex', flexDirection: 'column', marginTop: "2rem", verticalAlign: "middle" }}>
											<span style={{marginTop:"0.5rem"}}> Ab </span>
											<InputNumber 
												placeholder="Stück"
												value={selectedStückAnzahl} 
												onChange={(e) => onStückAnzahlChange(e)} 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px", marginTop:"0.5rem"}}/>

											<span style={{marginTop:"0.5rem"}}> Stück: </span>

											<InputNumber 
												placeholder="Individueller €-Wert"
												value={selectedEuroText} 
												onChange={(e) => onEuroChange(e)} 
												mode="currency" 
												currency="EUR" 
												locale="de-DE"
												min={0}
												inputStyle={{width: "100px", height:"40px", marginTop:"0.5rem"}}/>
											<span style={{marginTop:"0.5rem"}}> Rabatt, auf </span>
											

											<Dropdown 
												value={selectedMengenrabatt} 
												options={[{ value: 0, name: "alle Produkte dieser Art"},{ value: 1, name: "jedes weitere Produkt"}]}
												optionLabel="name" 
												optionValue="value"												onChange={e => setSelectedMengenrabatt(e.value)} 
												style={{minWidth:"8rem", maxWidth:"12.5rem", marginTop:"0.5rem"}} 
												placeholder="Options" 
											/>
											
											{selectedMengenrabatt === 0 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes Stück um ${selectedEuroText ?? 0} € günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}
											{selectedMengenrabatt === 1 && (
												<i 
													className="pi pi-info-circle" 
													style={{marginLeft:"0.5rem", verticalAlign: "middle", marginTop:"0.8rem"}} 
													title={
														`Ab ${selectedStückAnzahl} Stück, wird jedes weitere Stück um ${selectedEuroText ?? 0} € günstiger verkauft.`
													}
													data-pr-tooltip="Information über Aktionen" 
												/>
											)}

										{selectedProduct != null && ganzesSortiment === false && freiWählbar === false && (
											<>
											<div style={{ display: 'flex'}}>
												<span style={{marginTop:"1rem"}}>
													Neuer Verkaufspreis: {(Number(selectedProduktData) - Number(selectedEuroText)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"1rem"}}> €</span>
											</div>
											<div style={{ display: 'flex', verticalAlign:"middle"}}>
												<span style={{marginTop:"0.5rem"}}>
													Neuer DB: {(Number(selectedProduktData) - Number(selectedEuroText)	- Number(selectedProduktEK)).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
												</span>
												
												<span style={{ marginLeft:"0.2rem", marginTop:"0.5rem"}}> €</span>
											</div>
										</>
										)}
										</div>
									)}
									</div>
								)}
								</div>
								{aktion === 0 && (selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && proWertIndivi === 1 && proWert === 0 && ((selectedProzent || selectedProzentText) > 0 || selectedEuroText > 0 ) && ( 
									<div style={{ flex: 7, marginLeft: isLargeScreen ? "-10.2rem" : "-15.3rem", marginTop: isLargeScreen ? "7.5rem" : "22rem", width: isLargeScreen ? "" : "105%" }}>
										<Button
											label={`Bei ${produkteVKzuNiederig.filter(produkt => produkt.Rabattartikel === true).length} Produkt/en ist der VK oder DB zu niedrig`}
											icon="pi pi-exclamation-triangle"
											style={{marginLeft: isLargeScreen ? "14rem" : "2rem", marginTop: proWert === 0 ? "3.5rem" : "-1.5rem", width: isLargeScreen ? "" : "15rem"}}

											onClick={(e) => {showProdukteVKDBzuNiedrigChange(true)}}
										/>
									</div>
								)}
								{aktion === 0 && (selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && proWertIndivi === 0 && proWert === 0 && ((selectedProzent || selectedProzentText) > 0 || selectedEuroText > 0 ) && ( 
									<div style={{ flex: 7, marginLeft: isLargeScreen ? "-5rem" : "-15.3rem", marginTop: isLargeScreen ? "9rem" : "53rem" }}>
										<Button
											label={`Bei ${produkteVKzuNiederig.filter(produkt => produkt.Rabattartikel === true).length} Produkt/en ist der VK oder DB zu niedrig`}
											icon="pi pi-exclamation-triangle"										
											style={{marginLeft: isLargeScreen ? "14rem" : "2rem", marginTop: proWert === 0 ? "3.5rem" : "-1.5rem", width: isLargeScreen ? "" : "15rem"}}

											onClick={(e) => {showProdukteVKDBzuNiedrigChange(true)}}
										/>
									</div>
								)}
								{aktion === 0 && (selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && proWert === 1 && ((selectedProzent || selectedProzentText) > 0 || selectedEuroText > 0 ) && ( 
									<div style={{ flex: 7, marginLeft: isLargeScreen ? "-15rem" : "-15.3rem", marginTop: isLargeScreen ? "9rem" : "23rem" }}>
										<Button
											label={`Bei ${produkteVKzuNiederig.filter(produkt => produkt.Rabattartikel === true).length} Produkt/en ist der VK oder DB zu niedrig`}
											icon="pi pi-exclamation-triangle"
											style={{marginLeft: isLargeScreen ? "14rem" : "2rem", marginTop: proWert === 0 ? "3.5rem" : "-1.5rem", width: isLargeScreen ? "" : "15rem"}}
											onClick={(e) => {showProdukteVKDBzuNiedrigChange(true)}}
										/>
									</div>
								)}
								
								{aktion === 3 && (selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && selectedStückAnzahl > 0 && (selectedProzent || selectedProzentText) > 0 && proWert === 0 &&( 
									<div style={{ flex: 7, marginLeft:isLargeScreen ? "0rem" : "-15.3rem", marginTop: isLargeScreen ? "4.2rem" : "30rem"  }}>
										<Button
											label={`Bei ${produkteVKzuNiederig.filter(produkt => produkt.Rabattartikel === true).length} Produkt/en ist der VK oder DB zu niedrig`}
											icon="pi pi-exclamation-triangle"
											style={{marginLeft:"2rem", marginTop:"3.5rem"}}
											onClick={(e) => {showProdukteVKDBzuNiedrigChange(true)}}
										/>
									</div>
								)}
								{aktion === 3 && (selectedGruppe.length > 0 || ganzesSortiment || freiWählbar) && selectedStückAnzahl > 0 && selectedEuroText > 0 && proWert === 1 &&( 
									<div style={{ flex: 7, marginLeft:isLargeScreen ? "0rem" : "-15.3rem", marginTop: isLargeScreen ? "4.2rem" : "30rem"  }}>
										<Button
											label={`Bei ${produkteVKzuNiederig.filter(produkt => produkt.Rabattartikel === true).length} Produkt/en ist der VK oder DB zu niedrig`}
											icon="pi pi-exclamation-triangle"
											style={{marginLeft:"2rem", marginTop:"3.5rem"}}
											onClick={(e) => {showProdukteVKDBzuNiedrigChange(true)}}
										/>
									</div>
								)}
								
								</div>
								
								</AccordionTab>
							</Accordion>
						</div>

						<div className="field grid">
					<Accordion style={{width:"100%"}}>
						<AccordionTab 
							header={
								<React.Fragment>
									<span style={{verticalAlign: "middle"}}>Kassen *</span>
									<i 
										className="pi pi-info-circle" 
										style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
										title={"Auf welche Kassen die Aktion gelten soll."}
										data-pr-tooltip="Information über Aktionen" 
									/>
									<span style={{ marginRight: "auto", marginLeft:"1rem", verticalAlign: "middle", color:"silver" }}>  
										(aktuell ausgewählt: {selectedKassen.length})
									</span>

								</React.Fragment>
							}>
							<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'block' : 'none',  }}>
								<div className="col" style={{ width: '100%' }}>
								<Button   label={<><i className="pi pi-check"></i> alle auswählen</>} style={{ width:"10rem", height:"2rem", }} onClick={onAllKassen} />

									<div style={{ display: 'flex' ,marginTop:"1rem"}}>
										
										{/* Erste Spalte für die ersten 4 Kassen */}
										<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
											{kassen.slice(0, 4).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
													id={`kasse-${kasse.KassenID}`}
													checked={selectedKassen.includes(kasse.KassenID)}
													onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Zweite Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem"  }}>
											{kassen.slice(4,8).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kasse.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Dritte Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem" }}>
											{kassen.slice(8,12).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kassen.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>

										{/* Vierte Spalte für alle weiteren Kassen */}
										<div style={{ width: '50%', minWidth:"5rem" }}>
											{kassen.slice(12,16).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
												id={`kasse-${kasse.KassenID}`}
												checked={selectedKassen.includes(kasse.KassenID)}
												onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px' }}>{kasse.KassenID}</span>
											</div>
											))}
										</div>
									</div>
								</div>
							</div>

							<div className="field grid" style={{ display: 'flex', alignItems: 'flex-start', display: isLargeScreen ? 'none' : 'block',  }}>
								<div className="col" style={{ width: '100%' }}>
								<Button   label={<><i className="pi pi-check"></i> alle auswählen</>} style={{ width:"10rem", height:"2rem", }} onClick={onAllKassen} />

									<div style={{ display: 'flex' ,marginTop:"1rem"}}>
										<div style={{ width: '50%', marginRight: '10px', minWidth:"5rem" }}>
											{kassen.slice(0,16).map((kasse) => (
											<div key={kasse.KassenID} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
												<Checkbox
													id={`kasse-${kasse.KassenID}`}
													checked={selectedKassen.includes(kasse.KassenID)}
													onChange={() => handleCheckboxChange(kasse.KassenID)}
												/>
												<span style={{ marginLeft: '10px'}}>{kasse.KassenID}</span>
											</div>
											))}
										</div>
									</div>
								</div>
							</div>
                        </AccordionTab>
					</Accordion>
				</div>

				<div className="field grid">
					<Accordion style={{width:"100%"}} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<AccordionTab 
							header={
								<React.Fragment>
    								<div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>

										<span style={{ marginRight: "auto" }}>Serien</span>

										<i 
											className="pi pi-info-circle" 
											style={{marginLeft:"0.5rem", verticalAlign: "middle"}} 
											title={"Wann und in welchem Rythmus die Aktion wiederholt werden soll."}
											data-pr-tooltip="Information über Aktionen" 
										/>

										{(showSerie &&
											<span style={{ marginRight: "auto", marginLeft:"1rem", verticalAlign: "middle", color:"silver"  }}>
												{serienMuster === "Wöchentlich" 
													? `(aktuell ausgewählt: ${serienMuster}: ${ausgewählteWochentage()})` 
													: `(aktuell ausgewählt: ${serienMuster})`}										
											</span>
										)}
									</div>
								</React.Fragment>
							}
							disabled={!showSerie}


							>
						<div className="p-grid p-fluid">

							{/* Erste Sektion: Terminzeit */}
							<div className="section-container p-col-12 p-md-6" style={{ border: "2px solid lightgray", padding: "20px", marginTop:"0.2rem" }}>
								<div className="section-header" style={{ marginBottom: "15px", fontWeight: "bold", marginTop:"-2.1rem", position: "relative" }}>
									<span style={{ backgroundColor: "white", padding: "0 8px" }}>Terminzeit</span>
								</div>
								<div className="p-grid">
									<div className="p-col-12 p-md-3">
										<label htmlFor="startDatum">Beginn: </label>
									</div>
									<div className="p-col-12 p-md-9">
										<Calendar
											id="startDatum"
											value={startZeit}
											onChange={(e) => setStartZeit(e.value)}
											showTime
											showIcon = {false}
											hourFormat="24"
											timeOnly
											style={{width: "5rem", marginLeft:"4rem", marginTop:"-1.7rem"}}
											minDate={new Date()}
										/>
									</div>

									<div className="p-col-12 p-md-3" style={{marginTop:"1rem"}}>
										<label htmlFor="endDatum">Ende</label>
									</div>
									<div className="p-col-12 p-md-9">
										<Calendar
											id="endDatum"
											value={endZeit}
											onChange={(e) => setEndZeit(e.value)}
											showTime
											showIcon = {false}
											hourFormat="24"
											dateFormat="dd.mm.yy"
											timeOnly
											style={{width: "5rem", marginLeft:"4rem", marginTop:"-1.7rem"}}
											minDate={startZeit}
										/>
									</div>
								</div>
							</div>



							{/* Zweite Sektion: Serienmuster */}
							<div className="section-container p-col-12" style={{ border: "2px solid lightgray", padding: "20px", marginTop: "0.2rem", display: isLargeScreen ? 'block' : 'none',  }}>
								<div className="section-header" style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "-2.1rem", position: "relative" }}>
									<span style={{ backgroundColor: "white", padding: "0 8px" }}>Serienmuster</span>
								</div>					
								<div className="p-grid" style={{ display: "flex", alignItems: "center" }}>
									<div style={{ flex: 1 }}>
										<div className="p-field-radiobutton">
											<RadioButton
												id="Täglich"
												name="frequency"
												value="Täglich"
												checked={serienMuster === 'Täglich'}
												onChange={handleChange}
											/>
											<label htmlFor="Täglich" style={{ marginLeft: "0.5rem" }}>Täglich</label>
										</div>
										<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
											<RadioButton
												id="Wöchentlich"
												name="frequency"
												value="Wöchentlich"
												checked={serienMuster === 'Wöchentlich'}
												onChange={handleChange}
											/>
											<label htmlFor="Wöchentlich" style={{ marginLeft: "0.5rem" }}>Wöchentlich</label>
										</div>
										<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
											<RadioButton
												id="Monatlich"
												name="frequency"
												value="Monatlich"
												checked={serienMuster === 'Monatlich'}
												onChange={handleChange}
											/>
											<label htmlFor="Monatlich" style={{ marginLeft: "0.5rem" }}>Monatlich</label>
										</div>
										<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
											<RadioButton
												id="Jährlich"
												name="frequency"
												value="Jährlich"
												checked={serienMuster === 'Jährlich'}
												onChange={handleChange}
											/>
											<label htmlFor="Jährlich" style={{ marginLeft: "0.5rem" }}>Jährlich</label>
										</div>

									</div>

								{serienMuster =="Täglich" && (
									<>
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											<Divider layout="vertical" style={{ height: '8rem', marginLeft:"-68.1rem" }}/>
											<span>Jeden </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={31}
												style={{width:"4.5rem", height:"1.9rem",marginLeft:"0.5rem"}}
												value={jedeMonatTag}
												onChange={(e) => setJedeMonatTag(e.value)}
												/>
											<span style={{marginLeft:"0.5rem"}}> Tag </span>
										</div>
									</>
								)}
								{serienMuster =="Wöchentlich" && (
									<>
									<Divider layout="vertical" style={{ height: '8rem', marginLeft:"-2rem" }}/>
									<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

									<div style={{ flex: 1, minWidth: '260px', margin: '0.5rem'}}>
										<div style={{marginTop:"-2rem", marginBottom:"3.3rem" }}>
											<span>Jede/Alle </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={10}
												style={{width:"5rem", height:"2rem", marginLeft:"0rem"}}
												value={jedeWoche}
												onChange={(e) => setJedeWoche(e.value)}
												/>
											<span> Woche(n) am: </span>
										</div>
										<div style={{marginBottom:"1.9rem", marginTop:"-1.8rem", marginLeft:"0.5rem"}}>
											
										</div>
										<div style={{ marginTop: "1.5rem", position: "relative" }}>
											<Checkbox 
												id="montag" 
												name="montag" 
												checked={montag} 
												onChange={(e) => setMontag(e.target.checked)}
												/>
											<label htmlFor="montag" style={{marginLeft:"0.5rem"}}>Montag</label>
										</div>
										<div style={{ marginTop: "0.8rem", position: "relative" }}>
											<Checkbox 
												id="dienstag" 
												name="dienstag" 
												checked={dienstag} 
												onChange={(e) => setDienstag(e.target.checked)}
												/>
											<label htmlFor="dienstag" style={{marginLeft:"0.5rem"}}>Dienstag</label>
										</div>
									</div>

									<div style={{ flex: 1, minWidth: '250px', margin: '0.5rem' }}>
										<div style={{marginTop: "1.5rem", position: "relative"}}>
											<Checkbox 
												id="mittwoch" 
												name="mittwoch" 
												checked={mittwoch} 
												onChange={(e) => setMittwoch(e.target.checked)}
												/>
											<label htmlFor="mittwoch" style={{marginLeft:"0.5rem"}}>Mittwoch</label>
										</div>
										<div style={{ marginTop: "1rem", position: "relative" }}>
											<Checkbox 
												id="donnerstag" 
												name="donnerstag" 
												checked={donnerstag} 
												onChange={(e) => setDonnerstag(e.target.checked)}
												/>
											<label htmlFor="donnerstag" style={{marginLeft:"0.5rem"}}>Donnerstag</label>
										</div>
									</div>
									<div style={{ flex: 1, minWidth: '250px', margin: '0.5rem' }}>
										<div style={{ marginTop: "1.5rem", position: "relative" }}>
											<Checkbox 
												id="freitag" 
												name="freitag" 
												checked={freitag} 
												onChange={(e) => setFreitag(e.target.checked)}
												/>
											<label htmlFor="freitag" style={{marginLeft:"0.5rem"}}>Freitag</label>
										</div>
										<div style={{ marginTop: "1rem", position: "relative" }}>
											<Checkbox 
												id="samstag" 
												name="samstag" 
												checked={samstag} 
												onChange={(e) => setSamstag(e.target.checked)}
												/>
											<label htmlFor="samstag" style={{marginLeft:"0.5rem"}}>Samstag</label>
										</div>
									</div>

									<div style={{ flex: 1, minWidth: '250px', margin: '0.5rem' }}>
										<div style={{ marginTop: "1.5rem", position: "relative" }}>
											<Checkbox 
												id="sonntag" 
												name="sonntag" 
												checked={sonntag} 
												onChange={(e) => setSonntag(e.target.checked)}
												/>
											<label htmlFor="sonntag" style={{marginLeft:"0.5rem"}}>Sonntag</label>
										</div>
									</div>
									</div>
								</>
								)}
								{serienMuster =="Monatlich" && (
									<>
									<Divider layout="vertical" style={{ height: '8rem', marginLeft:"-55.7rem" }}/>
									<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
										<div >
											<RadioButton
												id="auswahl"
												name="first"
												value="first"
												checked={serienMusterAuswahl === 'first'}
												onChange={handleChangeMusterAuswahl}
												style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
											/>
											<span style={{marginLeft:"1rem"}}>Am </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={31}
												style={{width:"4.5rem", height:"1.9rem"}}
												value={jedeMonatTag}
												onChange={(e) => setJedeMonatTag(e.value)}
												/>
											<span> Tag jedes </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={100}
												style={{width:"4.5rem", height:"1.9rem", marginLeft:"0.3rem"}}
												value={jedeMonat}
												onChange={(e) => setJedeMonat(e.value)}
												/>
											<span> Monat </span>
										</div>
										<div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'row'}}>
											<RadioButton
												id="auswahl"
												name="second"
												value="second"
												checked={serienMusterAuswahl === 'second'}
												onChange={handleChangeMusterAuswahl}
												style={{marginTop:"0.49rem"}}
											/>
											<span style={{marginLeft:"1rem", marginTop:"0.49rem"}}>Am </span>
											<Dropdown 
												style={{width:"7rem", marginLeft:"0.3rem"}}
												value={jedeMonatTagDropdown}
												onChange={(e) => setJedeMonatTagDropdown(e.value)}
												options={[
													{ label: "ersten", value: "1" },
													{ label: "zweiten", value: "2" },
													{ label: "dritten", value: "3" },
													{ label: "vierten", value: "4" },
													{ label: "letzten", value: "5" }
												]}
											/>
											<Dropdown
												style={{width:"9rem",marginLeft:"0.3rem"}}
												value={jedeMonatWochentagDropdown}
												onChange={(e) => setJedeMonatWochentagDropdown(e.value)}
												options={["Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag","Sonntag"]}
											/>
											<span style={{marginLeft:"0.4rem", marginTop:"0.49rem"}}> jedes </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={100}
												style={{width:"4.5rem", height:"1.9rem", marginLeft:"0.3rem"}}
												value={jedeMonatDropdown}
												onChange={(e) => setJedeMonatDropdown(e.value)}
											/>
											<span style={{marginLeft:"0.4rem", marginTop:"0.49rem"}}>. Monat </span>
										</div>
									</div>
								</>
								)}

								{serienMuster =="Jährlich" && (
									<>
									    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

										<Divider layout="vertical" style={{ height: '8rem', marginLeft:"-68.1rem" }}/>

										<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ flex: 1 }}>
											<RadioButton
												id="auswahl"
												name="first"
												value="first"
												checked={serienMusterAuswahl === 'first'}
												onChange={handleChangeMusterAuswahl}
												style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
											/>
											<span style={{marginLeft:"1rem"}}>Am </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={1}
												max={31}
												style={{width:"4.5rem", height:"1.9rem"}}
												value={jährlichTag}
												onChange={(e) => setJährlichTag(e.value)}
												/>
											<span> Tag im </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={1}
												max={12}
												style={{width:"4.5rem", height:"1.9rem"}}
												value={jährlichMonat}
												onChange={(e) => setJährlichMonat(e.value)}
												/>
											<span> Monat, jedes </span>
											<InputNumber 
												mode="decimal"
												showButtons
												min={0}
												max={100}
												style={{width:"4.5rem", height:"1.9rem"}}
												value={jährlichJahr}
												onChange={(e) => setJährlichJahr(e.value)}
												/>
											<span> Jahr</span>
										</div>
										<div style={{display: 'flex', flex: 1, marginTop:"1rem" }}>
										<RadioButton
											id="auswahl"
											name="second"
											value="second"
											checked={serienMusterAuswahl === 'second'}
											onChange={handleChangeMusterAuswahl}
											style={{marginTop:"0.49rem"}}
										/>
										<span  style={{marginLeft:"1rem", marginTop:"0.49rem"}}>Am </span>
											<Dropdown 
												style={{width:"7rem",marginLeft:"0.3rem"}}
												value={jedeMonatTagDropdown}
												onChange={(e) => setJedeMonatTagDropdown(e.value)}
												options={[
													{ label: "ersten", value: "1" },
													{ label: "zweiten", value: "2" },
													{ label: "dritten", value: "3" },
													{ label: "vierten", value: "4" },
													{ label: "letzten", value: "5" }
												]}
											/>
											<Dropdown
												style={{width:"9rem",marginLeft:"0.3rem"}}
												value={jedeMonatWochentagDropdown}
												onChange={(e) => setJedeMonatWochentagDropdown(e.value)}
												options={["Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag","Sonntag"]}
											/>
											<span style={{marginLeft:"0.4rem", marginTop:"0.49rem"}}> im </span>
											<Dropdown
												style={{width:"9rem", marginLeft:"0.3rem"}}
												value={monatDropdown}
												onChange={(e) => setMonatDropdown(e.value)}
												options={[
													{ label: "Jänner", value: "1" },
													{ label: "Februar", value: "2" },
													{ label: "März", value: "3" },
													{ label: "April", value: "4" },
													{ label: "Mai", value: "5" },
													{ label: "Juni", value: "6" },
													{ label: "Juli", value: "7" },
													{ label: "August", value: "8" },
													{ label: "September", value: "9" },
													{ label: "Oktober", value: "10" },
													{ label: "November", value: "11" },
													{ label: "Dezember", value: "12" }
												]}
											/>
										</div>
										</div>
										</div>
									</>
								)}
							</div>
						</div>


						<div className="section-container p-col-12" style={{ border: "2px solid lightgray", padding: "20px", marginTop: "0.2rem",display: isLargeScreen ? 'none' : 'block',  }}>
							<div className="section-header" style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "-2.1rem", position: "relative" }}>
								<span style={{ backgroundColor: "white", padding: "0 8px" }}>Serienmuster</span>
							</div>

							<div className="p-grid" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
								<div style={{ flex: 1, minWidth: '200px' }}>
								<div className="p-field-radiobutton">
									<RadioButton
										id="Täglich"
										name="frequency"
										value="Täglich"
										checked={serienMuster === 'Täglich'}
										onChange={handleChange}
									/>
									<label htmlFor="Täglich" style={{ marginLeft: "0.5rem" }}>Täglich</label>
								</div>
								<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
									<RadioButton
										id="Wöchentlich"
										name="frequency"
										value="Wöchentlich"
										checked={serienMuster === 'Wöchentlich'}
										onChange={handleChange}
									/>
									<label htmlFor="Wöchentlich" style={{ marginLeft: "0.5rem" }}>Wöchentlich</label>
								</div>
								<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
									<RadioButton
										id="Monatlich"
										name="frequency"
										value="Monatlich"
										checked={serienMuster === 'Monatlich'}
										onChange={handleChange}
									/>
									<label htmlFor="Monatlich" style={{ marginLeft: "0.5rem" }}>Monatlich</label>
								</div>
								<div className="p-field-radiobutton" style={{ marginTop: "0.5rem" }}>
									<RadioButton
										id="Jährlich"
										name="frequency"
										value="Jährlich"
										checked={serienMuster === 'Jährlich'}
										onChange={handleChange}
									/>
									<label htmlFor="Jährlich" style={{ marginLeft: "0.5rem" }}>Jährlich</label>
								</div>
								</div>
								{serienMuster =="Täglich" && (
									<>
										<Divider/>
										<span>Jeden </span>
										<InputNumber 
											mode="decimal"
											showButtons
											min={0}
											max={31}
											style={{width:"4.5rem", height:"1.9rem",marginLeft:"0.5rem"}}
											value={jedeMonatTag}
											onChange={(e) => setJedeMonatTag(e.value)}
											/>
										<span style={{marginLeft:"0.5rem"}}> Tag </span>
									</>
								)}
								{serienMuster === "Wöchentlich" && (
								<>
									<Divider />
									<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
									<div style={{ flex: 1, minWidth: '250px', margin: '0.5rem' }}>
										<div style={{ maxWidth:"200px" }}>
										<span>Jede/Alle </span>
										<InputNumber
											mode="decimal"
											showButtons
											min={0}
											max={10}
											style={{ width: "5rem", height: "2rem", marginLeft: "0rem" }}
											value={jedeWoche}
											onChange={(e) => setJedeWoche(e.value)}
										/>
										<span> Woche(n) am: </span>
										</div>

										{["montag", "dienstag", "mittwoch", "donnerstag", "freitag", "samstag", "sonntag"].map((day, index) => (
										<div key={day} style={{ marginTop: index > 0 ? "0.8rem" : "1.5rem", position: "relative" }}>
											<Checkbox
											id={day}
											name={day}
											checked={eval(day)}
											onChange={(e) => eval(`set${day.charAt(0).toUpperCase() + day.slice(1)}(e.target.checked)`)}
											/>
											<label htmlFor={day} style={{ marginLeft: "0.5rem" }}>
											{day.charAt(0).toUpperCase() + day.slice(1)}
											</label>
										</div>
										))}
									</div>
									</div>
								</>
								)}

								{serienMuster === "Monatlich" && (
								<>
									<Divider />
									<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
									<div style={{ flex: 1, minWidth: '200px' }}>
										<RadioButton
											id="auswahl"
											name="first"
											value="first"
											checked={serienMusterAuswahl === 'first'}
											onChange={handleChangeMusterAuswahl}
											style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
										/>
										<span style={{marginLeft:"0.9rem"}}>Am </span>
										<InputNumber
											mode="decimal"
											showButtons
											min={0}
											max={31}
											style={{ width: "4.5rem", height: "1.9rem" }}
											value={jedeMonatTag}
											onChange={(e) => setJedeMonatTag(e.value)}
										/>
										<span>  Tag</span>
									</div>

									<div style={{ flex: 1, minWidth: '200px', marginTop:"0.6rem" }}>
										<span style={{marginLeft:"2.2rem"}}> jedes</span>
										<InputNumber
											mode="decimal"
											showButtons
											min={0}
											max={100}
											style={{ width: "4.5rem", height: "1.9rem", marginLeft: "0.3rem" }}
											value={jedeMonat}
											onChange={(e) => setJedeMonat(e.value)}
										/>
										<span>  Monat</span>
									</div>




									<div style={{ flex: 1, minWidth: '200px', marginTop:"3rem" }}>
										<RadioButton
											id="auswahl"
											name="second"
											value="second"
											checked={serienMusterAuswahl === 'second'}
											onChange={handleChangeMusterAuswahl}
											style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
										/>
										<span style={{marginLeft:"0.9rem"}}>Am </span>
										<Dropdown 
											style={{width:"7rem", marginLeft:"4.5rem", marginTop:"-1.8rem"}}
											value={jedeMonatTagDropdown}
											onChange={(e) => setJedeMonatTagDropdown(e.value)}
											options={[
												{ label: "ersten", value: "1" },
												{ label: "zweiten", value: "2" },
												{ label: "dritten", value: "3" },
												{ label: "vierten", value: "4" },
												{ label: "letzten", value: "5" }
											]}
											/>
										<Dropdown
											style={{width:"9.5rem",marginLeft:"2rem", marginTop:"0.6rem"}}
											value={jedeMonatWochentagDropdown}
											onChange={(e) => setJedeMonatWochentagDropdown(e.value)}
											options={["Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag","Sonntag"]}
											/>
									</div>

									<div style={{ flex: 1, minWidth: '200px', marginTop:"0.6rem" }}>
										<span style={{marginLeft:"2.2rem"}}> jedes</span>
										<InputNumber
											mode="decimal"
											showButtons
											min={0}
											max={100}
											style={{ width: "4.5rem", height: "1.9rem", marginLeft: "0.3rem" }}
											value={jedeMonat}
											onChange={(e) => setJedeMonat(e.value)}
										/>
										<span>  Monat</span>
									</div>

									</div>
								</>
								)}
								{serienMuster =="Jährlich" && (
									<>
										<Divider />
										<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
											<div style={{ flex: 1, minWidth: '200px' }}>
												<RadioButton
													id="auswahl"
													name="first"
													value="first"
													checked={serienMusterAuswahl === 'first'}
													onChange={handleChangeMusterAuswahl}
													style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
													/>
												<span style={{marginLeft:"0.9rem"}}>Am </span>
												<InputNumber 
													mode="decimal"
													showButtons
													min={1}
													max={31}
													style={{width:"4.5rem", height:"1.9rem"}}
													value={jährlichTag}
													onChange={(e) => setJährlichTag(e.value)}
												/>
												<span>  Tag</span>
											</div>

											<div style={{ flex: 1, minWidth: '200px', marginTop:"0.6rem", marginLeft:"2rem"}}>
												<span> im </span>
												<InputNumber 
													mode="decimal"
													showButtons
													min={1}
													max={12}
													style={{width:"4.5rem", height:"1.9rem"}}
													value={jährlichMonat}
													onChange={(e) => setJährlichMonat(e.value)}
												/>
												<span>  Monat,</span>
											</div>

											<div style={{ flex: 1, minWidth: '200px', marginTop:"0.6rem", marginLeft:"2rem" }}>
												<span> jedes </span>
												<InputNumber 
													mode="decimal"
													showButtons
													min={0}
													max={100}
													style={{width:"4.5rem", height:"1.9rem"}}
													value={jährlichJahr}
													onChange={(e) => setJährlichJahr(e.value)}
													/>
												<span> Jahr</span>
											</div>

											<div style={{ flex: 1, minWidth: '200px', marginTop:"3rem" }}>
												<RadioButton
													id="auswahl"
													name="second"
													value="second"
													checked={serienMusterAuswahl === 'second'}
													onChange={handleChangeMusterAuswahl}
													style={{verticalAlign:"middle", marginTop:"-0.1rem"}}
													/>
												<span style={{marginLeft:"0.9rem"}}>Am </span>
												<Dropdown 
													style={{width:"7rem", marginLeft:"4.5rem", marginTop:"-1.8rem"}}
													value={jedeMonatTagDropdown}
													onChange={(e) => setJedeMonatTagDropdown(e.value)}
													options={[
														{ label: "ersten", value: "1" },
														{ label: "zweiten", value: "2" },
														{ label: "dritten", value: "3" },
														{ label: "vierten", value: "4" },
														{ label: "letzten", value: "5" }
													]}
												/>
												<Dropdown
													style={{width:"9.5rem",marginLeft:"2rem", marginTop:"0.6rem"}}
													value={jedeMonatWochentagDropdown}
													onChange={(e) => setJedeMonatWochentagDropdown(e.value)}
													options={["Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag","Sonntag"]}
												/>
											</div>

											<div style={{ flex: 1, minWidth: '200px', marginTop:"0.6rem" }}>
												<span style={{marginLeft:"2.2rem"}}> im</span>
												<Dropdown
												style={{width:"9.5rem",marginLeft:"2rem", marginTop:"0.6rem"}}

												value={monatDropdown}
												onChange={(e) => setMonatDropdown(e.value)}
												options={[
													{ label: "Jänner", value: "1" },
													{ label: "Februar", value: "2" },
													{ label: "März", value: "3" },
													{ label: "April", value: "4" },
													{ label: "Mai", value: "5" },
													{ label: "Juni", value: "6" },
													{ label: "Juli", value: "7" },
													{ label: "August", value: "8" },
													{ label: "September", value: "9" },
													{ label: "Oktober", value: "10" },
													{ label: "November", value: "11" },
													{ label: "Dezember", value: "12" }
												]} 
												/>
											</div>
										</div>
									</>
								)}
							</div>
						</div>





							{/* Seriendauer */}
							<div className="section-container p-col-12" style={{ border: "2px solid lightgray", padding: "20px", marginTop: "0.2rem", display: isLargeScreen ? 'block' : 'none', }}>
								<div className="section-header" style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "-2.1rem", position: "relative" }}>
									<span style={{ backgroundColor: "white", padding: "0 8px" }}>Seriendauer</span>
								</div>
								<div className="p-grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
									
									<div className="p-col-12 p-md-6" style={{ display: 'flex', alignItems: 'center' }}>
										<div className="p-grid" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<div className="p-col-12 p-md-3" style={{ flex: 1 , marginTop:"-1.5rem"}}>
											<label htmlFor="serienStartDatum">Beginn: </label>
											</div>
											<div className="p-col-12 p-md-9" style={{ flex: 1 , marginTop:"-0.2rem"}}>
											<Calendar
												id="serienStartDatum"
												value={serienDauerStart}
												onChange={(e) => setSerienDauerStart(e.value)}
												style={{ width: "10rem", marginLeft: "4rem", marginTop: "-1.7rem" }}
												showIcon
												dateFormat="dd.mm.yy"
												minDate={new Date()}
											/>
											</div>
										</div>
									</div>

									<div className="p-col-12 p-md-6" style={{ display: 'flex', flexDirection: 'column', marginLeft:"2rem" }}>
										{/**
										 * gelöscht 
										 * Funktion nicht unterstützt
										 * 
										 * 
										<div className="p-field-radiobutton">
											<RadioButton
												id="neverEnd"
												name="serienEnd"
												value="neverEnd"
												checked={serienDauer === 'neverEnd'}
												onChange={handleChangeSerienDauer}
											/>
											<label htmlFor="neverEnd" style={{marginLeft:"0.5rem"}}>Kein Enddatum</label>
										</div>
										 */}
										<div className="p-field-radiobutton" style={{marginTop:"0.4rem"}}>
											<RadioButton
												id="nachEnd"
												name="serienEnd"
												value="nachEnd"
												checked={serienDauer === 'nachEnd'}
												onChange={handleChangeSerienDauer}
												style={{marginBottom:"0.4rem"}}
											/>
											<label htmlFor="nachEnd" style={{marginLeft:"0.5rem"}}>Endet nach: </label>
											<InputNumber
												id="serienCount"
												name="serienCount"
												value={serienCount}
												onChange={e => setSerienCount(e.value)}
												style={{width:"3rem", marginLeft:"1rem"}}
												max={(() => {
													const today = new Date();
													switch (serienMuster) {
													  case 'Täglich':
														return 90;
													  case 'Wöchentlich':
														return 42;
													  case 'Monatlich':
														return 12
													  case 'Jährlich':
														return 5
													  default:
														return today;
													}
												  })()
												}
											/>
											<label htmlFor="serienCount" style={{marginLeft:"0.5rem"}}>Terminen </label>

										</div>
										<div className="p-field-radiobutton" style={{marginTop:"0.5rem"}}>
											<RadioButton
												id="amEnd"
												name="serienEnd"
												value="amEnd"
												checked={serienDauer === 'amEnd'}
												onChange={handleChangeSerienDauer}
											/>
											<label htmlFor="amEnd" style={{marginLeft:"0.5rem"}}>Endet am: </label>
											<Calendar
												id="serienDauerEnd"
												value={serienDauerEnd}
												onChange={(e) => setSerienDauerEnd(e.value)}
												style={{ width: "10rem", marginLeft: "8.15rem", marginTop: "-1.7rem" }}
												showIcon
												dateFormat="dd.mm.yy"
												minDate={serienDauerStart}
												maxDate={
													(() => {
													  const today = new Date();
													  switch (serienMuster) {
														case 'Täglich':
														  return new Date(today.setMonth(today.getMonth() + 3));
														case 'Wöchentlich':
														  return new Date(today.setMonth(today.getMonth() + 6));
														case 'Monatlich':
														  return new Date(today.setFullYear(today.getFullYear() + 1));
														case 'Jährlich':
														  return new Date(today.setFullYear(today.getFullYear() + 5));
														default:
														  return today;
													  }
													})()
												}
											/>
										</div>
									</div>
								</div>
							</div>


							<div className="section-container p-col-12 p-hidden-lg" style={{ border: "2px solid lightgray", padding: "20px", marginTop: "0.2rem",display: isLargeScreen ? 'none' : 'block', }}>
								<div className="section-header" style={{ marginBottom: "15px", fontWeight: "bold", marginTop: "-2.1rem", position: "relative" }}>
									<span style={{ backgroundColor: "white", padding: "0 8px" }}>Seriendauer</span>
								</div>
								<div className="p-grid" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
									
									<div className="p-col-12 p-md-6" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '1rem' }}>
									<div className="p-grid" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
										<div className="p-col-12 p-md-3" style={{ flex: 1 }}>
										<label htmlFor="serienStartDatum">Beginn: </label>
										</div>
										<div className="p-col-12 p-md-9" style={{ flex: 1 }}>
										<Calendar
											id="serienStartDatum"
											value={serienDauerStart}
											onChange={(e) => setSerienDauerStart(e.value)}
											style={{minWidth:"10rem", maxWidth: "20rem", marginTop: "2rem", marginLeft:"-2rem" }}
											showIcon
											dateFormat="dd.mm.yy"
											minDate={new Date()}
										/>
										</div>
									</div>
									</div>

									<div className="p-col-12 p-md-6" style={{ display: 'flex', flexDirection: 'column' }}>
										{/**
										 * gelöscht 
										 * Funktion nicht unterstützt
										 * 
										 * 
											<div className="p-field-radiobutton">
												<RadioButton
													id="neverEnd"
													name="serienEnd"
													value="neverEnd"
													checked={serienDauer === 'neverEnd'}
													onChange={handleChangeSerienDauer}
												/>
												<label htmlFor="neverEnd" style={{ marginLeft: "0.5rem" }}>Kein Enddatum</label>
											</div>
										*/}

									<div className="p-field-radiobutton" style={{ marginTop: "1rem", display: "flex", flexDirection: "row", alignItems: "center" }}>
										<RadioButton
											id="nachEnd"
											name="serienEnd"
											value="nachEnd"
											checked={serienDauer === 'nachEnd'}
											onChange={handleChangeSerienDauer}
										/>
										<label htmlFor="nachEnd" style={{ marginLeft: "0.5rem" }}>Endet nach: </label>
										<InputNumber
											id="serienCount"
											name="serienCount"
											value={serienCount}
											onChange={e => setSerienCount(e.value)}
											style={{ width: "3rem", marginLeft: "1rem" }}
										/>
										<label htmlFor="serienCount" style={{ marginLeft: "0.5rem" }}>Terminen</label>
									</div>
									<div className="p-field-radiobutton" style={{ marginTop: "1rem" }}>
										<RadioButton
											id="amEnd"
											name="serienEnd"
											value="amEnd"
											checked={serienDauer === 'amEnd'}
											onChange={handleChangeSerienDauer}
										/>
										<label htmlFor="amEnd" style={{ marginLeft: "0.5rem" }}>Endet am: </label>
										<Calendar
											id="serienDauerEnd"
											value={serienDauerEnd}
											onChange={(e) => setSerienDauerEnd(e.value)}
											style={{ width: "100%", maxWidth: "10rem", marginTop: "1rem", marginLeft:"2.3rem" }}
											showIcon
											dateFormat="dd.mm.yy"
											minDate={serienDauerStart}
										/>
									</div>
									</div>
								</div>
							</div>
						</div>
                        </AccordionTab>
					</Accordion>
				</div>
			</div>


				<Dialog 
					header="Wähle eine Kategorien/Warengruppe oder Artikelgruppe aus" 
					visible={kategorienVisible} 
					style={{ width: isLargeScreen ? '40vw' : '80vw' }}
					footer={renderFooter}
					onHide={() => setKategorienVisible(false)}>
					<div class="flex">
						<ListBox
							className="mr-2" 
							value={selectedKategorien} 
							options={kategorien} 
							optionLabel="Bezeichnung" 
							optionValue="ID" 
							onChange={(e) => onKategorieChange(e.target.value)}
							/>

						<ListBox 
							className="mr-2" 
							value={selectedWarengruppen} 
							options={warengruppenOptions}
							optionLabel="Bezeichnung" 
							optionValue="ID" 
							onChange={(e) => onWarengruppenChange(e.target.value)} 
							/>

						<ListBox 
							value={selectedArtikelgruppen} 
							options={artikelgruppenOptions} 
							optionLabel="Bezeichnung" 
							optionValue="ID" 
							onChange={(e) => setSelectedArtikelgruppen(e.target.value)} 
							emptyMessage="Bitte eine Warengruppe auswählen"

							/>
					</div>
				</Dialog>


				<Dialog 
					header="Fehler: Produkt ist nicht rabattierbar!" 
					visible={showRabattierbar} 
					style={{ width: isLargeScreen ? '50vw' : '90vw',}} 
					footer={renderFooterFehler}
					onHide={() => {
						setShowRabattierbar(false);
					}}>
					<div class="flex">
					<span>
						Das Produkt: {
							selectedProduct && selectedProduct.ID
								? produkte.filter(x => x.ID === selectedProduct.ID)[0]?.Bezeichnung
								: "Unbekanntes Produkt"
						} ist nicht rabattierbar! 
					</span>		
					</div>
					<div style={{ marginTop: '10px' }}>	
						<InputSwitch                                 
							checked={produktRabattierbar} 
							onChange={(e) => onProduktRabattierbarChange(e)}
							autoFocus={false} 
							style={{ scale: "0.8", verticalAlign:"middle"}}
						/>
						<span style={{verticalAlign:"middle"}}> Produkt rabattierbar machen </span>		
					</div>
				</Dialog>

				<Dialog 
					header="Fehler: Produkte aus der Gruppe sind nicht rabattierbar!" 
					visible={showGruppeRabattierbar} 
					style={{ width: isLargeScreen ? '40vw' : '90vw',}} 
					footer={renderFooterFehler}
					onHide={() => {
						setShowGruppeRabattierbar(false);
					}}>
					<div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
						<span style={{ flex: 1 }}>
							{nichtRabattierbar.length > 99 ? '99+ Produkte sind nicht rabattierbar:' : `${nichtRabattierbar.length} Produkte sind nicht rabattierbar:`}
							<br />
							<DataTable 
								value={(Array.isArray(nichtRabattierbar) ? nichtRabattierbar.filter(product => product.rabattierbar === false) : [])}
								scrollHeight="300px" 
								scrollable  
								style={{maxWidth:"50rem", marginTop:"1rem"}}
								>

								<Column field="produktBezeichnung" header="Produktbezeichnung" />
								
								<Column 
									field="Rabattartikel" 
									header="Artikel rabattierbar machen" 
									style={{ textAlign: 'right', width:"14.2rem"}} 
									body={(rowData) => 
										<Checkbox 
											checked={rowData.neuRabattierbar} 
											onChange={(e) => {handleCheckboxChangeNeuRabattartikel(e, rowData)}} 
										/>
									} 
								/>
							</DataTable>
							<Button
								icon="pi pi-times"
								label="alle Artikel abwählen"
								style={{marginTop:"1rem"}}
								onClick={(e) => onallArtikelNichtRabattierbarClick(e)}
							/>
							<Button
								icon="pi pi-check"
								label="alle Artikel auswählen"
								style={{marginLeft: isLargeScreen ? "1rem" : "0rem", marginTop:"1rem"}}
								onClick={(e) => onallArtikelRabattierbarClick(e)}
							/>						
						</span>
					</div>
				</Dialog>

				<Dialog 
					header="Fehler: Verkaufspreis zu niedrig!" 
					visible={showVKzuNiedrig} 
					style={{ width: isLargeScreen ? '50vw' : '90vw' }} 
					footer={renderFooterFehler2}
					onHide={() => setShowVKzuNiedrig(false)}>
					<div class="flex">
						<span>
						{selectedProduct ? (
							<>
								{`Der neue Verkaufspreis oder der Deckungsbeitrag von dem Produkt: ${produkte.filter(x => x.ID === selectedProduct.ID)[0]?.Bezeichnung} ist unter 0 €!`}
								<br />
								<br />
								<div style={{ paddingLeft: '20px' }}>
									{`neuer VK: ${neuerVK} €`}
								</div>
								<div style={{ paddingLeft: '20px' }}>
									{`neuer DB: ${neuerDB} €`}
								</div>
							</>
							) : (
							"Kein Produkt ausgewählt."
							)}
						</span>					
					</div>
				</Dialog>

				<Dialog 
					header="Fehler: Verkaufspreis zu niedrig!" 
					visible={showVKzuNiedrigGruppe} 
					style={{ width: isLargeScreen ? '40vw' : '90vw', zIndex: 10000 }} 
					footer={renderFooterFehler2}
					onHide={() => setShowVKzuNiedrigGruppe(false)}>

					<div className="flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span style={{ flex: 1 }}>
						neuer VK oder neuer DB kleiner 0,00 €:
						<br />
						<DataTable 
							value={produkteVKzuNiederig.filter(product => product.Rabattartikel === true)} 
							scrollHeight="300px" 
							scrollable 
							style={{maxWidth:"50rem", marginTop:"1rem"}}
							>
								
							<Column field="Bezeichnung" header="Produktbezeichnung" />
							<Column 
								field="AlterVK" 
								header="VK" 
								style={{ textAlign: 'right', width:"4rem", whiteSpace: 'nowrap'  }} 
								body={(rowData) => 
									rowData.AlterVK && !isNaN(rowData.AlterVK) 
										? `${rowData.AlterVK.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
										: '0,00'
								} 
							/>							
							<Column 
								field="NeuerVK" 
								header="rabattierter VK" 
								style={{ textAlign: 'right', minwidth:"20rem", whiteSpace: 'nowrap'  }} 
								body={(rowData) => 
									rowData.NeuerVK && !isNaN(rowData.NeuerVK) 
										? `${rowData.NeuerVK.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
										: '0,00'
								} 
							/>
							<Column 
								field="DB" 
								header="DB" 
								style={{ textAlign: 'right', width:"3rem", whiteSpace: 'nowrap'  }} 
								body={(rowData) => 
									rowData.AlterDB && !isNaN(rowData.AlterDB) 
										? `${rowData.AlterDB.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
										: '0,00'
								} 
							/>
							<Column 
								field="neuerDB" 
								header="rabattierter DB" 
								style={{ textAlign: 'right', width:"3rem", whiteSpace: 'nowrap'   }} 
								body={(rowData) => 
									rowData.NeuerDB && !isNaN(rowData.NeuerDB) 
										? `${rowData.NeuerDB.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
										: '0,00'
								} 
							/>
							<Column 
								field="Rabattartikel" 
								header="Artikel rabattieren" 
								style={{ textAlign: 'right', minwidth:"3rem"  }} 
								body={(rowData) => 
									<Checkbox 
										checked={rowData.Artikelrabattieren} 
										onChange={(e) => {handleCheckboxChangeArtikel(e, rowData)
										}} 
									/>
								} 
							/>
						</DataTable>
						<Button
							icon="pi pi-times"
							label="alle Artikel abwählen"
							style={{marginTop:"1rem"}}
							onClick={(e) => onallArtikelEntfernenClick(e)}
							/>
						<Button
							icon="pi pi-check"
							label="alle Artikel auswählen"
							style={{marginLeft: "1rem", marginTop:"1rem"}}
							onClick={(e) => onallArtikelHinzufügenClick(e)}
							/>
					</span>					
				</div>
				</Dialog>
				
				<Toast ref={toast} />

				<Dialog visible={visibleProduktauswahl} onHide={e => setVisibleProduktauswahl(false)} position="right" style={{marginRight: "50px", width: '82vw'}}
					header={
						<React.Fragment>
							<i className='pi pi-search'/>
							<span className='p-pr-5'> Produkt</span>
						</React.Fragment>
					}
					footer= {
						<div className='grid col-12'>
							<Button label='Abbrechen' icon="pi pi-times" onClick={() => {
								setVisibleProduktauswahl(false);
								setSelectedProduct(null);
								setAnzahlProdukte(0);
								}} />
							<Button label='OK' icon="pi pi-check" onClick={() => setVisibleProduktauswahl(false)}/>
						</div>
					}
					>
						<div className='grid'>
							<div className="col-12" style={{textAlign: 'right'}}>
								<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
							</div>
						</div>
						<div className='grid'>
							<div className='col-12'>
								<DataTable 
									header={renderHeader} 
									value={produkte} 
									ref={dt} 
									responsive
									filterDisplay="row"
									className="p-datatable-striped tableCursorPointer"
									currentPageReportTemplate="({first} - {last} von {totalRecords})"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
									rowHover paginator rows={10} rowsPerPageOptions={[10, 20,30,50,100]} scrollable="true" scrollHeight="650px" scrollDirection="both"
									selectionMode="single"
									selection={selectedProduct} 
									globalFilter={globalFilterValue}
									onRowClick={(e) => { onRowClickFilterTabelle(e)}}
									sortOrder={1} sortMode="single"
								>
									<Column
										field="ID" sortable filter header="ID"
										filterElement={IDFilterRender}
										showFilterMenu={false}
										hidden={!columnVisibility.ID}
									/>

									<Column
										field="ArtNr" sortable filter header="ArtNr"
										showFilterMenu={false}
										hidden={!columnVisibility.ArtNr}
									/>

									<Column field="Bezeichnung" filter filterPlaceholder="Bezeichnung eingeben"
										sortable header="Bezeichnung"
										showFilterMenu={false}
										hidden={!columnVisibility.Bezeichnung}
									/>

									<Column field="Warengruppe" filter filterPlaceholder="Warengruppe eingeben"
										sortable header="Warengruppe" 
										filterElement={warengruppenFilterRender}
										showFilterMenu={false}
										hidden={!columnVisibility.Warengruppe}
									/>

									<Column field="Barcode" filter filterPlaceholder="Barcode eingeben" bodyStyle={{overflow: 'hidden'}}
										sortable header="Barcodes" 
										showFilterMenu={false}
										hidden={!columnVisibility.Barcode}
									/>

									<Column field="Lieferant"
										filter
										filterPlaceholder="Lieferant eingeben"
										sortable header="Lieferant"
										showFilterMenu={false}  
										hidden={!columnVisibility.Lieferant}
									/>

									<Column field="Produzent"
										filter
										filterPlaceholder='Produzent eingeben'
										sortable header='Produzent'
										showFilterMenu={false}
										hidden={!columnVisibility.Produzent}
									/>

									<Column field="Einheit" 
										filter
										filterPlaceholder="Einheit wählen"
										sortable header="Einheit"
										filterElement={einheitFilterRender}
										headerStyle={{maxWidth: '10em'}}
										style={{maxWidth: '10em'}}
										bodyStyle={{maxWidth: '10em'}}
										showFilterMenu={false}
										hidden={!columnVisibility.Einheit}
									/>
								</DataTable>
							</div>
						</div>
					</Dialog>

					<Dialog 
						header="Einstellungen" 
						visible={einstellungenVisible} 
						style={{ width: '15vw' }}
						footer={
							<div>
								<Button label="Schließen" icon="pi pi-times" onClick={e => {setEinstellungenVisble(false);}} />
							</div>
						}
						onHide={e => {
							setEinstellungenVisble(false);
						}}
						>
							<div className="grid">
								{['ID', 'ArtNr', 'Bezeichnung', 'Warengruppe', 'Barcode', 'Lieferant', 'Produzent', 'Einheit'].map((field) => (
								<div className="col-12" key={field}>
									<Checkbox 
									value={field} 
									checked={columnVisibility[field]} 
									className="mr-3"
									onChange={(e) => {
										setColumnVisibility(prev => ({
										...prev,
										[field]: e.checked,
										}));
									}}
									/>
									{field}
								</div>
								))}
							</div>
					</Dialog>

					<Dialog 
						header="Neuen Typ erstellen" 
						visible={showNeuerTyp} 
						style={{ width: isLargeScreen ? '20vw' : '90vw'}} 
						footer={
							<div>
								<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowNeuerTyp(false)}} />
								<Button label="Hinzufügen" icon="pi pi-check" onClick={e => {onAddTyp()}} />
							</div>
						}
						onHide={e => {
							setShowNeuerTyp(false);
						}}
						>
							<div className="grid">
								<span style={{marginTop:"0.4rem"}}>Typ: </span>
								<InputText
									value={newTyp}
									placeholder="Name"
									onChange={(e) => setNewTyp(e.target.value)}
									style={{marginLeft:"1rem", maxWidth: isLargeScreen ? "" : "9rem"}}
								/>
							</div>
					</Dialog>

					<Dialog 
						header="Fehler: Keine Produkte in dieser Kategorie!" 
						visible={showNichtBerechtigt} 
						style={{ width: '20vw' }} 
						footer={
							<div>
								<Button label="Schließen" icon="pi pi-times" onClick={e => {setShowNichtBerechtigt(false)}} />
							</div>
						}
						onHide={e => {setShowNichtBerechtigt(false)}}
						>
							<div className="grid">
								<span style={{marginTop:"0.4rem"}}>Fehler: Sie haben keine Produkte in dieser Kategorie. </span>
							</div>
					</Dialog>

					<Dialog 
						header={`${nichtRabattierbar.length} Produkte sind nicht rabattierbar`} 
						visible={produkteRabattierbarChange} 
						style={{ width: isLargeScreen ? '31vw' : '90vw' }}

						footer={
							<div>
								<Button label="Abbrechen" icon="pi pi-times" onClick={e => {showProdukteRabattierbarChange(false)}} />
								<Button label="Speichern" icon="pi pi-save" onClick={e => {showProdukteRabattierbarChange(false); handleSpeicherNeueRabattartikel()}} />
							</div>
						}
						onHide={e => {showProdukteRabattierbarChange(false)}}
						>
							<DataTable 
									value={(Array.isArray(nichtRabattierbar) ? nichtRabattierbar.filter(product => product.rabattierbar === false) : [])}
									scrollHeight="300px" 
									scrollable 
									style={{maxWidth:"20rem"}}

									>

									<Column field="produktBezeichnung" header="Produktbezeichnung" />
									
									<Column 
										field="Rabattartikel" 
										header="Artikel rabattierbar machen" 
										style={{ textAlign: 'right', width:"14.2rem"}} 
										body={(rowData) => 
											<Checkbox 
												checked={rowData.neuRabattierbar} 
												onChange={(e) => {handleCheckboxChangeNeuRabattartikel(e, rowData)}} 
											/>
										} 
									/>
								</DataTable>
								<Button
									icon="pi pi-times"
									label="alle Artikel abwählen"
									style={{marginTop:"1rem"}}
									onClick={(e) => onallArtikelNichtRabattierbarClick(e)}
								/>
								<Button
									icon="pi pi-check"
									label="alle Artikel auswählen"
									style={{marginLeft: isLargeScreen ? "1rem" : "0rem", marginTop:"1rem"}}
									onClick={(e) => onallArtikelRabattierbarClick(e)}
								/>
					</Dialog>


					<Dialog 
						header={`Bei ${produkteVKzuNiederig.length} Produkte ist der Verkaufspreis oder der Deckungsbeitrag zu niedrig`} 
						visible={produkteVKDBzuNiedrigChange} 
						style={{ width: isLargeScreen ? '40vw' : '90vw' }} 
						footer={
							<div>
								<Button label="Abbrechen" icon="pi pi-times" onClick={e => {showProdukteVKDBzuNiedrigChange(false)}} />
								<Button label="Speichern" icon="pi pi-save" onClick={e => {showProdukteVKDBzuNiedrigChange(false)}} />
							</div>
						}
						onHide={e => {showProdukteVKDBzuNiedrigChange(false)}}
						>
							<DataTable value={produkteVKzuNiederig.filter(product => product.Rabattartikel === true)} scrollHeight="300px" scrollable  style={{maxWidth:"60rem"}}>
								<Column field="Bezeichnung" header="Produktbezeichnung" />
								<Column 
									field="AlterVK" 
									header="VK" 
									style={{ textAlign: 'right', width:"4rem", whiteSpace: 'nowrap'  }} 
									body={(rowData) => 
										rowData.AlterVK && !isNaN(rowData.AlterVK) 
											? `${rowData.AlterVK.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
											: '0,00'
									} 
								/>							
								<Column 
									field="NeuerVK" 
									header="rabattierter VK" 
									style={{ textAlign: 'right', minwidth:"20rem", whiteSpace: 'nowrap'  }} 
									body={(rowData) => 
										rowData.NeuerVK && !isNaN(rowData.NeuerVK) 
											? `${rowData.NeuerVK.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
											: '0,00'
									} 
								/>
								<Column 
									field="DB" 
									header="DB" 
									style={{ textAlign: 'right', width:"3rem", whiteSpace: 'nowrap'  }} 
									body={(rowData) => 
										rowData.AlterDB && !isNaN(rowData.AlterDB) 
											? `${rowData.AlterDB.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
											: '0,00'
									} 
								/>
								<Column 
									field="neuerDB" 
									header="rabattierter DB" 
									style={{ textAlign: 'right', width:"3rem", whiteSpace: 'nowrap'   }} 
									body={(rowData) => 
										rowData.NeuerDB && !isNaN(rowData.NeuerDB) 
											? `${rowData.NeuerDB.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €` 
											: '0,00'
									} 
								/>
								<Column 
									field="Rabattartikel" 
									header="Artikel rabattieren" 
									style={{ textAlign: 'right', width:"3rem"  }} 
									body={(rowData) => 
										<Checkbox 
											checked={rowData.Artikelrabattieren} 
											onChange={(e) => {handleCheckboxChangeArtikel(e, rowData)
											}} 
										/>
									} 
								/>
							</DataTable>
							<Button
								icon="pi pi-times"
								label="alle Artikel abwählen"
								style={{marginTop:"1rem"}}
								onClick={(e) => onallArtikelEntfernenClick(e)}
								/>
							<Button
								icon="pi pi-check"
								label="alle Artikel auswählen"
								style={{marginLeft: isLargeScreen ? "1rem" : "0rem", marginTop:"1rem"}}
								onClick={(e) => onallArtikelHinzufügenClick(e)}
								/>
					</Dialog>



					<Dialog 
						header={`Fehler: Überschneidung mit anderere Aktion`} 
						visible={showAlreadyAktion} 
						style={{ width: isLargeScreen ? '40vw' : '90vw' }} 
						footer={
							<div>
								<Button label="Abbrechen" icon="pi pi-times" onClick={e => {setShowAlreadyAktion(false)}} />
							</div>
						}
						onHide={e => {setShowAlreadyAktion(false)}}
						>
							<DataTable 
								value={überlappungsdata} 
								scrollHeight="300px" 
								scrollable  
								style={{maxWidth:"60rem"}}>
									
								<Column 
									field="aktionsID" 
									header="AktionsID" 
									style={{ textAlign: 'left', width:"4rem", whiteSpace: 'nowrap'  }} 
									body={(rowData) => rowData.aktionsID}
									hidden={newAktion?.Serie}
									/>

								<Column 
									field="item1" 
									header="Datum" 
									body={(rowData) => {
										const date = new Date(rowData.item1);
										if (isNaN(date.getTime())) {
											return newAktion?.zeitraumStart.split(",")[0] + " bis " + newAktion?.zeitraumEnde.split(",")[0];
										}

										const day = String(date.getDate()).padStart(2, '0');
										const month = String(date.getMonth() + 1).padStart(2, '0');
										const year = date.getFullYear();
										return `${day}.${month}.${year}`;
									}} 
									hidden={newAktion?.Serie}
									/>

								<Column 
									field="item2" 
									header="Bezeichnung" 
									style={{ textAlign: 'right', width:"4rem", whiteSpace: 'nowrap'  }} 
									body={(rowData) => rowData.item2 ?? rowData.bezeichnung}
									hidden={newAktion?.Serie}
									/>		
							</DataTable>
					</Dialog>
		</React.Fragment>
		
	);
}

export default AktionenNeu;